const allowedPrefixes = ['http://', 'https://', 'http://www.', 'https://www.', 'www.', ''];

export const isLinkedinUrl = (val: string) => {
  const linkedinUrlMiddlePart = 'linkedin.com/in/';
  const url = val.trim().toLowerCase();
  const parts = url.split(linkedinUrlMiddlePart);

  if (parts.length !== 2) {
    return false;
  }
  if (parts[1].length === 0) {
    return false;
  }

  if (parts[0].length > 0) {
    if (!allowedPrefixes.some(prefix => parts[0] === prefix)) {
      return false;
    }
  }

  return true;
};

export const extractDomain = (url: string) => {
  const trimmedUrl = url.trim().toLowerCase();

  const withoutPrefix = allowedPrefixes.reduce((result, prefix) => {
    if (result.startsWith(prefix)) {
      return result.slice(prefix.length);
    }
    return result;
  }, trimmedUrl);

  const domainMatch = withoutPrefix.match(/^[^/]+/);
  return domainMatch ? domainMatch[0] : null;
};

export const isValidDomain = (val: string) => {
  const domain = extractDomain(val);

  if (!domain) {
    return false;
  }

  const domainPattern = /^[a-z0-9.-]+\.[a-z]{2,}$/;
  return domainPattern.test(domain);
};
