import cs from 'classnames';
import {useImportConnectionsModal} from '../../../contexts';
import {Avatar} from '../../avatar';
import {Button} from '../../button';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './UserMenu.module.scss';

type Props = {
  email: string;
  fullName: string;
  closeDropdown: () => void;
};

export const MenuHeader = ({email, fullName, closeDropdown}: Props) => {
  const {showImportModal} = useImportConnectionsModal();

  const handleAddConnections = () => {
    closeDropdown();
    showImportModal();
  };

  return (
    <div className={Styles.menuHeader}>
      <div className={cs(Styles.userLabel, Styles.userLabelHeader)}>
        <Avatar fullname={fullName} size="md" />
        <div className={Styles.userDetails}>
          <TruncateWithTooltip content={fullName} placement="right-center">
            {fullName}
          </TruncateWithTooltip>
          <TruncateWithTooltip content={email} placement="right-center" contentClassName={Styles.userEmail}>
            {email}
          </TruncateWithTooltip>
        </div>
      </div>
      <Button onClick={handleAddConnections} variant="black" size="sm" fullWidth>
        Add connections
      </Button>
    </div>
  );
};
