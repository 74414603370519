import {ColumnDef} from '@tanstack/react-table';
import cs from 'classnames';
import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Avatar} from '../../../components/avatar';
import {FavoriteButton} from '../../../components/favorite-button';
import {PermissionChecker} from '../../../components/permission';
import {PipelineEditModal, usePipelineFormModal} from '../../../components/pipeline-form';
import {PrivateLabel} from '../../../components/private-label';
import {Table, TableInnerHeader, useSwarmTable, UseSwarmTableProps} from '../../../components/table';
import {TruncateWithTooltip} from '../../../components/truncate-with-tooltip';
import {usePremiumFeatureCallback} from '../../../hooks';
import {GetPipelinesResponseItem, Permission} from '../../../types';
import {capitalizeString, getRelativeTimeToNow, getUserFullName} from '../../../utils';
import {DotsMenu} from '../components';
import Styles from './PipelinesList.module.scss';

type PipelineTableProps = {
  refetchPipelines: () => Promise<unknown>;
  pipelines: GetPipelinesResponseItem[];
  sorting: Required<UseSwarmTableProps>['sorting'];
};

export const PipelineTable = ({refetchPipelines, pipelines, sorting}: PipelineTableProps) => {
  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible, pipelineId} =
    usePipelineFormModal();
  const navigate = useNavigate();

  const goToPipeline = useCallback((id: string) => navigate(`/lists/${id}`), [navigate]);
  const goToPipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: goToPipeline,
    permission: Permission.Pipeline,
  });

  const columns = useMemo<ColumnDef<GetPipelinesResponseItem>[]>(
    () => [
      {
        accessorKey: 'title',
        header: () => <TableInnerHeader label="Name" />,
        size: 420,
        cell: info => {
          const {
            id,
            title,
            creator: {id: creatorId},
            private: privatePipeline,
          } = info.row.original.pipeline;
          return (
            <div className={Styles.pipelineTitleCell}>
              <TruncateWithTooltip content={title}>
                <span className={Styles.pipelineTitle} onClick={() => goToPipelineAsPremiumFeature(id)}>
                  {title}
                </span>
                {privatePipeline && (
                  <PrivateLabel className="ml-2" dataIntercomTarget="badge-private-pipeline" />
                )}
                <FavoriteButton listId={id} className={cs('ml-2')} />
              </TruncateWithTooltip>
              <PermissionChecker permission={Permission.Pipeline}>
                <DotsMenu
                  pipelineId={id}
                  pipelineTitle={title}
                  pipelineCreatorId={creatorId}
                  switchToEdit={() => openPipelineFormModal(id)}
                  circularTrigger
                  refetchPipelines={refetchPipelines}
                />
              </PermissionChecker>
            </div>
          );
        },
      },
      {
        accessorKey: 'pipelineType',
        header: () => <TableInnerHeader label="Type" />,
        cell: info => {
          const {pipelineType} = info.row.original.pipeline;
          return capitalizeString(pipelineType);
        },
      },
      {
        accessorKey: 'profilesCount',
        header: () => <TableInnerHeader label="Connections" />,
        cell: info => {
          const {profilesCount} = info.row.original;
          return <span className={Styles.connectionsNumber}>{profilesCount}</span>;
        },
      },
      {
        accessorKey: 'createdAt',
        header: () => <TableInnerHeader label="Created date" />,
        size: 150,
        cell: info => {
          const {createdAt} = info.row.original.pipeline;

          const lastActivityDate = new Date(createdAt);
          return getRelativeTimeToNow(lastActivityDate);
        },
      },
      {
        accessorKey: 'updatedAt',
        header: () => <TableInnerHeader label="Last status change" />,
        size: 150,
        cell: info => {
          const {updatedAt} = info.row.original.pipeline;

          const lastActivityDate = new Date(updatedAt);
          return getRelativeTimeToNow(lastActivityDate);
        },
      },
      {
        accessorKey: 'creator',
        header: () => <TableInnerHeader label="Creator" />,
        size: 220,
        cell: info => {
          const {creator} = info.row.original.pipeline;
          const fullName = getUserFullName(creator);
          return (
            <div>
              <Avatar size="sm" fullname={fullName} />
              <span className="ml-2">{fullName}</span>
            </div>
          );
        },
      },
    ],
    [refetchPipelines, goToPipelineAsPremiumFeature, openPipelineFormModal]
  );

  const table = useSwarmTable<GetPipelinesResponseItem>({
    uniqueName: 'pipeline-list',
    rows: pipelines,
    isLoaded: true,
    selectable: false,
    columns,
    defaultColumnSize: 112,
    defaultColumnMinSize: 112,
    sorting,
  });

  return (
    <>
      <Table
        headerGroups={table.getHeaderGroups()}
        rowModel={table.getRowModel()}
        totalWidth={table.getTotalSize()}
        cellHeight={60}
        noVerticalSeparators
      />
      {pipelineId && (
        <PermissionChecker permission={Permission.Pipeline}>
          <PipelineEditModal
            visible={pipelineFormModalVisible}
            onClose={closePipelineFormModal}
            pipelineId={pipelineId}
          />
        </PermissionChecker>
      )}
    </>
  );
};
