import {Button} from '../../button';
import {useBlockOverlaps} from './useBlockOverlaps';

type Props = {
  goWorkOverlaps: () => unknown;
};
export const AddWorkOverlapsButton = ({goWorkOverlaps}: Props) => {
  const {overlapsBlocked} = useBlockOverlaps();
  return (
    <Button onClick={goWorkOverlaps} variant="primary" className="w-[140px]" disabled={overlapsBlocked}>
      {overlapsBlocked ? 'Added' : 'Add'}
    </Button>
  );
};
