import {OptionGroupType, OptionType} from './types';

type PropsType = {
  options: OptionType[] | OptionGroupType[];
  placeholder?: string;
};
export const Options = ({options, placeholder}: PropsType) => (
  <>
    {placeholder && <Option key="placeholder" value="" text={placeholder} />}
    {options.map(opt => {
      if ('options' in opt) {
        return <OptionGroup key={opt.label} {...opt} />;
      }
      opt = opt as OptionType;
      return <Option key={opt.value} {...opt} />;
    })}
  </>
);

const OptionGroup = ({label, options}: OptionGroupType) => (
  <optgroup label={label}>
    {options.map(opt => (
      <Option key={opt.value} {...opt} />
    ))}
  </optgroup>
);

const Option = ({text, ...option}: OptionType) => (
  <option key={option.value} {...option}>
    {text}
  </option>
);
