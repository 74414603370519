import {Helmet} from 'react-helmet';
import {setCookie} from 'typescript-cookie';
import {Button} from '../button';
import Styles from './MobileBlocker.module.scss';
import {BLOCKER_OFF_COOKIE_EXPIRATION_DAYS, MOBILE_BLOCKER_OFF_COOKIE} from './consts';

const continueToApp = () => {
  setCookie(MOBILE_BLOCKER_OFF_COOKIE, 'true', {expires: BLOCKER_OFF_COOKIE_EXPIRATION_DAYS});
  window.location.reload();
};
export const MobileBlocker = () => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <div className={Styles.mobileBlocker}>
        <div className={Styles.mobileBlockerContent}>
          <img className={Styles.logo} src="/images/logo-dark.png" alt="The Swarm" />
          <div>
            <img className={Styles.mobileIllustration} src="/images/mobile-blocker.png" alt="The Swarm" />
            <h2>The Swarm is currently not optimized on mobile.</h2>
            <p>Please switch to desktop.</p>
            <Button variant="primary" className={Styles.offButton} onClick={continueToApp}>
              Show me anyway
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
