import {useEffect} from 'react';
import {Role} from '../../../types';
import {capitalizeString, segmentTrack} from '../../../utils';
import {RoleIcon} from '../../icons';
import {AddMembersForm} from '../add-members-form';
import Styles from './AddMembersModal.module.scss';
import {RolePermissionsDescription} from './RolePermissionsDescription';

type Props = {
  role: Role;
  goBack: () => void;
  onSuccess: () => void;
};
export const InviteMembersStep = ({role, goBack, onSuccess}: Props) => {
  useEffect(() => {
    segmentTrack('Modal Viewed', {
      label: `invite ${role}`,
      location: 'add members modal',
    });
  }, [role]);

  return (
    <>
      <h4 className={Styles.header}>
        <RoleIcon role={role} className={Styles.headerIcon} />
        Invite {capitalizeString(role)}
      </h4>

      <hr className={Styles.divider} />

      <AddMembersForm onSuccess={onSuccess} onCancel={goBack} role={role}>
        <RolePermissionsDescription role={role} />
      </AddMembersForm>
    </>
  );
};
