import cs from 'classnames';
import {ReactNode} from 'react';
import {List} from './List';
import Styles from './List.module.scss';

export type DataListItem = {
  label: string;
  value: ReactNode;
};

type Props = {
  headerTitle?: string;
  headerIcon?: ReactNode;
  itemClassName?: string;
  className?: string;
  items: DataListItem[];
  orientation?: 'inline' | 'stacked';
  titleWidth?: string; // valid CSS value with unit
  hideEmptyItems?: boolean;
  itemsExcludedFromHiding?: DataListItem['label'][];
  emptyItemsPlaceholder?: string;
  initialAmount?: number;
  cols?: 2 | 3 | 4 | 5;
  bottomBorder?: boolean;
};

export const DataList = ({
  items = [],
  orientation = 'inline',
  className,
  itemClassName,
  headerTitle,
  headerIcon,
  titleWidth,
  hideEmptyItems,
  itemsExcludedFromHiding = [],
  emptyItemsPlaceholder = '',
  initialAmount,
  cols,
  bottomBorder,
}: Props) => {
  const titleWidthStyle = titleWidth ? {width: titleWidth} : undefined;

  const filteredItems = items.filter(
    ({label, value}) => !hideEmptyItems || !!value || itemsExcludedFromHiding.includes(label)
  );

  if (!filteredItems.length) {
    return null;
  }

  return (
    <List
      className={className}
      headerTitle={headerTitle}
      headerIcon={headerIcon}
      itemClassName={cs(
        orientation === 'stacked' ? Styles.stackedListItem : Styles.inlineListItem,
        itemClassName
      )}
      items={filteredItems.map(({label, value}) => (
        <>
          <span
            className={cs(
              Styles.dataListItemTitle,
              orientation === 'stacked' ? Styles.stackedDataListItemTitle : Styles.inlineDataListItemTitle
            )}
            style={titleWidthStyle}
          >
            {label}
          </span>
          <span className={cs({[Styles.emptyItem]: !value})}>{value || emptyItemsPlaceholder}</span>
        </>
      ))}
      initialAmount={initialAmount}
      cols={cols}
      bottomBorder={bottomBorder}
    />
  );
};
