import {ProfilesTable} from '../../../components/profiles-table';
import {BffFilterOptions} from '../../../types';
import {UsePipelinesSavedSearchTableResult} from './usePipelinesSavedSearchTable';

type PipelinesSavedSearchTableProps = {
  filters: BffFilterOptions;
  tableData: UsePipelinesSavedSearchTableResult;
  onChangeSuccess: () => void;
  totalCount: number;
  pipelineId: string;
  pipelineName: string;
};

export const PipelinesSavedSearchTable = ({
  filters,
  tableData,
  onChangeSuccess,
  totalCount,
  pipelineId,
  pipelineName,
}: PipelinesSavedSearchTableProps) => (
  <ProfilesTable
    filters={filters}
    tableData={tableData}
    totalCount={totalCount}
    onChangeSuccess={onChangeSuccess}
    bulkActionsConfig={{
      addToParticularPipeline: {pipelineId, pipelineName},
    }}
  />
);
