import cs from 'classnames';
import {ReactNode} from 'react';
import {FiX as UnassignIcon} from 'react-icons/fi';
import {usePremiumFeatureCallback} from '../../../hooks';
import {Permission, TagColors} from '../../../types';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './Tag.module.scss';

type Props = {
  color?: TagColors;
  content?: ReactNode;
  unassign?: () => void;
  className?: string;
};

export const Tag = ({color = TagColors.Gray, content, unassign, className}: Props) => {
  const handleUnassign = usePremiumFeatureCallback({
    permission: Permission.TagAssign,
    callback: unassign,
  });

  return (
    <span className={cs(Styles.tag, Styles[`tag${color}`], className)}>
      <span className={Styles.contentContainer}>
        <TruncateWithTooltip content={content}>{content}</TruncateWithTooltip>
      </span>
      {unassign && (
        <span className={Styles.unassignIconContainer}>
          <UnassignIcon onClick={handleUnassign} className={Styles.unassignIcon} />
        </span>
      )}
    </span>
  );
};
