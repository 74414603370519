import {Range} from '../types';
export const parseRange = (rangeString: string): Range => {
  const [min, max] = rangeString.split('-').map(s => parseInt(s || '0', 10));
  return {min, max};
};

export const formatRange = (range: Range) => {
  if (range.max === 0) {
    const min = range.min === 0 ? 0 : range.min - 1;
    const minSize = min.toLocaleString('en-US');
    return `${minSize}+`;
  }

  const minSize = range.min.toLocaleString('en-US');
  const maxSize = range.max.toLocaleString('en-US');
  return `${minSize} - ${maxSize}`;
};
