import {AxiosResponse} from 'axios';
import {useQuery} from 'react-query';
import {
  CreateNoteRequest,
  EditNoteRequest,
  GetNotesCountRequest,
  GetNotesCountResponse,
  GetNotesRequest,
  GetNotesResponse,
  NoteResponse,
} from '../types';
import {apiClient} from './apiClient';

const getNotes = (params: GetNotesRequest) =>
  apiClient.get<GetNotesResponse>('/notes', {params}).then(res => res.data);

export const useQueryNotes = (params: GetNotesRequest) => useQuery(['notes', params], () => getNotes(params));

export const createNote = (noteProps: CreateNoteRequest): Promise<NoteResponse> =>
  apiClient
    .post<NoteResponse, AxiosResponse<NoteResponse>, CreateNoteRequest>('/notes', noteProps)
    .then(res => res.data);

export const editNote = (content: string, noteId: string): Promise<NoteResponse> =>
  apiClient
    .put<NoteResponse, AxiosResponse<NoteResponse>, EditNoteRequest>(`/notes/${noteId}`, {
      content,
    })
    .then(res => res.data);

export const deleteNote = (noteId: string) =>
  apiClient.delete<void>(`/notes/${noteId}`).then(res => res.data);

export const getNotesCount = (params: GetNotesCountRequest) =>
  apiClient.get<GetNotesCountResponse>('/notes/count', {params}).then(res => res.data);

export const useQueryNotesCount = (params: GetNotesCountRequest) =>
  useQuery(['notesCount', params], () => getNotesCount(params));
