/*
 * Generated type guards for "bffTypes.ts".
 * WARNING: Do not manually change this file.
 */
import {SocialMediaEnum, PipelineStatus} from './api';
import {TagColors} from './tag';
import {Crm} from './crm';
import {CompanyResponse, GetProfileResponse, GetProfilesListResponse, ProfilesListRow} from './bffTypes';

export function isGetCompanyResponse(obj: unknown): obj is CompanyResponse {
  const typedObj = obj as CompanyResponse;
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    typeof typedObj['name'] === 'string' &&
    (typeof typedObj['industry'] === 'undefined' || typeof typedObj['industry'] === 'string') &&
    (typeof typedObj['website'] === 'undefined' || typeof typedObj['website'] === 'string') &&
    (typeof typedObj['size'] === 'undefined' ||
      (((typedObj['size'] !== null && typeof typedObj['size'] === 'object') ||
        typeof typedObj['size'] === 'function') &&
        typeof typedObj['size']['min'] === 'number' &&
        typeof typedObj['size']['max'] === 'number')) &&
    (typeof typedObj['networks'] === 'undefined' ||
      (Array.isArray(typedObj['networks']) &&
        typedObj['networks'].every(
          (e: any) =>
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            (e['network'] === SocialMediaEnum.aboutme ||
              e['network'] === SocialMediaEnum.angellist ||
              e['network'] === SocialMediaEnum.behance ||
              e['network'] === SocialMediaEnum.crunchbase ||
              e['network'] === SocialMediaEnum.dribbble ||
              e['network'] === SocialMediaEnum.ello ||
              e['network'] === SocialMediaEnum.facebook ||
              e['network'] === SocialMediaEnum.flickr ||
              e['network'] === SocialMediaEnum.foursquare ||
              e['network'] === SocialMediaEnum.github ||
              e['network'] === SocialMediaEnum.gitlab ||
              e['network'] === SocialMediaEnum.google ||
              e['network'] === SocialMediaEnum.gravatar ||
              e['network'] === SocialMediaEnum.indeed ||
              e['network'] === SocialMediaEnum.instagram ||
              e['network'] === SocialMediaEnum.klout ||
              e['network'] === SocialMediaEnum.linkedin ||
              e['network'] === SocialMediaEnum.meetup ||
              e['network'] === SocialMediaEnum.myspace ||
              e['network'] === SocialMediaEnum.pinterest ||
              e['network'] === SocialMediaEnum.quora ||
              e['network'] === SocialMediaEnum.reddit ||
              e['network'] === SocialMediaEnum.soundcloud ||
              e['network'] === SocialMediaEnum.stackoverflow ||
              e['network'] === SocialMediaEnum.twitter ||
              e['network'] === SocialMediaEnum.vimeo ||
              e['network'] === SocialMediaEnum.wordpress ||
              e['network'] === SocialMediaEnum.xing ||
              e['network'] === SocialMediaEnum.youtube) &&
            (typeof e['url'] === 'undefined' || typeof e['url'] === 'string')
        ))) &&
    (typeof typedObj['locationName'] === 'undefined' || typeof typedObj['locationName'] === 'string') &&
    (typeof typedObj['logoURL'] === 'undefined' || typeof typedObj['logoURL'] === 'string') &&
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    Array.isArray(typedObj['tags']) &&
    typedObj['tags'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        typeof e['name'] === 'string' &&
        (e['color'] === TagColors.Gray ||
          e['color'] === TagColors.Blue ||
          e['color'] === TagColors.Turquoise ||
          e['color'] === TagColors.Green ||
          e['color'] === TagColors.Yellow ||
          e['color'] === TagColors.Orange ||
          e['color'] === TagColors.Peach ||
          e['color'] === TagColors.Pink ||
          e['color'] === TagColors.Violet)
    ) &&
    Array.isArray(typedObj['crms']) &&
    typedObj['crms'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['integrationId'] === 'string' &&
        (e['crm'] === Crm.hubspot || e['crm'] === Crm.salesforce || e['crm'] === Crm.affinity)
    )
  );
}

export function isGetProfileResponse(obj: unknown): obj is GetProfileResponse {
  const typedObj = obj as GetProfileResponse;
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    ((typedObj['info'] !== null && typeof typedObj['info'] === 'object') ||
      typeof typedObj['info'] === 'function') &&
    typeof typedObj['info']['fullName'] === 'string' &&
    typeof typedObj['info']['firstName'] === 'string' &&
    typeof typedObj['info']['lastName'] === 'string' &&
    typeof typedObj['info']['linkedinUrl'] === 'string' &&
    (typeof typedObj['info']['workEmail'] === 'undefined' ||
      typeof typedObj['info']['workEmail'] === 'string') &&
    (typeof typedObj['info']['personalEmails'] === 'undefined' ||
      (Array.isArray(typedObj['info']['personalEmails']) &&
        typedObj['info']['personalEmails'].every((e: any) => typeof e === 'string'))) &&
    (typeof typedObj['info']['industry'] === 'undefined' ||
      typeof typedObj['info']['industry'] === 'string') &&
    (typeof typedObj['info']['interests'] === 'undefined' ||
      (Array.isArray(typedObj['info']['interests']) &&
        typedObj['info']['interests'].every((e: any) => typeof e === 'string'))) &&
    (typeof typedObj['info']['skills'] === 'undefined' ||
      (Array.isArray(typedObj['info']['skills']) &&
        typedObj['info']['skills'].every((e: any) => typeof e === 'string'))) &&
    (typeof typedObj['info']['profiles'] === 'undefined' ||
      (Array.isArray(typedObj['info']['profiles']) &&
        typedObj['info']['profiles'].every(
          (e: any) =>
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            (e['network'] === SocialMediaEnum.aboutme ||
              e['network'] === SocialMediaEnum.angellist ||
              e['network'] === SocialMediaEnum.behance ||
              e['network'] === SocialMediaEnum.crunchbase ||
              e['network'] === SocialMediaEnum.dribbble ||
              e['network'] === SocialMediaEnum.ello ||
              e['network'] === SocialMediaEnum.facebook ||
              e['network'] === SocialMediaEnum.flickr ||
              e['network'] === SocialMediaEnum.foursquare ||
              e['network'] === SocialMediaEnum.github ||
              e['network'] === SocialMediaEnum.gitlab ||
              e['network'] === SocialMediaEnum.google ||
              e['network'] === SocialMediaEnum.gravatar ||
              e['network'] === SocialMediaEnum.indeed ||
              e['network'] === SocialMediaEnum.instagram ||
              e['network'] === SocialMediaEnum.klout ||
              e['network'] === SocialMediaEnum.linkedin ||
              e['network'] === SocialMediaEnum.meetup ||
              e['network'] === SocialMediaEnum.myspace ||
              e['network'] === SocialMediaEnum.pinterest ||
              e['network'] === SocialMediaEnum.quora ||
              e['network'] === SocialMediaEnum.reddit ||
              e['network'] === SocialMediaEnum.soundcloud ||
              e['network'] === SocialMediaEnum.stackoverflow ||
              e['network'] === SocialMediaEnum.twitter ||
              e['network'] === SocialMediaEnum.vimeo ||
              e['network'] === SocialMediaEnum.wordpress ||
              e['network'] === SocialMediaEnum.xing ||
              e['network'] === SocialMediaEnum.youtube) &&
            (typeof e['url'] === 'undefined' || typeof e['url'] === 'string')
        ))) &&
    typeof typedObj['info']['updatedAt'] === 'string' &&
    (typeof typedObj['info']['locationName'] === 'undefined' ||
      typeof typedObj['info']['locationName'] === 'string') &&
    Array.isArray(typedObj['experience']) &&
    typedObj['experience'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        (typeof e['company'] === 'undefined' ||
          (((e['company'] !== null && typeof e['company'] === 'object') ||
            typeof e['company'] === 'function') &&
            (typeof e['company']['id'] === 'undefined' || typeof e['company']['id'] === 'string') &&
            typeof e['company']['name'] === 'string' &&
            (typeof e['company']['logo'] === 'undefined' || typeof e['company']['logo'] === 'string') &&
            (typeof e['company']['locationName'] === 'undefined' ||
              typeof e['company']['locationName'] === 'string'))) &&
        typeof e['title'] === 'string' &&
        typeof e['startDate'] === 'string' &&
        (typeof e['endDate'] === 'undefined' || typeof e['endDate'] === 'string')
    ) &&
    (typeof typedObj['currentJob'] === 'undefined' ||
      (((typedObj['currentJob'] !== null && typeof typedObj['currentJob'] === 'object') ||
        typeof typedObj['currentJob'] === 'function') &&
        (typeof typedObj['currentJob']['title'] === 'undefined' ||
          typeof typedObj['currentJob']['title'] === 'string') &&
        (typeof typedObj['currentJob']['titleRole'] === 'undefined' ||
          typeof typedObj['currentJob']['titleRole'] === 'string') &&
        (typeof typedObj['currentJob']['titleLevels'] === 'undefined' ||
          (Array.isArray(typedObj['currentJob']['titleLevels']) &&
            typedObj['currentJob']['titleLevels'].every((e: any) => typeof e === 'string'))) &&
        (typeof typedObj['currentJob']['companyId'] === 'undefined' ||
          typeof typedObj['currentJob']['companyId'] === 'string') &&
        (typeof typedObj['currentJob']['companyName'] === 'undefined' ||
          typeof typedObj['currentJob']['companyName'] === 'string') &&
        (typeof typedObj['currentJob']['companyLogoUrl'] === 'undefined' ||
          typeof typedObj['currentJob']['companyLogoUrl'] === 'string') &&
        (typeof typedObj['currentJob']['companyIndustry'] === 'undefined' ||
          typeof typedObj['currentJob']['companyIndustry'] === 'string') &&
        (typeof typedObj['currentJob']['companyLocationName'] === 'undefined' ||
          typeof typedObj['currentJob']['companyLocationName'] === 'string'))) &&
    Array.isArray(typedObj['education']) &&
    typedObj['education'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        (typeof e['school'] === 'undefined' ||
          (((e['school'] !== null && typeof e['school'] === 'object') || typeof e['school'] === 'function') &&
            (typeof e['school']['name'] === 'undefined' || typeof e['school']['name'] === 'string') &&
            (typeof e['school']['website'] === 'undefined' || typeof e['school']['website'] === 'string'))) &&
        (typeof e['startDate'] === 'undefined' || typeof e['startDate'] === 'string') &&
        (typeof e['endDate'] === 'undefined' || typeof e['endDate'] === 'string') &&
        (typeof e['degrees'] === 'undefined' ||
          (Array.isArray(e['degrees']) && e['degrees'].every((e: any) => typeof e === 'string'))) &&
        (typeof e['majors'] === 'undefined' ||
          (Array.isArray(e['majors']) && e['majors'].every((e: any) => typeof e === 'string')))
    ) &&
    Array.isArray(typedObj['pipelines']) &&
    typedObj['pipelines'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        typeof e['name'] === 'string' &&
        (e['status'] === PipelineStatus.suggested ||
          e['status'] === PipelineStatus.introRequested ||
          e['status'] === PipelineStatus.inContact ||
          e['status'] === PipelineStatus.meetingSet ||
          e['status'] === PipelineStatus.removed) &&
        Array.isArray(e['suggestedBy']) &&
        e['suggestedBy'].every(
          (e: any) =>
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            typeof e['id'] === 'string' &&
            typeof e['firstName'] === 'string' &&
            typeof e['lastName'] === 'string' &&
            typeof e['email'] === 'string' &&
            (typeof e['isAnonymized'] === 'undefined' ||
              e['isAnonymized'] === false ||
              e['isAnonymized'] === true) &&
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            typeof e['linkedinUrl'] === 'string'
        ) &&
        typeof e['private'] === 'boolean'
    ) &&
    Array.isArray(typedObj['connections']) &&
    typedObj['connections'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        ((e['user'] !== null && typeof e['user'] === 'object') || typeof e['user'] === 'function') &&
        typeof e['user']['id'] === 'string' &&
        typeof e['user']['firstName'] === 'string' &&
        typeof e['user']['lastName'] === 'string' &&
        typeof e['user']['email'] === 'string' &&
        (typeof e['user']['isAnonymized'] === 'undefined' ||
          e['user']['isAnonymized'] === false ||
          e['user']['isAnonymized'] === true) &&
        ((e['user'] !== null && typeof e['user'] === 'object') || typeof e['user'] === 'function') &&
        typeof e['user']['linkedinUrl'] === 'string' &&
        Array.isArray(e['sources']) &&
        e['sources'].every(
          (e: any) =>
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            typeof e['origin'] === 'string' &&
            typeof e['network'] === 'string'
        ) &&
        (e['strength'] === 0 || e['strength'] === 1 || e['strength'] === 2 || e['strength'] === 3)
    ) &&
    Array.isArray(typedObj['crms']) &&
    typedObj['crms'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['integrationId'] === 'string' &&
        (e['crm'] === Crm.hubspot || e['crm'] === Crm.salesforce || e['crm'] === Crm.affinity)
    ) &&
    Array.isArray(typedObj['tags']) &&
    typedObj['tags'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        typeof e['name'] === 'string' &&
        (e['color'] === TagColors.Gray ||
          e['color'] === TagColors.Blue ||
          e['color'] === TagColors.Turquoise ||
          e['color'] === TagColors.Green ||
          e['color'] === TagColors.Yellow ||
          e['color'] === TagColors.Orange ||
          e['color'] === TagColors.Peach ||
          e['color'] === TagColors.Pink ||
          e['color'] === TagColors.Violet)
    )
  );
}

export function isGetProfilesListResponse(obj: unknown): obj is GetProfilesListResponse {
  const typedObj = obj as GetProfilesListResponse;
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    Array.isArray(typedObj['items']) &&
    typedObj['items'].every((e: any) => isProfilesListRow(e) as boolean) &&
    ((typedObj['meta'] !== null && typeof typedObj['meta'] === 'object') ||
      typeof typedObj['meta'] === 'function') &&
    typeof typedObj['meta']['offset'] === 'number' &&
    typeof typedObj['meta']['limit'] === 'number' &&
    ((typedObj['meta'] !== null && typeof typedObj['meta'] === 'object') ||
      typeof typedObj['meta'] === 'function') &&
    typeof typedObj['meta']['totalCount'] === 'number'
  );
}

export function isProfilesListRow(obj: unknown): obj is ProfilesListRow {
  const typedObj = obj as ProfilesListRow;
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    typeof typedObj['fullName'] === 'string' &&
    typeof typedObj['linkedinUrl'] === 'string' &&
    typeof typedObj['location'] === 'string' &&
    (typeof typedObj['currentJob'] === 'undefined' ||
      (((typedObj['currentJob'] !== null && typeof typedObj['currentJob'] === 'object') ||
        typeof typedObj['currentJob'] === 'function') &&
        (typeof typedObj['currentJob']['title'] === 'undefined' ||
          typeof typedObj['currentJob']['title'] === 'string') &&
        (typeof typedObj['currentJob']['companyId'] === 'undefined' ||
          typeof typedObj['currentJob']['companyId'] === 'string') &&
        (typeof typedObj['currentJob']['companyName'] === 'undefined' ||
          typeof typedObj['currentJob']['companyName'] === 'string') &&
        (typeof typedObj['currentJob']['startDate'] === 'undefined' ||
          typeof typedObj['currentJob']['startDate'] === 'string') &&
        (typeof typedObj['currentJob']['endDate'] === 'undefined' ||
          typeof typedObj['currentJob']['endDate'] === 'string'))) &&
    Array.isArray(typedObj['pipelines']) &&
    typedObj['pipelines'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        typeof e['name'] === 'string' &&
        (e['status'] === PipelineStatus.suggested ||
          e['status'] === PipelineStatus.introRequested ||
          e['status'] === PipelineStatus.inContact ||
          e['status'] === PipelineStatus.meetingSet ||
          e['status'] === PipelineStatus.removed) &&
        Array.isArray(e['suggestedBy']) &&
        e['suggestedBy'].every(
          (e: any) =>
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            typeof e['id'] === 'string' &&
            typeof e['firstName'] === 'string' &&
            typeof e['lastName'] === 'string' &&
            typeof e['email'] === 'string' &&
            (typeof e['isAnonymized'] === 'undefined' ||
              e['isAnonymized'] === false ||
              e['isAnonymized'] === true) &&
            ((e !== null && typeof e === 'object') || typeof e === 'function') &&
            typeof e['linkedinUrl'] === 'string'
        ) &&
        typeof e['private'] === 'boolean'
    ) &&
    Array.isArray(typedObj['connections']) &&
    typedObj['connections'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        ((e['user'] !== null && typeof e['user'] === 'object') || typeof e['user'] === 'function') &&
        typeof e['user']['id'] === 'string' &&
        typeof e['user']['firstName'] === 'string' &&
        typeof e['user']['lastName'] === 'string' &&
        typeof e['user']['email'] === 'string' &&
        (typeof e['user']['isAnonymized'] === 'undefined' ||
          e['user']['isAnonymized'] === false ||
          e['user']['isAnonymized'] === true) &&
        (e['strength'] === 0 || e['strength'] === 1 || e['strength'] === 2 || e['strength'] === 3)
    ) &&
    Array.isArray(typedObj['tags']) &&
    typedObj['tags'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['id'] === 'string' &&
        typeof e['name'] === 'string' &&
        (e['color'] === TagColors.Gray ||
          e['color'] === TagColors.Blue ||
          e['color'] === TagColors.Turquoise ||
          e['color'] === TagColors.Green ||
          e['color'] === TagColors.Yellow ||
          e['color'] === TagColors.Orange ||
          e['color'] === TagColors.Peach ||
          e['color'] === TagColors.Pink ||
          e['color'] === TagColors.Violet)
    ) &&
    Array.isArray(typedObj['crms']) &&
    typedObj['crms'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['integrationId'] === 'string' &&
        (e['crm'] === Crm.hubspot || e['crm'] === Crm.salesforce || e['crm'] === Crm.affinity)
    )
  );
}
