import {ColumnDef} from '@tanstack/react-table';
import cs from 'classnames';
import {uniqBy} from 'lodash';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {Button} from '../../components/button';
import {CompanyLink} from '../../components/company-link';
import {CrmIcon} from '../../components/crm-icon';
import {LinkedinProfileLink} from '../../components/linkedin-profile-link';
import {PermissionChecker} from '../../components/permission';
import {
  AddToParticularPipelineButton,
  AddToPipelineButton,
  RemoveFromPipelineButton,
} from '../../components/pipelines-actions';
import {PremiumFeatureWrapper} from '../../components/premium-feature-wrapper';
import {ProfileLink} from '../../components/profile-link';
import {RequestIntroButton} from '../../components/request-intro';
import {MultiScore, ScoreDropdown} from '../../components/score';
import {StatusSwitch} from '../../components/status-switch';
import {PipelineSuggestionsIndicator} from '../../components/suggestions-indicator';
import {TableInnerHeader} from '../../components/table';
import {
  ConnectedMembersCellContent,
  EmailsCellContent,
  KeyRelationshipCellContent,
} from '../../components/table-cells-content';
import {ProfileTagsList, TagsTableCell} from '../../components/tags';
import {Tooltip} from '../../components/tooltip';
import {Permission, ProfilesListRow, TagSimple} from '../../types';
import {addHttpsToUrl, integrationNameMap} from '../../utils';
import {ProfilesBffColumns, profilesBffLabelsMap as labelsMap} from './profilesBffColumns';

type FullNameDeps = {
  pipelineId?: string;
  pipelineName?: string;
  onChangeSuccess?: () => void;
  openIntroModal?: (profileId: string) => void;
};

type AddToPipelineDeps = {
  pipelineId: string;
  onChangeSuccess?: () => void;
};

type StatusInPipelineDeps = {
  pipelineId: string;
  onChangeSuccess?: () => void;
};

type TagsDeps = {
  onAssignTag: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag: (profileIds: string[], tagId: string) => void;
};

type ConnectionStrengthDeps = {
  canConnectionStrength: boolean;
  onScoreChange: () => void;
  currentUserId: string;
};

type TeamConnectionStrengthDeps = {
  canConnectionStrength: boolean;
};

type KeyRelationshipDeps = {
  openIntroModal?: (profileId: string) => void;
};

type ProfilesBffColumnsGetter<T> = (deps: T) => ColumnDef<ProfilesListRow>;
type ProfilesBffColumnsGetterNoParameters = () => ColumnDef<ProfilesListRow>;

type ProfilesBffColumnsDefinitions = {
  [ProfilesBffColumns.FullName]: ProfilesBffColumnsGetter<FullNameDeps>;
  [ProfilesBffColumns.AddToPipeline]: ProfilesBffColumnsGetter<AddToPipelineDeps>;
  [ProfilesBffColumns.StatusInPipeline]: ProfilesBffColumnsGetter<StatusInPipelineDeps>;
  [ProfilesBffColumns.Tags]: ProfilesBffColumnsGetter<TagsDeps>;
  [ProfilesBffColumns.ConnectionsStrength]: ProfilesBffColumnsGetter<ConnectionStrengthDeps>;
  [ProfilesBffColumns.TeamConnectionsStrength]: ProfilesBffColumnsGetter<TeamConnectionStrengthDeps>;
  [ProfilesBffColumns.JobTitle]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.JobCompanyName]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.JobCompanyWebsite]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.LocationName]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.KeyRelationship]: ProfilesBffColumnsGetter<KeyRelationshipDeps>;
  [ProfilesBffColumns.ConnectedMembersCount]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.StartDate]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.EndDate]: ProfilesBffColumnsGetterNoParameters;
  [ProfilesBffColumns.Emails]: ProfilesBffColumnsGetterNoParameters;
};

export const profilesBffTableCellDefinitions: ProfilesBffColumnsDefinitions = {
  [ProfilesBffColumns.FullName]: deps => ({
    accessorKey: ProfilesBffColumns.FullName,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.FullName]} />,
    cell: info => {
      const {id: profileId, linkedinUrl, fullName, crms, pipelines = [], connections} = info.row.original;
      const connectedMembers = connections.map(connection => connection.user);
      const {pipelineId, pipelineName, onChangeSuccess, openIntroModal} = deps;

      return (
        <div>
          <ProfileLink profileId={profileId} profileName={fullName} />
          {linkedinUrl && <LinkedinProfileLink linkedinUrl={linkedinUrl} />}
          <PipelineSuggestionsIndicator pipelines={pipelines} showPipelineTitle={true} />
          <PermissionChecker permission={Permission.CRM}>
            <div className={cs('flex shrink-0 flex-row gap-1', {'pr-1.5': crms.length})}>
              {uniqBy(crms, integration => integration.crm).map(({crm}, index) => (
                <Tooltip content={`Synchronized with ${integrationNameMap[crm]} CRM`} key={index}>
                  <CrmIcon crm={crm} className="!size-3.5" />
                </Tooltip>
              ))}
            </div>
          </PermissionChecker>
          <div className="grow" />
          <div className="flex shrink-0 gap-1">
            {!pipelineId && (
              <AddToPipelineButton
                profileId={profileId}
                profilePipelines={pipelines}
                onSuccess={onChangeSuccess}
              >
                <Tooltip content="Add to list" hideHovered>
                  <Button
                    size="xs"
                    variant="tertiary"
                    outline
                    iconOnly
                    rounded
                    icon={<PlusIcon size={16} />}
                  />
                </Tooltip>
              </AddToPipelineButton>
            )}
            {openIntroModal && (
              <Tooltip content="Send intro request" hideHovered>
                <RequestIntroButton
                  inTable
                  shape="circle"
                  showOnHover
                  showModal={() => openIntroModal(profileId)}
                  connectors={connectedMembers}
                  profileId={profileId}
                />
              </Tooltip>
            )}
            {pipelineId && pipelineName && (
              <Tooltip content="Remove from list" hideHovered>
                <RemoveFromPipelineButton
                  pipelineId={pipelineId}
                  pipelineName={pipelineName}
                  profileId={profileId}
                  onSuccess={onChangeSuccess}
                  inTable
                />
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
    size: 260,
  }),

  [ProfilesBffColumns.AddToPipeline]: deps => ({
    accessorKey: ProfilesBffColumns.AddToPipeline,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.AddToPipeline]} />,
    enableSorting: false,
    cell: info => {
      const {pipelineId, onChangeSuccess} = deps;
      const {id: profileId} = info.row.original;
      return pipelineId ? (
        <AddToParticularPipelineButton
          pipelineId={pipelineId}
          profileId={profileId}
          onSuccess={onChangeSuccess}
        />
      ) : null;
    },
    size: 44,
    minSize: 44,
  }),

  [ProfilesBffColumns.StatusInPipeline]: (deps: StatusInPipelineDeps) => ({
    accessorKey: ProfilesBffColumns.StatusInPipeline,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.StatusInPipeline]} />,
    cell: info => {
      const {pipelineId, onChangeSuccess} = deps;
      const {id: profileId, pipelines} = info.row.original;
      const status = pipelines.find(pipeline => pipeline.id === pipelineId)?.status;
      return status && pipelineId ? (
        <StatusSwitch
          currentStatus={status}
          profileId={profileId}
          pipelineId={pipelineId}
          onChangeSuccess={onChangeSuccess}
        />
      ) : null;
    },
    size: 150,
    minSize: 150,
  }),

  [ProfilesBffColumns.JobTitle]: () => ({
    accessorKey: ProfilesBffColumns.JobTitle,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobTitle]} />,
    cell: info => info.row.original.currentJob?.title,
    meta: {
      truncate: true,
    },
    size: 240,
  }),

  [ProfilesBffColumns.JobCompanyName]: () => ({
    accessorKey: ProfilesBffColumns.JobCompanyName,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobCompanyName]} />,
    cell: info => {
      const {currentJob} = info.row.original;
      const {companyId, companyName} = currentJob || {};
      return (
        <CompanyLink
          companyName={companyName || ''}
          companyId={companyId}
          dataIntercomTarget="company-link"
        />
      );
    },
    size: 240,
  }),

  [ProfilesBffColumns.JobCompanyWebsite]: () => ({
    accessorKey: ProfilesBffColumns.JobCompanyWebsite,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobCompanyWebsite]} />,
    cell: info => {
      const {currentJob} = info.row.original;
      const {companyWebsite} = currentJob || {};

      if (!companyWebsite) {
        return '-';
      }

      return (
        <a
          href={addHttpsToUrl(companyWebsite)}
          target="_blank"
          rel="nofollow noreferrer"
          className="text-swarm-black hover:underline"
        >
          {companyWebsite}
        </a>
      );
    },
    size: 240,
  }),

  [ProfilesBffColumns.LocationName]: () => ({
    accessorKey: ProfilesBffColumns.LocationName,
    enableSorting: false,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.LocationName]} />,
    cell: info => info.row.original.location,
    size: 150,
    meta: {
      truncate: true,
    },
  }),

  [ProfilesBffColumns.Tags]: (deps: TagsDeps) => ({
    accessorKey: ProfilesBffColumns.Tags,
    enableSorting: false,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.Tags]} />,
    cell: info => {
      const {id: profileId, tags} = info.row.original;
      const tagsDropdown = (
        <TagsTableCell
          targetId={profileId}
          assignedTags={tags}
          targetType="profile"
          onAssignSuccess={tag => deps.onAssignTag([profileId], tag)}
          onUnassignSuccess={tagId => deps.onUnassignTag([profileId], tagId)}
        />
      );

      return (
        <PermissionChecker
          permission={Permission.TagAssign}
          fallback={<ProfileTagsList targetId={profileId} targetType="profile" tags={tags} />}
          missingPlanFallback={tagsDropdown}
        >
          {tagsDropdown}
        </PermissionChecker>
      );
    },
    meta: {
      truncate: false,
    },
    size: 160,
    minSize: 80,
  }),

  [ProfilesBffColumns.TeamConnectionsStrength]: (deps: TeamConnectionStrengthDeps) => ({
    accessorKey: ProfilesBffColumns.TeamConnectionsStrength,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.TeamConnectionsStrength]} />,
    enableSorting: deps.canConnectionStrength,
    cell: info => {
      const {id: profileId, connections} = info.row.original;

      return (
        <div className="flex justify-center">
          <PremiumFeatureWrapper
            fallback="⚠️"
            permission={Permission.ConnectionStrength}
            featureName="Connection Strength"
            location="connection score"
          >
            <ProfileLink profileId={profileId} hash="connection-strength">
              <MultiScore connections={connections} limit={4} />
            </ProfileLink>
          </PremiumFeatureWrapper>
        </div>
      );
    },
    size: 90,
    minSize: 54,
  }),

  [ProfilesBffColumns.KeyRelationship]: ({openIntroModal}) => ({
    accessorKey: ProfilesBffColumns.KeyRelationship,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.KeyRelationship]} />,
    cell: info => {
      const {id: profileId, connections} = info.row.original;

      return (
        <div>
          <KeyRelationshipCellContent
            connections={connections}
            openIntroModal={openIntroModal}
            profileId={profileId}
          />
        </div>
      );
    },
    size: 220,
    minSize: 110,
    meta: {
      truncate: false,
    },
    enableSorting: false,
  }),

  [ProfilesBffColumns.ConnectedMembersCount]: () => ({
    accessorKey: ProfilesBffColumns.ConnectedMembersCount,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.ConnectedMembersCount]} />,
    cell: info => {
      const {connections} = info.row.original;
      const connectedMembers = connections.map(connection => connection.user);
      return <ConnectedMembersCellContent connectedMembers={connectedMembers} />;
    },
    size: 120,
    minSize: 100,
  }),

  [ProfilesBffColumns.Emails]: () => ({
    accessorKey: ProfilesBffColumns.Emails,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.Emails]} />,
    cell: info => {
      const {emails} = info.row.original;

      if (!emails?.length) {
        return '-';
      }

      return (
        <div>
          <EmailsCellContent emails={emails} />
        </div>
      );
    },
    size: 220,
    minSize: 110,
    meta: {
      truncate: false,
    },
    enableSorting: false,
  }),

  [ProfilesBffColumns.ConnectionsStrength]: (deps: ConnectionStrengthDeps) => ({
    accessorKey: ProfilesBffColumns.ConnectionsStrength,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.ConnectionsStrength]} />,
    enableSorting: deps.canConnectionStrength,
    cell: info => {
      const {id: profileId, connections} = info.row.original;
      const {onScoreChange, currentUserId} = deps;
      const currentUserConnection = connections.find(connection => connection.user.id === currentUserId);

      if (!currentUserConnection) {
        return null;
      }

      return (
        <div className="flex justify-center">
          <PremiumFeatureWrapper
            fallback="⚠️"
            permission={Permission.ConnectionStrength}
            featureName="Connection Strength"
            location="connection score"
          >
            <ScoreDropdown
              profileId={profileId}
              connection={currentUserConnection}
              onChange={onScoreChange}
            />
          </PremiumFeatureWrapper>
        </div>
      );
    },
    size: 90,
    minSize: 54,
  }),

  [ProfilesBffColumns.StartDate]: () => ({
    accessorKey: ProfilesBffColumns.StartDate,
    enableSorting: false,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.StartDate]} />,
    cell: info => info.row.original.currentJob?.startDate || '',
    size: 150,
    meta: {
      truncate: true,
    },
  }),

  [ProfilesBffColumns.EndDate]: () => ({
    accessorKey: ProfilesBffColumns.EndDate,
    enableSorting: false,
    header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.EndDate]} />,
    cell: info => info.row.original.currentJob?.endDate || 'Current',
    size: 150,
    meta: {
      truncate: true,
    },
  }),
};
