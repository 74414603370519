import {useMutation, useQuery} from 'react-query';
import {apiClient} from './apiClient';

type UiSettings<T = unknown> = Record<string, T>;

const getUiSettings = () => apiClient.get('/ui-settings').then(res => res.data);

export const useQueryUiSettings = () =>
  useQuery(['getUiSettings'], () => getUiSettings(), {
    keepPreviousData: true,
    refetchOnMount: false,
  });

const setUiSettings = (settings: UiSettings) => apiClient.put('/ui-settings', settings).then(res => res.data);

export const useMutationUiSettings = () => useMutation((settings: UiSettings) => setUiSettings(settings));
