import {useCallback} from 'react';
import {useQueryParam} from './useQueryParam';

const DEFAULT_ROUTE = '/';

const redirectBackDirectionKey = window.btoa('REDIRECT_BACK_DIRECTION_KEY');

const set = (to: string) => {
  localStorage.setItem(redirectBackDirectionKey, to);
};

const clear = () => localStorage.removeItem(redirectBackDirectionKey);

const get = () => localStorage.getItem(redirectBackDirectionKey) || DEFAULT_ROUTE;

export const useRedirectBackDirection = () => {
  const redirectTo = useQueryParam<string>('to');

  const save = useCallback(() => (redirectTo ? set(redirectTo) : clear()), [redirectTo]);

  return {
    getRedirectBackPath: get,
    saveRedirectBackPath: save,
    clearRedirectBackPath: clear,
  };
};
