import {CSSProperties} from 'react';

export const mentionStyle: CSSProperties = {
  backgroundColor: '#FEE8B3',
};

export const mentionsWrapperStyle = {
  control: {
    lineHeight: '1.5rem',
    maxWidth: '100%',
  },

  '&multiLine': {
    highlighter: {
      padding: '9px',
    },
    input: {
      padding: '9px',
      boxShadow: 'none',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: '100%',

    highlighter: {
      padding: '0.5rem 0.75rem',
    },
    input: {
      padding: '0.5rem 0.75rem',
      boxShadow: 'none',
    },
  },

  suggestions: {
    list: {
      border: '1px solid rgba(0,0,0,0.15)',
      boxShadow:
        'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
      borderRadius: '4px',
      padding: '4px',
    },
    item: {
      padding: '8px 12px',
      '&focused': {
        backgroundColor: 'rgb(245 245 245 / var(--tw-bg-opacity))',
        borderRadius: '4px',
        color: '#000',
      },
    },
  },
} as const;
