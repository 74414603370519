import {useMutationUiSettings} from '../../../api';
import {Button} from '../../../components/button';
import Styles from './Account.module.scss';

export const ClearUiSettings = () => {
  const {mutate, isLoading} = useMutationUiSettings();
  const clearUiSettings = () => {
    mutate({data: {}});
  };

  return (
    <div className={Styles.settingsActionBoxWrapper}>
      <p className={Styles.settingsActionBoxInfo}>
        All persisted UI settings from your Swarm account will be permanently deleted.
      </p>
      <Button onClick={clearUiSettings} variant="red" loading={isLoading}>
        Clear UI Settings
      </Button>
    </div>
  );
};
