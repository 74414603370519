import {useCallback} from 'react';

const sidebarCollapsedKey = 'SIDEBAR_COLLAPSED';
const favWidgetCollapsedKey = 'FAV_WIDGET_COLLAPSED';

export const useLayoutState = () => {
  const saveSidebarCollapsed = useCallback((collapsed: boolean) => {
    localStorage.setItem(sidebarCollapsedKey, collapsed ? '1' : '0');
  }, []);

  const getSidebarCollapsed = useCallback(() => {
    return localStorage.getItem(sidebarCollapsedKey) === '1';
  }, []);

  const saveFavWidgetCollapsed = useCallback((collapsed: boolean) => {
    localStorage.setItem(favWidgetCollapsedKey, collapsed ? '1' : '0');
  }, []);

  const getFavWidgetCollapsed = useCallback(() => {
    return localStorage.getItem(favWidgetCollapsedKey) === '1';
  }, []);

  return {saveSidebarCollapsed, getSidebarCollapsed, saveFavWidgetCollapsed, getFavWidgetCollapsed};
};
