import {useMergeLink} from '@mergeapi/react-merge-link';
import {useCallback, useState} from 'react';
import {useMutationCrmLinkAccount, useMutationCrmUnlinkAccount, useQueryCrmLinkToken} from '../../../api';
import {notify} from '../../../components/notifications';
import {Crm} from '../../../types';
import {integrationNameMap, notifyGenericError, segmentTrack, trackError} from '../../../utils';
import {useExistingIntegration} from './useExistingIntegration.hook';

export const useIntegrationAction = (crm: Crm) => {
  const [linkToken, setLinkToken] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);

  const emitFinishedEvent = useCallback(
    (result: 'success' | 'error' | 'abort', processType: 'connect' | 'disconnect') => {
      segmentTrack('Process Finished', {
        process: `crm integration ${processType}`,
        status: result,
        system: crm,
      });
    },
    [crm]
  );

  const {integration, isLoading: isIntegrationLoading, refetch} = useExistingIntegration(crm);

  const {refetch: getLinkToken, isLoading: isLinkTokenLoading} = useQueryCrmLinkToken(crm, {
    enabled: false,
  });

  const {mutateAsync: mutateLinkAsync} = useMutationCrmLinkAccount();

  const {mutateAsync: mutateUnlinkAsync} = useMutationCrmUnlinkAccount();

  const sendPublicToken = useCallback(
    (publicToken: string, crmName: Crm) => {
      mutateLinkAsync({publicToken})
        .then(() => {
          notify(`Successfully connected with ${integrationNameMap[crmName]} CRM`, true);
          refetch();
          emitFinishedEvent('success', 'connect');
        })
        .catch(e => {
          notifyGenericError();
          emitFinishedEvent('error', 'connect');
          trackError(e);
        })
        .finally(() => {
          setIsConnecting(false);
        });
    },
    [emitFinishedEvent, mutateLinkAsync, refetch]
  );

  const onConnectingSuccess = useCallback(
    (publicToken: string, crm: Crm) => {
      setIsConnecting(true);
      sendPublicToken(publicToken, crm);
    },
    [sendPublicToken]
  );

  const onAbortConnecting = useCallback(() => {
    if (isConnecting) {
      emitFinishedEvent('abort', 'connect');
      setIsConnecting(false);
      setLinkToken('');
    }
  }, [emitFinishedEvent, isConnecting]);

  const {open} = useMergeLink({
    linkToken,
    onExit: onAbortConnecting,
    onSuccess: (publicToken: string) => onConnectingSuccess(publicToken, crm),
  });

  const connect = useCallback(async () => {
    setIsConnecting(true);
    const {data} = await getLinkToken();

    if (!data) {
      return;
    }
    setLinkToken(data.linkToken);
    open();
  }, [getLinkToken, open]);

  const disconnect = useCallback(() => {
    if (!integration) {
      throw new Error('Integration not found');
    }

    setIsConnecting(true);
    mutateUnlinkAsync({integrationId: integration.id})
      .then(() => {
        notify(`Successfully disconnected from ${integrationNameMap[crm]} CRM`, true);
        refetch();
        emitFinishedEvent('success', 'disconnect');
      })
      .catch(e => {
        notifyGenericError();
        emitFinishedEvent('error', 'disconnect');
        trackError(e);
      })
      .finally(() => {
        setIsConnecting(false);
      });
  }, [crm, emitFinishedEvent, integration, mutateUnlinkAsync, refetch]);

  return {
    connect,
    disconnect,
    isConnected: integration && integration.state !== 'disconnecting',
    isLoading: isIntegrationLoading || isLinkTokenLoading || isConnecting,
  };
};
