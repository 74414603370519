import {useCallback} from 'react';
import {useRedirectBackDirection} from './useRedirectBack';

export const useGoToApp = () => {
  const {getRedirectBackPath, clearRedirectBackPath} = useRedirectBackDirection();

  const goToApp = useCallback(() => {
    const redirectBackDirection = getRedirectBackPath();
    clearRedirectBackPath();

    // in most cases it doesn't work with useNavigate, because we need to force a page reload
    window.location.href = redirectBackDirection;
  }, [getRedirectBackPath, clearRedirectBackPath]);

  return goToApp;
};
