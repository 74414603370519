import {AxiosResponse} from 'axios';
import {useQuery, UseQueryOptions} from 'react-query';
import {useCurrentTeam} from '../hooks';
import {
  AssignTagRequestParams,
  CreateTagInput,
  GetTagsListRequest,
  GetTagsResponse,
  GetTagsSimpleResponse,
  TagItem,
} from '../types';
import {apiClient} from './apiClient';

// GET TAG

const getTag = (tagId: string) => apiClient.get<TagItem>(`tags/${tagId}`).then(res => res.data);

export const useQueryTag = (tagId: string, options?: UseQueryOptions<TagItem>) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<TagItem>(['tag', teamId, tagId], () => getTag(tagId), options);
};

// GET TAGS LIST

const getTags = (params: GetTagsListRequest) =>
  apiClient.get<GetTagsResponse>('/tags', {params}).then(res => res.data);

export const useQueryTags = (params: GetTagsListRequest, options?: UseQueryOptions<GetTagsResponse>) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<GetTagsResponse>(['tags', teamId, params], () => getTags(params), options);
};

// GET TAGS SIMPLE LIST

const getTagsSimple = () =>
  apiClient
    .get<GetTagsSimpleResponse>('/tags/simple', {
      params: {
        'sort[]': 'createdAt.desc',
      },
    })
    .then(res => res.data);

export const useQueryTagsSimple = (options?: UseQueryOptions<GetTagsSimpleResponse>) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<GetTagsSimpleResponse>(['tags-simple', teamId], () => getTagsSimple(), options);
};

// CREATE TAG

export const createTag = (input: CreateTagInput): Promise<TagItem> =>
  apiClient.post<TagItem, AxiosResponse<TagItem>, CreateTagInput>('/tags', input).then(res => res.data);

// UPDATE TAG

export const updateTag = (input: CreateTagInput, tagId: string): Promise<TagItem> =>
  apiClient
    .put<TagItem, AxiosResponse<TagItem>, CreateTagInput>(`tags/${tagId}`, input)
    .then(res => res.data);

// DELETE TAG

export const deleteTag = (tagId: string) => apiClient.delete(`tags/${tagId}`);

// ASSIGN TAG

export const assignTag = (tagId: string, input: AssignTagRequestParams): Promise<void> =>
  apiClient.post(`/tags/${tagId}/relations`, input);

// UNASSIGN TAG

export const unassignTag = (tagId: string, input: AssignTagRequestParams): Promise<void> =>
  apiClient.delete(`/tags/${tagId}/relations`, {data: input});
