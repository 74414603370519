import {Select} from '../../../form';
import {FilterOperator} from '../../types';

type Props = {
  operator: FilterOperator;
  setOperator: (operator: FilterOperator) => void;
};

export const OperatorSelect = ({operator, setOperator}: Props) => {
  return (
    <Select
      value={operator}
      onChange={e => setOperator(e.target.value as FilterOperator)}
      options={Object.values(FilterOperator).map(op => ({value: op, text: op}))}
    />
  );
};
