import {CompanyResponse, TagSimple} from '../../../types';
import {BulkTags} from './BulkTags';

type Props = {
  selectedCompanies: CompanyResponse[];
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
};

export const BulkCompaniesTags = ({selectedCompanies, onAssignSuccess, onUnassignSuccess}: Props) => {
  const selectedEntitiesIds = selectedCompanies.map(entity => entity.id);
  const selectedTags = selectedCompanies.map(entity => entity.tags.map(tag => tag.id));
  return (
    <BulkTags
      targetType="company"
      selectedEntitiesIds={selectedEntitiesIds}
      selectedTags={selectedTags}
      onAssignSuccess={onAssignSuccess}
      onUnassignSuccess={onUnassignSuccess}
    />
  );
};
