import {useQuery, UseQueryOptions} from 'react-query';
import {useCurrentTeam} from '../hooks';
import {
  BffAggregateRequest,
  BffAggregateResponse,
  BffCompaniesBulkActionRequest,
  BffCompanyListRequest,
  BffCompanyListResponse,
  CompanyResponse,
} from '../types';
import {isGetCompanyResponse} from '../types/bffTypes.guard';
import {typeCheck} from '../utils';
import {apiClient} from './apiClient';

const getBffCompany = async (id: string): Promise<CompanyResponse> =>
  apiClient
    .get(encodeURI(`/companies/${id}`), {headers: {'X-Swarm-Bff': 'true'}})
    .then(res => typeCheck(res.data, isGetCompanyResponse));

export const useQueryBffCompany = (id: string) => useQuery(['company', id], () => getBffCompany(id));

const getBffCompanyList = (req: BffCompanyListRequest): Promise<BffCompanyListResponse> =>
  apiClient.post('/companies', req, {headers: {'X-Swarm-Bff': 'true'}}).then(res => res.data);

export const useQueryBffCompanyList = (
  getCompanyListInput: BffCompanyListRequest,
  options: UseQueryOptions<BffCompanyListResponse> = {}
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<BffCompanyListResponse>(
    ['companies-bff', teamId, getCompanyListInput],
    () => getBffCompanyList(getCompanyListInput),
    options
  );
};

const getCompanyBffAggregate = async (params: BffAggregateRequest): Promise<BffAggregateResponse> =>
  apiClient
    .post('/companies/aggregate', params, {
      headers: {'X-Swarm-Bff': 'true'},
    })
    .then(res => res.data);

export const useQueryCompanyBffAggregate = (
  getAggregateInput: BffAggregateRequest,
  options: UseQueryOptions<BffAggregateResponse> = {}
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<BffAggregateResponse>(
    ['companiesAggregate', teamId, getAggregateInput],
    () => getCompanyBffAggregate(getAggregateInput),
    options
  );
};

export const bffCompaniesBulkAction = (req: BffCompaniesBulkActionRequest) =>
  apiClient.post('/companies/bulk', req, {headers: {'X-Swarm-Bff': 'true'}});

export const useBffCompaniesBulkAction = ({filters, actionType, targetId}: BffCompaniesBulkActionRequest) => {
  return useQuery(['companiesBulkAction', filters, actionType, targetId], () =>
    bffCompaniesBulkAction({filters, actionType, targetId})
  );
};
