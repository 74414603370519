import {useCallback, useMemo} from 'react';
import {useMutationUiSettings, useQueryUiSettings} from '../api';

type ReturnUseUiSettings<T> = [
  setting: T, // value
  updateSetting: (value: T) => Promise<void>, // setter
];

export const useUiSetting = <T>(key: string): ReturnUseUiSettings<T> => {
  const {data: uiSettings, refetch} = useQueryUiSettings();
  const mutationUiSettings = useMutationUiSettings();

  const setting = useMemo(() => uiSettings?.[key] as T, [key, uiSettings]);

  const updateSetting = useCallback(
    async (value: T) => {
      const newSettings = {...uiSettings, [key]: value};
      return mutationUiSettings.mutateAsync(newSettings, {
        onSettled: refetch,
      });
    },
    [key, mutationUiSettings, refetch, uiSettings]
  );

  return [setting, updateSetting];
};
