import {ProfilesListRow, TagSimple} from '../../../types';
import {BulkTags} from './BulkTags';

type Props = {
  selectedProfiles: ProfilesListRow[];
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
  disabled?: boolean;
};

export const BulkProfilesTags = ({selectedProfiles, onAssignSuccess, onUnassignSuccess, disabled}: Props) => {
  const selectedEntitiesIds = selectedProfiles.map(entity => entity.id);
  const selectedTags = selectedProfiles.map(entity => entity.tags.map(tag => tag.id));
  return (
    <BulkTags
      targetType="profile"
      selectedEntitiesIds={selectedEntitiesIds}
      selectedTags={selectedTags}
      onAssignSuccess={onAssignSuccess}
      onUnassignSuccess={onUnassignSuccess}
      disabled={disabled}
    />
  );
};
