import Styles from './Account.module.scss';
import {Avatar} from '../../../components/avatar';
import {useCurrentUserSession} from '../../../hooks';
import {getUserFullName} from '../../../utils';
import {useForm} from 'react-hook-form';
import {Input, Label} from '../../../components/form';
import {Button} from '../../../components/button';
import {useCallback} from 'react';
import {useMutationUpdateFirstLastName} from '../../../api';
import {notify} from '../../../components/notifications';

type FormProps = {
  firstName: string;
  lastName: string;
};

export const Account = () => {
  const {refresh, user} = useCurrentUserSession();
  const userName = getUserFullName(user);

  const {id: userId, firstName: currentFirstName, lastName: currentLastName} = user;

  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
    watch,
  } = useForm<FormProps>({
    defaultValues: {firstName: currentFirstName, lastName: currentLastName},
  });

  const mutationUpdateFirstLastName = useMutationUpdateFirstLastName(userId, {
    onSuccess: () => {
      refresh().then(() => {
        notify('Profile updated successfully');
      });
    },
    onError: () => {
      setError('root', {message: 'Something went wrong. Please try again later.'});
    },
  });

  const onSubmit = useCallback(
    ({firstName, lastName}: FormProps) => {
      mutationUpdateFirstLastName.mutate({
        firstName,
        lastName,
      });
    },

    [mutationUpdateFirstLastName]
  );
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={Styles.accountSettings}>
          <Avatar size="xxl" fullname={userName} />
          <div className={Styles.formWrapper}>
            <div className={Styles.editUser}>
              <div className={Styles.editUserField}>
                <Label>First name</Label>
                <Input
                  {...register('firstName', {required: 'First name is required'})}
                  type="text"
                  placeholder="Enter your first name"
                  isInvalid={Boolean(errors.firstName)}
                  message={errors.firstName?.message}
                  messageClassName={Styles.errorMessage}
                />
              </div>

              <div className={Styles.editUserField}>
                <Label>Last name</Label>
                <Input
                  {...register('lastName', {required: 'Last name is required'})}
                  placeholder="Enter your last name"
                  type="text"
                  isInvalid={Boolean(errors.lastName)}
                  message={errors.lastName?.message}
                  messageClassName={Styles.errorMessage}
                />
              </div>
            </div>

            <div className={Styles.userEmail}>
              <Label>Email</Label>
              <Input disabled type="text" value={user.email} intercomTarget="Email address" />
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <Button
            type="submit"
            variant="primary"
            loading={isSubmitting}
            tracking={{
              label: 'edit user',
            }}
            disabled={currentFirstName === watch().firstName && currentLastName === watch().lastName}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
