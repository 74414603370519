import {ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {useSwarmTable, UseSwarmTableProps} from '../../components/table';
import {ProfilesBffColumns} from '../../defs';
import {profilesBffTableCellDefinitions} from '../../defs/columns/profilesBffTableCellDefinitions';
import {useCheckPermission, useCurrentUser} from '../../hooks';
import {GetProfilesListResponse, Permission, ProfilesListRow, TagSimple} from '../../types';
import {segmentTrack} from '../../utils';

type GenericUseSwarmTableProps = UseSwarmTableProps<GetProfilesListResponse>;

export type UseMyConnectionsTableProps = {
  rows: ProfilesListRow[];
  isLoaded: boolean;
  pagination: GenericUseSwarmTableProps['pagination'];
  sorting: GenericUseSwarmTableProps['sorting'];
  onAssignTag: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag: (profileIds: string[], tagId: string) => void;
  visibleColumnsState: GenericUseSwarmTableProps['visibleColumnsState'];
  columnsOrder: GenericUseSwarmTableProps['columnsOrder'];
  refetchProfiles: () => void;
};

export const useMyConnectionsTable = ({
  rows,
  isLoaded,
  pagination,
  sorting,
  onAssignTag,
  onUnassignTag,
  visibleColumnsState,
  columnsOrder,
  refetchProfiles,
}: UseMyConnectionsTableProps) => {
  const canConnectionStrength = useCheckPermission(Permission.ConnectionStrength);
  const {id: currentUserId} = useCurrentUser();

  const columns = useMemo<ColumnDef<ProfilesListRow>[]>(
    () => [
      profilesBffTableCellDefinitions[ProfilesBffColumns.FullName]({
        onChangeSuccess: refetchProfiles,
      }),
      profilesBffTableCellDefinitions[ProfilesBffColumns.ConnectionsStrength]({
        canConnectionStrength,
        onScoreChange: refetchProfiles,
        currentUserId,
      }),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobTitle](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobCompanyName](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobCompanyWebsite](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.LocationName](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.Emails](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.Tags]({onAssignTag, onUnassignTag}),
    ],
    [canConnectionStrength, currentUserId, refetchProfiles, onAssignTag, onUnassignTag]
  );

  const table = useSwarmTable<ProfilesListRow>({
    uniqueName: 'myConnections',
    rows,
    isLoaded,
    selectable: true,
    columns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
    onSelectionChange: ({checked, row, count}) => {
      segmentTrack(checked ? 'Item Selected' : 'Selection Removed', {
        label: 'profile',
        count,
        ...(row ? {profile_id: row.id} : {}),
      });
    },
  });

  return table;
};

export type UseProfilesTableResult = ReturnType<typeof useMyConnectionsTable>;
