import {useCallback, useState} from 'react';

export const useModal = (defaultVisible?: boolean) => {
  const [visible, setVisible] = useState(Boolean(defaultVisible));

  const show = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  return {
    visible,
    show,
    close,
  };
};
