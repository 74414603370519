import {useCallback, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  useCurrentPlan,
  useQuerySubscriptionCancelLink,
  useQuerySubscriptionCheckoutLink,
  useQuerySubscriptionManageLink,
  useQueryUpdateSubscriptionPaymentMethodLink,
} from '../api';
import {useUserSessionContext} from '../contexts';
import {PaymentPeriod, PlanType} from '../types';
import {useCurrentTeam} from './useCurrentUserSession';
import {useSearchParamsHelpers} from './useObjectInSearchParam';

const PLAN_UPDATED_QUERY_PARAM = 'plan-updated';
const TRIAL_STARTED_QUERY_PARAM = 'trial-started';
const LATEST_PLAN_UPDATED_AT_KEY = 'planUpdatedAt';
const RETRIES_LIMIT = 30;
const RETRY_INTERVAL = 1000;

export const useSubscription = (period?: PaymentPeriod, planType?: PlanType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [refetchCount, setRefetchCount] = useState(0);

  const shouldUpdatePlan = useMemo(
    () => searchParams.get(PLAN_UPDATED_QUERY_PARAM) === 'true',
    [searchParams]
  );

  const shouldFetchTrial = useMemo(
    () => searchParams.get(TRIAL_STARTED_QUERY_PARAM) === 'true',
    [searchParams]
  );

  const {refetch: refetchPermissions} = useUserSessionContext();
  const {id: teamId} = useCurrentTeam();
  const savedUpdatedAt = localStorage.getItem(LATEST_PLAN_UPDATED_AT_KEY);

  const {data: plan, isLoading: isPlanLoading} = useCurrentPlan({
    refetchInterval: (shouldUpdatePlan || shouldFetchTrial) && RETRY_INTERVAL,
    onSuccess: newPlan => {
      // determine if plan was updated based on comparing new plan's updatedAt with saved one OR
      // if trial was fetched
      const correctPlanFetched =
        (shouldUpdatePlan && savedUpdatedAt !== newPlan.updatedAt) ||
        (shouldFetchTrial && !newPlan.trialAvailable);

      if (correctPlanFetched || refetchCount === RETRIES_LIMIT) {
        refetchPermissions();
        searchParams.delete(PLAN_UPDATED_QUERY_PARAM);
        searchParams.delete(TRIAL_STARTED_QUERY_PARAM);
        setSearchParams(searchParams);
        setRefetchCount(0);
      } else {
        setRefetchCount(prevCount => prevCount + 1);
      }
    },
  });

  const isFreePlan = plan?.type === PlanType.Free;

  const upgradeSuccessUrl = `${window.location.origin}/upgrade-success?${PLAN_UPDATED_QUERY_PARAM}=true`;
  const planUpdatedUrl = `${window.location.origin}/team-settings/subscription?${PLAN_UPDATED_QUERY_PARAM}=true`;

  const saveUpdatedAt = useCallback(() => {
    localStorage.setItem(LATEST_PLAN_UPDATED_AT_KEY, plan?.updatedAt || '');
  }, [plan]);

  const {refetch: cancel} = useQuerySubscriptionCancelLink(
    teamId,
    {
      successUrl: planUpdatedUrl,
      cancelUrl: window.location.href,
    },
    {
      enabled: false,
    }
  );
  const redirectToCancel = useCallback(async () => {
    const {data} = await cancel();

    if (!data) {
      return;
    }

    saveUpdatedAt();
    window.location.assign(data.url);
  }, [cancel, saveUpdatedAt]);

  const {refetch: manage} = useQuerySubscriptionManageLink(teamId, planUpdatedUrl, {
    enabled: false,
  });
  const redirectToManage = useCallback(async () => {
    const {data} = await manage();

    if (!data) {
      return;
    }

    saveUpdatedAt();
    window.location.assign(data.url);
  }, [manage, saveUpdatedAt]);

  const {refetch: updatePaymentMethod} = useQueryUpdateSubscriptionPaymentMethodLink(
    teamId,
    {
      successUrl: planUpdatedUrl,
      cancelUrl: window.location.href,
    },
    {
      enabled: false,
    }
  );
  const redirectToUpdatePaymentMethod = useCallback(async () => {
    const {data} = await updatePaymentMethod();

    if (!data) {
      return;
    }

    saveUpdatedAt();
    window.location.assign(data.url);
  }, [updatePaymentMethod, saveUpdatedAt]);

  const {refetch: upgrade} = useQuerySubscriptionCheckoutLink(
    teamId,
    {
      successUrl: upgradeSuccessUrl,
      cancelUrl: window.location.href,
      period: period || PaymentPeriod.Monthly,
      planType: planType || PlanType.Base,
    },
    {
      enabled: false,
    }
  );
  const redirectToUpgrade = useCallback(async () => {
    const {data} = await upgrade();

    if (!data) {
      return;
    }

    saveUpdatedAt();
    window.location.assign(data.url);
  }, [upgrade, saveUpdatedAt]);

  const shouldRefetchPlan = shouldUpdatePlan || shouldFetchTrial;

  return {
    cancel: redirectToCancel,
    manage: redirectToManage,
    updatePaymentMethod: redirectToUpdatePaymentMethod,
    upgrade: redirectToUpgrade,
    plan,
    isFreePlan,
    isPlanLoading,
    shouldRefetchPlan,
    shouldFetchTrial,
  };
};

export const useTrialInUrlHelper = () => {
  const {saveInSearchParam: setTrialStartedInUrl} = useSearchParamsHelpers({
    searchParamName: TRIAL_STARTED_QUERY_PARAM,
    replaceHistory: true,
  });
  return setTrialStartedInUrl;
};
