import {Helmet} from 'react-helmet';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {IntercomProvider} from 'react-use-intercom';
import {ErrorBoundary} from './components/common';
import {isMobile, MobileBlocker} from './components/mobile-blocker';
import {Notifications} from './components/notifications';
import {UserSessionContextProvider} from './contexts';
import {BootstrapAmplifyAuth} from './providers';
import {routes} from './routes';
import {getConfig} from './utils';

BootstrapAmplifyAuth();

const {intercomAppId = ''} = getConfig();
const router = createBrowserRouter(routes);

function App() {
  if (isMobile()) {
    return <MobileBlocker />;
  }

  return (
    <ErrorBoundary name="app">
      <IntercomProvider appId={intercomAppId} autoBoot={true}>
        <UserSessionContextProvider>
          <Helmet>
            <title>The Swarm</title>
          </Helmet>
          <RouterProvider router={router} />
        </UserSessionContextProvider>
        <Notifications />
      </IntercomProvider>
    </ErrorBoundary>
  );
}

export default App;
