import cs from 'classnames';
import {ReactNode} from 'react';
import {PaymentPeriod, PlanType} from '../../types';
import Styles from './PlanDetails.module.scss';

type Props = {
  name: PlanType.Premium | PlanType.Base | PlanType.Free;
  billingPeriod?: PaymentPeriod;
  purchasedUnit: 'active user' | 'team';
  price: number;
  isCurrentPlan?: boolean;
  isTrial?: boolean;
  button?: ReactNode;
  featuresTitle: string;
  horizontal?: boolean;
  className?: string;
};

const details = {
  [PlanType.Free]: {
    description: 'Map your company network and keep it all in one place.',
    features: [
      'Combined networks',
      'Bulk imports from LinkedIn',
      'Overlaps',
      'Google integration',
      'Enriched profiles',
      'Intro requests',
      'Up to 100 seats',
    ],
  },
  [PlanType.Base]: {
    description: 'Manage your company network and run powerful searches.',
    features: [
      'AI Network Mapper',
      'Extended Swarm Network',
      'Connection strength',
      'Company-level filters',
      'Saved lists & exports',
      'Company & profile tags',
      'Up to 100 seats',
    ],
  },
  [PlanType.Premium]: {
    description: 'Connect your CRM and add your whole team at a set price.',
    features: [
      'HubSpot and Salesforce integrations',
      'Custom onboarding and team training',
      'Priority support via chat, email, or video calls',
      'Up to 500 seats',
    ],
  },
};

export const PlanDetails = ({
  name,
  billingPeriod,
  purchasedUnit,
  price,
  isCurrentPlan,
  isTrial,
  button,
  featuresTitle,
  horizontal,
  className,
}: Props) => (
  <div className={cs(Styles.planDetails, {[Styles.planDetailsHorizontal]: horizontal}, className)}>
    <div
      className={cs(
        Styles.planHeader,
        {[Styles.planHeaderPremium]: name === PlanType.Premium},
        {[Styles.planHeaderHorizontal]: horizontal}
      )}
    >
      <h3 className={Styles.planName} data-intercom-target="pricing plan: premium">
        {name}
      </h3>
      <p className={Styles.description}>{details[name].description}</p>
      {isTrial && isCurrentPlan ? (
        <p className={Styles.specialLabel}>Current Trial</p>
      ) : isCurrentPlan ? (
        <p className={Styles.specialLabel}>Current plan</p>
      ) : null}
    </div>
    <div className={Styles.detailsContent}>
      <div className={Styles.pricing}>
        <p className={Styles.planPrice}>${price}</p>
        <p className={Styles.paymentPeriod}>
          {!billingPeriod
            ? '/month or $2,990 /year'
            : name === PlanType.Free
              ? 'Free forever.'
              : billingPeriod === PaymentPeriod.Monthly
                ? `/${purchasedUnit} /month`
                : `/${purchasedUnit} /month, billed yearly`}
        </p>
      </div>
      <div className={Styles.features}>
        <h5 className={Styles.featuresTitle}>{featuresTitle}</h5>
        {details[name].features.map((feature, index) => (
          <p key={index}>✓ {feature}</p>
        ))}
      </div>
      {button}
    </div>
  </div>
);
