import {ColumnDef, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useEffect, useMemo} from 'react';
import {useLayoutContext} from '../../contexts';
import {useRestoreScrollOnPopState, useUiSetting} from '../../hooks';
import {IndeterminateCheckbox} from '../indeterminate-checkbox';
import {ColumnSizeRecord, UseSwarmTableProps} from './types';
import {getColumnWidthsSettingsKey} from './utils';

type ColumnDefWithAccessorKey<TRow> = ColumnDef<TRow> & {accessorKey: string};

export const useSwarmTable = <TRow,>({
  uniqueName,
  rows,
  isLoaded,
  selectable,
  onSelectionChange,
  columns,
  defaultColumnSize = 300,
  defaultColumnMinSize = 100,
  pagination,
  sorting,
  visibleColumnsState,
  columnsOrder,
}: UseSwarmTableProps<TRow>) => {
  const {scrollContentToTop} = useLayoutContext();

  const selectionColumn = useMemo<ColumnDef<TRow>>(
    () => ({
      accessorKey: 'selection',
      header: ({table}) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={event => {
            table.getToggleAllPageRowsSelectedHandler()(event);
            const checked = event.currentTarget.checked;
            onSelectionChange?.({
              count: pagination ? pagination.paginationState.pageSize : rows.length,
              checked,
            });
          }}
        />
      ),
      cell: ({row}) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={event => {
            row.getToggleSelectedHandler()(event);
            const checked = event.currentTarget.checked;
            onSelectionChange?.({
              checked,
              count: 1,
              row: row.original,
            });
          }}
        />
      ),
      size: 38,
      minSize: 38,
      enableResizing: false,
      enableSorting: false,
    }),
    [onSelectionChange, pagination, rows.length]
  );

  const columnWidthsSettingsKey = useMemo(() => getColumnWidthsSettingsKey(window.location.pathname), []);
  const [headersWidths] = useUiSetting<ColumnSizeRecord[]>(columnWidthsSettingsKey);

  const enhancedColumns = useMemo<ColumnDef<TRow>[]>(() => {
    const columnsToRender = selectable ? [selectionColumn, ...columns] : columns;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return !headersWidths
      ? columnsToRender
      : (columnsToRender as ColumnDefWithAccessorKey<TRow>[]).map(column => {
          const headerWidth =
            headersWidths.find(header => header.id === column.accessorKey)?.width || column.size;
          return {
            ...column,
            size: headerWidth,
          };
        });
  }, [columns, headersWidths, selectionColumn, selectable]);

  const table = useReactTable({
    data: rows,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    columns: enhancedColumns,
    defaultColumn: {
      size: defaultColumnSize,
      minSize: defaultColumnMinSize,
    },
    enableSorting: Boolean(sorting),
    state: {
      ...(sorting && {sorting: sorting.sortingState}),
      ...(pagination && {pagination: pagination.paginationState}),
      ...(visibleColumnsState && {columnVisibility: visibleColumnsState}),
      ...(columnsOrder && {columnOrder: ['selection', ...columnsOrder]}),
    },
    ...(sorting && {onSortingChange: sorting.setSorting}),
    ...(pagination && {
      manualPagination: true,
      onPaginationChange: pagination.setPaginationState,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => table.resetRowSelection(), [pagination?.currentPage, table]);
  useEffect(() => {
    typeof scrollContentToTop !== 'undefined' && scrollContentToTop();
  }, [pagination?.currentPage, scrollContentToTop]);

  useRestoreScrollOnPopState(uniqueName, isLoaded);

  return table;
};
