import {useIsRuturningUser} from '../utils/isReturningUser.hook';

type Props = {
  name: string;
};

export const DashboardTitle = ({name}: Props) => {
  const isReturningUser = useIsRuturningUser();
  const greeting = isReturningUser ? 'Welcome back' : 'Hi';

  return (
    <>
      <h2 className="pt-4 text-2xl font-semibold text-swarm-black">
        {greeting}, {name}.
      </h2>
      <p className="leading-relaxed text-swarm-gray-700">Let's work your network!</p>
    </>
  );
};
