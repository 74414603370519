export const upgradeMailTo = encodeURI(`mailto:hello@theswarm.com?subject=Upgrade?&body=Hi,

I would like to learn more information about upgrading.
Can you please reach out to me?`);

export const manageSubMailTo = encodeURI(`mailto:hello@theswarm.com?subject=Manage subscription&body=Hi,

I would like to update my subscription.
Please reach out to me asap.
`);

export const toUpgradePlan = '/upgrade-plan';
