import {useCallback, useState} from 'react';
export const usePipelineFormModal = () => {
  const [pipelineFormModalVisible, setPipelineFormModalVisible] = useState(false);
  const [pipelineId, setPipelineId] = useState('');

  const closePipelineFormModal = useCallback(() => {
    setPipelineFormModalVisible(false);
  }, []);

  const openPipelineFormModal = useCallback((id?: string) => {
    id && setPipelineId(id);
    setPipelineFormModalVisible(true);
  }, []);

  return {
    pipelineFormModalVisible,
    closePipelineFormModal,
    openPipelineFormModal,
    pipelineId,
  };
};
