import {ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {useSwarmTable, UseSwarmTableProps} from '../../../components/table';
import {ProfilesBffColumns} from '../../../defs';
import {profilesBffTableCellDefinitions} from '../../../defs/columns/profilesBffTableCellDefinitions';
import {useCheckPermission} from '../../../hooks';
import {GetProfilesListResponse, Permission, ProfilesListRow, TagSimple} from '../../../types';
import {segmentTrack} from '../../../utils';

type GenericUseSwarmTableProps = UseSwarmTableProps<GetProfilesListResponse>;

export type UsePipelineProfilesTableProps = {
  rows: ProfilesListRow[];
  isLoaded: boolean;
  pagination: GenericUseSwarmTableProps['pagination'];
  sorting: GenericUseSwarmTableProps['sorting'];
  onAssignTag: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag: (profileIds: string[], tagId: string) => void;
  openIntroModal: (profileId: string) => void;
  visibleColumnsState: GenericUseSwarmTableProps['visibleColumnsState'];
  columnsOrder: GenericUseSwarmTableProps['columnsOrder'];
  pipelineId: string;
  refetchProfiles?: () => void;
};

export const usePipelinesSavedSearchTable = ({
  rows,
  isLoaded,
  pagination,
  sorting,
  onAssignTag,
  onUnassignTag,
  openIntroModal,
  visibleColumnsState,
  columnsOrder,
  pipelineId,
  refetchProfiles,
}: UsePipelineProfilesTableProps) => {
  const canConnectionStrength = useCheckPermission(Permission.ConnectionStrength);

  const columns = useMemo<ColumnDef<ProfilesListRow>[]>(
    () => [
      profilesBffTableCellDefinitions[ProfilesBffColumns.FullName]({
        pipelineId,
        onChangeSuccess: refetchProfiles,
        openIntroModal,
      }),
      profilesBffTableCellDefinitions[ProfilesBffColumns.AddToPipeline]({
        pipelineId,
        onChangeSuccess: refetchProfiles,
      }),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobTitle](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobCompanyName](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobCompanyWebsite](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.LocationName](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.Tags]({onAssignTag, onUnassignTag}),
      profilesBffTableCellDefinitions[ProfilesBffColumns.TeamConnectionsStrength]({canConnectionStrength}),
      profilesBffTableCellDefinitions[ProfilesBffColumns.KeyRelationship]({openIntroModal}),
      profilesBffTableCellDefinitions[ProfilesBffColumns.ConnectedMembersCount](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.Emails](),
    ],
    [canConnectionStrength, openIntroModal, onAssignTag, onUnassignTag, pipelineId, refetchProfiles]
  );

  const table = useSwarmTable<ProfilesListRow>({
    uniqueName: 'pipelineSavedSearch',
    rows,
    isLoaded,
    selectable: true,
    columns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
    onSelectionChange: ({checked, row, count}) => {
      segmentTrack(checked ? 'Item Selected' : 'Selection Removed', {
        label: 'profile',
        count,
        ...(row ? {profile_id: row.id} : {}),
      });
    },
  });

  return table;
};

export type UsePipelinesSavedSearchTableResult = ReturnType<typeof usePipelinesSavedSearchTable>;
