import {useMemo} from 'react';

export const range = ({start, end}: {start: number; end: number}): number[] => {
  const length = end - start + 1;
  /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
  return Array.from({length}, (_, idx) => idx + start);
};

export const usePaginationButtons = ({
  totalCount,
  pageSize,
  currentPage,
}: {
  totalCount: number;
  pageSize: number;
  currentPage: number;
}) => {
  const paginationRange = useMemo((): number[] | undefined => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = 5;
    const siblingCount = 1;

    /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
      */
    if (totalPageNumbers >= totalPageCount) {
      return range({start: 1, end: totalPageCount});
    }

    /*
          Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
      */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
        We show only 5 page numbers, the rest (greater or less than this range) are hidden
      */
    const shouldHidePreviousPages = leftSiblingIndex > 1;
    const shouldHideNextPages = rightSiblingIndex < totalPageCount - 1;

    if (!shouldHidePreviousPages && shouldHideNextPages) {
      const leftItemCount = 5;
      const leftRange = range({start: 1, end: leftItemCount});

      return [...leftRange];
    }

    if (shouldHidePreviousPages && !shouldHideNextPages) {
      const rightItemCount = 5;
      const rightRange = range({start: totalPageCount - rightItemCount + 1, end: totalPageCount});

      return [...rightRange];
    }

    if (shouldHidePreviousPages && shouldHideNextPages) {
      return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    }
    return undefined;
  }, [totalCount, pageSize, currentPage]);

  return paginationRange;
};
