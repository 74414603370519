import {useCallback, useState} from 'react';

export const useTagModal = () => {
  const [tagModalVisible, setTagModalVisible] = useState(false);
  const [tagToEdit, setTagToEdit] = useState<string>();

  const closeTagModal = useCallback(() => {
    setTagModalVisible(false);
  }, []);

  const openTagModal = useCallback(() => {
    setTagModalVisible(true);
  }, []);

  return {
    tagModalVisible,
    closeTagModal,
    openTagModal,
    tagToEdit,
    setTagToEdit,
  };
};
