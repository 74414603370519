import {Button} from '../../../components/button';
import {PremiumFeatureClickWrapper} from '../../../components/premium-feature-wrapper';
import {Permission} from '../../../types';

type Props = {
  label?: string;
  createPipeline: () => void;
  location: 'topbar' | 'empty screen';
};
export const CreatePipelineButton = ({label = 'Create list', createPipeline, location}: Props) => {
  return (
    <PremiumFeatureClickWrapper permission={Permission.Pipeline}>
      <Button
        onClick={createPipeline}
        variant="primary"
        data-intercom-target="Create pipeline"
        tracking={{label: 'create pipeline', location}}
      >
        {label}
      </Button>
    </PremiumFeatureClickWrapper>
  );
};
