import {useCallback} from 'react';
import {useMutationUpdateOnboardingStatus, useOnboardingStatus} from '../api';
import {Onboarding} from '../types';

const defaultOnboardingStatus: Onboarding = {
  hasFirstLastName: false,
  hasTeam: false,
  hasPendingTeamInvitations: false,
  linkedinUrl: '',
  hasSkippedLinkedin: false,
  linkedinSuggestions: [],
  shouldInviteMembers: false,
};

export const useShouldOpenImportModal = () => {
  const mutationUpdateOnboardingStatus = useMutationUpdateOnboardingStatus();
  const {data: onboardingStatus = defaultOnboardingStatus, isLoading} = useOnboardingStatus();
  const {linkedinUrl, hasSkippedLinkedin} = onboardingStatus;

  const turnOffShowingModal = useCallback(() => {
    mutationUpdateOnboardingStatus.mutate({hasSkippedLinkedin: true});
  }, [mutationUpdateOnboardingStatus]);

  const showingModalDisabled = hasSkippedLinkedin;
  const shouldCompleteLinkedInProfile = !isLoading && !linkedinUrl && !showingModalDisabled;

  return {
    shouldShowImportModal: !isLoading && !showingModalDisabled && shouldCompleteLinkedInProfile,
    turnOffShowingModal,
  };
};
