import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {addProfilesPreview} from '../../../../api/connectors';
import {VerifyLinkedinProfilesInput, VerifyLinkedinProfilesResponse} from '../../../../types';
import {isLinkedinUrl} from '../../../../utils/urlCheckers';
import {FormWrapper} from '../../../form-wrapper';
import {MatchingLinesInput} from '../../../form/matching-lines-input/MatchingLinesInput';
import Styles from '../AddMembersModal.module.scss';

const placeholder = `for example:
https://www.linkedin.com/in/connorsdavid/
https://www.linkedin.com/in/olivier-roth/
https://www.linkedin.com/in/rzmota/
...
`;

type FormState = VerifyLinkedinProfilesInput;

type Props = {
  onSuccess: (verificationResult: VerifyLinkedinProfilesResponse) => void;
  goBack: () => void;
};

export const FormScreen = ({onSuccess, goBack}: Props) => {
  const {
    formState: {isSubmitting, errors},
    control,
    handleSubmit,
  } = useForm<FormState>({
    defaultValues: {
      linkedinUrls: [],
    },
  });

  const onSubmit = useCallback(
    (formState: FormState) => {
      const {linkedinUrls} = formState;
      addProfilesPreview({linkedinUrls: linkedinUrls.filter(line => line.length)}).then(result => {
        onSuccess(result);
      });
    },
    [onSuccess]
  );

  return (
    <>
      <h4 className={Styles.header}>Add Connectors</h4>
      <p className={Styles.subheader}>Enter LinkedIn profile URLs in separate lines below</p>

      <hr className={Styles.divider} />

      <FormWrapper
        skipButton={{label: 'Back', onClick: goBack}}
        actionButton={{
          label: 'Continue',
          onClick: handleSubmit(onSubmit),
          loading: isSubmitting,
        }}
        reverseButtonsOrder
        onModal
        fullWidth
      >
        <div className={Styles.formContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="linkedinUrls"
              control={control}
              render={({field: {onChange, value}}) => (
                <MatchingLinesInput
                  value={value}
                  onChange={onChange}
                  validateSingleLineHandler={isLinkedinUrl}
                  placeholder={placeholder}
                  info={{
                    title: 'LinkedIn URL matching tester',
                    message: 'Paste LinkedIn profile URLs first.',
                    successMessage: 'All entered links are formatted correctly.',
                    failMessage: 'Some links are invalid. Please fix them and try again.',
                  }}
                  isInvalid={Boolean(errors.linkedinUrls)}
                  message={errors.linkedinUrls?.message}
                />
              )}
              rules={{
                required: 'Please enter at least one LinkedIn profile URL.',
                validate: (value: string[]) =>
                  value.every(isLinkedinUrl) || 'There are invalid LinkedIn profile URLs.',
              }}
            />
          </form>
        </div>

        <hr className={Styles.divider} />
      </FormWrapper>
    </>
  );
};
