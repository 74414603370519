import cs from 'classnames';
import {Gear as SubscriptionIcon, ArrowSquareUp as UpgradeIcon, Wallet as WalletIcon} from 'phosphor-react';
import {useNavigate} from 'react-router-dom';
import {useCheckPermission, useSubscription} from '../../../../hooks';
import {Permission, PlanType} from '../../../../types';
import {LoadingSpinner} from '../../../animations';
import {Button} from '../../../button';
import {ProgressBar} from '../../../progress-bar';
import {Tooltip} from '../../../tooltip';
import {UpgradeLink} from '../../../upgrade-link';
import Styles from './SubscriptionWidget.module.scss';

const TRIAL_TOTAL_DAYS = 30;

type Props = {
  collapsed: boolean;
};

export const SubscriptionWidget = ({collapsed}: Props) => {
  const canPlanUpdate = useCheckPermission(Permission.PlanUpdate);
  const {plan, shouldRefetchPlan, isPlanLoading} = useSubscription();

  const navigate = useNavigate();

  if (shouldRefetchPlan || isPlanLoading)
    return (
      <div className={Styles.subscriptionWidget}>
        <LoadingSpinner centered color="black" size="small" className="pb-4" />
      </div>
    );

  const handleSubscription = () => {
    navigate('/team-settings/subscription');
  };

  const {payment, type: planType, trialAvailable} = plan || {};
  const {trialActive, endDate, creditCardCollected, canceled} = payment || {};

  const trialDaysRemaining =
    (endDate && Math.ceil((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))) || 0;

  const trialEnding = trialDaysRemaining <= 7;

  const showUpgradeButton =
    canPlanUpdate &&
    (planType === PlanType.Free ||
      planType === PlanType.Base ||
      (trialActive && !creditCardCollected) ||
      canceled);
  const showSubscriptionButton = canPlanUpdate && !showUpgradeButton;
  const showTrialEnded = !trialAvailable && planType === PlanType.Free;
  const showCounter = trialActive && !creditCardCollected;
  const showPlanName = planType && !showTrialEnded && !showCounter;

  return (
    <div className={Styles.subscriptionWidget}>
      {showCounter && (
        <div className={Styles.trial}>
          <Tooltip content="days left in free trial" placement="right-center" disabled={!collapsed}>
            <ProgressBar
              maxValue={TRIAL_TOTAL_DAYS}
              currentValue={TRIAL_TOTAL_DAYS - trialDaysRemaining}
              hasWarningThreshold={trialEnding}
              bgColor="#F5F5F5"
              circular
            />
          </Tooltip>
          {!collapsed && (
            <span className={cs({['text-swarm-red']: trialEnding})}>days left in free trial</span>
          )}
        </div>
      )}
      {showTrialEnded && (
        <p className={Styles.trialEnded}>
          <Tooltip content="Your free trial has ended" placement="right-center" disabled={!collapsed}>
            <span className={Styles.freeTrialEndedIcon}>⚠️</span>
          </Tooltip>
          {!collapsed && 'Your free trial has ended'}
        </p>
      )}
      {showPlanName && (
        <p className={Styles.planName}>
          <span className={cs({[Styles.planIconCollapsed]: collapsed})}>
            <Tooltip content={`${planType} plan`} placement="right-center" disabled={!collapsed}>
              {planType === PlanType.Free ? (
                <span className={Styles.freePlanIcon}>⚠️</span>
              ) : (
                <WalletIcon size={18} className={Styles.paidPlanIcon} />
              )}
            </Tooltip>
          </span>{' '}
          {!collapsed && `${planType} plan`}
        </p>
      )}
      {showUpgradeButton && (
        <UpgradeLink>
          <Tooltip content="Upgrade plan" placement="right-center" disabled={!collapsed} offset={-10}>
            <Button
              variant="black"
              fullWidth={!collapsed}
              size="sm"
              className={collapsed ? Styles.upgradeButtonCollapsed : Styles.upgradeButton}
              tracking={{
                label: canceled ? 'renew plan' : 'upgrade plan',
                location: 'sidebar',
              }}
            >
              {collapsed ? (
                <UpgradeIcon className={Styles.upgradeIcon} size={16} />
              ) : canceled ? (
                'Renew plan'
              ) : (
                'Upgrade plan'
              )}
            </Button>
          </Tooltip>
        </UpgradeLink>
      )}
      {showSubscriptionButton && (
        <Tooltip content="Manage subscription" placement="right-center" disabled={!collapsed}>
          <Button
            variant="tertiary"
            fullWidth={!collapsed}
            size="sm"
            outline
            onClick={handleSubscription}
            className={collapsed ? Styles.subscriptionButtonCollapsed : Styles.subscriptionButton}
            tracking={{
              label: 'subscription',
              location: 'sidebar',
            }}
          >
            {collapsed ? <SubscriptionIcon className={Styles.subscriptionIcon} size={16} /> : 'Subscription'}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
