import {Tab} from '@headlessui/react';
import cs from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {useHash} from '../../hooks';
import {segmentTrack, slugify} from '../../utils';
import {Tooltip} from '../tooltip';
import Styles from './Tabs.module.scss';
import {TabsItem, TabsType} from './types';

type Props = {
  tabs: TabsType;
  defaultTab?: string; // `title` of certain tab
  inPage?: boolean; // when Tabs are used in page content (instead od modal, sidebar etc)
  trackEvents?: boolean; // whether to track tab clicks
  onTabChange?: (tabIdx: number) => void; // tab index as argument
};

export const Tabs = ({tabs, defaultTab, inPage, trackEvents, onTabChange}: Props) => {
  const {getHash, setHash, removeHash} = useHash();
  const locationHash = getHash();
  const indexFromHash = tabs.findIndex(({path}) => `#${path}` === locationHash);

  // decides which tab to be selected by default; primarily by index from hash (if valid), then by defaultTab
  const defaultTabIdx =
    indexFromHash !== -1 ? indexFromHash : defaultTab ? tabs.findIndex(({title}) => title === defaultTab) : 0;

  const [selectedIndex, setSelectedIndex] = useState(defaultTabIdx);

  const setHashIfProvided = useCallback(
    (index: number) => {
      const {path} = tabs[index];
      if (path) {
        setHash(path);
      } else {
        removeHash();
      }
    },
    [tabs, removeHash, setHash]
  );

  const sendTabEvent = (tabTitle: string) => {
    segmentTrack('Tab Switched', {
      label: tabTitle.toLowerCase(),
      location: 'profile',
    });
  };

  const handleOnClick = (index: number) => {
    if (trackEvents) {
      sendTabEvent(tabs[index].title);
    }
    setHashIfProvided(index);
  };

  const handleTabChange = useCallback(
    (tabIdx: number) => {
      setSelectedIndex(tabIdx);
      if (onTabChange) {
        onTabChange(tabIdx);
      }
    },
    [onTabChange]
  );

  useEffect(() => {
    setSelectedIndex(defaultTabIdx);
  }, [defaultTabIdx]);

  return (
    <div className={Styles.wrapper}>
      <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
        <Tab.List className={cs(Styles.tabsList, {[Styles.tabsListInPage]: inPage})}>
          {tabs.map((tab, index) => {
            const onClick = () => handleOnClick(index);
            return <TabsListItem tab={tab} onClick={onClick} key={tab.key || tab.title} inPage={inPage} />;
          })}
        </Tab.List>
        <Tab.Panels className={cs(Styles.contentWrapper, {[Styles.contentWrapperInPage]: inPage})}>
          {tabs.map(({title, content, key}) => (
            <Tab.Panel className={Styles.contentPanel} key={key || title}>
              {content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

type TabsListItemProps = {
  tab: TabsItem;
  onClick: () => void;
  inPage?: boolean;
};
const TabsListItem = ({tab, onClick, inPage}: TabsListItemProps) => {
  const {title, disabled, badge, tooltip} = tab;

  const label = (
    <>
      {inPage ? <span data-intercom-target={'tab-' + slugify(title)}>{title}</span> : title}
      {badge}
    </>
  );

  const labelWithTooltip = tooltip ? <Tooltip content={tooltip}>{label}</Tooltip> : label;

  return (
    <Tab
      onClick={onClick}
      className={({selected}) =>
        cs(Styles.tab, {
          [Styles.selectedTab]: selected,
          [Styles.unselectedTab]: !selected,
          [Styles.disabledTab]: disabled,
        })
      }
      disabled={disabled}
    >
      {labelWithTooltip}
    </Tab>
  );
};
