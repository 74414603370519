import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {Helmet} from 'react-helmet';
import {QueryClient, QueryClientProvider} from 'react-query';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import {
  Envs,
  initializeFirstPromoter,
  initializeGTM,
  initializeSegment,
  initializeSentry,
  isEnv,
} from './utils';
const queryClient = new QueryClient();

initializeGTM();
initializeSentry();
initializeSegment();
initializeFirstPromoter();

const isStaging = isEnv(Envs.STAGING);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    {isStaging && (
      <Helmet>
        <title>BeeSmoker – The Swarm</title>
        <link rel="icon" href="/beesmoker-favicon.ico" />
      </Helmet>
    )}
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
