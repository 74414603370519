import cs from 'classnames';
import {getInitialsFromNames} from '../../../utils';
import Styles from './Avatar.module.scss';

const SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  XL: 'xl',
  XXL: 'xxl',
};
type Size = (typeof SIZE)[keyof typeof SIZE];

const AVATAR_COLORS = [
  'blue',
  'yellow',
  'orange',
  'coral',
  'bubblegum',
  'violet',
  'purple',
  'turquoise',
] as const;

export type AvatarProps = {
  fullname: string;
  avatarImage?: string;
  size?: Size;
  empty?: boolean;
  outline?: boolean;
};

export const Avatar = ({avatarImage, fullname, size = 'sm', empty, outline}: AvatarProps) => {
  const [firstName, lastName] = fullname.split(' ');
  const colorIndex = fullname.length % AVATAR_COLORS.length;
  const bgColor = empty ? 'gray' : AVATAR_COLORS[colorIndex];
  const initials = getInitialsFromNames(firstName, lastName);

  return (
    <div
      className={cs(Styles.avatar, Styles[size], Styles[bgColor], {
        [Styles.outline]: outline,
        [Styles.image]: avatarImage,
      })}
      title={fullname}
    >
      {avatarImage ? <img src={avatarImage} alt={fullname} className={Styles.image} /> : initials}
    </div>
  );
};
