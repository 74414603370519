import {DashboardTasks} from '../../components/dashboard-tasks';
import {HelpCenterArticles} from '../../components/help-center-articles';
import {MainUseCaseGuides} from '../../components/main-use-case-guides';
import {User} from '../../types';
import {DashboardTitle} from './components/DashboardTitle';

type Props = {
  user: User;
};

export const Dashboard = ({user}: Props) => {
  const {firstName} = user;

  return (
    <>
      <DashboardTitle name={firstName} />
      <DashboardTasks />
      <MainUseCaseGuides />
      <HelpCenterArticles />
    </>
  );
};
