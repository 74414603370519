import {useCallback, useEffect, useState} from 'react';
import {useOnboardingContext} from '../../../../contexts';
import {segmentVirtualPageview} from '../../../../utils';
import {
  getExtensionVersion,
  getIsBlueAuthenticated,
  getUserBlueUrl,
} from '../../../../utils/extensionCommunication';
import {FormScreen} from './FormScreen';
import {InstallExtensionScreen} from './InstallExtensionScreen';

enum WorkExperienceStepScreens {
  InstallExtensionScreen = 'InstallExtensionScreen',
  FormScreen = 'FormScreen',
}
export enum InstallationWizardSteps {
  Initial = 'Initial',
  InstallationTips = 'InstallationTips',
  LoginToLinkedin = 'LoginToLinkedin',
  ConnectedToLinkedin = 'ConnectedToLinkedin',
}

type Props = {
  isChromiumBrowser: boolean;
};

export const WorkExperienceStep = ({isChromiumBrowser}: Props) => {
  const {onFinishStep} = useOnboardingContext();
  const [currentView, setCurrentView] = useState<WorkExperienceStepScreens>(
    isChromiumBrowser
      ? WorkExperienceStepScreens.InstallExtensionScreen
      : WorkExperienceStepScreens.FormScreen
  );

  const [wizardStep, setWizardStep] = useState<InstallationWizardSteps>(InstallationWizardSteps.Initial);
  const [blueUserUrl, setBlueUserUrl] = useState<string | undefined>(undefined);
  const [extensionVersion, setExtensionVersion] = useState<string | undefined>(undefined);
  const [blueAuthenticated, setBlueAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    segmentVirtualPageview('/add-overlaps');
    getExtensionVersion().then(version => {
      setExtensionVersion(version);
      if (version) {
        getIsBlueAuthenticated().then(isAuthenticated => {
          setBlueAuthenticated(isAuthenticated);
          if (isAuthenticated) {
            getUserBlueUrl().then(url => setBlueUserUrl(url));
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (blueUserUrl) {
      setCurrentView(WorkExperienceStepScreens.FormScreen);
    } else if (blueAuthenticated) {
      setWizardStep(InstallationWizardSteps.ConnectedToLinkedin);
    } else if (extensionVersion) {
      setWizardStep(InstallationWizardSteps.LoginToLinkedin);
    }
  }, [blueUserUrl, extensionVersion, blueAuthenticated]);

  const goToFormScreen = useCallback(() => setCurrentView(WorkExperienceStepScreens.FormScreen), []);

  switch (currentView) {
    case WorkExperienceStepScreens.InstallExtensionScreen:
      return (
        <InstallExtensionScreen
          onSkip={goToFormScreen}
          setBlueUserUrl={setBlueUserUrl}
          setBlueAuthenticated={setBlueAuthenticated}
          setExtensionVersion={setExtensionVersion}
          wizardStep={wizardStep}
          setWizardStep={setWizardStep}
        />
      );
    case WorkExperienceStepScreens.FormScreen:
      return <FormScreen onSuccess={onFinishStep} blueUserUrl={blueUserUrl} />;
    default:
      return <>Invalid screen</>;
  }
};
