import {Icon} from 'phosphor-react';

type FindOutMoreItemProps = {
  title: string;
  text: string;
  icon: Icon;
};
export const FindOutMoreItem = ({title, text, icon}: FindOutMoreItemProps) => {
  const Icon = icon;
  return (
    <div className="mb-6 flex flex-row gap-2">
      <div className="flex w-12 shrink-0 content-center justify-center text-center">
        <Icon size={25} />
      </div>
      <div className="text-left">
        <h3 className="text-lg font-bold text-swarm-black">{title}</h3>
        <p className="mb-0 mt-1 text-base font-light text-swarm-gray-700">{text}</p>
      </div>
    </div>
  );
};
