import {flexRender, HeaderGroup} from '@tanstack/react-table';
import cs from 'classnames';
import {ReactNode, useMemo} from 'react';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {useUiSetting} from '../../hooks';
import Styles from './TableHeader.module.scss';
import {ColumnSizeRecord} from './types';
import {getColumnWidthsSettingsKey} from './utils';

type Props<TItem> = {
  headerGroups: HeaderGroup<TItem>[];
  height: string;
  bulkActionsSlot?: ReactNode;
};

export const TableHeader = <TItem extends object>({headerGroups, height, bulkActionsSlot}: Props<TItem>) => {
  const columnWidthsSettingsKey = useMemo(() => getColumnWidthsSettingsKey(window.location.pathname), []);

  const initialSettingsWidths = headerGroups[0].headers.map(header => {
    return {
      id: header.id,
      width: header.getSize(),
    };
  });
  const [, setHeadersWidths] = useUiSetting<ColumnSizeRecord[]>(columnWidthsSettingsKey);

  const saveSize = (id: string, width: number) => {
    const newHeadersWidths = initialSettingsWidths.map(header => {
      if (header.id === id) {
        return {
          id,
          width,
        };
      }
      return header;
    });
    setHeadersWidths(newHeadersWidths);
  };

  return (
    <div className={Styles.stickyHeader}>
      {headerGroups.map(headerGroup => (
        <div key={headerGroup.id} className={Styles.tableHeader} style={{height}}>
          {bulkActionsSlot && <div className={Styles.bulkActions}>{bulkActionsSlot}</div>}
          {headerGroup.headers.map(header => {
            return (
              <div
                key={header.id}
                className={cs(Styles.tableTh)}
                style={{
                  left: header.getStart(),
                  width: header.getSize(),
                  height,
                }}
              >
                {header.isPlaceholder ? null : (
                  <div
                    className={cs(Styles.tableThContent, {
                      [Styles.tableThSortable]: header.column.getCanSort(),
                    })}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort()
                      ? {
                          asc: <FiChevronUp />,
                          desc: <FiChevronDown />,
                        }[header.column.getIsSorted() as string]
                      : null}
                  </div>
                )}
                {header.column.getCanResize() && (
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    onMouseUp={() => saveSize(header.id, header.getSize())}
                    onTouchEnd={() => saveSize(header.id, header.getSize())}
                    className={Styles.resizer}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
