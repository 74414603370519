import {ReactElement} from 'react';

type Props = {
  steps: ReactElement[];
  displayStepIndex: number;
};

export const SimpleCarousel = ({steps, displayStepIndex}: Props) => {
  return steps[displayStepIndex];
};
