import {DotsThreeVertical, Trash} from 'phosphor-react';
import {ReactElement, useCallback, useState} from 'react';
import {FiUser as UserIcon} from 'react-icons/fi';
import {Button} from '../../../components/button';
import {Dropdown} from '../../../components/dropdown';
import {PaidRoleBadge} from '../../../components/paid-role-badge';
import {useCheckPermission, useConfirm, useTeamMembers} from '../../../hooks';
import {Permission, Role} from '../../../types';
import {segmentTrack} from '../../../utils';
import {MembersListItem, MembersListItemStatus} from '../types';

type Props = {
  member: MembersListItem;
};
export const MemberOptionsDropdown = ({member}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {isConfirmed} = useConfirm();

  const canTeamMemberUpdate = useCheckPermission(Permission.TeamMemberUpdate);

  const {onUpdateMemberRole, onDeleteMember} = useTeamMembers();

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const isActive = member.status === MembersListItemStatus.Active;

  const isOwnerRow = member.role === Role.Owner;

  // Change role buttons
  const shouldDisplayChangeRoleButton = canTeamMemberUpdate && isActive;
  const changeRole = useCallback(
    (newRole: Role) => onUpdateMemberRole(member, newRole),
    [onUpdateMemberRole, member]
  );

  const changeRoleToAdminButton = (
    <Dropdown.Item
      key="make-admin"
      label={
        <>
          Make an Admin <PaidRoleBadge role={Role.Admin} />
        </>
      }
      type={{
        name: 'button',
        onClick: () => {
          changeRole(Role.Admin);
          segmentTrack('Selector Applied', {
            label: 'make an admin',
          });
          closeDropdown();
        },
      }}
      icon={<UserIcon />}
    />
  );
  const changeRoleToContributorButton = (
    <Dropdown.Item
      key="make-contributor"
      label={
        <>
          Make a Contributor <PaidRoleBadge role={Role.Contributor} />
        </>
      }
      type={{
        name: 'button',
        onClick: () => {
          changeRole(Role.Contributor);
          segmentTrack('Selector Applied', {
            label: 'make a contributor',
          });
          closeDropdown();
        },
      }}
      icon={<UserIcon />}
    />
  );
  const changeRoleToGuestButton = (
    <Dropdown.Item
      key="make-guest"
      label={
        <>
          Make a Guest <PaidRoleBadge role={Role.Guest} />
        </>
      }
      type={{
        name: 'button',
        onClick: () => {
          changeRole(Role.Guest);
          segmentTrack('Selector Applied', {
            label: 'make a guest',
          });
          closeDropdown();
        },
      }}
      icon={<UserIcon />}
    />
  );

  const rolesToButtonsMap: Record<Role, ReactElement[]> = {
    [Role.Owner]: [],
    [Role.Admin]: [changeRoleToContributorButton, changeRoleToGuestButton],
    [Role.Contributor]: [changeRoleToAdminButton, changeRoleToGuestButton],
    [Role.Guest]: [changeRoleToAdminButton, changeRoleToContributorButton],
    [Role.Connector]: [],
  };

  const changeRoleButtons = shouldDisplayChangeRoleButton ? rolesToButtonsMap[member.role] : null;

  // Remove button
  const shouldDisplayRemoveMemberButton = canTeamMemberUpdate && isActive && !isOwnerRow;
  const deleteMember = useCallback(
    () => onDeleteMember(member).then(() => setIsOpen(false)),
    [onDeleteMember, member]
  );

  const removeMemberButton = shouldDisplayRemoveMemberButton ? (
    <Dropdown.Item
      label="Remove"
      type={{
        name: 'button',
        onClick: async () => {
          closeDropdown();
          const agreed = await isConfirmed(
            'Are you sure you want to remove this member from your team? Their connections will be removed.',
            'removal'
          );
          if (agreed) {
            deleteMember();
          }
        },
        variant: 'danger',
      }}
      icon={<Trash weight="bold" />}
      tracking={{
        label: 'remove',
        location: 'remove member modal',
      }}
    />
  ) : null;

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => (
        <Button
          iconOnly
          icon={<DotsThreeVertical weight="bold" size={20} />}
          onClick={() => setIsOpen(open => !open)}
          variant="tertiary"
        />
      )}
      placement="bottom-end"
    >
      {changeRoleButtons}
      {removeMemberButton}
    </Dropdown>
  );
};
