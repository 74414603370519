import {AxiosResponse} from 'axios';
import {useQuery, UseQueryOptions} from 'react-query';
import {
  CreateBulkSuggestionsInput,
  CreatePipelineRequest,
  GetPipelinesRequest,
  GetPipelinesResponse,
  PipelineCommonResponse,
  UpdateProfileRequest,
} from '../types';
import {apiClient} from './apiClient';
import {getBffProfileListAsCsv} from './bffProfiles';

// GET PIPELINES LIST

const getPipelines = (params: GetPipelinesRequest) =>
  apiClient.get<GetPipelinesResponse>('/pipelines', {params}).then(res => res.data);
export const useQueryPipelines = (
  teamId: string,
  params: GetPipelinesRequest,
  options?: UseQueryOptions<GetPipelinesResponse>
) => {
  return useQuery<GetPipelinesResponse>(['pipelines', teamId, params], () => getPipelines(params), options);
};

// GET PIPELINE

const getPipeline = (id: string) =>
  apiClient.get<PipelineCommonResponse>(`/pipelines/${id}`).then(res => res.data);

export const useQueryPipeline = (
  id: string,
  teamId: string,
  options?: UseQueryOptions<PipelineCommonResponse>
) => useQuery<PipelineCommonResponse>(['pipeline', {id, teamId}], () => getPipeline(id), options);

// CREATE PIPELINE

export const createPipeline = (
  teamId: string,
  input: CreatePipelineRequest
): Promise<PipelineCommonResponse> =>
  apiClient
    .post<
      PipelineCommonResponse,
      AxiosResponse<PipelineCommonResponse>,
      CreatePipelineRequest
    >('/pipelines', input)
    .then(resp => resp.data);

// UPDATE PIPELINE

export const updatePipeline = (
  input: CreatePipelineRequest,
  pipelineId: string
): Promise<PipelineCommonResponse> =>
  apiClient
    .put<
      PipelineCommonResponse,
      AxiosResponse<PipelineCommonResponse>,
      CreatePipelineRequest
    >(`/pipelines/${pipelineId}`, input)
    .then(resp => resp.data);

// REMOVE PIPELINE

export const deletePipeline = (teamId: string, pipelineId: string) =>
  apiClient.delete<void>(`/pipelines/${pipelineId}`);

// CREATE SUGGESTION

export const createSuggestion = (pipelineId: string, profileId: string) =>
  apiClient.put(`/pipelines/${pipelineId}/profiles/${profileId}/suggestions`);

// CREATE MULTIPLE SUGGESTIONS

export const createBulkSuggestions = async (pipelineId: string, input: CreateBulkSuggestionsInput) =>
  apiClient.put(`/pipelines/${pipelineId}/suggestions`, input);

// UPDATE PROFILE IN PIPELINE STATUS

export const updateStatus = (pipelineId: string, profileId: string, input: UpdateProfileRequest) =>
  apiClient.put(`/pipelines/${pipelineId}/profiles/${profileId}`, input);

// GET CSV

export const getPipelineProfilesCSV = async (pipelineId: string) =>
  getBffProfileListAsCsv({filters: {inPipeline: [pipelineId]}});

export const deletePipelineProfile = (pipelineId: string, profileId: string) =>
  apiClient.delete(`/pipelines/${pipelineId}/profiles/${profileId}`);

export const deletePipelineProfiles = (pipelineId: string, profileIDs: string[]) =>
  apiClient.delete(`/pipelines/${pipelineId}/profiles`, {data: {profileIDs}});
