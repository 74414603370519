import {capitalize} from 'lodash';
import {PipelineCommonResponse} from '../../../types';
import {MaybePlaceholder, SubCard} from '../components';
import Styles from './PipelineDetails.module.scss';

export const Details = ({pipeline}: {pipeline: PipelineCommonResponse}) => {
  return (
    <div className={Styles.detailsWrapper}>
      <SubCard title="List type">
        <MaybePlaceholder value={capitalize(pipeline.pipelineType)} />
      </SubCard>
      <SubCard title="List name">
        <MaybePlaceholder value={pipeline.title} />
      </SubCard>
      <SubCard title="Description">
        <MaybePlaceholder value={pipeline.descriptionUrl} />
      </SubCard>
      <SubCard title="Forwardable template">
        <MaybePlaceholder value={pipeline.forwardableTemplate} asHtml />
      </SubCard>

      <SubCard title="Privacy">
        <MaybePlaceholder value={`This list is ${pipeline.private ? 'private' : 'public'}`} />
      </SubCard>
    </div>
  );
};
