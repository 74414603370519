import {addHttpsToUrl, segmentTrack} from '../../utils';
import {LinkedinIcon} from '../icons';

const trackClick = () =>
  segmentTrack('Button Clicked', {
    label: 'linkedin icon',
  });

type Props = {
  linkedinUrl: string;
};

export const LinkedinProfileLink = ({linkedinUrl}: Props) => {
  return (
    <a
      href={addHttpsToUrl(linkedinUrl)}
      target="_blank"
      rel="noreferrer"
      onClick={trackClick}
      className="shrink-0 opacity-50 grayscale hover:opacity-100 hover:grayscale-0"
    >
      <LinkedinIcon className="ml-1 size-3 shrink-0 cursor-pointer" />
    </a>
  );
};
