import {Dialog} from '@headlessui/react';

import Styles from './ImportConnectionsCSV.module.scss';
import {ImportConnectionsCSVForm} from './ImportConnectionsCSVForm';

type Props = {
  onClose: () => void;
  goToSuccessScreen: () => void;
};

export const ImportConnectionsCSV = ({onClose, goToSuccessScreen}: Props) => {
  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        Import Linkedin CSV manually
      </Dialog.Title>

      <Dialog.Description className="mb-4 mt-2 font-normal">
        Download your connections form LinkedIn
      </Dialog.Description>

      <ul className={Styles.list}>
        <li>
          Get a copy of your data from Linkedin:
          <br />
          <a
            href="https://www.linkedin.com/mypreferences/d/download-my-data"
            target="_blank"
            rel="noreferrer"
            className={Styles.link}
          >
            Follow this link
          </a>{' '}
          or go to Settings & Privacy.
        </li>
        <li>
          Pick “<em>Connections</em>” and “<em>Request archive</em>”.
        </li>
        <li>You’ll receive an email from Linkedin with a zip file after a few minutes.</li>
        <li>
          Unzip it and save the “<em>Connections.CSV</em>” file.
        </li>
      </ul>

      <ImportConnectionsCSVForm onClose={onClose} goToSuccessScreen={goToSuccessScreen} />
    </>
  );
};
