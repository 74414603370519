import React from 'react';
import {Role} from '../../../../types';
import {Button} from '../../../button';
import {RoleIcon} from '../../../icons';
import {CheckMark, XMark} from '../../../marks';

type RolesToChoose = Role.Admin | Role.Contributor | Role.Guest | Role.Connector;
const rolesToChoose: RolesToChoose[] = [Role.Admin, Role.Contributor, Role.Guest, Role.Connector];

const rolesPermissions: Record<string, Record<RolesToChoose, boolean>> = {
  Overlaps: {
    [Role.Admin]: true,
    [Role.Contributor]: true,
    [Role.Guest]: true,
    [Role.Connector]: true,
  },
  'LinkedIn Connections': {
    [Role.Admin]: true,
    [Role.Contributor]: true,
    [Role.Guest]: true,
    [Role.Connector]: false,
  },
  'Email & Calendar Contacts': {
    [Role.Admin]: true,
    [Role.Contributor]: true,
    [Role.Guest]: true,
    [Role.Connector]: false,
  },
  'Intro Requests': {
    [Role.Admin]: true,
    [Role.Contributor]: true,
    [Role.Guest]: true,
    [Role.Connector]: false,
  },
  'Full Network Visibility': {
    [Role.Admin]: true,
    [Role.Contributor]: true,
    [Role.Guest]: false,
    [Role.Connector]: false,
  },
  'Member Management': {
    [Role.Admin]: true,
    [Role.Contributor]: false,
    [Role.Guest]: false,
    [Role.Connector]: false,
  },
};

type Props = {
  setSelectedRole: (role: Role) => void;
};

export const RolesOverview = ({setSelectedRole}: Props) => {
  return (
    <div className="mx-auto w-full py-4">
      <div className="flex justify-end pb-4">
        {rolesToChoose.map(role => (
          <div key={role} className="flex w-1/5 flex-col items-center">
            <RoleIcon role={role} className="mb-1 h-4" />
            <span className="text-center font-semibold capitalize">{role}</span>
          </div>
        ))}
      </div>

      {Object.entries(rolesPermissions).map(([feature, rolePermissions]) => (
        <div key={feature} className="flex items-center border-t border-swarm-gray-400">
          <div className="w-1/5 py-1 text-sm">
            <div className="w-32">{feature}</div>
          </div>
          {rolesToChoose.map(role => (
            <div key={role} className="w-1/5 p-2 text-center">
              {rolePermissions[role] ? (
                <CheckMark size="x-small" shape="circle" />
              ) : (
                <XMark size="x-small" shape="circle" />
              )}
            </div>
          ))}
        </div>
      ))}

      <div className="flex justify-end pt-4">
        {rolesToChoose.map(role => (
          <div key={role} className="flex w-1/5 flex-col items-center px-2">
            <Button onClick={() => setSelectedRole(role)} variant="primary" fullWidth size="md">
              {role === Role.Connector ? 'Add' : 'Invite'}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
