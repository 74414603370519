import {ReactElement, ReactNode} from 'react';
import {IconContext} from 'react-icons';
import {
  FaAngellist as Angellist,
  FaBehance as Behance,
  FaDribbble as Dribbble,
  FaEllo as Ello,
  FaFacebook as Facebook,
  FaFlickr as Flickr,
  FaFoursquare as Foursquare,
  FaGithub as Github,
  FaGitlab as Gitlab,
  FaGoogle as Google,
  FaInstagram as Instagram,
  FaLinkedin as Linkedin,
  FaMeetup as Meetup,
  FaPinterest as Pinterest,
  FaQuora as Quora,
  FaReddit as Reddit,
  FaStackOverflow as Stackoverflow,
  FaVimeo as Vimeo,
  FaWordpress as Wordpress,
  FaXing as Xing,
  FaYoutube as Youtube,
} from 'react-icons/fa';
import {FaXTwitter as X} from 'react-icons/fa6';
import {GrSoundcloud as Soundcloud} from 'react-icons/gr';
import {
  SiCrunchbase as Crunchbase,
  SiGravatar as Gravatar,
  SiIndeed as Indeed,
  SiMyspace as Myspace,
} from 'react-icons/si';
import {addHttpsToUrl} from '../../utils';
import Styles from './Vcard.module.scss';
import {SocialMediaEnum} from '../../types';

const IconsMap: Record<SocialMediaEnum, () => ReactNode> = {
  aboutme: () => <img src="/icons/current-step.svg" alt="aboutme icon" className="mr-4 mt-1 h-5" />,
  angellist: () => <Angellist />,
  behance: () => <Behance />,
  crunchbase: () => <Crunchbase />,
  dribbble: () => <Dribbble />,
  ello: () => <Ello />,
  facebook: () => <Facebook />,
  flickr: () => <Flickr />,
  foursquare: () => <Foursquare />,
  github: () => <Github />,
  gitlab: () => <Gitlab />,
  google: () => <Google />,
  gravatar: () => <Gravatar />,
  indeed: () => <Indeed />,
  instagram: () => <Instagram />,
  klout: () => <img src="/icons/klout.svg" alt="klout icon" className="mr-4 mt-1 h-5" />,
  linkedin: () => <Linkedin />,
  meetup: () => <Meetup />,
  myspace: () => <Myspace />,
  pinterest: () => <Pinterest />,
  quora: () => <Quora />,
  reddit: () => <Reddit />,
  soundcloud: () => <Soundcloud />,
  stackoverflow: () => <Stackoverflow />,
  twitter: () => <X />,
  vimeo: () => <Vimeo />,
  wordpress: () => <Wordpress />,
  xing: () => <Xing />,
  youtube: () => <Youtube />,
};

type Props = {
  fullName: string;
  specialization: string;
  where: ReactNode;
  profiles: {network: SocialMediaEnum | ''; url: string}[];
  avatar?: ReactElement;
  icons?: ReactElement;
};

export const Vcard = ({fullName, specialization, where, profiles, avatar, icons}: Props) => {
  return (
    <div className={Styles.vcard}>
      {avatar}
      <div>
        <h2 className={Styles.title}>
          {fullName}
          {icons}
        </h2>
        {specialization && (
          <div className={Styles.specialization}>
            <h3 className={Styles.specializationHeader}>
              {specialization}
              <br />
              {where && <span className={Styles.where}>{where}</span>}
            </h3>
          </div>
        )}
        <div className={Styles.socials}>
          <IconContext.Provider value={{className: 'text-xl mr-4 mt-1'}}>
            {profiles.map(({network, url}, index) => {
              return network && url ? (
                <a href={addHttpsToUrl(url)} target="_blank" rel="noreferrer" key={index}>
                  {IconsMap[network]()}
                </a>
              ) : null;
            })}
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};
