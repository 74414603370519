import {getConfig, loadScript} from '../../utils';
import {CodeClientConfig} from './types';

const {googleOauthClientId} = getConfig();

declare global {
  interface Window {
    google?: {
      accounts: {
        oauth2: {
          initCodeClient: (config: CodeClientConfig) => {
            requestCode: () => void;
          };
        };
      };
    };
  }
}

const GAPI_SCRIPT_URL = 'https://accounts.google.com/gsi/client';

type GetGoogleAuthCodeParams = {
  onSuccess: (authCode: string) => void;
  scope?: string;
};

const DEFAULT_SCOPE = 'profile';

export const getGoogleAuthCode = async ({onSuccess, scope = DEFAULT_SCOPE}: GetGoogleAuthCodeParams) => {
  if (!googleOauthClientId) {
    throw new Error('Google API Client config is not set');
  }

  await loadScript(GAPI_SCRIPT_URL);

  window.google?.accounts.oauth2
    .initCodeClient({
      client_id: googleOauthClientId,
      scope,
      ux_mode: 'popup',
      callback: response => {
        if (response.error) {
          console.error(response.error, response.error_description, response.error_uri);
        }

        onSuccess(response.code);
      },
    })
    .requestCode();
};
