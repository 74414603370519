import {AuthBox, SignIn, SignInAside, TermsAgreements} from '../../../components/auth';

type Props = {
  afterSignIn: () => unknown;
};

export const Welcome = ({afterSignIn}: Props) => {
  return (
    <AuthBox
      aside={<SignInAside />}
      headerTitle={
        <>
          Join and build up your <br /> company network
        </>
      }
      headerDescription={
        <>
          We recommend using your work email. <br />
          It keeps work and life separate.
        </>
      }
    >
      <SignIn onSuccess={afterSignIn} />
      <TermsAgreements />
    </AuthBox>
  );
};
