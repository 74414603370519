import {ReactNode, useCallback, useState} from 'react';
import {FiCheck as CheckIcon, FiX as XIcon} from 'react-icons/fi';
import {getCookie, setCookie} from 'typescript-cookie';
import {useMutationStartGoogleImportTask} from '../../api';
import {useCurrentUser} from '../../hooks';
import {getConfig} from '../../utils';
import {Button} from '../button';
import {ButtonProps} from '../button/Button';
import {ImageGoogleButton} from '../sign-in-with-google-button';
import {Tooltip} from '../tooltip';
import {getGoogleAuthCode} from './getGoogleAuthCode';

const {googleBrandingGuidesBtnEnabled} = getConfig();

const COOKIE_NAME = 'googleImportDone';
const COOKIE_EXPIRATION_DAYS = 1;

enum ButtonState {
  ready = 'ready',
  inProgress = 'inProgress',
  done = 'done',
  error = 'error',
}

type Props = {
  label?: string;
  className?: string;
  onClick?: () => unknown;
  onSuccess?: () => unknown;
};

const SCOPES_FOR_GOOGLE_IMPORT = [
  'https://www.googleapis.com/auth/contacts.readonly',
  'https://www.googleapis.com/auth/contacts.other.readonly',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/calendar.readonly',
].join(' ');

const getLabel = (state: ButtonState, readyLabel?: string): string => {
  switch (state) {
    case ButtonState.ready:
      return readyLabel || 'Import';
    case ButtonState.inProgress:
      return 'Importing';
    case ButtonState.done:
      return 'Imported';
    case ButtonState.error:
      return 'Try again';
    default:
      ((_: never) => {})(state);
      return '';
  }
};

const getVariant = (state: ButtonState): ButtonProps['variant'] => {
  switch (state) {
    case ButtonState.ready:
      return 'primary';
    case ButtonState.inProgress:
      return 'secondary';
    case ButtonState.done:
      return 'green';
    case ButtonState.error:
      return 'red';
    default:
      ((_: never) => {})(state);
      return 'primary';
  }
};

const getStatus = (state: ButtonState) => {
  switch (state) {
    case ButtonState.error:
      return (
        <StatusWrapper>
          <XIcon size={20} /> No access
        </StatusWrapper>
      );
    default:
      return null;
  }
};

const getIcon = (state: ButtonState) => {
  switch (state) {
    case ButtonState.done:
      return <CheckIcon size={20} />;
    default:
      return null;
  }
};

const getDisabled = (state: ButtonState) => {
  switch (state) {
    case ButtonState.done:
      return true;
    default:
      return false;
  }
};

const getTooltip = (state: ButtonState) => {
  switch (state) {
    case ButtonState.error:
      return 'Allow all permissions to access your\ncontacts. Try again and select all\ncheckboxes on the next screen.';
    // TODO: uncomment when we have a way to remove access
    // case ButtonState.done:
    //   return 'We will no longer have access to\nyour Google Contacts. \nImported connections will remain.';
    default:
      return undefined;
  }
};

export const GoogleImportButton = ({className, label, onClick, onSuccess}: Props) => {
  const {id} = useCurrentUser();
  const cookieName = `${COOKIE_NAME}_${id}`;
  const [buttonState, setButtonState] = useState<ButtonState>(
    !!getCookie(cookieName) ? ButtonState.done : ButtonState.ready
  );

  const mutationStartGoogleImportTask = useMutationStartGoogleImportTask();

  const onFinished = useCallback(() => {
    setButtonState(ButtonState.done);
    setCookie(cookieName, '1', {expires: COOKIE_EXPIRATION_DAYS});
    onSuccess?.();
  }, [cookieName, onSuccess]);

  const onGatheredAuthCode = useCallback(
    (googleAuthCode: string) => {
      mutationStartGoogleImportTask.mutate(
        {googleAuthCode},
        {
          onSuccess: onFinished,
          onError: () => setButtonState(ButtonState.error),
        }
      );
    },
    [mutationStartGoogleImportTask, onFinished]
  );

  const onButtonClick = useCallback(() => {
    setButtonState(ButtonState.inProgress);
    getGoogleAuthCode({
      scope: SCOPES_FOR_GOOGLE_IMPORT,
      onSuccess: onGatheredAuthCode,
    });
    onClick?.();
  }, [onClick, onGatheredAuthCode]);

  const tooltipContent = getTooltip(buttonState);

  const googleButton = <ImageGoogleButton onClick={onButtonClick} />;
  const swarmButton = (
    <Button
      variant={getVariant(buttonState)}
      disabled={getDisabled(buttonState)}
      className={className}
      onClick={onButtonClick}
      loading={buttonState === ButtonState.inProgress}
      icon={getIcon(buttonState)}
    >
      {getLabel(buttonState, label)}
    </Button>
  );

  const btn = googleBrandingGuidesBtnEnabled ? googleButton : swarmButton;

  return (
    <div className="flex flex-row gap-4">
      {getStatus(buttonState)}
      {tooltipContent ? <Tooltip content={tooltipContent}>{btn}</Tooltip> : btn}
    </div>
  );
};

const StatusWrapper = ({children}: {children: ReactNode}) => (
  <div className="flex items-center gap-2 font-bold">{children}</div>
);
