import {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {FiCheck as CheckIcon} from 'react-icons/fi';
import {createTag, updateTag} from '../../../../api';
import {TagColors, TagItem} from '../../../../types';
import {trackError} from '../../../../utils';
import {Input, Message} from '../../../form';
import {FormWrapper} from '../../../form-wrapper';
import {notify} from '../../../notifications';
import {Tag} from '../../tag';
import Styles from './TagForm.module.scss';

type TagFormState = {
  name: string;
  color: TagColors;
};

type Props = {
  onAssign?: (tagId: string) => Promise<unknown>;
  onAssignSuccess?: (tag: TagItem) => void;
  onClose?: () => void;
  onSaveSuccess?: () => void;
  tag?: TagItem;
};

const placeholderTagContent = 'New tag';

export const TagForm = ({onAssign, onAssignSuccess, onClose, onSaveSuccess, tag}: Props) => {
  const [isAssigning, setIsAssigning] = useState(false);
  const {
    formState: {isSubmitting, errors},
    setError,
    register,
    control,
    watch,
    handleSubmit,
  } = useForm<TagFormState>({
    defaultValues: {
      name: tag?.name || '',
      color: tag?.color || TagColors.Gray,
    },
  });

  type ErrorProps = {
    response: {
      status: number;
    };
  };
  const handleError = useCallback(
    ({response}: ErrorProps) => {
      if (response.status === 422) {
        setError('root.serverError', {
          type: 'manual',
          message: 'This tag name already exists.',
        });
      } else {
        setError('root.serverError', {
          type: 'manual',
          message: 'Something went wrong. Please try again later.',
        });
      }
    },
    [setError]
  );

  const onCreate = useCallback(
    ({name, color}: TagFormState) => {
      createTag({name, color})
        .then(tag => {
          const {id} = tag;
          if (onAssign) {
            setIsAssigning(true);
            onAssign(id).then(() => {
              onSaveSuccess?.();
              onAssignSuccess?.(tag);
              notify(`Tag "${name}" created and assigned!`);
              setIsAssigning(false);
              onClose?.();
            });
          } else {
            notify(`Tag "${name}" created!`);
            onSaveSuccess?.();
            onClose?.();
          }
        })
        .catch(e => {
          handleError(e);
          trackError(e);
        });
    },

    [onAssign, onAssignSuccess, onSaveSuccess, onClose, handleError]
  );

  const onEdit = useCallback(
    ({name, color}: TagFormState) => {
      if (name === tag?.name && color === tag.color) {
        notify(`Tag "${name}" left unchanged.`);
        onClose?.();
        return;
      }
      tag &&
        updateTag({name, color}, tag.id)
          .then(() => {
            if (tag.name !== name) {
              notify(`Tag "${tag.name}" updated to "${name}"!`);
            } else {
              notify(`Tag "${name}" updated!`);
            }
            onSaveSuccess?.();
            onClose?.();
          })
          .catch(e => {
            handleError(e);
            trackError(e);
          });
    },
    [onSaveSuccess, onClose, handleError, tag]
  );

  const form = (
    <form className={Styles.createTagForm}>
      <div>
        <Tag color={watch('color')} content={watch('name') || placeholderTagContent} />
      </div>
      <div>
        <span className={Styles.inputLabel}>name</span>
        <Input
          {...register('name', {
            required: 'Please enter a tag name.',
            minLength: {value: 2, message: 'Tag name must be at least 2 characters long.'},
          })}
          type="text"
          placeholder={placeholderTagContent}
          isInvalid={Boolean(errors.name)}
          message={errors.name?.message}
        />
      </div>
      <div>
        <span className={Styles.inputLabel}>color</span>
        <Controller
          control={control}
          name="color"
          render={({field: {onChange, value}}) => <ColorSelect value={value} onChange={onChange} />}
        />
      </div>
      <p className={Styles.info}>You will be able to edit it later in the settings.</p>
      {errors.root?.serverError && (
        <Message message={errors.root.serverError.message || ''} hasError={true} className="py-1" />
      )}
    </form>
  );

  return (
    <FormWrapper
      {...(onClose && {skipButton: {label: 'Cancel', onClick: onClose}})}
      actionButton={{
        label: onAssign ? 'Submit and assign' : 'Submit',
        onClick: handleSubmit(tag ? onEdit : onCreate),
        loading: isSubmitting || isAssigning,
        tracking: tag
          ? {label: 'save', location: 'edit tag modal'}
          : {label: 'submit', location: 'create tag modal'},
      }}
      reverseButtonsOrder
      onModal
      fullWidth
    >
      {form}
      <hr className={Styles.divider} />
    </FormWrapper>
  );
};

type ColorSelectProps = {
  value: string;
  onChange: (value: string) => void;
};

const ColorSelect = ({value, onChange}: ColorSelectProps) => (
  <div className={Styles.colorSelect}>
    {(Object.keys(TagColors) as Array<keyof typeof TagColors>).map(color => (
      <span onClick={() => onChange(TagColors[color])} key={color}>
        <Tag
          color={TagColors[color]}
          className={Styles.colorSelectTag}
          content={value === TagColors[color] ? <CheckIcon /> : ''}
        />
      </span>
    ))}
  </div>
);
