import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './EmailCellContent.module.scss';

type Props = {
  emails: string[];
};

export const EmailsCellContent = ({emails}: Props) => {
  const linkifiedEmails = emails.map((email, idx) => (
    <>
      {idx > 0 && <span key={idx}>, </span>}
      <a href={`mailto:${email}`} className={Styles.link} key={email}>
        {email}
      </a>
    </>
  ));

  return (
    <TruncateWithTooltip content={linkifiedEmails}>
      <span className={Styles.wrapper}>{linkifiedEmails}</span>
    </TruncateWithTooltip>
  );
};
