import {FiBook as BookIcon} from 'react-icons/fi';
import {ArticleCard} from './ArticleCard';
import {articles} from './consts';
import Styles from './HelpCenterArticles.module.scss';

export const HelpCenterArticles = () => {
  return (
    <div className={Styles.wrapper}>
      <h1 className={Styles.header}>
        <BookIcon size={28} />
        Help Center
      </h1>
      <div className={Styles.content}>
        {articles.map((article, idx) => (
          <ArticleCard article={article} key={idx} />
        ))}
      </div>
    </div>
  );
};
