import cs from 'classnames';
import {MouseEventHandler, ReactElement, useCallback} from 'react';
import {FiLock as LockIcon, FiX as RemoveIcon} from 'react-icons/fi';
import {useLayoutContext} from '../../../contexts';
import {useLinkInButton, usePremiumFeatureModal} from '../../../hooks';
import {segmentTrack} from '../../../utils';
import {Tooltip} from '../../tooltip';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './Navigation.module.scss';

type Props = {
  isActive: boolean;
  icon: ReactElement;
  label: string;
  path: string;
  locked?: boolean;
  removeItem?: () => void;
  tracking?: Record<string, string>;
};

export const NavItem = ({isActive, icon, label, path, locked, removeItem, tracking}: Props) => {
  const showPremiumFeatureModal = usePremiumFeatureModal();
  const {sidebarCollapsed} = useLayoutContext();
  const {onClick: appNavigate, href: navItemHref} = useLinkInButton(path);

  const handleNavigate = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    e => {
      segmentTrack(
        'Button Clicked',
        tracking || {
          label,
          location: 'sidebar',
        }
      );
      if (locked) {
        showPremiumFeatureModal();
        e.preventDefault();
      } else {
        appNavigate(e);
      }
    },
    [appNavigate, label, locked, showPremiumFeatureModal, tracking]
  );

  const handleRemoveItem = useCallback<MouseEventHandler<HTMLSpanElement>>(
    e => {
      e.stopPropagation();
      e.preventDefault();
      removeItem?.();
    },
    [removeItem]
  );

  return (
    <a
      href={navItemHref}
      className={cs(Styles.navItem, {
        [Styles.navItemActive]: isActive,
        [Styles.navItemIconOnly]: sidebarCollapsed,
        [Styles.navItemLocked]: locked,
      })}
      onClick={handleNavigate}
    >
      <Tooltip content={label} placement="right-center" disabled={!sidebarCollapsed}>
        <span className={Styles.navItemInner}>
          <span className={Styles.icon}>{icon}</span>
          <TruncateWithTooltip content={label} placement="right-center">
            {sidebarCollapsed ? null : label}
          </TruncateWithTooltip>
          {locked ? (
            <span className={Styles.iconRight}>
              <LockIcon />
            </span>
          ) : removeItem ? (
            <span className={cs(Styles.iconRight, Styles.iconRemove)} onClick={handleRemoveItem}>
              <Tooltip content="Remove favorite" placement="top-center" hideHovered offset={16}>
                <RemoveIcon />
              </Tooltip>
            </span>
          ) : null}
        </span>
      </Tooltip>
    </a>
  );
};
