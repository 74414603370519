import cs from 'classnames';
import {UserCircle} from 'phosphor-react';
import {Avatar, AvatarWithFullname} from '../../components/avatar';
import {Card} from '../../components/card';
import {DataList, DataListItem} from '../../components/list';
import {ProfileCrmData} from '../../types';
import {prepareCrmData, prettifyValue, transformLabel} from '../../utils';
import Styles from './SingleProfilePageContent.module.scss';

type Props = {
  profileCrmData: ProfileCrmData;
};

export const CrmData = ({profileCrmData}: Props) => {
  const {crmData, initialAmount} = prepareCrmData(profileCrmData.rawData);

  return (
    <div className={Styles.profileWidthContainer}>
      <div className={cs(Styles.profileColumn, Styles.crmDataColumn)}>
        <DataList
          items={[
            ...(profileCrmData.owner
              ? [
                  {
                    label: 'Contact Owner',
                    value: <AvatarWithFullname fullname={profileCrmData.owner.fullName} />,
                  },
                ]
              : []),
            ...(profileCrmData.emails
              ? [
                  {
                    label: 'Email Address',
                    value: profileCrmData.emails.join(', '),
                  },
                ]
              : []),
          ]}
          headerTitle="Contact Details"
          headerIcon={<UserCircle />}
          className={Styles.profileContentBlock}
          orientation="stacked"
          bottomBorder
        />

        <DataList
          items={crmData.map(
            ([label, value]) =>
              ({
                label: transformLabel(label),
                value: prettifyValue(value),
              }) satisfies DataListItem
          )}
          headerTitle="Raw CRM Data"
          headerIcon={<UserCircle />}
          className={Styles.profileContentBlock}
          orientation="stacked"
          cols={2}
          bottomBorder
          initialAmount={initialAmount}
          emptyItemsPlaceholder="-"
        />

        <DataList
          items={[
            {
              label: 'Synced at',
              value: new Date(profileCrmData.lastSyncedAt).toLocaleString(),
            },
          ]}
          className="mt-8 w-1/2"
        />
      </div>

      {profileCrmData.owner && (
        <div className={Styles.profileColumn}>
          <DataList
            items={[
              {
                label: '',
                value: (
                  <Card className="px-4 py-6">
                    <h3 className="pb-4 text-lg font-semibold">{profileCrmData.connectedCompany.name}</h3>
                    <div className="flex flex-row gap-2 ">
                      <span className="font-light text-swarm-gray-700">Owner</span>
                      <Avatar fullname={profileCrmData.owner.fullName} /> {profileCrmData.owner.fullName}
                    </div>
                  </Card>
                ),
              },
            ]}
            headerTitle="Connected Company"
            headerIcon={<UserCircle />}
            orientation="stacked"
            className={Styles.profileContentBlock}
          />
        </div>
      )}
    </div>
  );
};
