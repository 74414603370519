import cs from 'classnames';
import {parse} from 'papaparse';
import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useUploadCSV} from '../../../api';
import {trackError} from '../../../utils';
import {Button} from '../../button';
import {trackCancelButtonOnStrategyView, trackStartImportClick} from '../analytics.utils';
import {FilePickerContent} from '../FilePickerContent';

type Props = {
  onClose: () => void;
  goToSuccessScreen: () => void;
};

const isValidExtension = (filename: string): boolean => {
  const validExtension = '.csv';
  const fileExt = filename.substring(filename.lastIndexOf('.'));
  if (fileExt.indexOf(validExtension) < 0) {
    return false;
  }
  return true;
};

export const ImportConnectionsCSVForm = ({onClose, goToSuccessScreen}: Props) => {
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [isDraggedOverArea, setIsDraggedOverArea] = useState(false);

  const filePickerRef = useRef<HTMLLabelElement>(null);

  const {mutateAsync} = useUploadCSV();

  const {
    register,
    handleSubmit,
    formState: {isSubmitting},
    resetField,
  } = useForm<{file: File[]}>();

  const handleDragEnter = useCallback(() => setIsDraggedOverArea(true), []);
  const handleDragLeave = useCallback(() => setIsDraggedOverArea(false), []);

  useEffect(() => {
    let filePickerElement = filePickerRef.current;
    if (filePickerRef.current) {
      filePickerElement = filePickerRef.current;
      filePickerElement.addEventListener('dragenter', handleDragEnter);
      filePickerElement.addEventListener('dragleave', handleDragLeave);
      filePickerElement.addEventListener('drop', handleDragLeave);
    }

    return () => {
      if (filePickerElement) {
        filePickerElement.removeEventListener('dragenter', handleDragEnter);
        filePickerElement.removeEventListener('dragleave', handleDragLeave);
        filePickerElement.removeEventListener('drop', handleDragLeave);
      }
    };
  }, [handleDragEnter, handleDragLeave]);

  const onSubmit: SubmitHandler<{file: File[]}> = useCallback(
    async data => {
      try {
        await mutateAsync(data.file[0]);
        goToSuccessScreen();
      } catch (e) {
        setError(true);
        trackError(e);
      }
    },
    [goToSuccessScreen, mutateAsync]
  );

  const onFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (file && isValidExtension(file.name)) {
        parse(file, {
          complete: results => {
            setTotal(results.data.length);
          },
        });
        setError(false);
      } else {
        resetField('file');
        setError(true);
      }
    },
    [resetField]
  );

  const onImportClick = useCallback(() => {
    handleSubmit(onSubmit)();
    trackStartImportClick('csv');
  }, [handleSubmit, onSubmit]);

  const onCancelButtonClick = useCallback(() => {
    onClose();
    trackCancelButtonOnStrategyView('csv');
  }, [onClose]);

  return (
    <>
      <form className="flex w-full items-center justify-center">
        <label
          ref={filePickerRef}
          htmlFor="dropzone-file"
          className={cs(
            'flex flex-col items-center justify-center',
            'h-48 w-full',
            'rounded-lg',
            'hover:bg-swarm-gray-100',
            'border-2',
            'relative',
            {
              'border-dashed border-swarm-gray-500': !total,
              'border-solid border-swarm-green': total > 1,
              'border-swarm-red': error,
              '!border-solid bg-swarm-gray-100': isDraggedOverArea,
            },
            'cursor-pointer'
          )}
        >
          <div className="flex flex-col items-center justify-center">
            <FilePickerContent isLoading={isSubmitting} hasError={error} isFileAttached={Boolean(total)} />
          </div>

          <input
            id="dropzone-file"
            accept=".csv"
            type="file"
            {...register('file', {
              required: true,
              onChange: onFileInputChange,
            })}
            className="absolute inset-0 cursor-pointer opacity-0"
          />
        </label>
      </form>

      <div className="mt-8 flex justify-between">
        <Button onClick={onCancelButtonClick}>Cancel</Button>

        {total > 0 && (
          <Button
            onClick={onImportClick}
            variant="primary"
            type="submit"
            loading={isSubmitting}
            disabled={error}
            icon="&rarr;"
            reversedOrder
          >
            Import
          </Button>
        )}
      </div>
    </>
  );
};
