import {ColumnDef} from '@tanstack/react-table';
import {uniq} from 'lodash';
import {useMemo} from 'react';
import {IconContext} from 'react-icons';
import {AvatarWithFullname} from '../../../components/avatar';
import {ScoreDropdown} from '../../../components/score';
import {Table, TableInnerHeader, useSwarmTable} from '../../../components/table';
import {Tooltip} from '../../../components/tooltip';
import {useCurrentUser, useSortBffConnections} from '../../../hooks';
import {BffDetailedConnection, BffUser, Role, ScoreValue} from '../../../types';
import {getUserFullName} from '../../../utils';
import Styles from './Connections.module.scss';
import {ConnectionSourceIconsMap, ConnectionSourceNetworkEnum} from './ConnectionsSourceIconsMap';

type Props = {
  connections: BffDetailedConnection[];
  profileId: string;
  onScoreUpdate: (newScore: ScoreValue) => void;
};

const getRelationship = (network: string) => {
  switch (network) {
    case ConnectionSourceNetworkEnum.twitter:
      return 'Connected on X';
    case ConnectionSourceNetworkEnum.facebook:
      return 'Connected on Facebook';
    case ConnectionSourceNetworkEnum.linkedin:
      return 'Connected on LinkedIn';
    case ConnectionSourceNetworkEnum.google:
      return 'Connected via Google';
    case ConnectionSourceNetworkEnum.calendar:
      return 'Share common events';
    case ConnectionSourceNetworkEnum.overlap:
      return 'Connected by one or more Work Overlaps';
    case ConnectionSourceNetworkEnum.educationOverlap:
      return 'Connected by one or more Education Overlaps';
    default:
      return 'Connected';
  }
};

export const Connections = ({connections, profileId, onScoreUpdate}: Props) => {
  const {id: currentUserId} = useCurrentUser();
  const sortedConnections = useSortBffConnections({connections, currentUserOnTop: true});

  const columns = useMemo<ColumnDef<BffDetailedConnection>[]>(() => {
    return [
      {
        accessorKey: 'user',
        header: () => <TableInnerHeader label="Member" />,
        cell: info => {
          const user = info.getValue() as BffUser;
          const name = getUserFullName(user);
          const isConnector = user.role === Role.Connector;
          const isCurrentUser = user.id === currentUserId;

          return (
            <div className="text-swarm-black">
              <AvatarWithFullname
                fullname={isCurrentUser ? `${name} (you)` : name}
                outline={isConnector}
                empty={isConnector}
              />
            </div>
          );
        },
        meta: {
          truncate: false,
        },
      },
      {
        accessorKey: 'strength',
        header: () => <TableInnerHeader label="Strength" />,
        cell: info => {
          return (
            <ScoreDropdown
              connection={info.row.original}
              profileId={profileId}
              big
              onChange={onScoreUpdate}
            />
          );
        },
        meta: {
          truncate: false,
        },
      },
      {
        accessorKey: 'sources',
        header: () => <TableInnerHeader label="Sources" />,
        cell: info => {
          const sources = info.getValue() as BffDetailedConnection['sources'];

          const relationshipsToShow = uniq(
            sources.map(({network, origin}) => {
              if (origin === 'overlaps') return 'overlap';
              if (origin === 'google-calendar') return 'calendar';
              if (origin === 'education-overlaps') return 'educationOverlap';
              return network;
            })
          );
          return (
            <div className={Styles.relationshipCellWrapper}>
              <IconContext.Provider value={{className: 'text-xl mr-4 mt-1'}}>
                {relationshipsToShow.map(relationship => {
                  const relationshipDescription = getRelationship(relationship);
                  return (
                    <Tooltip key={relationship} content={relationshipDescription}>
                      <div className={Styles.relationship}>
                        {ConnectionSourceIconsMap[relationship as ConnectionSourceNetworkEnum]()}
                      </div>
                    </Tooltip>
                  );
                })}
              </IconContext.Provider>
            </div>
          );
        },
        meta: {
          truncate: false,
        },
      },
    ];
  }, [currentUserId, onScoreUpdate, profileId]);

  const table = useSwarmTable<BffDetailedConnection>({
    uniqueName: 'profile-connections',
    rows: sortedConnections,
    isLoaded: true,
    selectable: false,
    columns,
    defaultColumnSize: 250,
    defaultColumnMinSize: 50,
  });

  return (
    <div className={Styles.tableWrapper}>
      <Table
        headerGroups={table.getHeaderGroups()}
        rowModel={table.getRowModel()}
        totalWidth={table.getTotalSize()}
        noVerticalSeparators
      />
    </div>
  );
};
