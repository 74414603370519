import {Amplify} from 'aws-amplify';
import {getConfig} from '../../utils';

const {
  cognitoUserPoolId = '',
  cognitoUserPoolWebClientId = '',
  cognitoUserPoolDomain = '',
  googleOauthRedirectSignInUrl = '',
  googleOauthRedirectSignOutUrl = '',
} = getConfig();

export function BootstrapAmplifyAuth() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: cognitoUserPoolId,
        userPoolClientId: cognitoUserPoolWebClientId,
        loginWith: {
          oauth: {
            domain: cognitoUserPoolDomain,
            scopes: ['email', 'profile', 'openid'],
            redirectSignIn: [googleOauthRedirectSignInUrl],
            redirectSignOut: [googleOauthRedirectSignOutUrl],
            responseType: 'code',
          },
        },
      },
    },
  });
}
