import {FileCsv} from 'phosphor-react';
import {LoadingSpinner} from '../animations';
import {Button} from '../button';
import {CheckMark, XMark} from '../marks';

export const FilePickerContent = (props: {
  isLoading: boolean;
  hasError: boolean;
  isFileAttached: boolean;
}) => {
  const {isLoading, isFileAttached, hasError} = props;

  if (isLoading) {
    return <LoadingSpinner size="medium" />;
  }

  if (hasError) {
    return (
      <>
        <p className="flex flex-row items-center text-swarm-black">
          <XMark shape="circle" size="small" />
          <span className="pl-2 text-xl font-semibold">Sorry, this file type is not supported</span>
        </p>
        <span className="pt-2 text-swarm-gray-700">Upload “Connection.csv” file.</span>
        <Button className="pointer-events-none mt-2">Try again</Button>
      </>
    );
  }

  if (isFileAttached) {
    return (
      <>
        <p className="flex flex-row items-center text-swarm-black">
          <CheckMark shape="circle" size="small" />
          <span className="pl-2 text-xl font-semibold">File successfully attached</span>
        </p>
        <span className="pt-2 text-swarm-gray-700">You can continue with the import</span>
      </>
    );
  }

  return (
    <p className="flex flex-col items-center text-swarm-black">
      <p className="flex flex-row">
        <FileCsv size={24} />
        <span className="pl-2 text-xl font-semibold">Select “Connections.csv” file to import</span>
      </p>
      <p className="font-light text-swarm-gray-600">or drag and drop it here</p>
    </p>
  );
};
