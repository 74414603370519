import {useEffect, useMemo, useState} from 'react';
import {EditorProps} from './types';
import {TagsCloud} from '../../../../tags-cloud';

export const Editor = ({
  tokens,
  onChange,
  tokensLabel = 'Recognized tokens',
  minItemLength = 1,
}: EditorProps) => {
  const [inputText, setInputText] = useState(tokens.join(', '));
  const newTokens = useMemo(() => {
    const newTokens = inputText
      .split(/[,]+|\n+/)
      .filter((token: string) => token.trim().length >= minItemLength);
    return newTokens.map((token: string) => token.trim());
  }, [inputText, minItemLength]);

  useEffect(() => {
    onChange(newTokens);
  }, [newTokens, onChange]);

  return (
    <div className="flex flex-col gap-4">
      <textarea
        rows={8}
        onChange={event => setInputText(event.target.value)}
        className="w-full"
        defaultValue={inputText}
      />
      {tokens.length ? (
        <span className="text-sm">
          {tokensLabel} ({tokens.length}):
        </span>
      ) : null}
      <TagsCloud items={newTokens} initialAmount={8} className="pt-0" />
    </div>
  );
};
