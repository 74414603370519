import cs from 'classnames';
import {AvatarWithFullname} from '../../../components/avatar';
import {Card} from '../../../components/card';
import {DataList} from '../../../components/list';
import {Pill, PillColor} from '../../../components/pill';
import {Opportunity, OpportunityStatus} from '../../../types';
import {getRelativeTimeToNow} from '../../../utils';
import Styles from './OpportunityTile.module.scss';

type Props = {
  opportunity: Opportunity;
};

const statusColorMap: Record<OpportunityStatus, PillColor> = {
  [OpportunityStatus.open]: 'blue',
  [OpportunityStatus.won]: 'green',
  [OpportunityStatus.lost]: 'red',
};

const isValidDate = (date: Date) => {
  return !isNaN(date.getTime());
};

export const OpportunityTile = ({opportunity}: Props) => {
  const {name, description, status, owner, stage} = opportunity;

  const {createdAt, closedAt, lastActivityAt, lastSyncedAt} = opportunity;

  const createdAtDate = new Date(createdAt);
  const lastActivityAtDate = new Date(lastActivityAt);
  const closedAtDate = new Date(closedAt);

  return (
    <Card className={Styles.opportunityTile}>
      <div className={cs(Styles.row, Styles.rowBetween)}>
        <Pill color={statusColorMap[opportunity.status] || 'default'}>{status}</Pill>
        <p className={Styles.small}>
          <span className={Styles.label}>Last sync:</span> {getRelativeTimeToNow(new Date(lastSyncedAt))}
        </p>
      </div>

      <div className={Styles.row}>
        <h3 className={Styles.name}>{name}</h3>
      </div>

      <div className={Styles.row}>
        <p className={Styles.description}>{description}</p>
      </div>

      <p className={Styles.row}>
        <span className={Styles.label}>Owner:</span>
        <AvatarWithFullname fullname={owner.fullName} />
      </p>
      <p className={Styles.row}>
        <span className={Styles.label}>Stage:</span>
        <Pill color="gray" size="large">
          {stage.name}
        </Pill>
      </p>

      <DataList
        items={[
          ...(isValidDate(createdAtDate)
            ? [
                {
                  label: 'Created at',
                  value: createdAtDate.toLocaleString(),
                },
              ]
            : []),
          ...(isValidDate(lastActivityAtDate)
            ? [
                {
                  label: 'Last activity at',
                  value: lastActivityAtDate.toLocaleString(),
                },
              ]
            : []),
          ...(isValidDate(closedAtDate)
            ? [
                {
                  label: 'Closed at',
                  value: closedAtDate.toLocaleString(),
                },
              ]
            : []),
        ]}
      />
    </Card>
  );
};
