import {useCallback, useMemo, useState} from 'react';
import {FiTag as TagIcon} from 'react-icons/fi';
import {assignTag, unassignTag} from '../../../api';
import {usePremiumFeatureCallback} from '../../../hooks';
import {AssignTagRequestParams, ExportCrmTargetType, Permission, TagSimple} from '../../../types';
import {Button} from '../../button';
import {TagsListDropdown} from '../index';
import {TagsCellButton} from './tags-table-cell/tags-cell-button';

type Props = {
  tags: TagSimple[];
  targetId: string;
  targetType: ExportCrmTargetType;
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
  inTableCell?: boolean;
};
export const TagsProfileAction = ({
  tags,
  targetId,
  targetType,
  onAssignSuccess,
  onUnassignSuccess,
  inTableCell,
}: Props) => {
  const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(false);

  const handleCLickTrigger = usePremiumFeatureCallback({
    callback: () => setIsTagsDropdownOpen(!isTagsDropdownOpen),
    permission: Permission.TagAssign,
  });

  const params: AssignTagRequestParams = useMemo(
    () => ({
      targetIds: [targetId],
      targetType,
    }),
    [targetId, targetType]
  );

  const handleAssign = useCallback((tagId: string) => assignTag(tagId, params), [params]);
  const handleUnassign = useCallback((tagId: string) => unassignTag(tagId, params), [params]);

  const assignedTags = tags.map(tag => tag.id);

  return (
    <TagsListDropdown
      isOpen={isTagsDropdownOpen}
      setIsOpen={handleCLickTrigger}
      assignedTags={assignedTags}
      onAssign={tagId => handleAssign(tagId)}
      onAssignSuccess={onAssignSuccess}
      onUnassign={tagId => handleUnassign(tagId)}
      onUnassignSuccess={onUnassignSuccess}
      trigger={
        inTableCell ? (
          <TagsCellButton assignedTags={tags} onClick={handleCLickTrigger} isActive={isTagsDropdownOpen} />
        ) : (
          <Button
            icon={<TagIcon />}
            variant="tertiary"
            outline
            size="xs"
            chevron="down"
            className="h-6"
            onClick={handleCLickTrigger}
          >
            Add tag
          </Button>
        )
      }
    />
  );
};
