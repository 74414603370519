import {ReactNode} from 'react';
import {Modal} from '../modal';
import Styles from './ActionPopup.module.scss';
import {Button} from '../button';

type Props = {
  visible: boolean;
  onClose: () => unknown;
  children?: ReactNode;
  action: (value?: unknown) => void;
  actionType?: 'removal' | 'info';
  dangerousAction?: boolean;
  icon?: ReactNode;
  customBadge?: ReactNode;
  heading?: string;
  content?: string;
  actionLabel?: string;
  cancelLabel?: string;
};

export const ActionPopup = ({
  visible,
  onClose,
  children,
  action,
  actionType,
  dangerousAction = actionType === 'removal',
  icon,
  customBadge,
  heading,
  content,
  actionLabel,
  cancelLabel,
}: Props) => {
  const displayedActionLabel = actionLabel || actionType === 'removal' ? 'Remove' : 'Confirm';
  const displayedCancelLabel = cancelLabel || actionType === 'info' ? 'Close' : 'Cancel';

  return (
    <Modal visible={visible} onClose={onClose} closeButtonVisible className={Styles.modal}>
      <div className={Styles.wrapper}>
        {icon && (
          <div className={Styles.icon}>
            {icon}
            {customBadge && (
              <div className={Styles.iconBadge} color="yellow">
                {customBadge}
              </div>
            )}
          </div>
        )}
        {heading && <h3 className={Styles.heading}>{heading}</h3>}
        <p>{content}</p>
        {children}
        <div className="flex w-full items-stretch gap-2">
          <Button onClick={onClose} className="flex-1">
            {displayedCancelLabel}
          </Button>
          <Button onClick={action} variant={dangerousAction ? 'red' : 'primary'} className="flex-1">
            {displayedActionLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
