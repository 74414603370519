import {useMemo} from 'react';
import {useQueryCrmIntegrations} from '../../../api';
import {Crm} from '../../../types';

export const useExistingIntegration = (crm: Crm) => {
  const {
    data: existingIntegrations,
    isLoading: isExistingIntegrationsLoading,
    refetch,
  } = useQueryCrmIntegrations({
    keepPreviousData: true,
    refetchOnMount: false,
  });

  const integration = useMemo(
    () => existingIntegrations?.integrations.find(integration => integration.crm === crm),
    [crm, existingIntegrations?.integrations]
  );

  return {
    integration,
    isLoading: isExistingIntegrationsLoading,
    refetch,
  };
};
