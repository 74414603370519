import {useMemo} from 'react';
import {BulkActionsType, Permission, ProfilesListRow} from '../../../../types';
import {getConfig, pluralizedPeople} from '../../../../utils';
import {BulkCrmExportMenu} from '../../../crm-export-menu';
import {PermissionChecker} from '../../../permission';
import {
  BulkAddToParticularPipelineButton,
  BulkAddToPipelineButton,
} from '../../../pipelines-actions/add-to-pipeline';
import {BulkRemoveFromPipelineButton} from '../../../pipelines-actions/remove-from-pipeline';
import {PremiumFeatureClickWrapper} from '../../../premium-feature-wrapper';
import {BulkScoreDropdown} from '../../../score';
import {BulkProfilesTags} from '../../../tags';

type BulkActionsProps = {
  selectedProfiles: ProfilesListRow[];
  onBulkActionSuccess: () => void;
  actions: BulkActionsType;
  disabled?: boolean;
};

const {crmProfileExportEnabled} = getConfig();

export const BulkActions = ({selectedProfiles, onBulkActionSuccess, actions, disabled}: BulkActionsProps) => {
  const selectedProfileIds = useMemo(() => selectedProfiles.map(profile => profile.id), [selectedProfiles]);
  const selectedProfilesCount = selectedProfileIds.length;

  const selectedRowsInfo = useMemo(
    () => `${selectedProfilesCount} ${pluralizedPeople(selectedProfilesCount)} selected`,
    [selectedProfilesCount]
  );

  return (
    <>
      {actions.addToPipeline && (
        <BulkAddToPipelineButton
          selectedProfiles={selectedProfiles}
          onSuccess={onBulkActionSuccess}
          disabled={disabled}
        />
      )}

      {actions.tags && (
        <BulkProfilesTags
          selectedProfiles={selectedProfiles}
          onAssignSuccess={onBulkActionSuccess}
          onUnassignSuccess={onBulkActionSuccess}
          disabled={disabled}
        />
      )}

      {actions.crmExport && crmProfileExportEnabled && (
        <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
          <BulkCrmExportMenu
            targetIds={selectedProfileIds}
            targetType="profile"
            onSuccess={onBulkActionSuccess}
            disabled={disabled}
          />
        </PremiumFeatureClickWrapper>
      )}

      {actions.editStrength && (
        <PermissionChecker permission={Permission.ConnectionStrength}>
          <BulkScoreDropdown
            profileIds={selectedProfileIds}
            onScoreChangeSuccess={onBulkActionSuccess}
            disabled={disabled}
          />
        </PermissionChecker>
      )}

      {actions.addToParticularPipeline && (
        <BulkAddToParticularPipelineButton
          profileIDs={selectedProfileIds}
          onSuggestionsChangeSuccess={onBulkActionSuccess}
          pipelineId={actions.addToParticularPipeline.pipelineId}
          pipelineName={actions.addToParticularPipeline.pipelineName}
          disabled={disabled}
        />
      )}

      {actions.removeFromPipeline && (
        <BulkRemoveFromPipelineButton
          profileIDs={selectedProfileIds}
          pipelineId={actions.removeFromPipeline.pipelineId}
          pipelineName={actions.removeFromPipeline.pipelineName}
          onSuccess={onBulkActionSuccess}
          disabled={disabled}
        />
      )}

      {!disabled && <span>{selectedRowsInfo}</span>}
    </>
  );
};
