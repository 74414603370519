import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useCurrentTeam, useCurrentUser, usePrevious} from '../../hooks';
import {PipelineCommonResponse, PipelineType} from '../../types';
import {segmentTrack} from '../../utils';
import {Input, RadioGroup, Textarea} from '../form';
import {FormWrapper} from '../form-wrapper';
import Styles from './PipelineForm.module.scss';
import {usePipelineForm} from './usePipelineForm';

type PipelineFormState = {
  pipelineType?: PipelineType;
  title: string;
  description: string;
  forwardableTemplate: string;
  privatePipeline: boolean;
  savedSearch: string;
};

type Props = {
  pipeline?: PipelineCommonResponse;
  close: () => void;
  onSubmit?: (pipelineId: string, pipelineTitle: string) => void;
  profileIDs?: string[];
  location: 'create pipeline modal' | 'edit pipeline modal';
};
export const PipelineForm = ({pipeline, close, onSubmit, profileIDs, location}: Props) => {
  const edit = Boolean(pipeline);
  const {id: teamId} = useCurrentTeam();
  const {id: currentUerId} = useCurrentUser();
  const {creator} = pipeline || {};
  const markPrivateVisible = (edit && creator?.id === currentUerId) || !edit;

  const defaultValues = {
    pipelineType: pipeline?.pipelineType,
    title: pipeline?.title || '',
    description: pipeline?.descriptionUrl || '',
    forwardableTemplate: pipeline?.forwardableTemplate || '',
    privatePipeline: pipeline?.private || false,
    savedSearch: pipeline?.savedSearch || '{}',
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: {isSubmitting},
  } = useForm<PipelineFormState>({
    defaultValues: defaultValues,
  });

  const pipelineTypeValue = watch('pipelineType');
  const previousPipelineTypeValue = usePrevious(pipelineTypeValue);
  useEffect(() => {
    if (previousPipelineTypeValue !== undefined && pipelineTypeValue !== previousPipelineTypeValue) {
      segmentTrack('Selector Applied', {
        label: 'pipeline type',
        selection: pipelineTypeValue,
        location,
      });
    }
  }, [location, pipelineTypeValue, previousPipelineTypeValue]);

  const {pipelineType, handleCreatePipeline, handleEditPipeline} = usePipelineForm({
    getValues,
    setValue,
    teamId,
    onSubmit,
    close,
    pipeline,
    profileIDs,
  });

  return (
    <>
      <hr className="-mx-8 mb-4" />
      <FormWrapper
        skipButton={{label: 'Cancel', onClick: close}}
        actionButton={{
          label: edit ? 'Save' : 'Create',
          onClick: handleSubmit(edit ? handleEditPipeline : handleCreatePipeline),
          loading: isSubmitting,
          tracking: {
            label: edit ? 'save' : 'create',
            location,
          },
        }}
        {...(markPrivateVisible && {
          additionalButton: (
            <label className={Styles.markPipelinePrivate} data-intercom-target="Mark as private pipeline">
              <input {...register('privatePipeline')} type="checkbox" /> Make private
            </label>
          ),
        })}
        reverseButtonsOrder
        fullWidth
        onModal
      >
        <form>
          <h3 className={Styles.label}>Type</h3>
          <Controller
            control={control}
            name="pipelineType"
            render={({fieldState: {error}}) => (
              <RadioGroup
                isStacked={true}
                name="pipelineType"
                radioButtons={pipelineType}
                message={error?.message}
                isInvalid={!!error}
                defaultValue={getValues('pipelineType') as PipelineType}
              />
            )}
            rules={{required: 'Pipeline type must be defined'}}
          />
          <h3 className={Styles.label}>Name</h3>
          <Controller
            control={control}
            name="title"
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <Input
                onChange={onChange}
                value={value}
                placeholder="List name"
                message={error?.message}
                isInvalid={!!error}
                messageClassName="-mt-1"
              />
            )}
            rules={{required: "Pipeline name can't be empty"}}
          />
          <Controller
            control={control}
            name="description"
            render={({field: {onChange, value}}) => (
              <Textarea
                onChange={onChange}
                value={value}
                placeholder="Add more details (optional)"
                className={Styles.textarea}
                rows={3}
              />
            )}
          />
          <h3 className={Styles.label}>
            <img src="/icons/request-intro.svg" className="h-4" /> Forwardable intro template
          </h3>
          <Controller
            control={control}
            name="forwardableTemplate"
            render={({field: {onChange, value}}) => (
              <Textarea
                onChange={onChange}
                value={value}
                placeholder={`More about us:
- Product: (e.g. value proposition, use cases)
- Traction: (e.g. customers, trials, proof points)
- Founders: (e.g. experience, exits)
- Investors: (e.g. amount raised, key investors)`}
                className={Styles.textarea}
                rows={6}
              />
            )}
          />
        </form>
      </FormWrapper>
    </>
  );
};
