import {getConfigFlag, isConfigFlagTruth, ScreamingSnakeToCamel, toCamelCase} from './getConfigFlag';
import {BooleanEnvKeys, StringEnvKeys} from './keys';

type AddBooleanConfigKeySuffix<S extends string> = `${S}_ENABLED`;
type ConfigKeyRecord<KeyType extends string, ReturnValueType> = Record<
  ScreamingSnakeToCamel<KeyType>,
  ReturnValueType
>;

const stringConfigFlags = Object.fromEntries(
  Object.values(StringEnvKeys).map(v => [toCamelCase(v), getConfigFlag(v)])
) as ConfigKeyRecord<StringEnvKeys, string | undefined>;

const booleanConfigFlags = Object.fromEntries(
  Object.values(BooleanEnvKeys).map(v => [toCamelCase(`${v}_ENABLED`), isConfigFlagTruth(v)])
) as ConfigKeyRecord<AddBooleanConfigKeySuffix<BooleanEnvKeys>, boolean>;

export const getConfig = () => ({
  ...stringConfigFlags,
  ...booleanConfigFlags,
});
