import {Children, Dispatch, isValidElement, ReactNode, SetStateAction} from 'react';
import Styles from './Switcher.module.scss';
import {SwitcherContextProvider} from './SwitcherContext';

type Props = {
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string | undefined>>;
  children: ReactNode;
};

export const Switcher = ({value, setValue, children}: Props) => {
  const validChildren = Children.toArray(children).filter(isValidElement);

  return (
    <SwitcherContextProvider selectedValue={value} setValue={setValue}>
      <div className={Styles.switcher}>{validChildren}</div>
    </SwitcherContextProvider>
  );
};
