import {useCallback, useEffect, useMemo} from 'react';
import {useQueryTeam} from '../api';
import {useUserSessionContext} from '../contexts';
import {EnhancedTeam, Role, User} from '../types';
import {usePrevious} from './usePrevious';

// Pls use useCurrentUser & useCurrentTeam inside authorized pages
// useCurrentUserSession hook should be used in authorization layer
// and in actions resulting refreshing user data
export const useCurrentUserSession = () => {
  const {
    user: userData,
    teams = [],
    isRefetching: isRefetchingIdentity,
    isFetched: isFetchedIdentity,
    refetch: refetchIdentity,
    activeTeamId,
  } = useUserSessionContext();
  const {id, email, firstName, lastName} = userData;

  const {
    data: teamData,
    refetch: refetchTeam,
    isFetched: isFetchedTeam,
    isRefetching: isRefetchingTeam,
  } = useQueryTeam(activeTeamId, {
    keepPreviousData: true,
  });

  const isRefetching = isRefetchingIdentity || isRefetchingTeam;
  const isFetched = isFetchedIdentity && (!activeTeamId || isFetchedTeam);

  const refetchTeamIfNeeded: () => Promise<unknown> = useCallback(() => {
    if (activeTeamId) {
      return refetchTeam();
    }
    return Promise.resolve();
  }, [refetchTeam, activeTeamId]);

  const refresh = useCallback(
    () => refetchIdentity().then(refetchTeamIfNeeded),
    [refetchIdentity, refetchTeamIfNeeded]
  );

  const user: User = useMemo(
    () => ({
      id,
      email,
      firstName,
      lastName,
    }),
    [email, firstName, id, lastName]
  );

  const team: EnhancedTeam | undefined = useMemo(() => {
    if (!teamData) {
      return undefined;
    }

    const {role} = teams.find(({id}) => id === activeTeamId) ?? {
      role: Role.Contributor,
    };

    return {
      ...teamData,
      role,
      isActive: true,
    };
  }, [activeTeamId, teams, teamData]);

  return {
    user,
    team,
    activeTeamId,
    refresh,
    isRefetching,
    isFetched,
    refetchTeam,
  };
};

type VerbosityLevel = 'quiet' | 'loud';
export function useCurrentTeam(verbosity: 'quiet'): EnhancedTeam | undefined;
export function useCurrentTeam(verbosity?: 'loud'): EnhancedTeam;
export function useCurrentTeam(verbosity: VerbosityLevel = 'loud'): EnhancedTeam | undefined {
  const {team} = useCurrentUserSession();
  if (!team && verbosity === 'loud') {
    throw new Error('Team not found in current session');
  }

  return team;
}
export const useCurrentUser = (): User => {
  const {user} = useCurrentUserSession(); // TODO: make user from useCurrentUserSession optional
  // if (!user) {
  //   throw new Error('User not found in current session');
  // }
  return user;
};

export const useOnTeamChanged = (onTeamChanged: () => void) => {
  const {team} = useCurrentUserSession();
  const currentTeamId = team?.id;
  const previousTeamId = usePrevious(currentTeamId);

  useEffect(() => {
    if (!previousTeamId) {
      return;
    }

    if (currentTeamId !== previousTeamId) {
      onTeamChanged();
    }
  }, [currentTeamId, onTeamChanged, previousTeamId]);
};
