import * as Sentry from '@sentry/react';
import {Primitive} from 'utility-types';
import {getConfig} from '../getConfig';
import {UserAnalyticsData} from './utils';
import {replayIntegration} from '@sentry/react';

const ignoreErrors = [
  'The user is not authenticated', // TODO: we shouldn't show this message on login/register page
  /CreateAuthChallenge failed with error user is disabled/,
];

const {envName, sentryDsn, revision} = getConfig();

export const initializeSentry = () => {
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      environment: envName,
      release: revision,
      ignoreErrors,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],
    });
  }
};

/*
 * tags: Indexed and searchable by Sentry
 * docs: https://docs.sentry.io/enriching-error-data/context/?platform=node#tagging-events
 *
 * extras: Extra data to attach to the event for easier debugging
 * docs: https://docs.sentry.io/enriching-error-data/context/?platform=node#extra-context
 */
export function trackError(
  error: unknown,
  options?: {
    tags?: Record<string, Primitive>;
    extras?: Record<string, unknown>;
  }
) {
  const {tags = {}, extras = {}} = options || {};

  Sentry.withScope(scope => {
    Object.entries(tags).forEach(([key, value]) => scope.setTag(key, value)); // We want these to be indexed by Sentry
    Object.entries(extras).forEach(([key, value]) => scope.setExtra(key, value));
    Sentry.captureException(error);
  });

  console.error(error, tags, extras);
}

export const sentryIdentify = (user: UserAnalyticsData) => {
  Sentry.setTag('userId', user.id);
  Sentry.setTag('userMail', user.email);
  Sentry.setExtra('user', user);
};
