import {AddMembersModal, useAddMembersModal} from '../../../components/add-members';
import {Button, ButtonProps} from '../../../components/button';
import {PermissionChecker} from '../../../components/permission';
import {Permission, Role} from '../../../types';

type Props = {
  label?: string;
  variant?: ButtonProps['variant'];
  initialRole?: Role;
  onSuccess?: () => void;
};
export const AddMembersButton = ({onSuccess, label, variant, initialRole: initialRoleFromProps}: Props) => {
  const {
    addMembersVisible,
    initialRole: initialRoleFromHook,
    closeAddMembersModal,
    openAddMembersModal,
  } = useAddMembersModal();

  return (
    <PermissionChecker permission={Permission.TeamMemberUpdate}>
      <Btn onClick={() => openAddMembersModal(initialRoleFromProps)} label={label} variant={variant} />
      <AddMembersModal
        visible={addMembersVisible}
        onClose={closeAddMembersModal}
        onSuccess={onSuccess}
        initialRole={initialRoleFromHook}
      />
    </PermissionChecker>
  );
};

type BtnProps = {
  onClick: () => unknown;
  label?: string;
  variant?: Props['variant'];
};
const Btn = ({onClick, variant = 'primary', label = 'Add members'}: BtnProps) => (
  <Button
    onClick={onClick}
    variant={variant}
    data-intercom-target="Invite members"
    tracking={{label: 'add members'}}
  >
    {label}
  </Button>
);
