import {BffFilterOptions, BulkActionsType} from '../../../../types';
import {
  MassAddToParticularPipelineButton,
  MassAddToPipelineButton,
  MassRemoveFromParticularPipelineButton,
  MassRemoveFromPipelineButton,
} from '../../../pipelines-actions';

type MassActionsProps = {
  actions: BulkActionsType;
  filters: BffFilterOptions;
  totalCount: number;
  onBulkActionSuccess: () => void;
};

export const MassActions = ({actions, filters, totalCount, onBulkActionSuccess}: MassActionsProps) => {
  return (
    <>
      {actions.addToPipeline && (
        <>
          <MassAddToPipelineButton
            filters={filters}
            totalCount={totalCount}
            onSuccess={onBulkActionSuccess}
          />
          <MassRemoveFromPipelineButton
            filters={filters}
            totalCount={totalCount}
            onSuccess={onBulkActionSuccess}
          />
        </>
      )}
      {actions.addToParticularPipeline && (
        <MassAddToParticularPipelineButton
          filters={filters}
          totalCount={totalCount}
          pipelineId={actions.addToParticularPipeline.pipelineId}
          pipelineName={actions.addToParticularPipeline.pipelineName}
          onSuccess={onBulkActionSuccess}
        />
      )}
      {actions.removeFromPipeline && (
        <MassRemoveFromParticularPipelineButton
          filters={filters}
          totalCount={totalCount}
          pipelineId={actions.removeFromPipeline.pipelineId}
          pipelineName={actions.removeFromPipeline.pipelineName}
          onSuccess={onBulkActionSuccess}
        />
      )}
      {actions.tags && (
        <>
          <span>[add tags]</span>
          <span>[unassign tags]</span>
        </>
      )}
    </>
  );
};
