import databaseImg from './illustrations/feature-database.png';
import filtersImg from './illustrations/feature-filters.png';
import pipelinesImg from './illustrations/feature-pipelines.png';
import strengthImg from './illustrations/feature-strength.png';

export const planBenefits = [
  {
    title: 'Full database access',
    subtitle: 'Full database access',
    description:
      'Drive list with warm data plus unlimited access to 800m+ profiles and 20m+ companies, including contact information, at no additional cost.',
    articleId: 8608235,
    image: databaseImg,
  },
  {
    title: '',
    subtitle: 'Connection strength',
    description: 'Know who knows who and how well. Review connection strength to find the best intro paths.',
    articleId: 8117404,
    image: strengthImg,
  },
  {
    title: 'Company -level filters',
    subtitle: 'Company-level filters',
    description: 'Browse companies and profiles by company name, size, industry and other.',
    articleId: 8443865,
    image: filtersImg,
  },
  {
    title: 'Powerful lists',
    subtitle: 'Powerful lists & Exports',
    description:
      'Start building lists you and your team members can collaborate on. You can set up a search inside a list and save search parameters there for later!',
    articleId: 6674481,
    image: pipelinesImg,
  },
];
