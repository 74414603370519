import {ReactNode} from 'react';
import {FiUnlock as LockIcon} from 'react-icons/fi';
import {Permission} from '../../types';
import {Button} from '../button';
import {PremiumStarsIcon} from '../icons';
import {PermissionChecker} from '../permission';
import Styles from './PremiumFeatureWarningContent.module.scss';

type Props = {
  title?: ReactNode;
  location: 'premium feature modal' | 'premium feature page';
};

const defaultTitle = 'Unlock this feature';

export const PremiumFeatureWarningContent = (props: Props) => {
  const {title = defaultTitle, location} = props;

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.icon}>
        <LockIcon size={32} />
        <div className={Styles.iconBadge} color="yellow">
          <PremiumStarsIcon className="h-4" />
        </div>
      </div>
      <h3 className={Styles.title}>{title}</h3>
      <p className={Styles.description}>
        <PermissionChecker
          permission={Permission.PlanUpdate}
          fallback={
            <>
              Please contact your team Owner to upgrade. <br />
              Visit{' '}
              <a href="https://theswarm.com/pricing" target="_blank" rel="noreferrer">
                theswarm.com/pricing
              </a>{' '}
              for more details.
            </>
          }
        >
          Let's review and upgrade your plan.
          <br />
          You can pause or cancel anytime.
          <Button
            href="/upgrade-plan"
            variant="primary"
            fullWidth
            size="lg"
            className={Styles.button}
            tracking={{
              label: 'review plan details',
              location,
            }}
          >
            Find out more
          </Button>
        </PermissionChecker>
      </p>
    </div>
  );
};
