import {useCallback, useMemo} from 'react';
import {useQueryPipelines} from '../api';
import {useCurrentTeam, useUiSetting} from './index';

const FAVORITE_LISTS_SETTINGS_KEY = 'FAVORITE_LISTS';

export const useFavoriteLists = () => {
  const {id: teamId} = useCurrentTeam();
  const {data: allLists} = useQueryPipelines(teamId, {limit: 100, offset: 0});
  const [favorites = {}, setFavorites] =
    useUiSetting<Record<typeof teamId, string[] | undefined>>(FAVORITE_LISTS_SETTINGS_KEY);

  const favoriteLists = useMemo(
    () =>
      favorites[teamId]?.map(fav => {
        return {
          id: fav,
          title: allLists?.items.find(({pipeline}) => pipeline.id === fav)?.pipeline.title || '',
        };
      }),
    [favorites, allLists, teamId]
  );

  const removeFav = useCallback(
    (listId: string) => {
      setFavorites({
        ...favorites,
        [teamId]: favorites[teamId]?.filter(id => id !== listId),
      });
    },
    [favorites, setFavorites, teamId]
  );

  const addFav = useCallback(
    (listId: string) => {
      setFavorites({
        ...favorites,
        [teamId]: [...(favorites[teamId] || []), listId],
      });
    },
    [favorites, setFavorites, teamId]
  );

  return {
    favoritesIds: favorites[teamId] || [],
    favoriteLists: favoriteLists || [],
    removeFav,
    addFav,
  };
};
