import {useConfirm} from '../../hooks';
import {PiTrashSimple as TrashIcon} from 'react-icons/pi';
import {ActionPopup} from '../action-popup';

export const ConfirmationPopup = () => {
  const {prompt = '', isOpen = false, proceed, cancel, actionType} = useConfirm();
  return (
    <ActionPopup
      visible={isOpen}
      onClose={cancel}
      icon={actionType === 'removal' ? <TrashIcon className="text-swarm-red" /> : undefined}
      actionType={actionType}
      action={proceed}
      content={prompt}
    />
  );
};
