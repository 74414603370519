import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {FiHelpCircle as SupportIcon} from 'react-icons/fi';
import {useIntercom} from 'react-use-intercom';
import {Button} from '../../../components/button';
import {useCurrentUser, useGoToApp} from '../../../hooks';
import {AppLayout} from '../../../layouts';
import {firstPromoterReferral} from '../../../utils';
import {planBenefits} from './const';
import Styles from './ThankYouPage.module.scss';

export const ThankYouPage = () => {
  const {email} = useCurrentUser();
  const goToApp = useGoToApp();

  useEffect(() => {
    firstPromoterReferral(email);
  }, [email]);

  return (
    <>
      <Helmet>
        <title>Thank you - The Swarm</title>
      </Helmet>
      <AppLayout>
        <div className={Styles.wrapper}>
          <h2 className={Styles.heading}>Your account is ready!</h2>
          <h3 className={Styles.subheading}>Explore the Power of Network at full scale!</h3>
          <div className={Styles.content}>
            {planBenefits.map(({title, subtitle, description, articleId, image}) => (
              <PlanBenefit
                key={title}
                title={title}
                subtitle={subtitle}
                description={description}
                articleId={articleId}
                image={image}
              />
            ))}
          </div>
          <div className={Styles.prioritySupport}>
            <SupportIcon size={24} color="black" />
            <div>
              <h3 className={Styles.supportHeading} data-intercom-target="pricing plan: enterprise">
                Priority support
              </h3>
              <p>We're here to help you get the most of The Swarm.</p>
            </div>
            <div className="ml-auto">
              <Button
                href="https://www.theswarm.com/company/contact"
                target="_blank"
                variant="tertiary"
                outline
                tracking={{
                  label: 'schedule a call',
                }}
              >
                Schedule a call
              </Button>
            </div>
          </div>
          <Button
            variant="primary"
            className={Styles.goToSwarmButton}
            size="lg"
            onClick={goToApp}
            tracking={{
              label: 'start using the swarm',
            }}
          >
            Start using The Swarm at full scale!
          </Button>
        </div>
      </AppLayout>
    </>
  );
};

type PlanBenefitProps = {
  title: string;
  subtitle: string;
  description: string;
  articleId: number;
  image: string;
};

const PlanBenefit = ({title, subtitle, description, articleId, image}: PlanBenefitProps) => {
  const {showArticle} = useIntercom();

  return (
    <div className={Styles.planBenefit}>
      <div className={Styles.benefitTitle}>
        <img src={image} alt={title} className={Styles.benefitIllustration} />
        <h4 className={Styles.benefitHeading}>{title}</h4>
      </div>
      <div className={Styles.benefitDescription}>
        <h5 className={Styles.benefitSubheading}>{subtitle}</h5>
        {description}{' '}
        <span className={Styles.learnMoreLink} onClick={() => showArticle(articleId)}>
          Learn more
        </span>
      </div>
    </div>
  );
};
