import cs from 'classnames';
import {ReactNode, useCallback} from 'react';
import {
  CgChevronLeft as ChevronLeft,
  CgChevronRight as ChevronRight,
  CgPushChevronLeft as ChevronGoToFirst,
  CgPushChevronRight as ChevronGoToLast,
} from 'react-icons/cg';
import {getConfig, segmentTrack} from '../../utils';
import {Button} from '../button';
import {Select} from '../form';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import Styles from './PaginationButtons.module.css';
import {usePaginationButtons} from './usePaginationButtons';

const {goToLastInPaginationEnabled} = getConfig();

type Props = {
  visibleItemsString: string;
  pageIndex: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  navigate: {
    next: () => void;
    previous: () => void;
    goToPage: (page: number) => void;
  };
  totalCount: number;
  pageSize: number;
  setPageSize?: (pageSize: number) => void;
};

export const PaginationButtons = ({
  visibleItemsString,
  pageIndex,
  isFirstPage,
  isLastPage,
  navigate,
  totalCount,
  pageSize,
  setPageSize,
}: Props) => {
  const paginationRange = usePaginationButtons({totalCount, pageSize, currentPage: pageIndex});

  const totalPageCount = Math.ceil(totalCount / pageSize);

  const onChangePageSize = useCallback(
    (newPageSize: string) => {
      segmentTrack('Selector applied', {label: 'items per page', value: newPageSize});
      setPageSize?.(parseInt(newPageSize, 10));
    },
    [setPageSize]
  );

  return (
    <div className={Styles.wrapper}>
      <TruncateWithTooltip content={visibleItemsString}>{visibleItemsString}</TruncateWithTooltip>

      <div className={Styles.buttonsWrapper}>
        <Button
          onClick={() => navigate.goToPage(1)}
          variant="tertiary"
          disabled={isFirstPage}
          icon={<ChevronGoToFirst className={Styles.chevron} />}
          size="md"
          iconOnly
        />

        <Button
          onClick={navigate.previous}
          variant="tertiary"
          disabled={isFirstPage}
          icon={<ChevronLeft className={Styles.chevron} />}
          size="md"
          className={Styles.chevronButtonLeft}
        >
          Prev
        </Button>
        {paginationRange?.map((page, index) => (
          <PaginationButton
            key={`${page}-${index}`}
            onClick={() => navigate.goToPage(page as number)}
            isActive={pageIndex === page}
          >
            {page}
          </PaginationButton>
        ))}
        <Button
          onClick={navigate.next}
          variant="tertiary"
          disabled={isLastPage}
          icon={<ChevronRight className={Styles.chevron} />}
          reversedOrder
          size="md"
          className={Styles.chevronButtonRight}
        >
          Next
        </Button>
        {goToLastInPaginationEnabled && (
          <Button
            onClick={() => navigate.goToPage(totalPageCount)}
            variant="tertiary"
            disabled={isLastPage}
            icon={<ChevronGoToLast className={Styles.chevron} />}
            size="md"
            iconOnly
          />
        )}
      </div>

      <div className={Styles.pageSizeSelectWrapper}>
        {setPageSize && (
          <Select
            options={[
              {value: '25', text: '25 per page'},
              {value: '50', text: '50 per page'},
              {value: '100', text: '100 per page'},
            ]}
            value={pageSize.toString()}
            onChange={e => onChangePageSize(e.target.value)}
            className={Styles.pageSizeSelect}
          />
        )}
      </div>
    </div>
  );
};

export const PaginationButton = ({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) => {
  return (
    <Button
      onClick={onClick}
      variant="tertiary"
      size="md"
      className={cs(Styles.paginationButton, {[Styles.paginationButtonActive]: isActive})}
    >
      {children}
    </Button>
  );
};
