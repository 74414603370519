import {useEffect} from 'react';
import {segmentTrack} from '../../utils';
import {Modal} from '../modal';
import {PipelineForm} from './PipelineForm';

import Styles from './PipelineForm.module.scss';

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit?: (pipelineId: string, pipelineTitle: string) => void;
  profileIDs?: string[];
};
export const PipelineCreateModal = ({visible, onClose, onSubmit, profileIDs}: Props) => {
  useEffect(() => {
    if (visible) {
      segmentTrack('Modal Viewed', {label: 'create pipeline'});
    }
  }, [visible]);

  return (
    <Modal visible={visible} className={Styles.pipelineFormModal} onClose={onClose} closeButtonVisible>
      <h3 className={Styles.header}>Create list</h3>
      <PipelineForm
        close={onClose}
        onSubmit={onSubmit}
        profileIDs={profileIDs}
        location="create pipeline modal"
      />
    </Modal>
  );
};
