import {InputHTMLAttributes} from 'react';
import Styles from './Radio.module.scss';

export type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  color?: 'blue' | 'black';
  onValueChange?: (value: string) => void;
};

export const Radio = ({label, color = 'blue', onValueChange, ...rest}: RadioProps) => {
  return (
    <div className={Styles.radioButtonWrapper}>
      <label className={Styles.radioButtonWrapperLabel}>
        <input
          type="radio"
          className={Styles[`${color}Radio`]}
          {...(onValueChange ? {onChange: e => onValueChange(e.target.value)} : {})}
          {...rest}
        />
        <span className={Styles.radioButtonLabel}>{label}</span>
      </label>
    </div>
  );
};
