import {useQuery, UseQueryOptions} from 'react-query';
import {GetTasksResponse} from '../types';
import {apiClient} from './apiClient';

// GET TASKS LIST

const getTasks = () => apiClient.get<GetTasksResponse>('/profiles/imports/tasks').then(res => res.data);

export const useQueryTasks = (options?: UseQueryOptions<GetTasksResponse>) =>
  useQuery<GetTasksResponse>(['importTasks'], () => getTasks(), options);
