import {Helmet} from 'react-helmet';
import {PageHeader} from '../../components/page-header';
import {TabLinks} from '../../components/tabs';
import {TabPageContent} from '../../components/tabs/';
import {useCurrentTeam} from '../../hooks';
import {AppLayout} from '../../layouts';
import {TeamProfile} from './general';
import {CompanyLinkedin} from './general/CompanyLinkedin';
import {useTabsConfig} from './useTabsConfig';

export const TeamSettingsPage = () => {
  const tabs = useTabsConfig();
  const {companyLinkedinURL} = useCurrentTeam();

  return (
    <>
      <Helmet>
        <title>General Team Settings - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Team Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="General" />}
      >
        <TabPageContent heading="Team profile" subheading="Personalize how your team is presented to members">
          <TeamProfile />
        </TabPageContent>
        {companyLinkedinURL && (
          <TabPageContent heading="Company Linkedin profile">
            <CompanyLinkedin />
          </TabPageContent>
        )}
      </AppLayout>
    </>
  );
};
