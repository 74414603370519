export function getHashParamValue(key: string) {
  const {hash} = window.location;
  const paramRegex = new RegExp(`(?:#|&)${key}=([^&]+|$)`, 'g');

  const match = paramRegex.exec(hash);

  if (match) {
    return match[1];
  }

  return null;
}
