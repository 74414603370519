import {ReactNode} from 'react';
import {Card} from '../../card';
import Styles from './ImportMethodTile.module.scss';

type ImportMethodTileProps = {
  icon: ReactNode;
  name: string;
  title: ReactNode;
  subtitle?: string;
  action: ReactNode;
};
export const ImportMethodTile = ({name, title, subtitle, icon, action}: ImportMethodTileProps) => (
  <Card className={Styles.tile}>
    <div className={Styles.inner}>
      <div className={Styles.content} data-intercom-target={name}>
        <div className={Styles.icon}>{icon}</div>
        <div className={Styles.description}>
          <div className={Styles.title}>{title}</div>
          <span className={Styles.subtitle}>{subtitle}</span>
        </div>
      </div>
      {action}
    </div>
  </Card>
);
