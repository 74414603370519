import {LoadingSpinner} from '../../../components/animations';
import {Table, UseSwarmTableProps} from '../../../components/table';
import {EmptyTagsListInfo} from '../../../components/tags';
import {GetTagsResponse, TagListItem} from '../../../types';
import {useTagsTable} from './useTagsTable';

type Props = {
  tags: GetTagsResponse;
  isLoading: boolean;
  editTag: (tagId: string) => void;
  removeTag: (tagId: string) => void;
  sorting: UseSwarmTableProps['sorting'];
};

export const TagsTable = ({tags, isLoading, editTag, removeTag, sorting}: Props) => {
  const table = useTagsTable({
    rows: tags.items,
    isLoaded: !isLoading,
    editTag,
    removeTag,
    sorting,
  });

  if (isLoading) return <LoadingSpinner size="small" color="black" centered />;
  if (tags.items.length === 0) {
    return <EmptyTagsListInfo className="py-8" />;
  }

  return (
    <Table<TagListItem>
      headerGroups={table.getHeaderGroups()}
      rowModel={table.getRowModel()}
      totalWidth={table.getTotalSize()}
      cellHeight={60}
      noVerticalSeparators
    />
  );
};
