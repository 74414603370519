import {upperCase} from 'lodash';
import {useCallback, useMemo} from 'react';
import {useUiSetting} from './useUiSetting';

const getColumnsState = <ColumnType extends string>(
  availableColumns: ColumnType[],
  visibleColumns: ColumnType[],
  columnsWithForcedVisibility?: ColumnType[]
) =>
  availableColumns.reduce(
    (state, column) => {
      state[column] = columnsWithForcedVisibility?.includes(column) || visibleColumns.includes(column);
      return state;
    },
    {} as Record<ColumnType, boolean>
  );

type ColumnSettings<ColumnType> = {
  visibleColumns: ColumnType[];
  columnsOrder: ColumnType[];
};

type Options<ColumnType> = {
  initiallyHiddenColumns?: ColumnType[];
  columnsWithForcedVisibility?: ColumnType[];
};

export const useColumnSettings = <ColumnType extends string>(
  availableColumns: ColumnType[],
  settingsKey: string,
  options: Options<ColumnType> = {}
) => {
  const {initiallyHiddenColumns = [], columnsWithForcedVisibility = []} = options;

  const initiallyVisibleColumns = useMemo(
    () => availableColumns.filter(column => !initiallyHiddenColumns.includes(column)),
    [availableColumns, initiallyHiddenColumns]
  );
  const [
    columnSettings = {
      visibleColumns: [...initiallyVisibleColumns],
      columnsOrder: [...availableColumns],
    },
    setColumnSettings,
  ] = useUiSetting<ColumnSettings<ColumnType>>(upperCase(settingsKey));

  const visibleColumnsState = useMemo(
    () =>
      getColumnsState<ColumnType>(
        availableColumns,
        columnSettings.visibleColumns,
        columnsWithForcedVisibility
      ),
    [availableColumns, columnSettings.visibleColumns, columnsWithForcedVisibility]
  );

  const columnsOrder = useMemo(() => columnSettings.columnsOrder, [columnSettings]);

  const customSettingsApplied = useMemo(
    () =>
      columnSettings.columnsOrder.toString() !== availableColumns.toString() ||
      columnSettings.visibleColumns.length !== initiallyVisibleColumns.length,
    [availableColumns, columnSettings, initiallyVisibleColumns]
  );

  const resetColumnSettings = useCallback(
    () =>
      setColumnSettings({
        visibleColumns: [...initiallyVisibleColumns],
        columnsOrder: [...availableColumns],
      }),
    [availableColumns, initiallyVisibleColumns, setColumnSettings]
  );

  return {
    columnSettings,
    setColumnSettings,
    resetColumnSettings: customSettingsApplied ? resetColumnSettings : undefined,
    columnsOrder,
    visibleColumnsState,
  };
};
