import {format, formatDistanceToNowStrict} from 'date-fns';

export const extractMonthAndYear = (dateString: string) => {
  if (!dateString.length) {
    return '';
  }
  const dateSplitted = dateString.split('-');
  const month = dateSplitted[1];
  const date = new Date(dateString);
  const formattedDate = month ? format(date, 'LLL y') : format(date, 'y');

  return formattedDate;
};

export const getRelativeTimeToNow = (dateString: Date) =>
  formatDistanceToNowStrict(dateString, {addSuffix: true, roundingMethod: 'ceil'});

export const humanizeDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};
