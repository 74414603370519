import cs from 'classnames';
import {LottieAnimation} from '../lottie-animation';
import progressProcessing from './progressProcessing.json';

type Props = {
  className?: string;
};

export const ProgressProcessing = ({className}: Props) => (
  <LottieAnimation animationData={progressProcessing} className={cs(className)} />
);
