import cs from 'classnames';
import {FiArrowLeft as ArrowLeft, FiChevronLeft as ChevronLeft} from 'react-icons/fi';
import {AvatarWithFullname} from '../../avatar';
import {Button} from '../../button';
import {Card} from '../../card';
import {Message} from '../../form';
import {FieldRow} from '../field-row';
import {RequestIntroFormType} from '../types';
import Styles from './Preview.module.scss';

type Props = {
  close: () => void;
  data?: RequestIntroFormType;
  sender: string;
  senderEmail: string;
};

export const Preview = ({close, data, sender, senderEmail}: Props) => {
  return (
    <div>
      <h3 className={Styles.heading}>
        <Button onClick={close} icon={<ChevronLeft size={18} />} variant="tertiary" iconOnly>
          Go back
        </Button>
        Preview
      </h3>
      <Card>
        <FieldRow label="From">
          <div className={Styles.previewItem}>{sender} via The Swarm</div>
        </FieldRow>
        <FieldRow label="To">
          <div className={Styles.previewItem}>
            {data?.receivers.map(receiver => (
              <span key={receiver} className={Styles.connectorPreview}>
                <AvatarWithFullname fullname={receiver} />
              </span>
            ))}
          </div>
          <Message
            message="The message will be sent separately to each recipient."
            className={cs(Styles.receiversInfo, {['hidden']: data && data.receivers.length < 2})}
          />
        </FieldRow>
        <FieldRow label="CC">
          <div className={Styles.previewItem}>{senderEmail}</div>
        </FieldRow>
        <FieldRow label="Subject">
          <div className={Styles.previewItem}>{data?.subject}</div>
        </FieldRow>
        <hr className={Styles.separator} />
        <FieldRow label="Message" />

        <div className={Styles.previewMessage} dangerouslySetInnerHTML={{__html: data?.message || ''}} />
      </Card>
      <Button onClick={close} icon={<ArrowLeft size={18} />} variant="tertiary" className="mx-auto mt-6">
        Back
      </Button>
    </div>
  );
};
