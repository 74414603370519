import cs from 'classnames';
import {ReactNode} from 'react';
import Styles from './Divider.module.css';

type Props = {
  children?: ReactNode;
  className?: string;
};

export const Divider = ({children, className}: Props) => {
  return (
    <div className={cs(Styles.divider, className)}>
      <span className={Styles.dividerText}>{children}</span>
    </div>
  );
};
