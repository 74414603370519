import {loadScript} from '../loadScript';
import {addElementsTo, createElement} from './utils';

type FirstPromoterMethod = 'referral';
type WindowWithFirstPromoter = Window & {
  fpr: (method: FirstPromoterMethod, payload: object) => unknown;
};

const isWindowWithFirstPromoter = (window: Window): window is WindowWithFirstPromoter =>
  Boolean((window as WindowWithFirstPromoter).fpr);

export const initializeFirstPromoter = async () => {
  const snippet = `
(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"tt2lqu88"});`;

  addElementsTo('html', [createElement('script', snippet)]);
  loadScript('https://cdn.firstpromoter.com/fpr.js');
};

export const firstPromoterReferral = (email: string) => {
  if (isWindowWithFirstPromoter(window)) {
    window.fpr('referral', {email});
  }
};
