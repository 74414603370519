import cs from 'classnames';
import {forwardRef, TextareaHTMLAttributes} from 'react';
import {ValidationProps} from '../types';
import {Message} from '../utils';
import Styles from './Textarea.module.scss';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> &
  ValidationProps & {
    value?: string;
    handleOnChange?: (value: string) => void;
    limit?: number;
    rows?: number;
    placeholder?: string;
    className?: string;
  };

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({value, handleOnChange, limit, rows, placeholder, isInvalid, message, className, ...rest}, ref) => {
    return (
      <div>
        <textarea
          className={cs(Styles.textarea, {[Styles.invalid]: isInvalid}, className)}
          maxLength={limit}
          rows={rows}
          value={value}
          placeholder={placeholder}
          onChange={e => handleOnChange?.(e.target.value)}
          ref={ref}
          {...rest}
        />
        {limit && (
          <span className={Styles.textareaLimit}>
            {value?.length}/{limit}
          </span>
        )}
        {message && <Message message={message} hasError={isInvalid} />}
      </div>
    );
  }
);

// below code is added according to:
// https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref

Textarea.displayName = 'Textarea';

export {Textarea};
