import {useCallback} from 'react';
import {useCurrentTeam} from '../../hooks';
import {TrialNotificationKeys} from './const';

export const usePersistCloseNotification = () => {
  const {id: currentTeamId} = useCurrentTeam();
  const persistHideNotification = useCallback(
    (key: TrialNotificationKeys) => {
      localStorage.setItem(key + currentTeamId, '1');
    },
    [currentTeamId]
  );

  const getNotificationAlreadyClosed = useCallback(
    (key: TrialNotificationKeys) => {
      return localStorage.getItem(key + currentTeamId) === '1';
    },
    [currentTeamId]
  );

  return {persistHideNotification, getNotificationAlreadyClosed};
};
