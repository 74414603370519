import cs from 'classnames';
import {FiX as X} from 'react-icons/fi';
import Styles from './Multiselect.module.scss';
import {OptionType} from './types';

type Props = {
  option: OptionType;
  onClick?: () => void;
  inDropdown?: boolean;
  singleOption?: boolean;
};

export const OptionTag = ({option, onClick, inDropdown, singleOption}: Props) => (
  <div className={cs(Styles.tag, {[Styles.tagTransparent]: inDropdown || singleOption})}>
    {option.template ? option.template : option.label ? option.label : option.value}
    {onClick && !singleOption && (
      <div onClick={onClick} className={Styles.closingX}>
        <X />
      </div>
    )}
  </div>
);
