import cs from 'classnames';
import {FiChevronDown as ChevronDown, FiChevronUp as ChevronUp} from 'react-icons/fi';
import Styles from './ExpandButton.module.scss';

type Props = {
  expanded: boolean;
  onClick: () => void;
  inline?: boolean;
};
export const ExpandButton = ({expanded, onClick, inline}: Props) => {
  return (
    <div className={cs(Styles.expandButton, {[Styles.expandButtonInline]: inline})} onClick={onClick}>
      {expanded ? (
        <>
          View less <ChevronUp />
        </>
      ) : (
        <>
          View all <ChevronDown />
        </>
      )}
    </div>
  );
};
