import {ReactNode} from 'react';
import {FiMail as MailIcon} from 'react-icons/fi';
import {Card} from '../card';
import {Textarea} from '../form';
import Styles from './EmailPreview.module.scss';

type EmailPreviewProps = {
  tempNote: string;
  setTempNote: (value: string) => void;
  subject: string;
  title: ReactNode;
  children: ReactNode;
};

export const EmailPreview = ({tempNote, setTempNote, subject, title, children}: EmailPreviewProps) => {
  const placeholder = 'Type a few words here for additional context...';

  return (
    <Card>
      <div className={Styles.emailPreviewTitle}>
        <h4>
          <MailIcon /> <span>subject</span> {subject}
        </h4>
      </div>
      <div className={Styles.emailPreviewContent}>
        <img className={Styles.logo} src="/images/logo-dark.png" alt="The Swarm" />
        <h2>{title}</h2>
        <div className="mb-6">{children}</div>
        <Textarea
          value={tempNote}
          handleOnChange={setTempNote}
          limit={500}
          rows={4}
          placeholder={placeholder}
        />
      </div>
    </Card>
  );
};
