import {useQueriesTeams} from '../api';
import {useUserSessionContext} from '../contexts';
import {EnhancedTeam, Role} from '../types';

export const useChangeTeam = () => {
  const {changeActiveTeam} = useUserSessionContext();
  return changeActiveTeam;
};

export const useTeamsList = (): EnhancedTeam[] => {
  const {activeTeamId, teams: userTeams} = useUserSessionContext();

  const {data} = useQueriesTeams({
    refetchOnMount: false,
    keepPreviousData: true,
  });

  return (
    data?.teams.map(team => {
      const userTeam = userTeams.find(userTeam => userTeam.id === team.id);
      return {
        ...team,
        role: userTeam?.role || Role.Contributor,
        isActive: userTeam?.id === activeTeamId,
      };
    }) || []
  );
};
