import {useCallback, useState} from 'react';

export const useCreateTeamModal = () => {
  const [createTeamVisible, setCreateTeamVisible] = useState(false);

  const closeCreateTeamModal = () => {
    setCreateTeamVisible(false);
  };

  const openCreateTeamModal = useCallback(() => {
    setCreateTeamVisible(true);
  }, []);

  return {
    createTeamModalVisible: createTeamVisible,
    closeCreateTeamModal,
    openCreateTeamModal,
  };
};
