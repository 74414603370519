import {useCallback} from 'react';
import {updateInvite, useQueryMyInvites, UserRespondedStatus} from '../../api';
import {useChangeTeam, useCurrentUserSession, useGoToApp} from '../../hooks';
import {InviteStatus} from '../../types';
import {useDismissInvitationsModal} from './useDismissInvitationsModal';

export const useMyInvites = () => {
  const {dismissInvitationsModal} = useDismissInvitationsModal();
  const goToApp = useGoToApp();
  const {refresh} = useCurrentUserSession();
  const changeTeam = useChangeTeam();
  const {data: invites = [], refetch} = useQueryMyInvites();

  const onUpdateStatus = useCallback(
    async (id: string, status: UserRespondedStatus, team_id?: string) => {
      await updateInvite(id, {status});
      await refresh();
      refetch(); // just refresh the invites blocking not needed waiting here

      if (status === InviteStatus.Accepted && team_id) {
        changeTeam(team_id);
        goToApp();
      }
      dismissInvitationsModal();
    },
    [refresh, refetch, dismissInvitationsModal, changeTeam, goToApp]
  );

  const pendingInvites = invites.filter(invite => invite.invite_status === InviteStatus.Pending);

  return {
    invites: pendingInvites,
    onUpdateStatus,
  };
};
