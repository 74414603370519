import {Card} from '../../components/card';
import {CompanyLink} from '../../components/company-link';
import {BffProfile} from '../../types';
import {extractMonthAndYear} from '../../utils';
import Styles from './SingleProfilePageContent.module.scss';

type WorkExperienceType = NonNullable<BffProfile['experience']>[0];

const getDuration = (start_date?: string, end_date?: string) => {
  if (!start_date) return null;
  const today = new Date();
  const startYear = Number(start_date.split('-')[0]);
  const startMonth = Number(start_date.split('-')[1]);
  const endYear = end_date ? Number(end_date.split('-')[0]) : today.getFullYear();
  const endMonth = end_date ? Number(end_date.split('-')[1]) : today.getMonth() + 1;

  const duration = (endYear - startYear) * 12 + (endMonth - startMonth);
  const years = Math.floor(duration / 12);
  const months = duration % 12;
  return (
    (years > 0 ? `${years} ${years === 1 ? 'year' : 'years'} ` : '') +
    (months > 0 ? `${months} ${months === 1 ? 'month' : 'months'}` : '')
  );
};
export const WorkExperience = ({title, company, startDate, endDate}: WorkExperienceType) => (
  <Card className="border-swarm-gray-300 p-4">
    <div>
      <div className={Styles.singleExperiencePosition}>{title}</div>

      {company?.id ? (
        <div>
          <CompanyLink
            companyName={company.name}
            companyId={company.id}
            logoUrl={company.logo}
            className={Styles.singleExperienceCompany}
          />
        </div>
      ) : (
        <div className={Styles.singleExperienceCompany}>{company?.name}</div>
      )}
      {startDate && (
        <div className={Styles.timeframe}>
          {startDate && extractMonthAndYear(startDate)} - {endDate ? extractMonthAndYear(endDate) : 'Present'}{' '}
          <span className="mx-1">&bull;</span> {getDuration(startDate, endDate)}
        </div>
      )}
      <div>
        <span className="text-sm font-light">{company?.locationName}</span>
      </div>
    </div>
  </Card>
);

export const WorkExperienceGrouped = ({
  companyName,
  companyId,
  experiences,
}: {
  companyName: string;
  companyId?: string;
  experiences: WorkExperienceType[];
}) => (
  <Card className="border-swarm-gray-300 p-4">
    {companyId ? (
      <CompanyLink companyName={companyName} companyId={companyId} className={Styles.companyName} />
    ) : (
      <span className={Styles.companyName}>{companyName}</span>
    )}
    <span className={Styles.experienceDuration}>
      {getDuration(experiences[experiences.length - 1].startDate, experiences[0].endDate)}
    </span>
    <div className={Styles.experienceItems}>
      {experiences.map(({title, startDate, endDate, company}, index) => (
        <div className={Styles.multiExperienceItem} key={index}>
          <span className={Styles.title}>{title}</span>
          <div className={Styles.timeframe}>
            {startDate && extractMonthAndYear(startDate)} -{' '}
            {endDate ? extractMonthAndYear(endDate) : 'Present'} <span className="mx-1">&bull;</span>{' '}
            {getDuration(startDate, endDate)}
          </div>
          <div className={Styles.location}>{company?.locationName}</div>
        </div>
      ))}
    </div>
  </Card>
);
