import {useCallback, useState} from 'react';

export const useRequestIntroModal = () => {
  const [introModalVisible, setIntroModalVisible] = useState(false);
  const [introProfileId, setIntroProfileId] = useState('');

  const closeIntroModal = () => {
    setIntroModalVisible(false);
  };

  const openIntroModal = useCallback((profileId: string) => {
    setIntroProfileId(profileId);
    setIntroModalVisible(true);
  }, []);

  return {
    introModalVisible,
    closeIntroModal,
    openIntroModal,
    introProfileId,
  };
};
