import {DotsThreeVertical} from 'phosphor-react';
import {useCallback, useState} from 'react';
import {FiAlertOctagon as AlertIcon} from 'react-icons/fi';
import {Button} from '../../../components/button';
import {Dropdown} from '../../../components/dropdown';
import {useSwarmAnalytics} from '../../../hooks';

export const DotsMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const {reportInaccurateData} = useSwarmAnalytics();

  return (
    <Dropdown
      renderDropdownTrigger={() => (
        <Button
          intercomTarget="single-profile-dots-menu"
          variant="tertiary"
          icon={<DotsThreeVertical weight="bold" size={22} />}
          iconOnly
        ></Button>
      )}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      placement="bottom-start"
    >
      <Dropdown.Item
        label="Report inaccurate data"
        icon={<AlertIcon />}
        type={{
          name: 'button',
          onClick: () => reportInaccurateData(closeDropdown),
        }}
      />
    </Dropdown>
  );
};
