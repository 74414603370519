import {FiLock as LockIcon} from 'react-icons/fi';
import {Pill} from '../../components/pill';
import {UpgradeTooltipContent} from '../../components/upgrade-tooltip';
import {useCheckPermission, usePermissionWithReason} from '../../hooks';
import {Permission} from '../../types';

export const useTabsConfig = () => {
  const isPlanUpdateAllowed = useCheckPermission(Permission.PlanUpdate);
  const isTeamSettingsUpdateAllowed = useCheckPermission(Permission.TeamSettingsUpdate);

  const crmIntegrationPermissionWithReason = usePermissionWithReason(Permission.CRMIntegration);
  const isCrmIntegrationAllowed = crmIntegrationPermissionWithReason.hasPermission;
  const isCrmIntegrationNotAllowedByPlan =
    !isCrmIntegrationAllowed && crmIntegrationPermissionWithReason.reason === 'plan';

  const tagUpdatePermissionWithReason = usePermissionWithReason(Permission.TagUpdate);
  const isTagUpdateAllowed = tagUpdatePermissionWithReason.hasPermission;
  const isTagUpdateNotAllowedByPlan = !isTagUpdateAllowed && tagUpdatePermissionWithReason.reason === 'plan';

  const tabs = [
    {
      title: 'General' as const,
      path: '/team-settings' as const,
      disabled: !isTeamSettingsUpdateAllowed,
    },
    {
      title: 'Subscription',
      path: '/team-settings/subscription',
      disabled: !isPlanUpdateAllowed,
    },
    {
      title: 'Integrations',
      path: '/team-settings/integrations',
      badge: isCrmIntegrationNotAllowedByPlan ? (
        <Pill className="ml-2" color="yellow">
          <LockIcon />
          Upgrade
        </Pill>
      ) : null,
      disabled: !isCrmIntegrationAllowed,
      tooltip: isCrmIntegrationNotAllowedByPlan ? (
        <UpgradeTooltipContent featureName="CRM Integrations" location="team settings tabs" />
      ) : undefined,
    },
    {
      title: 'Tags',
      path: '/team-settings/tags',
      badge: isTagUpdateNotAllowedByPlan ? (
        <Pill className="ml-2" color="yellow">
          <LockIcon />
          Upgrade
        </Pill>
      ) : null,
      disabled: !isTagUpdateAllowed,
      tooltip: isTagUpdateNotAllowedByPlan ? (
        <UpgradeTooltipContent featureName="Tags feature" location="team settings tabs" />
      ) : undefined,
    },
  ];

  return tabs;
};
