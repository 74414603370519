import cs from 'classnames';
import Styles from './ProgressBar.module.scss';

type Props = {
  maxValue: number;
  currentValue: number;
  hasWarningThreshold?: boolean;
  color?: 'gray' | 'orange' | 'yellow' | 'blue' | 'coral' | 'red' | 'green' | 'gradient';
  size?: 'sm' | 'md' | 'lg';
  bordered?: boolean;
  circular?: boolean;
  bgColor?: string;
  className?: string;
};

const ColorsMap: Record<NonNullable<Props['color']>, Record<'background' | 'border', string>> = {
  gray: {background: 'bg-swarm-gray-400', border: 'border-swarm-gray-500'},
  orange: {background: 'bg-swarm-orange', border: 'border-swarm-orange-darken'},
  yellow: {background: 'bg-swarm-yellow', border: 'border-swarm-yellow-darken'},
  blue: {background: 'bg-swarm-blue', border: 'border-swarm-blue-darken'},
  coral: {background: 'bg-swarm-coral', border: 'border-swarm-coral-darken'},
  red: {background: 'bg-swarm-red', border: 'border-swarm-red-darken'},
  green: {background: 'bg-swarm-green', border: 'border-swarm-green-darken'},
  gradient: {background: 'bg-swarm-gradient', border: 'border-none'},
};

const SizesMap: Record<NonNullable<Props['size']>, Record<'wrapper' | 'bar', string>> = {
  sm: {wrapper: 'h-[6px]', bar: 'min-w-[6px]'},
  md: {wrapper: 'h-2', bar: 'min-w-[8px]'},
  lg: {wrapper: 'h-4 !rounded-lg', bar: 'min-w-[16px] !rounded-lg'},
};

const CircularSizesMap: Record<NonNullable<Props['size']>, string> = {
  sm: 'h-8 w-8',
  md: 'h-12 w-12',
  lg: 'h-16 w-16',
};

const dangerRed = '#ED4D37';
const baseGray = '#D8D7D6';

const getRingStyles = (bgColor: string, baseColor: string, progressColor: string, percentage: number) =>
  `radial-gradient(closest-side, ${bgColor} 79%, transparent 80% 100%), conic-gradient(${progressColor} ${percentage}%, ${baseColor} 0)`;

export const ProgressBar = ({
  maxValue,
  currentValue,
  hasWarningThreshold = true,
  color = 'gray',
  size = 'sm',
  bordered = false,
  circular,
  bgColor = 'white',
  className,
}: Props) => {
  const calculateBarWidth = () => {
    const percentage = (currentValue / maxValue) * 100;
    return percentage <= 100 ? percentage : 100;
  };

  const barWidth = calculateBarWidth();

  const warningThreshold = 80;

  const hasWarning = circular ? hasWarningThreshold : hasWarningThreshold && barWidth >= warningThreshold;
  const hasError = hasWarningThreshold && barWidth === 100;

  if (circular) {
    return (
      <div
        className={cs(Styles.circular, CircularSizesMap[size], {['text-swarm-red']: hasWarning})}
        style={{
          background: getRingStyles(bgColor, baseGray, hasWarning ? dangerRed : 'black', barWidth),
        }}
      >
        {maxValue - currentValue}
        {size !== 'sm' && <>/{maxValue}</>}
      </div>
    );
  }

  return (
    <div
      className={cs(
        Styles.progressBarWrapper,
        {'bg-white': bordered, 'bg-swarm-gray-200': !bordered},
        SizesMap[size].wrapper,
        className
      )}
    >
      <span
        className={cs(ColorsMap[color].background, SizesMap[size].bar, 'transition-[width] duration-500', {
          [Styles.progressBar]: currentValue > 0,
          ['border']: bordered && currentValue > 0,
          [ColorsMap[color].border]: bordered,
          [Styles.progressBarWarning]: hasWarning,
          [Styles.progressBarError]: hasError,
        })}
        style={{width: `${barWidth}%`}}
      ></span>
    </div>
  );
};
