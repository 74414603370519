import {useCallback} from 'react';
import {FiChevronsLeft as CollapseIcon, FiChevronsRight as ExpandIcon} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {useLayoutContext} from '../../../contexts';
import {useSwarmLogo} from '../../../hooks';
import {segmentTrack} from '../../../utils';
import {DelayedAppearingContent} from '../../common/DelayedAppearingContent';
import Styles from './SidebarLogo.module.scss';

type SwarmIconProps = {
  className?: string;
};
const SwarmIcon = ({className}: SwarmIconProps) => (
  <img alt="Swarm Icon" src="/icons/s-letter.svg" className={className} />
);

export const SidebarLogo = () => {
  const {sidebarCollapsed, collapseSidebar, expandSidebar} = useLayoutContext();
  const logoSrc = useSwarmLogo(sidebarCollapsed);

  const handleExpandSidebar = useCallback(() => {
    segmentTrack('Sidebar Expanded');
    expandSidebar();
  }, [expandSidebar]);

  const handleCollapseSidebar = useCallback(() => {
    segmentTrack('Sidebar Collapsed');
    collapseSidebar();
  }, [collapseSidebar]);

  return (
    <div className={Styles.sidebarToggle}>
      <div className={Styles.sidebarToggleInner}>
        <div className={Styles.logoWrapper}>
          <Link to="/" data-intercom-target="The Swarm logo">
            <img alt="the swarm logo" src={logoSrc} className={Styles.sidebarLogo} />
          </Link>
          {sidebarCollapsed && (
            <span className={Styles.innerIcon}>
              <SwarmIcon className={Styles.swarmIcon} />
              <DelayedAppearingContent delayMs={300}>
                <ExpandIcon onClick={handleExpandSidebar} className={Styles.expandIcon} />
              </DelayedAppearingContent>
            </span>
          )}
        </div>
        <CollapseIcon onClick={handleCollapseSidebar} className={Styles.collapseIcon} />
      </div>
    </div>
  );
};
