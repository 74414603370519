import {isEqual} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {PipelineCommonResponse, PipelineForm} from '../types';
import {useInputProps} from './useInputProps';

const requiredFields = ['title'] as const;

export const usePipelineForm = (pipeline: PipelineCommonResponse) => {
  const [formState, setFormState] = useState<PipelineForm>(pipeline);
  useEffect(() => {
    setFormState(pipeline);
  }, [pipeline]);

  const isFormEdited = useMemo(() => !isEqual(pipeline, formState), [formState, pipeline]);
  const isFormFilled = useMemo(() => {
    const emptyFields = requiredFields.filter(field => {
      return formState[field] === '';
    });
    return emptyFields.length === 0;
  }, [formState]);

  const changeFormState = (key: keyof PipelineForm, value: string) =>
    setFormState({...formState, [key]: value});

  const {inputProps, labelProps} = useInputProps({formState, setFormState: changeFormState});
  const resetForm = useCallback(() => setFormState(pipeline), [pipeline]);
  return {
    resetForm,
    inputProps,
    labelProps,
    isFormEdited,
    isFormFilled,
    formState,
  };
};
