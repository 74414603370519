import {HeaderGroup, RowModel} from '@tanstack/react-table';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {BffFilterOptions, BulkActionsType, ProfilesListRow} from '../../types';
import {getConfig} from '../../utils';
import {Table} from '../table';
import {BulkActionsWrapper} from './bulk-actions-wrapper';

type ProfilesTableData = {
  getSelectedRowModel: () => {rows: Array<{original: ProfilesListRow}>};
  getIsAllRowsSelected: () => boolean;
  toggleAllRowsSelected: (selected: boolean) => void;
  getHeaderGroups: () => HeaderGroup<ProfilesListRow>[];
  getRowModel: () => RowModel<ProfilesListRow>;
  getTotalSize: () => number;
};

type ProfilesTableProps = {
  filters: BffFilterOptions;
  tableData: ProfilesTableData;
  onChangeSuccess: () => void;
  bulkActionsConfig: BulkActionsType;
  totalCount: number;
};

const {tableMassActionsEnabled} = getConfig();

export const ProfilesTable = ({
  filters,
  tableData,
  totalCount,
  onChangeSuccess,
  bulkActionsConfig,
}: ProfilesTableProps) => {
  const [massActionsMode, setMassActionsMode] = useState(false);

  const selectedRows = tableData.getSelectedRowModel().rows;
  const selectedProfiles = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedProfileIds = useMemo(() => selectedProfiles.map(profile => profile.id), [selectedProfiles]);
  const selectedProfilesCount = selectedProfileIds.length;

  const shouldShowBulkActions = selectedProfilesCount > 0;
  const shouldShowMassActionsSwitch =
    tableMassActionsEnabled && tableData.getIsAllRowsSelected() && selectedProfilesCount < totalCount;

  const unselectAllRows = useCallback(() => tableData.toggleAllRowsSelected(false), [tableData]);

  const onBulkActionSuccess = useCallback(() => {
    onChangeSuccess();
    unselectAllRows();
  }, [onChangeSuccess, unselectAllRows]);

  const activateMassActions = useCallback(() => setMassActionsMode(true), []);

  useEffect(() => {
    if (!shouldShowMassActionsSwitch) {
      setMassActionsMode(false);
    }
  }, [shouldShowMassActionsSwitch]);

  return (
    <Table<ProfilesListRow>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
      rowKeyPath="original.id"
      bulkActionsSlot={
        (tableMassActionsEnabled || shouldShowBulkActions) && ( // TODO: when removing tableMassActionsEnabled, remove shouldShowBulkActions condition from here as well
          <BulkActionsWrapper
            selectedProfiles={selectedProfiles}
            onBulkActionSuccess={onBulkActionSuccess}
            actions={bulkActionsConfig}
            disabled={!shouldShowBulkActions}
            totalCount={totalCount}
            massActionsMode={massActionsMode}
            filters={filters}
          />
        )
      }
      massActionsSwitchData={
        shouldShowMassActionsSwitch
          ? {
              massActionsMode,
              activateMassActions,
              unselectAllRows,
              selectedRowsCount: selectedProfilesCount,
              totalCount,
            }
          : undefined
      }
    />
  );
};
