import {useCallback, useMemo} from 'react';
import {getCookie, setCookie} from 'typescript-cookie';
import {useCurrentTeam, useCurrentUser} from '../../../hooks';

const BLOCK_OVERLAPS_KEY = 'blockImportOverlaps';
const COOKIE_EXPIRATION_DAYS = 7;

const generateCookieName = (userId: string, teamId: string) => `${BLOCK_OVERLAPS_KEY}_${userId}_${teamId}`;

export const useBlockOverlaps = () => {
  const {id: userId} = useCurrentUser();
  const {id: teamId} = useCurrentTeam();

  const blockOverlaps = useCallback(() => {
    setCookie(generateCookieName(userId, teamId), 'true', {expires: COOKIE_EXPIRATION_DAYS});
  }, [userId, teamId]);

  const overlapsBlocked = useMemo(() => {
    return getCookie(generateCookieName(userId, teamId)) === 'true';
  }, [userId, teamId]);

  return {
    blockOverlaps,
    overlapsBlocked,
  };
};
