import {useCallback, useContext} from 'react';
import {PremiumFeatureModalContext} from '../contexts/PremiumFeatureModalContext';

export const usePremiumFeatureModal = () => {
  const contextValue = useContext(PremiumFeatureModalContext);

  const showPremiumFeatureModal = useCallback(() => {
    contextValue.showPremiumFeatureModal();
  }, [contextValue]);

  return showPremiumFeatureModal;
};
