import {useCallback, useEffect, useMemo, useState} from 'react';
import {Role} from '../../../types';
import {segmentTrack} from '../../../utils';
import {Modal} from '../../modal';
import {AddConnectorsStep} from './add-connectors';
import Styles from './AddMembersModal.module.scss';
import {InviteMembersStep} from './InviteMembersStep';
import {SelectRoleStep} from './SelectRoleStep';

type AddMembersModalProps = {
  visible: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  initialRole?: Role;
};

export const AddMembersModal = ({visible, onClose, onSuccess, initialRole}: AddMembersModalProps) => {
  const [selectedRole, setSelectedRole] = useState<Role | null>(initialRole || null);

  useEffect(() => {
    if (initialRole) {
      setSelectedRole(initialRole);
    }
  }, [initialRole, selectedRole]);

  const clearSelectedRole = () => setSelectedRole(null);

  useEffect(() => {
    if (visible) {
      if (!initialRole) {
        clearSelectedRole();
      }

      segmentTrack('Modal Viewed', {label: 'add members'});
    }
  }, [initialRole, visible]);

  const onInviteSuccess = useCallback(() => {
    onSuccess?.();
    onClose();
  }, [onSuccess, onClose]);

  const content = useMemo(() => {
    switch (selectedRole) {
      case Role.Admin:
        return <InviteMembersStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} role={Role.Admin} />;
      case Role.Contributor:
        return (
          <InviteMembersStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} role={Role.Contributor} />
        );
      case Role.Guest:
        return <InviteMembersStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} role={Role.Guest} />;
      case Role.Connector:
        const goBack = initialRole === Role.Connector ? onClose : clearSelectedRole;
        return <AddConnectorsStep goBack={goBack} onSuccess={onInviteSuccess} />;
      default:
        return <SelectRoleStep setSelectedRole={setSelectedRole} />;
    }
  }, [initialRole, onClose, onInviteSuccess, selectedRole]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      closeButtonVisible
      className={Styles.addMembersModal}
      magicBorder
    >
      {content}
    </Modal>
  );
};
