import {FiltersState} from './types';

/*
 function transforming the filtersState object to request format
 */

export const filtersStateToRequest = (filtersState: FiltersState) =>
  Object.fromEntries(
    Object.entries(filtersState)
      .filter(([, {value}]) => value.length > 0)
      .map(([key, {value}]) => {
        return [key, value];
      })
  );
