import {trackError} from '../sentry';
import {addElementsTo, createElement, EventFunction, UserAnalyticsData} from '../utils';

type GTMEvent = Readonly<{
  event: string;
  [key: string]: unknown;
}>;

type GTMConfig = Readonly<{
  id: string | undefined;
  dataLayer?: Array<GTMEvent>;
}>;

type WindowWithDataLayer = Window & {
  dataLayer: Array<GTMEvent>;
};

const isWindowWithDataLayer = (window: Window): window is WindowWithDataLayer =>
  !!(window as WindowWithDataLayer).dataLayer;

function pushToDataLayer(gtmEvent: GTMEvent) {
  if (isWindowWithDataLayer(window)) {
    window.dataLayer.push(gtmEvent);
  }
}

export function initializeGTM(config: GTMConfig) {
  const {id, dataLayer} = config;

  if (!id) {
    trackError(new Error('No GTM Id provided'));

    return;
  }

  const {snippetGTM, snippetIframe} = getSnippets(config);

  addElementsTo('html', [createElement('script', snippetGTM)]);
  addElementsTo('body', [createElement('noscript', snippetIframe)]);

  if (dataLayer) {
    dataLayer.forEach(pushToDataLayer);
  }
}

function getSnippets(config: GTMConfig) {
  const {id} = config;

  const snippetGTM = `
    (function(w,d,s,l,i){w[l]=w[l]||[];
      w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');`;

  const snippetIframe = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  return {snippetGTM, snippetIframe};
}

export const gtmTrack: EventFunction = (event, data) => pushToDataLayer({event, ...data});
export const gtmSetupUser = (user: UserAnalyticsData) => {
  const {email, name, id, teamId, role, userTeams} = user;
  gtmTrack('login user', {email, name, userId: id, teamId, role, userTeams});
};
