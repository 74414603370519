import {LoadingSpinner} from '../../animations';
import {IndeterminateCheckbox} from '../../indeterminate-checkbox';
import {PrivateLabel} from '../../private-label';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './PipelineDropdownItem.module.scss';

type ItemProps = {
  pipelineName: string;
  isPrivate?: boolean;
  checked: boolean;
  indeterminate?: boolean;
  isPending?: boolean;
  noCheckbox?: boolean;
};

export const PipelineDropdownItem = ({
  pipelineName,
  isPrivate,
  checked,
  indeterminate,
  isPending,
  noCheckbox,
}: ItemProps) => {
  const content = (
    <>
      <TruncateWithTooltip content={pipelineName}>{pipelineName}</TruncateWithTooltip>
      {isPrivate && <PrivateLabel className="ml-1" />}
    </>
  );

  if (noCheckbox) {
    return content;
  }
  return (
    <div className={Styles.item}>
      {isPending ? (
        <LoadingSpinner size="x-small" />
      ) : (
        <IndeterminateCheckbox checked={checked} indeterminate={indeterminate} onChange={() => {}} />
      )}
      {content}
    </div>
  );
};
