import {createContext, Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {ConfirmationPopup} from '../components/confirmation-popup';

export type ConfirmContextType = {
  prompt: string;
  isOpen: boolean;
  proceed: (value: unknown) => void;
  cancel: () => unknown;
  actionType?: 'removal' | 'info';
};

export const ConfirmContext = createContext<
  [ConfirmContextType, Dispatch<SetStateAction<ConfirmContextType>>]
>([{} as ConfirmContextType, () => {}]);
ConfirmContext.displayName = 'SwarmConfirmContext';

type Props = {
  children: ReactNode;
};
export const ConfirmContextProvider = ({children}: Props) => {
  const [confirm, setConfirm] = useState({} as ConfirmContextType);

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
      <ConfirmationPopup />
    </ConfirmContext.Provider>
  );
};
