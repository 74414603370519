import {useCallback} from 'react';
import {useIntercom} from 'react-use-intercom';

export const useSwarmAnalytics = () => {
  const {trackEvent} = useIntercom();

  const reportInaccurateData = useCallback(
    (next?: () => void) => {
      trackEvent('report-inaccurate-data');
      next?.();
    },
    [trackEvent]
  );

  return {
    reportInaccurateData,
  };
};
