import {useCallback} from 'react';
import {useIntercom} from 'react-use-intercom';
import {useSubscription} from '../../hooks';
import {PlanType} from '../../types';
import {Notification} from '../notification';
import {UpgradeLink} from '../upgrade-link';
import {TrialNotificationKeys} from './const';
import {usePersistCloseNotification} from './usePersistCloseNotification';

export const TrialNotification = () => {
  const {showArticle} = useIntercom();
  const {persistHideNotification, getNotificationAlreadyClosed} = usePersistCloseNotification();
  const {plan, shouldRefetchPlan, isPlanLoading} = useSubscription();

  const trialPlanName = plan?.type === PlanType.Premium ? PlanType.Premium : PlanType.Base;

  const showReadMore = useCallback(() => showArticle(6671538), [showArticle]);

  const {payment, type: planType, trialAvailable} = plan || {};
  const {trialActive, endDate: endDateString, creditCardCollected} = payment || {};

  const isEnterprise = planType === PlanType.Enterprise;

  if (creditCardCollected || shouldRefetchPlan || isPlanLoading || isEnterprise) return null;

  const endDate = endDateString && new Date(endDateString);
  const today = new Date();
  const trialDaysLeft = Math.ceil(
    ((endDate && endDate.getTime() - today.getTime()) || 0) / (1000 * 60 * 60 * 24)
  );

  const showAvailableTrialNotification =
    trialAvailable && !getNotificationAlreadyClosed(TrialNotificationKeys.available);
  const showPendingTrialNotification =
    trialActive && !getNotificationAlreadyClosed(TrialNotificationKeys.pending);
  const showTrialEndingSoonNotification =
    endDateString && trialDaysLeft <= 7 && !getNotificationAlreadyClosed(TrialNotificationKeys.endingSoon);
  const showFinishedTrialNotification =
    !trialActive &&
    !trialAvailable &&
    planType === PlanType.Free &&
    !getNotificationAlreadyClosed(TrialNotificationKeys.finished);

  if (showAvailableTrialNotification)
    return (
      <Notification
        closable
        symbol="info"
        color="yellow"
        onClose={() => persistHideNotification(TrialNotificationKeys.available)}
        title={
          <>
            <UpgradeLink>Start trial</UpgradeLink> today.
          </>
        }
      >
        Unlock {trialPlanName} features with our 30-day free trial.{' '}
        <span className="cursor-pointer underline" onClick={showReadMore}>
          Read more.
        </span>
      </Notification>
    );

  if (showTrialEndingSoonNotification)
    return (
      <Notification
        closable
        symbol="clock"
        color="red"
        onClose={() => persistHideNotification(TrialNotificationKeys.endingSoon)}
        title={
          <>
            Hurry up! Your free trial ends in {trialDaysLeft} days! <UpgradeLink /> to keep {trialPlanName}{' '}
            features.
          </>
        }
      >
        If you choose not to upgrade, your plan will revert back to Free with limited features once your trial
        ends.{' '}
        <span className="underline" onClick={showReadMore}>
          Read more.
        </span>
      </Notification>
    );

  if (showPendingTrialNotification)
    return (
      <Notification
        closable
        symbol="clock"
        color="yellow"
        onClose={() => persistHideNotification(TrialNotificationKeys.pending)}
        title={
          <>
            Your team account is on a free trial of {trialPlanName} for the next {trialDaysLeft} days.{' '}
            <UpgradeLink>Upgrade plan</UpgradeLink>.
          </>
        }
      >
        If you choose not to upgrade, your plan will revert back to Free with limited features once your trial
        ends.{' '}
        <span className="underline" onClick={showReadMore}>
          Read more.
        </span>
      </Notification>
    );

  if (showFinishedTrialNotification)
    return (
      <Notification
        closable
        symbol="warning"
        color="black"
        onClose={() => persistHideNotification(TrialNotificationKeys.finished)}
        title={
          <>
            Your free trial has ended. <UpgradeLink /> anytime.
          </>
        }
      >
        Get access to pipelines, email information, connection strength and more.
      </Notification>
    );
  else return null;
};
