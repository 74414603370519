import cs from 'classnames';
import {useState} from 'react';
import {ExpandButton} from '../expand-button';
import {Pill} from '../pill';

type Props = {
  items: string[];
  initialAmount?: number;
  className?: string;
};

export const TagsCloud = ({items, initialAmount, className}: Props) => {
  const [expanded, setExpanded] = useState(initialAmount ? initialAmount > items.length : true);
  const itemsToRender = expanded ? items : items.slice(0, initialAmount);

  return (
    <div className={cs('block', className)}>
      {itemsToRender.map((skill, idx) => (
        <Pill key={idx} className="mb-1 mr-1">
          {skill}
        </Pill>
      ))}
      {initialAmount && initialAmount < items.length && (
        <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} inline />
      )}
    </div>
  );
};
