import cs from 'classnames';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useMutationUpdateUserLinkedinURL} from '../../../../api';
import {Button} from '../../../../components/button';
import {Input, Label} from '../../../../components/form';
import {useUserSessionContext} from '../../../../contexts';
import {segmentTrack} from '../../../../utils';

const ERROR_MESSAGE = 'Please enter a valid LinkedIn URL';

type WorkExperienceFormState = {
  linkedInURL: string;
};

type Props = {
  onSuccess: () => void;
  blueUserUrl?: string;
};

export const Form = ({onSuccess, blueUserUrl}: Props) => {
  const {
    user: {id: userId, linkedInUrl},
  } = useUserSessionContext();

  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
  } = useForm<WorkExperienceFormState>({
    defaultValues: {linkedInURL: linkedInUrl},
  });

  useEffect(() => {
    if (blueUserUrl) {
      setValue('linkedInURL', blueUserUrl);
    }
  }, [getValues, setValue, blueUserUrl]);

  const mutationUpdateUserLinkedinURL = useMutationUpdateUserLinkedinURL(userId, {
    onSuccess,
  });

  const onSubmit = useCallback(
    ({linkedInURL}: WorkExperienceFormState) => {
      mutationUpdateUserLinkedinURL.mutate(
        {
          linkedinUrl: linkedInURL,
        },
        {
          onSuccess,
          onError: () => setError('linkedInURL', {message: ERROR_MESSAGE}),
        }
      );
    },

    [mutationUpdateUserLinkedinURL, onSuccess, setError]
  );

  const onClickLinkedinProfileUrlLink = useCallback(() => {
    segmentTrack('Button Clicked', {
      label: 'linkedin url',
      location: 'add overlaps',
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2 text-left">
      <div className={cs({['hidden']: blueUserUrl})}>
        <Label>
          Enter your{' '}
          <a
            href="https://www.linkedin.com/in/"
            target="_blank"
            rel="noreferrer"
            className="underline"
            onClick={onClickLinkedinProfileUrlLink}
          >
            LinkedIn profile URL
          </a>
        </Label>
        <Input
          {...register('linkedInURL')}
          type="text"
          placeholder="https://www.linkedin.com/in/yourname/"
          isInvalid={Boolean(errors.linkedInURL)}
          message={errors.linkedInURL?.message}
        />
      </div>

      <Button
        type="submit"
        variant="primary"
        fullWidth
        loading={isSubmitting}
        size="lg"
        tracking={{
          label: 'continue',
          location: 'add overlaps',
        }}
      >
        Continue
      </Button>
    </form>
  );
};
