import {PiMonitorPlayBold as MonitorIcon} from 'react-icons/pi';
import {useCurrentTeam} from '../../hooks';
import {Permission, TeamMainUseCase} from '../../types';
import {PermissionChecker} from '../permission';
import Styles from './MainUseCaseGuides.module.scss';

const mainUseCaseMovies: Record<TeamMainUseCase, string> = {
  [TeamMainUseCase.sales]: '5dafb51a59ee477cb4d48c91b6c820bd',
  [TeamMainUseCase.recruiting]: '21ed2f18d1be4be6acdc6e579baa3a74',
  [TeamMainUseCase.fundraising]: '9cf34ea089f440afacc65764b84c8dd1',
  [TeamMainUseCase.vcNetwork]: '548f8d9bd2bd427c950843fc15f26be0',
  [TeamMainUseCase.personalNetwork]: '',
  [TeamMainUseCase.communityNetwork]: '4736a156567c44ec8c3a36670e677f13',
  [TeamMainUseCase.other]: '',
};

export const MainUseCaseGuides = () => {
  const {mainUseCase} = useCurrentTeam();

  if (!mainUseCase) {
    return null;
  }

  const mainUseCaseMovie = mainUseCaseMovies[mainUseCase];

  if (!mainUseCaseMovie) {
    return null;
  }

  return (
    <PermissionChecker permission={Permission.ShowGettingStartedVideo}>
      <div className={Styles.wrapper}>
        <h1 className={Styles.header}>
          <MonitorIcon size={28} />
          Getting started
        </h1>
        <div className={Styles.content}>
          <div className={Styles.videoWrapper}>
            <iframe
              src={`https://www.loom.com/embed/${mainUseCaseMovie}?hide_owner=true&hide_share=true&hide_title=true&hide_title=true&hideEmbedTopBar=true.`}
              allowFullScreen
              className={Styles.iframe}
            ></iframe>
          </div>
        </div>
      </div>
    </PermissionChecker>
  );
};
