import cs from 'classnames';
import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

export const AuthBoxIcon = ({children, className}: Props) => (
  <div
    className={cs(
      'inline-flex size-16 items-center justify-center rounded-full border border-swarm-gray-400 shadow-lg',
      className
    )}
  >
    {children}
  </div>
);
