import {PaginationState} from '@tanstack/react-table';

const DEFAULT_PAGE_INDEX = 1;
const DEFAULT_PAGE_SIZE = 50;

export const stringifyPaginationState = (pagination: PaginationState): string => {
  return pagination.pageIndex.toString() + ':' + pagination.pageSize.toString();
};

export const parsePaginationState = (pagination = ''): PaginationState => {
  const [pageIndex, pageSize] = pagination.split(':');
  return {
    pageIndex: parseInt(pageIndex, 10) || DEFAULT_PAGE_INDEX,
    pageSize: parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
  };
};
