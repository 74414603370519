import {useEffect, useRef} from 'react';

export function usePrevious<T>(value: T, uniquePrevious = false) {
  const previousRef = useRef<T>();
  const uniquePreviousRef = useRef<T>();
  useEffect(() => {
    if (previousRef.current !== uniquePreviousRef.current) {
      uniquePreviousRef.current = previousRef.current;
    }
    previousRef.current = value;
  }, [value]);
  if (uniquePrevious) {
    if (value === previousRef.current) {
      return uniquePreviousRef.current;
    }
  }
  return previousRef.current;
}
