import {SortingState} from '@tanstack/react-table';
import {isEmpty, isEqual, uniqBy} from 'lodash';
import {ArrowCounterClockwise as ResetIcon} from 'phosphor-react';
import {useCallback, useEffect, useMemo} from 'react';
import {FiBookmark as BookmarkIcon, FiSearch as SearchIcon} from 'react-icons/fi';
import {updatePipeline, useQueryBffProfilesList, useQueryProfileListBffAggregate} from '../../../api';
import {LoadingSpinner} from '../../../components/animations';
import {Button} from '../../../components/button';
import {ColumnSettingsDropdown} from '../../../components/column-settings';
import {filtersStateToRequest, FiltersWrapper, useFiltersState, useScope} from '../../../components/filters';
import {Input} from '../../../components/form';
import {NoResults} from '../../../components/no-results';
import {notify} from '../../../components/notifications';
import {PaginationButtons} from '../../../components/pagination-buttons';
import {PermissionChecker} from '../../../components/permission';
import {PremiumFeatureWrapper} from '../../../components/premium-feature-wrapper';
import {RequestIntro, useRequestIntroModal} from '../../../components/request-intro';
import {Switcher, SwitcherItem} from '../../../components/switcher';
import {useTablePagination, useTableSearch, useTableSorting} from '../../../components/table';
import {useAdjustSortToQuery} from '../../../components/table/useAdjustSortToQuery';
import {TabLinks} from '../../../components/tabs';
import {Tooltip} from '../../../components/tooltip';
import {ProfilesBffColumns, ProfilesBffFilter, profilesBffLabelsMap as labelsMap} from '../../../defs';
import {
  useCheckPermission,
  useColumnSettings,
  useCurrentUser,
  useOnFilteringEvent,
  useOnTeamChanged,
  usePrevious,
  useRestoreScrollOnPopState,
} from '../../../hooks';
import {AppLayout} from '../../../layouts';
import {
  GetProfilesListRequest,
  GetProfilesListResponse,
  Permission,
  PipelineCommonResponse,
} from '../../../types';
import {getRequestOffset, pluralizedPeople, sortingStateToSortOptions} from '../../../utils';
import {PipelineHeader} from '../components/PipelineHeader';
import {getTabLinksConfig} from '../getTabLinksConfig';
import {availableColumns, PIPELINES_SAVED_SEARCH_PREFERENCES_KEY} from './columns';
import {filtersConfig} from './filtersConfig';
import {PipelinesSavedSearchTable} from './PipelinesSavedSearchTable';
import {usePipelinesSavedSearchTable} from './usePipelinesSavedSearchTable';
import {translateSavedSearchFilters} from './utils';

const emptyProfilesResponse: GetProfilesListResponse = {
  items: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};

const subSorting: SortingState = [
  {id: ProfilesBffColumns.ConnectedMembersCount, desc: true},
  {id: ProfilesBffColumns.FullName, desc: false},
];

const oonSubSorting: SortingState = [{id: ProfilesBffColumns.FullName, desc: false}];

const DEFAULT_SCOPE = 'inNetwork';

type Props = {
  pipeline: PipelineCommonResponse;
  refetchPipeline: () => void;
};

export const SavedSearch = ({pipeline, refetchPipeline}: Props) => {
  const {id: pipelineId, savedSearch} = pipeline;
  const {id: currentUserId} = useCurrentUser();

  const isOwnPipeline = currentUserId === pipeline.creator.id;
  const canTeamPipelineUpdate = useCheckPermission(Permission.TeamPipelineUpdate);

  const {scope, setScope} = useScope(DEFAULT_SCOPE, 'savedSearch');

  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();

  // using `savedSearch` fields from pipeline response as a default here
  const parsedSavedSearch = useMemo(
    () => translateSavedSearchFilters(savedSearch, filtersConfig.availableFilters),
    [savedSearch]
  );
  const {filtersState, setFilter, restoreFiltersToDefault, isFilterActive} =
    useFiltersState(parsedSavedSearch);

  const {columnSettings, setColumnSettings, resetColumnSettings, visibleColumnsState, columnsOrder} =
    useColumnSettings(availableColumns, PIPELINES_SAVED_SEARCH_PREFERENCES_KEY, {
      initiallyHiddenColumns: [ProfilesBffColumns.JobCompanyWebsite],
      columnsWithForcedVisibility: isFilterActive(ProfilesBffFilter.currentJobCompanyWebsite)
        ? [ProfilesBffColumns.JobCompanyWebsite]
        : undefined,
    });

  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();
  // bulk actions
  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const {sortingState, setSorting} = useTableSorting();
  const sort = useMemo(
    () =>
      sortingStateToSortOptions(
        uniqBy([...sortingState, ...(scope === 'inNetwork' ? subSorting : oonSubSorting)], item => item.id)
      ),
    [sortingState, scope]
  );

  const adjustedSort = useAdjustSortToQuery(sort, searchQueryFetchParam);

  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);

  const filters = useMemo(() => {
    return {
      ...filtersStateToRequest(filtersState),
      ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
      ...(scope ? {scope: [scope]} : {}),
      outPipeline: [pipelineId],
    };
  }, [filtersState, searchQueryFetchParam, scope, pipelineId]);

  useOnFilteringEvent({
    filters,
  });

  const saveSearch = async () => {
    await updatePipeline(
      {
        ...pipeline,
        savedSearch: JSON.stringify(filters),
      },
      pipelineId
    );
    refetchPipeline();
    notify('Filters combination saved.');
  };

  const clearSavedSearchChanges = () => {
    restoreFiltersToDefault();
    notify('Saved search changes undone.');
  };

  const searchParams: GetProfilesListRequest = useMemo(
    () => ({
      offset,
      limit: paginationState.pageSize,
      filters,
      sort: adjustedSort,
    }),
    [offset, paginationState.pageSize, filters, adjustedSort]
  );

  const {
    data: pipelineProfiles = emptyProfilesResponse,
    isFetchedAfterMount,
    refetch,
  } = useQueryBffProfilesList(searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });

  const table = usePipelinesSavedSearchTable({
    rows: pipelineProfiles.items,
    isLoaded: isFetchedAfterMount,
    pagination: {
      paginationState,
      setPaginationState,
      currentPage,
      totalCount,
      setMeta,
    },
    sorting: {
      sortingState,
      setSorting,
    },
    onAssignTag: () => refetch(),
    onUnassignTag: () => refetch(),
    refetchProfiles: refetch,
    visibleColumnsState,
    columnsOrder,
    openIntroModal,
    pipelineId,
  });

  useRestoreScrollOnPopState('people', isFetchedAfterMount);

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilters, returnToFirstPage]);

  const paginationFullInfo = `${visibleItemsString} ${pluralizedPeople(totalCount)}`;

  const tabLinksConfig = getTabLinksConfig(pipelineId);

  const hasProfiles = totalCount > 0;

  const operationsDisabled = isEqual(JSON.stringify(filters), savedSearch);

  useOnTeamChanged(() => setScope(DEFAULT_SCOPE));

  const noFiltersAndOutOfNetwork =
    (!scope || scope === 'outOfNetwork') &&
    isEmpty(filtersStateToRequest(filtersState)) &&
    !searchQueryFetchParam;

  let content;

  switch (true) {
    case noFiltersAndOutOfNetwork:
      content = (
        <NoResults
          heading="Let’s narrow things down…"
          subheading={
            '"+ Add filters" to refine your search across our entire database,\n or switch back to "In network" for more relevant connections.'
          }
          page="oon-no-filters"
        />
      );
      break;

    case !isFetchedAfterMount:
      content = <LoadingSpinner size="small" color="black" centered />;
      break;

    case !hasProfiles:
      content = (
        <NoResults
          heading="Sorry, there are no results."
          subheading={
            'Please clear search filters and try again.\nConsider adding members to expand your network.'
          }
          page="results"
        />
      );
      break;

    default:
      content = (
        <>
          <PipelinesSavedSearchTable
            filters={filters}
            tableData={table}
            onChangeSuccess={refetch}
            pipelineId={pipelineId}
            pipelineName={pipeline.title}
            totalCount={totalCount}
          />
          <RequestIntro profileId={introProfileId} visible={introModalVisible} onClose={closeIntroModal} />
        </>
      );
      break;
  }

  return (
    <AppLayout
      header={<PipelineHeader pipeline={pipeline} />}
      subHeader={
        <>
          <TabLinks tabs={tabLinksConfig} activeTab="Saved search" />
          <div className="p-2 pb-1">
            <Switcher value={scope} setValue={setScope}>
              <PermissionChecker permission={Permission.OutOfNetworkScope}>
                <SwitcherItem value={undefined}>All</SwitcherItem>
              </PermissionChecker>
              <SwitcherItem value="inNetwork">In Network</SwitcherItem>
              <PremiumFeatureWrapper
                permission={Permission.OutOfNetworkScope}
                featureName="Out of Network feature"
                fallbackProps={{disabled: true}}
                tooltipPlacement="top-center"
                location="network scope switcher"
              >
                <SwitcherItem value="outOfNetwork">Out of Network</SwitcherItem>
              </PremiumFeatureWrapper>
            </Switcher>
          </div>
          <div className="flex items-end justify-between pl-2">
            <FiltersWrapper
              filtersState={filtersState}
              onFilterSet={setFilter}
              filtersConfig={filtersConfig}
              useFetchOptions={useQueryProfileListBffAggregate}
              outOfStateFilters={{
                outPipeline: [pipelineId],
                ...(scope ? {scope: [scope]} : {}),
                ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
              }}
              className="pb-0"
              additionalActionsSlot={
                <>
                  {(isOwnPipeline || canTeamPipelineUpdate) && (
                    <Tooltip content="Save this search">
                      <Button
                        data-intercom-target="Save this search"
                        onClick={saveSearch}
                        variant="tertiary"
                        icon={<BookmarkIcon />}
                        disabled={operationsDisabled}
                        outline
                        tracking={{
                          label: 'save this search',
                          location: 'pipeline',
                        }}
                        iconOnly
                      >
                        Save this search
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip content="Clear changes">
                    <Button
                      data-intercom-target="Clear saved search changes"
                      onClick={clearSavedSearchChanges}
                      variant="tertiary"
                      icon={<ResetIcon />}
                      disabled={operationsDisabled}
                      tracking={{
                        label: 'clear changes',
                        location: 'pipeline',
                      }}
                      iconOnly
                      outline
                    >
                      Clear changes
                    </Button>
                  </Tooltip>
                </>
              }
            />
          </div>
          <div className="m-2 flex items-center justify-between">
            <div className="w-72">
              <Input
                icon={SearchIcon}
                type="text"
                value={searchQuery}
                onChange={e => onChangeSearchQuery(e.target.value)}
                onClear={onClearSearchQuery}
                className="max-w-sm"
                placeholder="Search for name, email, title..."
                intercomTarget="search"
              />
            </div>
            <ColumnSettingsDropdown
              columnSettings={columnSettings}
              setColumnSettings={setColumnSettings}
              resetColumnSettings={resetColumnSettings}
              fixedColumns={[ProfilesBffColumns.FullName, ProfilesBffColumns.AddToPipeline]}
              labelsMap={labelsMap}
            />
          </div>
        </>
      }
      footer={
        hasProfiles &&
        !noFiltersAndOutOfNetwork && (
          <PaginationButtons
            visibleItemsString={paginationFullInfo}
            pageIndex={currentPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            navigate={{
              next: nextPage,
              previous: previousPage,
              goToPage: goToPage,
            }}
            totalCount={totalCount}
            pageSize={paginationState.pageSize}
            setPageSize={setPageSize}
          />
        )
      }
    >
      {content}
    </AppLayout>
  );
};
