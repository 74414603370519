import {ReactNode} from 'react';
import Styles from './SubCard.module.css';

type SubCardProps = {title: ReactNode; children: ReactNode; icon?: ReactNode; extraText?: ReactNode};
export const SubCard = ({title, icon, children, extraText}: SubCardProps) => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        {icon}
        <span className={Styles.title}>{title}</span>
        {extraText}
      </div>
      <div>{children}</div>
    </div>
  );
};
