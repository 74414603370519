import {flatten, intersection, uniq} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {createBulkSuggestions, deletePipelineProfiles, useQueryPipelines} from '../../../../api';
import {useConfirm, useCurrentTeam, usePremiumFeatureCallback} from '../../../../hooks';
import {Permission, PipelineCommonResponse, ProfilesListRow} from '../../../../types';
import {pluralizedConnection, pluralizedPeople, segmentTrack, sleep} from '../../../../utils';
import {Button} from '../../../button';
import {Dropdown} from '../../../dropdown';
import {DropdownSearch} from '../../../dropdown-search';
import {notify} from '../../../notifications';
import {PipelineCreateModal, usePipelineFormModal} from '../../../pipeline-form';
import {SuggestionIcon} from '../../../suggestions-indicator';
import {EmptyPipelinesListInfo} from '../../empty-pipelines-list';
import {PipelineDropdownItem} from '../../pipeline-dropdown-item';
import Styles from './BulkAddToPipelineButton.module.scss';

type BulkAddToPipelineButtonProps = {
  selectedProfiles: ProfilesListRow[];
  onSuccess?: () => void;
  disabled?: boolean;
};

export const BulkAddToPipelineButton = ({
  selectedProfiles,
  onSuccess,
  disabled,
}: BulkAddToPipelineButtonProps) => {
  const {isConfirmed: isDeletionFromPipelineConfirmed} = useConfirm();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const {id: teamId} = useCurrentTeam();

  const profileIDs = selectedProfiles.map(entity => entity.id);
  const selectedProfilesCount = profileIDs.length;

  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible} = usePipelineFormModal();

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const handleCreatePipeline = useCallback(() => {
    openPipelineFormModal();
    closeDropdown();
  }, [closeDropdown, openPipelineFormModal]);

  const handleCreatePipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleCreatePipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const {data} = useQueryPipelines(teamId, {limit: 100, offset: 0});
  const pipelines = useMemo(() => data?.items || [], [data]);

  const pipelinesListNotEmpty = pipelines.length > 0;

  const selectedPipelines = selectedProfiles.map(entity => entity.pipelines.map(pipeline => pipeline.id));
  const usedPipelines = uniq(flatten(selectedPipelines));
  const assignedPipelines = intersection(...selectedPipelines);

  const handleBulkAction = useCallback(
    async (pipeline: PipelineCommonResponse, action: 'add' | 'remove') => {
      closeDropdown();
      const isAddAction = action === 'add';
      const actionLabel = isAddAction ? 'added to' : 'removed from';
      const notifyMessage = isAddAction
        ? `Added ${profileIDs.length} ${pluralizedConnection(profileIDs.length)} to ${pipeline.title}.`
        : `Removed ${profileIDs.length} ${pluralizedPeople(profileIDs.length)} from "${pipeline.title}" list.`;

      if (!isAddAction) {
        const confirmDelete = await isDeletionFromPipelineConfirmed(
          `Are you sure you want to remove ${selectedProfilesCount} ${pluralizedPeople(selectedProfilesCount)} from ${pipeline.title} list?`,
          'removal'
        );
        if (!confirmDelete) return;
      }

      try {
        setLoading(true);
        await (isAddAction
          ? createBulkSuggestions(pipeline.id, {profileIDs})
          : deletePipelineProfiles(pipeline.id, profileIDs));
        await sleep(2000);
        notify(notifyMessage);
        onSuccess?.();
      } finally {
        setLoading(false);
        segmentTrack('Bulk Action Applied', {
          label: actionLabel,
          profile_count: profileIDs.length,
          pipeline_id: pipeline.id,
        });
      }
    },
    [closeDropdown, isDeletionFromPipelineConfirmed, onSuccess, profileIDs, selectedProfilesCount]
  );

  const bulkAddToPipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: (pipeline: PipelineCommonResponse) => handleBulkAction(pipeline, 'add'), // Type added here
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const bulkRemoveFromPipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: (pipeline: PipelineCommonResponse) => handleBulkAction(pipeline, 'remove'), // Type added here
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const handleToggle = useCallback(
    async (pipeline: PipelineCommonResponse) => {
      if (assignedPipelines.includes(pipeline.id)) {
        await bulkRemoveFromPipelineAsPremiumFeature(pipeline);
      } else {
        await bulkAddToPipelineAsPremiumFeature(pipeline);
      }
    },
    [assignedPipelines, bulkAddToPipelineAsPremiumFeature, bulkRemoveFromPipelineAsPremiumFeature]
  );

  const searchedPipelines = pipelines.filter(pipeline =>
    pipeline.pipeline.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const CreateNewPipelineButton = () => (
    <Button
      fullWidth
      size="sm"
      variant="tertiary"
      onClick={handleCreatePipelineAsPremiumFeature}
      icon={<PlusIcon />}
    >
      Save to new list
    </Button>
  );

  useEffect(() => {
    if (isOpen) {
      setSearchQuery('');
    }
  }, [isOpen]);

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderDropdownTrigger={() => (
          <Button
            className={Styles.bulkAddToPipelineButton}
            size="sm"
            variant="tertiary"
            outline
            chevron="down"
            icon={<SuggestionIcon />}
            loading={loading}
            disabled={disabled}
          >
            Add to list
          </Button>
        )}
        dropdownHeader={
          <DropdownSearch
            placeholder="Search for a List"
            onChange={q => setSearchQuery(q)}
            visible={pipelines.length > 3}
          />
        }
        dropdownFooter={<CreateNewPipelineButton />}
      >
        {pipelinesListNotEmpty ? (
          <Dropdown.ItemsGroup
            items={searchedPipelines.map(({pipeline}) => ({
              label: pipeline.title,
              type: {
                name: 'custom',
                onClick: () => handleToggle(pipeline),
                element: (
                  <PipelineDropdownItem
                    pipelineName={pipeline.title}
                    checked={assignedPipelines.includes(pipeline.id)}
                    indeterminate={
                      usedPipelines.includes(pipeline.id) && !assignedPipelines.includes(pipeline.id)
                    }
                    isPending={loading}
                    isPrivate={pipeline.private}
                  />
                ),
              },
              dataIntercomTarget: 'add selected profiles to pipeline',
            }))}
          ></Dropdown.ItemsGroup>
        ) : (
          <EmptyPipelinesListInfo />
        )}
      </Dropdown>
      <PipelineCreateModal
        visible={pipelineFormModalVisible}
        onClose={closePipelineFormModal}
        onSubmit={onSuccess}
        profileIDs={profileIDs}
      />
    </>
  );
};
