import {Dialog} from '@headlessui/react';
import {useCallback} from 'react';
import {Button} from '../../button';
import {GoogleImportButton} from '../../google-import-button';
import {trackCancelButtonOnStrategyView, trackStartImportClick} from '../analytics.utils';
import illustration from './google-contacts-import-instruction.png';

type Props = {
  onClose: () => void;
  goToSuccessScreen: () => void;
};

const onClickImportGoogleContacts = () => trackStartImportClick('google');

export const ImportConnectionsGoogleInstruction = ({onClose, goToSuccessScreen}: Props) => {
  const onCancelButtonClick = useCallback(() => {
    onClose();
    trackCancelButtonOnStrategyView('google');
  }, [onClose]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        Import your Google Contacts &amp; Calendar
      </Dialog.Title>

      <Dialog.Description className="mb-4 mt-2 font-normal">
        Authorize Google Contacts to share data with The&nbsp;Swarm.
      </Dialog.Description>

      <img src={illustration} alt="Google Import screenshot" className="mb-4 h-[373px] w-full" />

      <ul className="list-decimal space-y-2 pl-5 font-normal leading-6">
        <li>
          We’ll import your “Contacts” and “Other contacts” from{' '}
          <a
            href="https://contacts.google.com"
            target="_blank"
            rel="noreferrer"
            className="text-swarm-black underline"
          >
            contacts.google.com
          </a>{' '}
          with a match in our database, as well as contacts from your Google Calendar.
        </li>
        <li>
          Click on “Import Google Contacts” below, select an account, and authorize access. We never access or
          download any of your emails, attachments, and calendars.
        </li>
        <li>Refresh your browser to start seeing connections appear in batches.</li>
      </ul>

      <div className="mt-8 flex justify-between">
        <Button onClick={onCancelButtonClick}>Cancel</Button>

        <GoogleImportButton
          label="Import Google Contacts"
          onSuccess={goToSuccessScreen}
          onClick={onClickImportGoogleContacts}
        />
      </div>
    </>
  );
};
