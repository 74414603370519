type Props = {
  label: string;
  hideLabel?: boolean;
};

export const TableInnerHeader = ({label, hideLabel}: Props) => (
  <span className="capitalize" data-intercom-target={`table header: ${label}`}>
    {hideLabel ? '' : label}
  </span>
);
