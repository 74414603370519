import {ProfilesBffColumns} from '../../../defs';

export const PROFILES_IN_PIPELINE_PREFERENCES_KEY = 'list_profiles_in_pipeline_table_columns_5'; // postfix in the name to abandon old, not-compatible settings before columns naming changes

export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.StatusInPipeline,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.JobCompanyWebsite,
  ProfilesBffColumns.Tags,
  ProfilesBffColumns.TeamConnectionsStrength,
  ProfilesBffColumns.KeyRelationship,
  ProfilesBffColumns.ConnectedMembersCount,
  ProfilesBffColumns.Emails,
  ProfilesBffColumns.LocationName,
];
