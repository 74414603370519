import {Role} from '../../types';
import {Pill} from '../pill';

const paidRoles = [Role.Admin, Role.Contributor];

type Props = {
  role: Role;
};

export const PaidRoleBadge = ({role}: Props) => {
  const isPaid = paidRoles.includes(role);
  const badgeText = isPaid ? 'Paid' : 'Free';
  const badgeColor = isPaid ? 'yellow' : 'blue';

  return <Pill color={badgeColor}>{badgeText}</Pill>;
};
