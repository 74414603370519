import {sample} from 'lodash';
import Styles from './Testimonials.module.scss';
import {testimonials} from './consts';

export const Testimonials = () => {
  const {quote, author, role} = sample(testimonials) || {};

  return (
    <blockquote className={Styles.testimonial}>
      <p className={Styles.quote}>{quote}</p>
      <cite>
        <span className={Styles.author}>{author}</span>
        <span className={Styles.role}>{role}</span>
      </cite>
    </blockquote>
  );
};
