import cs from 'classnames';
import {Message} from '../utils';
import {Radio, RadioProps} from './Radio';
import Styles from './Radio.module.scss';

type Props = {
  radioButtons: RadioProps[];
  name: string;
  color?: 'blue' | 'black';
  formLabel?: string;
  isStacked?: boolean;
  isInvalid?: boolean;
  className?: string;
  message?: string;
  messageClassName?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
};

export const RadioGroup = ({
  name,
  isStacked = false,
  isInvalid,
  formLabel,
  radioButtons,
  color,
  className,
  message,
  messageClassName,
  defaultValue,
  onValueChange,
}: Props) => (
  <>
    <div className={cs(className, isStacked ? Styles.radioGroupStacked : Styles.radioGroupInline)}>
      <h2 className={cs('font-semibold', isStacked ? 'mb-2' : 'mr-6')}>{formLabel}</h2>
      <div className={cs(Styles.radioButtonsWrapper, {[Styles.radioButtonsWrapperInvalid]: isInvalid})}>
        {radioButtons.map((el, index) => (
          <Radio
            key={index}
            {...el}
            color={color}
            name={name}
            checked={defaultValue === el.value}
            onValueChange={onValueChange}
          />
        ))}
      </div>
    </div>
    {message && <Message message={message} hasError={isInvalid} className={messageClassName || 'pt-1'} />}
  </>
);
