import cs from 'classnames';

type Props = {
  imgSrc: string;
  iconName: string;
  alt?: string;
  className?: string;
};

export const SwarmIcon = (creationProps: Props) => {
  const {alt, imgSrc} = creationProps;
  const iconConstructor = (props: Partial<Props>) => {
    return <img src={imgSrc} alt={alt} className={cs('', creationProps.className, props.className)} />;
  };
  iconConstructor.displayName = `${creationProps.iconName}Icon`;

  return iconConstructor;
};
