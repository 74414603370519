import {SortingState} from '@tanstack/react-table';
import {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useQueryPipelines} from '../../../api';
import {NoResults} from '../../../components/no-results';
import {PageHeader} from '../../../components/page-header';
import {PipelineCreateModal, usePipelineFormModal} from '../../../components/pipeline-form';
import {useTableSorting} from '../../../components/table';
import {useCurrentTeam, useRestoreScrollOnPopState} from '../../../hooks';
import {AppLayout} from '../../../layouts';
import {sortingStateToSort} from '../../../utils';
import {CreatePipelineButton} from './CreatePipelineButton';
import Styles from './PipelinesList.module.scss';
import {PipelineTable} from './PipelineTable';

const defaultSorting: SortingState = [{id: 'createdAt', desc: true}];

export const PipelinesList = () => {
  const {id: currentTeamId} = useCurrentTeam();
  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible} = usePipelineFormModal();

  const sorting = useTableSorting(defaultSorting);
  const sort = useMemo(() => sortingStateToSort(sorting.sortingState), [sorting]);

  const {
    data,
    isFetchedAfterMount,
    refetch: refetchPipelines,
  } = useQueryPipelines(currentTeamId, {sort, limit: 100, offset: 0});
  useRestoreScrollOnPopState('pipelines', isFetchedAfterMount);

  const pipelines = useMemo(() => data?.items || [], [data]);

  const noResultsContent = {
    heading: "Lists, it's all in the name.",
    subheading:
      'Create a list to save connections. Share it or make it private. Export it. Rinse and repeat!',
  };

  const isEmpty = isFetchedAfterMount && pipelines.length === 0;

  const pageTitle = isEmpty ? 'Lists' : `Lists (${pipelines.length})`;

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - The Swarm`}</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader
            title={pageTitle}
            rightSlot={<CreatePipelineButton createPipeline={openPipelineFormModal} location="topbar" />}
            hasBottomSeparator
          />
        }
      >
        <div className={Styles.pageWrapper}>
          <PipelineTable refetchPipelines={refetchPipelines} pipelines={pipelines} sorting={sorting} />

          {isEmpty && (
            <NoResults
              heading={noResultsContent.heading}
              subheading={noResultsContent.subheading}
              page="pipelines"
            >
              <CreatePipelineButton
                label="Try it now"
                createPipeline={openPipelineFormModal}
                location="empty screen"
              />
            </NoResults>
          )}
        </div>
        <PipelineCreateModal
          visible={pipelineFormModalVisible}
          onClose={closePipelineFormModal}
          onSubmit={() => refetchPipelines()}
        />
      </AppLayout>
    </>
  );
};
