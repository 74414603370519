import {useEffect} from 'react';
import {AuthBox} from '../../../../../../components/auth';
import {Button} from '../../../../../../components/button';
import {Divider} from '../../../../../../components/divider';
import {Invitation} from '../../../../../../components/invitation';
import {usePrevious} from '../../../../../../hooks';
import {segmentVirtualPageview} from '../../../../../../utils';
import {useMyInvites} from '../../useMyInvites.hook';

type Props = {
  goToCreateTeam: () => void;
  onSuccess: () => void;
};

export const Invitations = ({goToCreateTeam, onSuccess}: Props) => {
  const {invitations, onUpdateStatus} = useMyInvites({onInviteResponse: onSuccess});
  const prevInvitations = usePrevious(invitations);

  useEffect(() => {
    segmentVirtualPageview('/join-team');
  }, []);

  useEffect(() => {
    // invitations list is asynchronously loaded and at the beginning the value is undefined, so this is why better approach is to compare with previous value
    if (prevInvitations && prevInvitations.length > 0 && invitations.length === 0) {
      goToCreateTeam();
    }
  }, [prevInvitations, invitations, goToCreateTeam]);

  return (
    <div className="flex-col">
      <AuthBox headerTitle="Join your team">
        {invitations.map(invitation => (
          <Invitation
            key={invitation.invite_id}
            invite={invitation}
            onUpdateStatus={(inviteId, status, teamId) => onUpdateStatus(inviteId, status, teamId)}
          />
        ))}
      </AuthBox>

      <Divider className="mx-4 py-0">or</Divider>

      <AuthBox headerTitle="Create a new team">
        <Button
          onClick={goToCreateTeam}
          size="lg"
          fullWidth
          tracking={{
            label: 'continue',
            location: 'join team',
          }}
        >
          Continue
        </Button>
      </AuthBox>
    </div>
  );
};
