import {useUserSessionContext} from '../../../contexts';
import {Modal} from '../../modal';
import {CreateTeamForm} from '../create-team-form';
import Styles from './CreateTeamModal.module.scss';

type CreateTeamModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const CreateTeamModal = ({visible, onClose}: CreateTeamModalProps) => {
  const {refetch: refetchTeams} = useUserSessionContext();
  const handleCreateTeamSuccess = () => {
    refetchTeams();
    onClose();
  };

  return (
    <Modal visible={visible} onClose={onClose} closeButtonVisible className={Styles.createTeamModal}>
      <h4 className={Styles.header}>Create team</h4>
      <hr className={Styles.divider} />
      <CreateTeamForm onSuccess={handleCreateTeamSuccess} onClose={onClose} isOnModal />
    </Modal>
  );
};
