import cs from 'classnames';
import {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import Styles from './TruncateProfileLink.module.scss';

type Props = {
  href: string;
  children: ReactNode;
  contentClassName?: string;
};

export const TruncateLink = ({href, children, contentClassName}: Props) => {
  return (
    <TruncateWithTooltip content={children} contentClassName={contentClassName}>
      <Link to={href}>
        <span className={cs(Styles.nameLinkContent, contentClassName)}>{children}</span>
      </Link>
    </TruncateWithTooltip>
  );
};
