export const TermsAgreements = () => {
  return (
    <p className="pt-6 text-sm font-light text-swarm-gray-600">
      By signing up, you agree to the <br />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.theswarm.com/terms"
        className="text-swarm-black hover:underline"
      >
        Terms & Conditions
      </a>{' '}
      and{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.theswarm.com/privacy"
        className="text-swarm-black hover:underline"
      >
        Privacy Policy
      </a>
      .
    </p>
  );
};
