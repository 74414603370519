import cs from 'classnames';
import {UserCircle} from 'phosphor-react';
import {AvatarWithFullname} from '../../../components/avatar';
import {DataList, DataListItem} from '../../../components/list';
import {CompanyCrmData} from '../../../types';
import {prepareCrmData, prettifyValue, transformLabel} from '../../../utils';
import Styles from './CompanyCrmDataView.module.scss';
import {OpportunityTile} from './OpportunityTile';

type Props = {
  companyCrmData: CompanyCrmData;
};

export const CompanyCrmDataView = ({companyCrmData}: Props) => {
  const {crmData, initialAmount} = prepareCrmData(companyCrmData.rawData);

  return (
    <div className={Styles.profileWidthContainer}>
      <div className={cs(Styles.profileColumn, Styles.crmDataColumn)}>
        <DataList
          items={[
            ...(companyCrmData.owner
              ? [
                  {
                    label: 'Contact Owner',
                    value: <AvatarWithFullname fullname={companyCrmData.owner.fullName} />,
                  },
                ]
              : []),
          ]}
          headerTitle="Contact Details"
          headerIcon={<UserCircle />}
          className={Styles.profileContentBlock}
          orientation="stacked"
          bottomBorder
        />

        <h3 className={Styles.sectionHeader}>Company Details</h3>
        <DataList
          items={crmData.map(
            ([label, value]) =>
              ({
                label: transformLabel(label),
                value: prettifyValue(value),
              }) satisfies DataListItem
          )}
          headerIcon={<UserCircle />}
          className={Styles.profileContentBlock}
          orientation="stacked"
          cols={2}
          bottomBorder
          initialAmount={initialAmount}
          emptyItemsPlaceholder="-"
        />

        <DataList
          items={[
            {
              label: 'Synced at',
              value: new Date(companyCrmData.lastSyncedAt).toLocaleString(),
            },
          ]}
          className="mt-8 w-1/2"
        />
      </div>
      {companyCrmData.opportunities && (
        <div className={cs(Styles.profileColumn, Styles.profileContentBlock)}>
          <h3 className={Styles.sectionHeader}>Opportunities</h3>

          {companyCrmData.opportunities.map((opportunity, idx) => (
            <OpportunityTile opportunity={opportunity} key={idx} />
          ))}
        </div>
      )}
    </div>
  );
};
