import {ChangeEvent, useState} from 'react';
import {PipelineForm} from '../types';

export function useInputProps(state: {
  formState: PipelineForm;
  setFormState: (key: keyof PipelineForm, value: string) => void;
}) {
  const {formState, setFormState} = state;

  const [fieldsWithErrors, setFieldsWithErrors] = useState<Array<keyof PipelineForm>>([]);

  const validateRequired = (fieldName: keyof PipelineForm) => {
    if (formState[fieldName] === '' && !fieldsWithErrors.includes(fieldName)) {
      setFieldsWithErrors([...fieldsWithErrors, fieldName]);
    } else if (formState[fieldName] !== '') {
      setFieldsWithErrors(fieldsWithErrors.filter(item => item !== fieldName));
    }
  };

  const checkIsInvalid = (fieldName: keyof PipelineForm) => fieldsWithErrors.includes(fieldName);
  const inputProps = (name: keyof PipelineForm) => ({
    name: name,
    value: formState[name],
    onChange: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
      setFormState(name, ev.target.value),
    onBlur: () => validateRequired(name),
    onClear: () => setFormState(name, ''),
    isInvalid: checkIsInvalid(name),
    message: checkIsInvalid(name) ? "This field can't be empty" : undefined,
  });

  const labelProps = (name: keyof PipelineForm) => ({
    htmlFor: name,
    hasError: checkIsInvalid(name),
    className: 'font-bold',
  });

  return {inputProps, labelProps};
}
