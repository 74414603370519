import {useCallback, useState} from 'react';
import {FiTrash2 as DeleteIcon} from 'react-icons/fi';
import {deletePipelineProfiles} from '../../../api';
import {useConfirm} from '../../../hooks';
import {pluralizedPeople, segmentTrack, sleep} from '../../../utils';
import {Button} from '../../button';
import {notify} from '../../notifications';

type Props = {
  profileIDs: string[];
  onSuccess?: () => void;
  pipelineId: string;
  pipelineName: string;
  disabled?: boolean;
};

export const BulkRemoveFromPipelineButton = ({
  profileIDs,
  onSuccess,
  pipelineId,
  pipelineName,
  disabled,
}: Props) => {
  const {isConfirmed: isDeletionFromPipelineConfirmed} = useConfirm();
  const [loading, setLoading] = useState(false);
  const selectedProfilesCount = profileIDs.length;

  const handleBulkRemoveFromPipeline = useCallback(async () => {
    const confirmDelete = await isDeletionFromPipelineConfirmed(
      `Are you sure you want to remove ${selectedProfilesCount} ${pluralizedPeople(selectedProfilesCount)} from ${pipelineName} list?`,
      'removal'
    );

    if (confirmDelete) {
      deletePipelineProfiles(pipelineId, profileIDs).then(() => {
        setLoading(true);
        sleep(2000).then(() => {
          onSuccess?.();
          notify(
            <>
              {profileIDs.length} {pluralizedPeople(profileIDs.length)} removed from the "{pipelineName}"
              list.
            </>
          );
          setLoading(false);
        });
        segmentTrack('Bulk Action Applied', {
          label: 'remove from pipeline',
          location: 'pipeline profiles list',
          profile_count: profileIDs.length,
        });
      });
    }
  }, [
    isDeletionFromPipelineConfirmed,
    onSuccess,
    pipelineId,
    pipelineName,
    profileIDs,
    selectedProfilesCount,
  ]);

  return (
    <Button
      onClick={handleBulkRemoveFromPipeline}
      variant="tertiary"
      outline
      icon={<DeleteIcon color="#ED4D37" />}
      size="sm"
      intercomTarget="remove selected profiles from pipeline"
      loading={loading}
      disabled={disabled}
    >
      <span className="text-swarm-red">Remove</span>
    </Button>
  );
};
