import {FiChevronLeft as BackIcon, FiShare2 as ShareIcon} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {FavoriteButton} from '../../../components/favorite-button';
import {notify} from '../../../components/notifications';
import {PageHeader} from '../../../components/page-header';
import {PipelineEditModal, usePipelineFormModal} from '../../../components/pipeline-form';
import {PrivateLabel} from '../../../components/private-label';
import {PipelineCommonResponse} from '../../../types';
import {copyTextToClipboard} from '../../../utils/clipboard';
import {DotsMenu} from './DotsMenu';
import Styles from './PipelineHeader.module.scss';

type Props = {
  pipeline: PipelineCommonResponse;
};

export const PipelineHeader = ({pipeline}: Props) => {
  const navigate = useNavigate();
  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible} = usePipelineFormModal();
  const {id, title, private: privatePipeline} = pipeline;
  const sharePipeline = () => {
    const {href: url} = window.location;
    copyTextToClipboard(url);
    notify('Link copied to the clipboard');
  };

  const goBack = () => navigate('/lists');

  return (
    <>
      <PageHeader
        rightSlot={
          <div className={Styles.headerButtons}>
            {!privatePipeline && (
              <Button
                data-intercom-target="Share pipeline"
                onClick={sharePipeline}
                variant="tertiary"
                icon={<ShareIcon />}
                tracking={{
                  label: 'share pipeline',
                }}
              >
                Share list
              </Button>
            )}

            <DotsMenu
              pipelineId={id}
              pipelineTitle={title}
              pipelineCreatorId={pipeline.creator.id}
              switchToEdit={openPipelineFormModal}
              alwaysVisible
              redirectToPipelinesList
            />
          </div>
        }
      >
        <Button onClick={goBack} icon={<BackIcon />} variant="tertiary" iconOnly>
          Go back
        </Button>
        <div className={Styles.title}>
          {title}
          {privatePipeline && <PrivateLabel className="ml-3" />}
          <FavoriteButton alwaysVisible listId={id} className="ml-3" />
        </div>
      </PageHeader>
      <PipelineEditModal
        visible={pipelineFormModalVisible}
        onClose={closePipelineFormModal}
        pipelineId={id}
      />
    </>
  );
};
