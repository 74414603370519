import {useQuery, UseQueryOptions} from 'react-query';
import {useCurrentTeam} from '../hooks';
import {PaymentPeriod, Plan, PlanType} from '../types';
import {apiClient} from './apiClient';

type RedirectUrls = {
  successUrl: string;
  cancelUrl: string;
};

const getPlan = async (teamId: string): Promise<Plan> =>
  apiClient.get<Plan>(`/teams/${teamId}/plan`).then(res => res.data);

type UseCurrentPlanOptions = UseQueryOptions<Plan, Error, Plan, ['getPlan', string]>;

export const useCurrentPlan = (options?: UseCurrentPlanOptions) => {
  const {id: currentTeamId} = useCurrentTeam();
  return useQuery(['getPlan', currentTeamId], () => getPlan(currentTeamId), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  });
};

export type UrlResponse = {
  url: string;
};

const getCancelLink = async (teamId: string, params: RedirectUrls): Promise<UrlResponse> =>
  apiClient
    .get<UrlResponse>(`/teams/${teamId}/plan/cancel-sub-link`, {
      params,
    })
    .then(res => res.data);

export const useQuerySubscriptionCancelLink = (
  teamId: string,
  params: RedirectUrls,
  options: UseQueryOptions<UrlResponse> = {}
) => useQuery<UrlResponse>(['getCancelLink', teamId, params], () => getCancelLink(teamId, params), options);

const getManageLink = async (teamId: string, returnUrl: string): Promise<UrlResponse> =>
  apiClient
    .get<UrlResponse>(`/teams/${teamId}/plan/customer-portal-link`, {
      params: {
        returnUrl,
      },
    })
    .then(res => res.data);

export const useQuerySubscriptionManageLink = (
  teamId: string,
  returnUrl: string,
  options: UseQueryOptions<UrlResponse> = {}
) =>
  useQuery<UrlResponse>(
    ['getManageLink', teamId, returnUrl],
    () => getManageLink(teamId, returnUrl),
    options
  );

export type GetCheckoutLinkParams = {
  successUrl: string;
  cancelUrl: string;
  planType: PlanType;
  period: PaymentPeriod;
};

const getCheckoutLink = async (teamId: string, params: GetCheckoutLinkParams): Promise<UrlResponse> =>
  apiClient
    .get<UrlResponse>(`/teams/${teamId}/plan/create-checkout-link`, {
      params,
    })
    .then(res => res.data);

export const useQuerySubscriptionCheckoutLink = (
  teamId: string,
  params: GetCheckoutLinkParams,
  options: UseQueryOptions<UrlResponse> = {}
) => useQuery<UrlResponse>(['getCheckoutLink', params], () => getCheckoutLink(teamId, params), options);

export const startTrial = async (teamId: string): Promise<void> =>
  apiClient.post<void>(`/teams/${teamId}/plan/start-trial`).then(res => res.data);

const getUpdatePaymentMethodLink = async (teamId: string, params: RedirectUrls): Promise<UrlResponse> =>
  apiClient
    .get<UrlResponse>(`/teams/${teamId}/plan/update-payment-method-link`, {
      params,
    })
    .then(res => res.data);

export const useQueryUpdateSubscriptionPaymentMethodLink = (
  teamId: string,
  params: RedirectUrls,
  options: UseQueryOptions<UrlResponse> = {}
) =>
  useQuery<UrlResponse>(
    ['getUpdatePaymentMethodLink', teamId, params],
    () => getUpdatePaymentMethodLink(teamId, params),
    options
  );
