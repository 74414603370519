import {ReactNode} from 'react';
import Styles from './CardHeader.module.scss';

type Props = {
  children: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
};

export const CardHeader = ({children, icon, onClick}: Props) => {
  return (
    <div className={Styles.wrapper}>
      {icon && (
        <button onClick={onClick} disabled={!onClick} className="mr-4">
          {icon}
        </button>
      )}
      <h2 className={Styles.text}>{children}</h2>
    </div>
  );
};
CardHeader.displayName = 'Card.Header';
