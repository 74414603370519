import {RoleAdminIcon, RoleConnectorIcon, RoleContributorIcon, RoleGuestIcon} from '.';
import {Role} from '../../types';

type RoleIconProps = {
  role: Role;
  className?: string;
};
export const RoleIcon = ({role, className}: RoleIconProps) => {
  let IconComponent;

  switch (role) {
    case Role.Admin:
      IconComponent = RoleAdminIcon;
      break;
    case Role.Contributor:
      IconComponent = RoleContributorIcon;
      break;
    case Role.Guest:
      IconComponent = RoleGuestIcon;
      break;
    case Role.Connector:
      IconComponent = RoleConnectorIcon;
  }

  return IconComponent ? <IconComponent className={className} /> : null;
};
