import {useCallback} from 'react';
import {useQueryNotes} from '../../api';
import {useCurrentUserSession} from '../../hooks';
import {segmentTrack} from '../../utils';
import {notify} from '../notifications';
import Styles from './ProfileNotes.module.scss';
import {NoteForm} from './note-form';
import {NotesList} from './notes-list';

type Props = {
  profileId: string;
  profileFullName?: string;
  refetchNotesCount?: () => void;
};
const defaultPage = {limit: 100, offset: 0};
export const ProfileNotes = ({profileId, profileFullName, refetchNotesCount}: Props) => {
  const {activeTeamId: teamId} = useCurrentUserSession();
  const {data = {items: []}, refetch: refetchNotes} = useQueryNotes({teamId, profileId, ...defaultPage});
  const {items: notes} = data;

  const noteFormSuccess = useCallback(() => {
    segmentTrack('Notification Viewed', {
      message: 'note added successfully',
      type: 'confirmation',
      process: 'profile notes',
    });
    notify('Note added successfully.');
    refetchNotes();
    refetchNotesCount?.();
  }, [refetchNotes, refetchNotesCount]);

  return (
    <div className={Styles.profileNotes}>
      {teamId && profileId && (
        <>
          <NoteForm profileId={profileId} teamId={teamId} onSuccess={noteFormSuccess} editMode={false} />
          <NotesList profileFullName={profileFullName} notes={notes} refetchNotes={refetchNotes} />
        </>
      )}
    </div>
  );
};
