import cs from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {FaRegStar as StarOutline, FaStar as Star} from 'react-icons/fa';
import {useFavoriteLists} from '../../hooks';
import {Permission} from '../../types';
import {segmentTrack, sleep} from '../../utils';
import {notify} from '../notifications';
import {PermissionChecker} from '../permission';
import {onRowHoverVisible} from '../table';
import {Tooltip} from '../tooltip';
import Styles from './FavoriteButton.module.scss';

type Props = {
  listId: string;
  alwaysVisible?: boolean;
  className?: string;
};

const gold = '#FDD167';

export const FavoriteButton = ({listId, alwaysVisible, className}: Props) => {
  const {favoritesIds, addFav, removeFav} = useFavoriteLists();

  const [hovered, setHovered] = useState(false);
  const [displayActive, setDisplayActive] = useState(favoritesIds.includes(listId));
  // keepFilled state is used to keep the star filled for a short time after switching
  // to active, otherwise it would be instantly outlined when still hovered after click
  const [keepFilled, setKeepFilled] = useState(false);

  useEffect(() => {
    setDisplayActive(() => {
      return favoritesIds.includes(listId);
    });
  }, [favoritesIds, listId]);

  const handleClick = useCallback(() => {
    setDisplayActive(displayActive => {
      segmentTrack('Button Clicked', {
        label: displayActive ? 'unstar' : 'star',
        pipeline_id: listId,
      });
      if (displayActive) {
        setKeepFilled(false);
        removeFav(listId);
        return false;
      } else {
        if (favoritesIds.length === 5) {
          notify('You can only have 5 favorite lists at a time.');
          return false;
        }
        addFav(listId);
        setKeepFilled(true);
        sleep(1500).then(() => setKeepFilled(false));
        return true;
      }
    });
  }, [favoritesIds, listId, removeFav, addFav]);

  const starProps = {size: 14, color: displayActive ? gold : 'black'};
  const fullStarVisible = (displayActive && !hovered) || (!displayActive && hovered) || keepFilled;

  return (
    <PermissionChecker permission={Permission.Pipeline}>
      <span
        className={cs(Styles.favButton, {[onRowHoverVisible]: !(displayActive || alwaysVisible)}, className)}
        data-intercom-target="favorite star"
        onClick={handleClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => {
          setHovered(false);
          setKeepFilled(false);
        }}
      >
        <Tooltip content="Favorite" hideHovered offset={14}>
          <div className={Styles.starHolder}>
            <Star {...starProps} className={cs(Styles.star, {[Styles.visible]: fullStarVisible})} />
            <StarOutline {...starProps} className={cs(Styles.star, {[Styles.visible]: !fullStarVisible})} />
          </div>
        </Tooltip>
      </span>
    </PermissionChecker>
  );
};
