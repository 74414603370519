import {ReactNode} from 'react';

type Props = {
  onClick: () => void;
  children: ReactNode;
  className?: string;
};

export const PillInteractiveIcon = ({children, className, onClick}: Props) => {
  return (
    <button onClick={onClick} className={className}>
      {children}
    </button>
  );
};
