import {Crm} from '../../../types';
import {IntegrationItem} from './types';

export const integrationsListData: IntegrationItem[] = [
  {
    title: 'HubSpot',
    description: 'Sync company data from HubSpot',
    crmName: Crm.hubspot,
  },
  {
    title: 'Salesforce',
    description: 'Sync company data from Salesforce',
    crmName: Crm.salesforce,
  },
  {
    title: 'Affinity',
    description: 'Sync company data from Affinity',
    crmName: Crm.affinity,
  } satisfies IntegrationItem,
];
