import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../components/button';
import {NoResults} from '../../components/no-results';
import {useCurrentUserSession} from '../../hooks';
import {AppLayout} from '../../layouts';

type Props = {forbidden?: boolean};
export const ErrorPage = ({forbidden = false}: Props) => {
  const {refresh} = useCurrentUserSession();

  useEffect(() => {
    // after unsuccessful navigating to page we want to reload id token
    refresh();
  }, [refresh]);

  const navigate = useNavigate();

  const errorContent = forbidden
    ? {
        heading: 'Access denied.',
        subheading: 'You don’t have permissions to access this page.',
      }
    : {
        heading: "Something's missing.",
        subheading: 'Sorry, the page you were looking for was not found.',
      };

  return (
    <>
      <Helmet>
        <title>{`${errorContent.heading} - The Swarm`}</title>
      </Helmet>
      <AppLayout>
        <NoResults
          heading={errorContent.heading}
          subheading={errorContent.subheading}
          page={forbidden ? 'forbidden' : 'error'}
        >
          <Button variant="secondary" onClick={() => navigate('/')}>
            Go to Home
          </Button>
        </NoResults>
      </AppLayout>
    </>
  );
};
