import linkifyHtml from 'linkify-html';
import {Opts} from 'linkifyjs';

const options: Opts = {
  attributes: {
    rel: 'noopener noreferrer nofollow',
  },
  target: (href, type) => {
    return type === 'url' ? '_blank' : '';
  },
  ignoreTags: ['script', 'style', 'a'],
};

export const linkify = (val: string): string => linkifyHtml(val, options);
