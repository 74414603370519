import {ColumnDef} from '@tanstack/react-table';
import {uniqBy} from 'lodash';
import {useMemo} from 'react';
import {CompanyLogo} from '../../components/company-logo';
import {CrmIcon} from '../../components/crm-icon';
import {PermissionChecker} from '../../components/permission';
import {TableInnerHeader, useSwarmTable, UseSwarmTableProps} from '../../components/table';
import {CompanyWebsiteCellContent, ConnectedMembersCellContent} from '../../components/table-cells-content';
import {TagsTableCell} from '../../components/tags';
import {Tooltip} from '../../components/tooltip';
import {TruncateLink} from '../../components/truncate-link';
import {BffCompanyListRow, Permission, TagSimple} from '../../types';
import {formatRange, integrationNameMap, segmentTrack} from '../../utils';
import {CompaniesColumns, labelsMap} from './columns';

type GenericUseSwarmTableProps = UseSwarmTableProps<BffCompanyListRow>;

export type UseCompaniesTableProps = {
  rows: BffCompanyListRow[];
  isLoaded: boolean;
  pagination: GenericUseSwarmTableProps['pagination'];
  sorting: GenericUseSwarmTableProps['sorting'];
  onAssignTag: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag: (profileIds: string[], tagId: string) => void;
  visibleColumnsState: GenericUseSwarmTableProps['visibleColumnsState'];
  columnsOrder: GenericUseSwarmTableProps['columnsOrder'];
};

export const useCompaniesTable = ({
  rows,
  isLoaded,
  pagination,
  sorting,
  onAssignTag,
  onUnassignTag,
  visibleColumnsState,
  columnsOrder,
}: UseCompaniesTableProps) => {
  const columns = useMemo<ColumnDef<BffCompanyListRow>[]>(
    () => [
      {
        accessorKey: CompaniesColumns.Name,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.Name]} />,
        cell: info => {
          const {name, id, logoURL: companyLogo, crms} = info.row.original;
          return (
            <div className="flex items-center gap-2">
              <CompanyLogo logoUrl={companyLogo} companyName={name} companyId={id} />
              <TruncateLink href={encodeURI(`/companies/${id}`)}>{name}</TruncateLink>{' '}
              <PermissionChecker permission={Permission.CRM}>
                <div className="flex shrink-0 flex-row gap-1">
                  {uniqBy(crms, integration => integration.crm).map(({crm}, index) => (
                    <Tooltip content={`Synchronized with ${integrationNameMap[crm]} CRM`} key={index}>
                      <CrmIcon crm={crm} className="!size-3.5" />
                    </Tooltip>
                  ))}
                </div>
              </PermissionChecker>
            </div>
          );
        },
        meta: {
          truncate: true,
        },
        size: 240,
      },
      {
        accessorKey: CompaniesColumns.Tags,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.Tags]} />,
        cell: info => {
          const {id: companyId, tags: assignedTags} = info.row.original;
          return (
            <TagsTableCell
              targetId={companyId}
              assignedTags={assignedTags}
              targetType="company"
              onAssignSuccess={tag => onAssignTag([companyId], tag)}
              onUnassignSuccess={tagId => onUnassignTag([companyId], tagId)}
            />
          );
        },
        meta: {
          truncate: false,
        },
        size: 160,
        minSize: 80,
      },
      {
        accessorKey: CompaniesColumns.Industry,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.Industry]} />,
        cell: info => info.row.original.industry || '',
        meta: {
          truncate: true,
        },
        size: 240,
      },
      {
        accessorKey: CompaniesColumns.Headquarters,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.Headquarters]} />,
        cell: info => info.row.original.locationName,
        meta: {
          truncate: true,
        },
        size: 240,
      },
      {
        accessorKey: CompaniesColumns.Size,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.Size]} />,
        cell: info => {
          const range = info.row.original.size;
          return range ? formatRange(range) : '';
        },
        meta: {
          truncate: true,
        },
        size: 130,
      },
      {
        accessorKey: CompaniesColumns.ProfileCount,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.ProfileCount]} />,
        cell: info => info.row.original.connectedProfilesCount,
        size: 100,
        minSize: 100,
      },
      {
        accessorKey: CompaniesColumns.ConnectedMembers,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.ConnectedMembers]} />,
        cell: info => {
          const connectedMembers = info.row.original.connectedTo;
          return (
            <div>
              {connectedMembers.length === 0 ? (
                'No connections'
              ) : (
                <ConnectedMembersCellContent connectedMembers={connectedMembers} />
              )}
            </div>
          );
        },
        size: 250,
        minSize: 110,
      },
      {
        accessorKey: CompaniesColumns.Website,
        header: () => <TableInnerHeader label={labelsMap[CompaniesColumns.Website]} />,
        cell: info => {
          const companyWebsite = info.row.original.website;

          if (!companyWebsite) {
            return '-';
          }

          return <CompanyWebsiteCellContent domain={companyWebsite} />;
        },
        size: 250,
        minSize: 110,
      },
    ],
    [onAssignTag, onUnassignTag]
  );

  const table = useSwarmTable<BffCompanyListRow>({
    uniqueName: 'companies',
    rows,
    isLoaded,
    selectable: true,
    columns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
    onSelectionChange: ({checked, row, count}) => {
      segmentTrack(checked ? 'Item Selected' : 'Selection Removed', {
        label: 'company',
        count,
        ...(row ? {profile_id: row.id} : {}),
      });
    },
  });

  return table;
};

export type UseCompaniesTableResult = ReturnType<typeof useCompaniesTable>;
