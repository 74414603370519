import cs from 'classnames';
import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  visible: boolean;
  className?: string;
};

export const HideableContent = ({children, visible, className}: Props) => (
  <div
    className={cs(
      'transition-all duration-300',
      {
        'opacity-100 max-h-[1000px]': visible,
        'opacity-0 max-h-0 pointer-events-none': !visible,
      },
      className
    )}
  >
    {children}
  </div>
);
