import {Warning as AlertIcon} from 'phosphor-react';
import {useState} from 'react';
import {BsChevronDown as ChevronDown, BsChevronUp as ChevronUp} from 'react-icons/bs';
import {Card} from '../../../card';
import {UnaddableProfilesList} from './UnaddableProfilesList';

type Props = {
  notFoundProfiles: string[];
  alreadyInTeamProfiles: string[];
};
export const UnaddableProfilesAlert = ({notFoundProfiles, alreadyInTeamProfiles}: Props) => {
  const unaddableProfolesCount = notFoundProfiles.length + alreadyInTeamProfiles.length;

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(open => !open);

  if (unaddableProfolesCount === 0) {
    return null;
  }

  return (
    <Card
      className="mt-4 cursor-pointer border-none p-4 text-sm text-swarm-gray-900"
      color="red"
      onClick={toggle}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-1">
          <AlertIcon className="text-lg text-swarm-red" />
          <span className="">There are ({unaddableProfolesCount}) profiles that won’t be added.</span>
        </div>
        <div className="flex flex-row items-center gap-2 text-swarm-black">
          {open ? <ChevronUp /> : <ChevronDown />}
          {open ? 'Hide' : 'Show'}
        </div>
      </div>

      {open && (
        <div className="mt-2">
          {notFoundProfiles.length > 0 && (
            <UnaddableProfilesList
              title="We couldn’t find these profiles in our database. They may have been removed from public sources or are no longer searchable."
              profiles={notFoundProfiles}
              className="border-b border-swarm-gray-400"
            />
          )}
          {alreadyInTeamProfiles.length > 0 && (
            <UnaddableProfilesList
              title={`${alreadyInTeamProfiles.length === 1 ? 'This profile already exists' : 'These profiles already exist'} in your team and won’t be added again.`}
              profiles={alreadyInTeamProfiles}
            />
          )}
        </div>
      )}
    </Card>
  );
};
