import {useCallback, useMemo} from 'react';
import {Permission} from '../types';
import {usePermissionWithReason, usePremiumFeatureModal} from './index';

type Props<TCallback = () => void> = {
  callback: TCallback;
  permission: Permission;
  onPermissionDenied?: () => void;
};

export const usePremiumFeatureCallback = <T>({callback, permission, onPermissionDenied}: Props<T>): T => {
  const showPremiumFeatureModal = usePremiumFeatureModal();
  const permissionWithReason = usePermissionWithReason(permission);
  const canPerformAction = permissionWithReason.hasPermission;
  const isPermissionDeniedByPlan = !canPerformAction && permissionWithReason.reason === 'plan';

  const handlePermissionDeniedByPlan = useCallback(() => {
    showPremiumFeatureModal();
    onPermissionDenied?.();
  }, [showPremiumFeatureModal, onPermissionDenied]);

  const newCallback = useMemo(() => {
    if (canPerformAction) {
      return callback;
    } else if (isPermissionDeniedByPlan) {
      return handlePermissionDeniedByPlan as T;
    } else {
      return (onPermissionDenied as T) || ((() => {}) as T);
    }
  }, [
    canPerformAction,
    isPermissionDeniedByPlan,
    callback,
    handlePermissionDeniedByPlan,
    onPermissionDenied,
  ]);

  return newCallback;
};
