import {FiShare as ShareIcon} from 'react-icons/fi';
import {Crm} from '../../types';
import {Button} from '../button';
import {CrmExportDropdown} from './CrmExportDropdown';

type Props = {
  targetIds: string[];
  targetType: 'profile' | 'company';
  onSuccess?: (crm: Crm) => void;
};

export const CrmExportMenu = ({targetIds, targetType, onSuccess}: Props) => (
  <CrmExportDropdown
    targetIds={targetIds}
    targetType={targetType}
    trigger={
      <Button variant="tertiary" icon={<ShareIcon size={18} />} outline chevron="down">
        Export
      </Button>
    }
    onExportSuccess={onSuccess}
  />
);
