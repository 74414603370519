export enum Crm {
  hubspot = 'hubspot',
  salesforce = 'salesforce',
  affinity = 'affinity',
}

export type CrmIntegration = {
  id: string;
  crm: Crm;
  state: 'connected' | 'disconnecting';
  lastSyncedAt: string;
};

export type CrmDataOwner = {
  fullName: string;
  swarmId?: string;
};

export type ProfileCrmData = {
  crm: Crm;
  owner?: CrmDataOwner;
  connectedCompany: {
    swarmId?: string;
    owner?: CrmDataOwner;
    name: string;
  };
  rawData: Record<string, string>;
  emails?: string[];
  lastSyncedAt: string;
};

export enum OpportunityStatus {
  open = 'OPEN',
  won = 'WON',
  lost = 'LOST',
}

export type Opportunity = {
  id: string;
  name: string;
  description: string;
  status: OpportunityStatus;
  stage: {
    name: string;
  };
  createdAt: string;
  closedAt: string;
  lastActivityAt: string;
  lastSyncedAt: string;
  owner: CrmDataOwner;
};

export type CompanyCrmData = {
  crm: Crm;
  integrationId: Crm;
  owner?: CrmDataOwner;
  rawData: Record<string, unknown>;
  lastSyncedAt: string;
  opportunities?: Opportunity[];
};

export type ExportCrmTargetType = 'profile' | 'company';
