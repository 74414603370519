import {useCallback, useEffect} from 'react';
import {useQueryPipeline} from '../../api';
import {useCurrentTeam} from '../../hooks';
import {segmentTrack} from '../../utils';
import {Modal} from '../modal';
import {PipelineForm} from './PipelineForm';

import Styles from './PipelineForm.module.scss';

type Props = {
  visible: boolean;
  onClose: () => void;
  pipelineId: string;
};
export const PipelineEditModal = ({visible, onClose, pipelineId}: Props) => {
  const {id: teamId} = useCurrentTeam();
  const {data: pipeline, refetch} = useQueryPipeline(pipelineId, teamId);

  useEffect(() => {
    if (visible) {
      segmentTrack('Modal Viewed', {label: 'edit pipeline'});
    }
  }, [visible]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return pipelineId ? (
    <Modal visible={visible} className={Styles.pipelineFormModal} onClose={handleClose} closeButtonVisible>
      <h3 className={Styles.header}>Edit list</h3>
      {pipeline && (
        <PipelineForm
          close={handleClose}
          pipeline={pipeline}
          onSubmit={() => refetch}
          location="edit pipeline modal"
        />
      )}
    </Modal>
  ) : null;
};
