import {Dispatch, useCallback, useMemo, useState} from 'react';
import {segmentTrack} from '../../../utils';
import {Button} from '../../button';
import {Dropdown} from '../../dropdown';
import {AppliedFilter} from '../applied-filter';
import {FilterKind, FilterOperator, FilterOptions, FilterState, FilterType} from '../types';
import {useIsFilterOpen} from '../useIsFilterOpen';
import {EnumFilter, ListFilter, StringFilter} from './filter-kinds';

import Styles from './Filter.module.scss';
import {OperatorSelect} from './operator-select';

type Props = {
  filterConfig: FilterType;
  filterState: FilterState;
  onChange: Dispatch<FilterState>;
  onRemove: () => void;
  operatorSelect: boolean;
};

export const Filter = ({filterConfig, filterState, onChange, onRemove, operatorSelect}: Props) => {
  const {kind, title, searchable} = filterConfig;

  const [isFilterOpen, setIsFilterOpen] = useState(filterState.value.length === 0);
  const [isOpen, setIsOpen] = useIsFilterOpen({
    externalIsOpen: isFilterOpen,
    setExternalIsOpen: setIsFilterOpen,
    title: title || '',
  });

  const [visibleOptions, setVisibleOptions] = useState<FilterOptions>([]);
  const [localFilterState, setLocalFilterState] = useState<FilterState>(filterState);

  const allSelected = useMemo(() => {
    return localFilterState.value.length === visibleOptions.length;
  }, [localFilterState.value, visibleOptions]);

  const isMultipleChoice =
    !operatorSelect || [FilterOperator.isOneOf, FilterOperator.isNoneOf].includes(localFilterState.operator);

  const emitApplyEvent = useCallback(
    () =>
      segmentTrack('Selector Applied', {
        label: 'filter',
        filter: title,
      }),
    [title]
  );

  const onApply = useCallback(() => {
    onChange(localFilterState);
    emitApplyEvent();
    setIsOpen(false);
  }, [emitApplyEvent, localFilterState, onChange, setIsOpen]);

  const toggleSelectAll = useCallback(() => {
    const newValue = allSelected ? [] : visibleOptions.map(option => option.value);
    setLocalFilterState(prevState => ({
      operator: prevState.operator,
      value: newValue,
      selectedOptions: allSelected ? [] : visibleOptions,
    }));
  }, [allSelected, visibleOptions]);

  const onApplyList = useCallback(
    (list: string[]) => {
      const newState = {...localFilterState, value: list};
      setLocalFilterState(newState);
      onChange(newState);
      emitApplyEvent();
      setIsOpen(false);
    },
    [emitApplyEvent, localFilterState, onChange, setIsOpen]
  );

  const onCancel = useCallback(() => {
    if (filterState.value.length === 0) {
      onRemove();
    } else {
      setLocalFilterState(filterState);
    }
    setIsOpen(false);
  }, [filterState, onRemove, setIsOpen]);

  const locallyChanged = useMemo(
    () => JSON.stringify(localFilterState) !== JSON.stringify(filterState),
    [localFilterState, filterState]
  );
  if (kind === FilterKind.LIST) {
    return (
      <>
        <ListFilter
          onApply={onApplyList}
          onCancel={onCancel}
          value={localFilterState.value}
          isOpen={isOpen}
          {...filterConfig}
        />
        <div onClick={() => setIsOpen(true)} className={Styles.listFilterTrigger}>
          <AppliedFilter
            config={filterConfig}
            filterState={filterState}
            isOpen={isOpen}
            onRemove={onRemove}
          />
        </div>
      </>
    );
  }
  let filter;
  switch (kind) {
    case FilterKind.ENUM:
      filter = (
        <EnumFilter
          filterConfig={filterConfig}
          currentValue={localFilterState.value}
          selectedOptions={localFilterState.selectedOptions}
          onChange={setLocalFilterState}
          searchable={searchable}
          isMultipleChoice={isMultipleChoice}
          setVisibleOptions={setVisibleOptions}
        />
      );
      break;
    case FilterKind.STRING:
      filter = (
        <StringFilter
          currentValue={localFilterState.value}
          onChange={value => setLocalFilterState({...localFilterState, value})}
          isMultipleChoice={isMultipleChoice}
        />
      );
      break;
    default:
      filter = `Unknown filter kind '${kind}'`;
  }

  return (
    <Dropdown
      placement="bottom-start"
      renderDropdownTrigger={() => (
        <div>
          <AppliedFilter
            config={filterConfig}
            filterState={filterState}
            isOpen={isOpen}
            onRemove={onRemove}
          />
        </div>
      )}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dropdownFooter={
        <div className={Styles.filterActions}>
          {kind === FilterKind.ENUM && (
            <Button
              size="sm"
              variant="tertiary"
              className={Styles.selectAll}
              disableTitleCase
              onClick={toggleSelectAll}
            >
              {allSelected ? 'Unselect All' : 'Select All'}
            </Button>
          )}
          <Button size="sm" variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={onApply} disabled={!locallyChanged}>
            Apply
          </Button>
        </div>
      }
    >
      <div className={Styles.wrapper}>
        {operatorSelect && (
          <OperatorSelect
            operator={localFilterState.operator}
            setOperator={operator => setLocalFilterState({value: [], operator, selectedOptions: []})}
          />
        )}
        {filter}
      </div>
    </Dropdown>
  );
};
