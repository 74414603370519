import {Role} from '../../../types';

export const INITIAL_NUMBER_OF_INVITEES = 1;

export const defaultInvitees = Array.from({length: INITIAL_NUMBER_OF_INVITEES}, () => ({
  email: undefined,
}));

export const permissions: Record<Role, string[]> = {
  [Role.Owner]: [],
  [Role.Admin]: [
    'Views and manages billing',
    'Can invite, manage, and remove other members',
    'Can create, edit, and delete lists',
    'Can save searches for all lists',
    'Can send intro requests',
  ],
  [Role.Contributor]: [
    'Can create, edit, and delete lists',
    'Can save searches for their own lists',
    'Can send intro requests',
  ],
  [Role.Guest]: [
    'Can create, edit, and delete own lists',
    'Can send intro requests',
    'Cannot view the connections of other guests',
    'Cannot view the Members and Companies tabs',
    'Cannot view Profiles contacts (work & personal email)',
  ],
  [Role.Connector]: [],
};
