import {Button} from '../../../components/button';
import {AddToPipelineButton} from '../../../components/pipelines-actions/add-to-pipeline';
import {SuggestionIcon} from '../../../components/suggestions-indicator';
import {BffProfile} from '../../../types';
import {PipelineTag} from './PipelineTag';
import Styles from './ProfileInPipelines.module.scss';

type Props = {
  profile: BffProfile;
  refetchProfile: () => void;
};

export const ProfileInPipelines = ({profile, refetchProfile}: Props) => {
  const {id: profileId, pipelines} = profile;

  return (
    <div className={Styles.pipelineSuggestions}>
      <div>
        <h4 className={Styles.suggestionHeader} data-intercom-target="current-pipelines">
          <SuggestionIcon creator="user" /> Current List
        </h4>
        <div className={Styles.suggestionsContent}>
          <ul className={Styles.pipelinesList}>
            {pipelines.map(pipeline => (
              <li key={pipeline.id}>
                <PipelineTag pipeline={pipeline} onSuccess={refetchProfile} profileId={profileId} />
              </li>
            ))}
          </ul>
          <AddToPipelineButton
            profileId={profileId}
            alwaysVisible
            onSuccess={refetchProfile}
            profilePipelines={pipelines}
          >
            <Button
              className="shrink-0 cursor-pointer hover:text-swarm-black"
              variant="tertiary"
              size="md"
              chevron="down"
              outline
              data-intercom-target="suggest-to-pipeline"
            >
              Add to list
            </Button>
          </AddToPipelineButton>
        </div>
      </div>
    </div>
  );
};
