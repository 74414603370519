import {createContext, Dispatch, ReactNode, SetStateAction, useContext} from 'react';

type ContextValue = {
  selectedValue: string | undefined;
  setValue: Dispatch<SetStateAction<string | undefined>>;
};

const SwitcherContext = createContext<ContextValue>({
  selectedValue: undefined,
  setValue: () => {},
});

type Props = ContextValue & {
  children: ReactNode;
};

export const SwitcherContextProvider = ({selectedValue, setValue, children}: Props) => {
  return <SwitcherContext.Provider value={{selectedValue, setValue}}>{children}</SwitcherContext.Provider>;
};

export const useSwitcherContext = () => useContext(SwitcherContext);
