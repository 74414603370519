import {PaginationState} from '@tanstack/react-table';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {usePrevious, useSearchParamsHelpers} from '../../hooks';
import type {PaginationMeta} from './types';
import {parsePaginationState, stringifyPaginationState} from './useTablePagination.utils';

const DEFAULT_PAGINATION_STATE: PaginationState = {pageIndex: 1, pageSize: 50};

const createPaginationInfo = (meta: PaginationMeta, isLastPage: boolean) => {
  const pageFrom = (meta.offset + 1).toLocaleString('en-US');
  const pageTo = (isLastPage ? meta.totalCount : meta.offset + meta.limit).toLocaleString('en-US');
  const pageOf = meta.totalCount.toLocaleString('en-US');

  return `${pageFrom}-${pageTo} of ${pageOf}`;
};

export const useTablePagination = () => {
  const {getFromSearchParam, saveInSearchParam} = useSearchParamsHelpers({
    searchParamName: 'page',
    replaceHistory: true,
  });

  const gatherPaginationFromUrl = useCallback(() => {
    const pageFromUrl = getFromSearchParam() || '';
    return parsePaginationState(pageFromUrl);
  }, [getFromSearchParam]);

  const initialPagination = useMemo(() => gatherPaginationFromUrl(), [gatherPaginationFromUrl]);
  const [paginationState, setPaginationState] = useState<PaginationState>(initialPagination);

  const [meta, setMeta] = useState<PaginationMeta>({
    totalCount: 0,
    limit: paginationState.pageSize,
    offset: 0,
  });

  const setPageSize = useCallback((pageSize: number) => {
    setPaginationState(prev => ({
      ...prev,
      pageIndex: 1, // reset to first page when changing page size because the current page might not exist anymore
      pageSize,
    }));
  }, []);

  const pagesCount = Math.ceil(meta.totalCount / paginationState.pageSize);

  const isFirstPage = paginationState.pageIndex === 1;
  const isLastPage = paginationState.pageIndex === pagesCount;

  const visibleItemsString = useMemo(() => createPaginationInfo(meta, isLastPage), [meta, isLastPage]);

  const goToPage = useCallback((page: number) => {
    setPaginationState(prev => ({
      ...prev,
      pageIndex: page,
    }));
  }, []);

  const nextPage = useCallback(() => {
    if (isLastPage) return;
    setPaginationState(prev => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  }, [isLastPage]);

  const previousPage = useCallback(() => {
    if (isFirstPage) return;
    setPaginationState(prev => ({
      ...prev,
      pageIndex: prev.pageIndex - 1,
    }));
  }, [isFirstPage]);

  const resetPagination = useCallback(() => setPaginationState(DEFAULT_PAGINATION_STATE), []);

  const page = stringifyPaginationState(paginationState);
  const prevPage = usePrevious(page);
  useEffect(() => {
    if (!prevPage || prevPage === page) {
      return;
    }
    const isEmpty = !page || page === '1';
    const value = isEmpty ? null : page;
    saveInSearchParam(value);
  }, [prevPage, page, saveInSearchParam]);

  return {
    paginationState,
    setPaginationState,
    resetPagination,
    currentPage: paginationState.pageIndex,
    isFirstPage,
    isLastPage,
    totalCount: meta.totalCount,
    meta,
    setMeta,
    visibleItemsString,
    nextPage,
    previousPage,
    goToPage,
    setPageSize,
  };
};
