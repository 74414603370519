import {getHashParamValue} from './hashParamsUtils';
import {ConfigKeys} from './keys';

const getEnvValue = (key: ConfigKeys) => {
  const envKey = `REACT_APP_${key}`;

  if (envKey && process.env[envKey]) {
    return process.env[envKey] as string;
  }

  return null;
};

type SnakeToCamel<S extends string> = S extends `${infer Head}_${infer Tail}`
  ? `${Head}${Capitalize<SnakeToCamel<Tail>>}`
  : S;
export type ScreamingSnakeToCamel<S extends string> = SnakeToCamel<Lowercase<S>>;

const handleCamelCaseCapitalize = (stringPiece: string, index: number) =>
  index === 0 ? stringPiece : stringPiece.charAt(0).toUpperCase() + stringPiece.slice(1);

export const toCamelCase = <S extends string>(key: S): ScreamingSnakeToCamel<S> =>
  key.toLowerCase().split('_').map(handleCamelCaseCapitalize).join('') as ScreamingSnakeToCamel<S>;

const getParamValue = (key: ConfigKeys) => {
  const camelCasedKey = toCamelCase(key);
  return getHashParamValue(camelCasedKey);
};

export const getConfigFlag = (key: ConfigKeys): string | undefined => {
  const paramValue = getParamValue(key);

  if (paramValue) {
    return paramValue;
  }

  const envValue = getEnvValue(key);

  if (envValue) {
    return envValue;
  }

  return undefined;
};

export const isConfigFlagTruth = (key: ConfigKeys): boolean => {
  const paramValue = getParamValue(key);

  if (paramValue === 'true') {
    return true;
  }

  if (paramValue === 'false') {
    return false;
  }

  const envValue = getEnvValue(key);

  if (envValue === 'true') {
    return true;
  }

  return false;
};
