import {ClipboardEvent, FormEvent, useCallback, useMemo, useState} from 'react';
import PinInput from 'react-pin-input';
import {useAuthContext} from '../../../contexts';
import {useGoToApp} from '../../../hooks';
import {segmentTrack} from '../../../utils';
import {Button} from '../../button';
import {Message} from '../../form';

const errorMessage = 'The code is invalid. Try to log in again.';

const isValidCode = (code?: string) => code?.match(/^[0-9]{6}$/);

const pinDigitStyle = {
  width: '48px',
  height: '72px',
  borderRadius: '4px',
  border: '1px solid #CDCCCB',
  margin: '0 4px',
  fontSize: '36px',
  fontWeight: 600,
};

const pinDigitFocusStyle = {
  border: '1px solid #1D1D1D',
};

const confirmationCodeLimit = 6;

const GmailIcon = () => <img src="/icons/gmail.svg" alt="gmail icon" className="h-4" />;
const OutlookIcon = () => <img src="/icons/outlook.svg" alt="outlook icon" className="h-6" />;

export const ConfirmationForm = () => {
  const goToApp = useGoToApp();
  const [confirmationCode, setConfirmationCode] = useState('');
  const {confirmAuth, confirmationError, loading, isNewlyCreatedUser, email} = useAuthContext();

  const emailDomain = email.split('@')[1];
  const isGmail = emailDomain === 'gmail.com';
  const isOutlook = emailDomain === 'outlook.com';

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const success = await confirmAuth(confirmationCode);
      if (success) {
        segmentTrack(isNewlyCreatedUser ? 'User Signed Up' : 'User Signed In');
        goToApp();
      }
    },
    [confirmAuth, confirmationCode, goToApp, isNewlyCreatedUser]
  );

  const canSendCode = useMemo(() => isValidCode(confirmationCode), [confirmationCode]);

  const onInputChange = useCallback(
    (value: string) => setConfirmationCode(value.trim()),
    [setConfirmationCode]
  );

  const handlePaste = useCallback((e: ClipboardEvent<HTMLInputElement>) => {
    setConfirmationCode(e.clipboardData.getData('text').trim());
  }, []);

  const gmailSniperLinkHref = encodeURI(
    `https://mail.google.com/mail/u/${email}/#search/from:@theswarm.com+in:anywhere+newer_than:1h+subject:verification+code`
  );

  const outlookSniperLinkHref = 'https://outlook.live.com/mail/0/';

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <div onPaste={handlePaste}>
        <PinInput
          length={confirmationCodeLimit}
          focus
          initialValue={confirmationCode}
          onChange={value => onInputChange(value)}
          type="numeric"
          inputMode="number"
          style={{padding: '10px'}}
          inputStyle={pinDigitStyle}
          inputFocusStyle={pinDigitFocusStyle}
          autoSelect={true}
          regexCriteria={/^[0-9]{6}$/}
        />
      </div>
      {confirmationError && <Message message={errorMessage} hasError />}
      <Button
        type="submit"
        variant="primary"
        fullWidth
        disabled={!canSendCode}
        loading={loading}
        size="lg"
        tracking={{
          label: 'continue',
          location: 'verify email',
        }}
      >
        Continue
      </Button>
      <div className="flex justify-center gap-2">
        {!isOutlook && (
          <div className="flex-1">
            <Button
              href={gmailSniperLinkHref}
              target="_blank"
              variant="tertiary"
              fullWidth
              size="lg"
              icon={<GmailIcon />}
              outline
              tracking={{
                label: 'open gmail',
                location: 'verify email',
              }}
            >
              Open Gmail
            </Button>
          </div>
        )}
        {!isGmail && (
          <div className="flex-1">
            <Button
              href={outlookSniperLinkHref}
              target="_blank"
              variant="tertiary"
              fullWidth
              size="lg"
              icon={<OutlookIcon />}
              outline
              tracking={{
                label: 'open outlook',
                location: 'verify email',
              }}
            >
              Open Outlook
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};
