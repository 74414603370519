import {sanitize} from 'dompurify';
import {get, uniq} from 'lodash';

const leftDelimiter = '{';
const rightDelimiter = '}';
const placeholderRegexp = new RegExp(`${leftDelimiter}([\\w.-]+\\b)${rightDelimiter}`, 'gm');

type TemplateStringPlaceholder = {
  placeholder: string;
  valuePath: string;
};
const getTemplateStringPlaceholders = (templateString: string): TemplateStringPlaceholder[] => {
  const matches = uniq(templateString.match(placeholderRegexp));

  return matches.map(match => {
    const matchedFieldName = match.substring(leftDelimiter.length, match.length - rightDelimiter.length);
    return {
      placeholder: match,
      valuePath: matchedFieldName,
    };
  });
};

type FillTemplateStringOptions = {
  templateString: string;
  vars?: Record<string, unknown>;
  unresolvedMatchesReplacer?: string; // false: removes placeholder without resolved value | true: leaves as is in template
  withSanitization?: boolean; // return string secured from XSS vulnerabilities
};

export const fillTemplate = ({
  templateString = '',
  vars = {},
  unresolvedMatchesReplacer,
  withSanitization,
}: FillTemplateStringOptions): string => {
  const matches = getTemplateStringPlaceholders(templateString);

  let filledString = `${templateString}`;

  for (const match of matches) {
    const defaultValue = unresolvedMatchesReplacer ?? match.placeholder;
    const value = get(vars, match.valuePath, defaultValue);
    const readableValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
    filledString = filledString.replace(new RegExp(match.placeholder, 'g'), readableValue);
  }

  return withSanitization ? sanitize(filledString, {ADD_ATTR: ['target']}) : filledString;
};

type VerifyTemplateOptions = Pick<FillTemplateStringOptions, 'templateString' | 'vars'>;
export const verifyTemplate = ({templateString, vars}: VerifyTemplateOptions): boolean => {
  const NOT_FOUND = '<<!![[NOT FOUND KEY]]!!>>';
  const compiledTemplateString = fillTemplate({
    templateString,
    vars,
    unresolvedMatchesReplacer: NOT_FOUND,
  });

  return !compiledTemplateString.includes(NOT_FOUND);
};

export const isTemplateString = (templateString: string): boolean => {
  const matches = getTemplateStringPlaceholders(templateString);
  return matches.length > 0;
};

export const isVariableUsedInTemplate = (templateString: string, variable: string): boolean => {
  const matches = getTemplateStringPlaceholders(templateString);
  return matches.map(match => match.valuePath).includes(variable);
};
