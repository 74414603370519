import {FieldError, UseFormRegisterReturn} from 'react-hook-form';
import {Input} from '../../form';
import Styles from './AddMembersForm.module.scss';

type Props = {
  registerEmail: UseFormRegisterReturn<`invitees.${number}.email`>;
  invalidEmailMessage?: FieldError['message'];
};

export const AddMemberRow = ({registerEmail, invalidEmailMessage}: Props) => {
  return (
    <Input
      className={Styles.emailInput}
      {...registerEmail}
      placeholder="Enter your member’s email"
      isInvalid={Boolean(invalidEmailMessage)}
      message={invalidEmailMessage}
      messageClassName={Styles.emailInputMessage}
    />
  );
};
