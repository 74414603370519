import {ReactNode} from 'react';
import {Dropdown} from './Dropdown';
import Styles from './Dropdown.module.scss';
import {DropdownItemProps} from './DropdownItem';

type Props = {
  title?: string;
  description?: ReactNode;
  items?: DropdownItemProps[];
  children?: ReactNode;
};

export const DropdownItemsGroup = ({title, description, items, children}: Props) => {
  return (
    <>
      {title && <div className={Styles.dropdownItemsGroupTitle}>{title}</div>}
      {description && <div>{description}</div>}
      {items && (
        <ul>
          {items.map((item, index) => (
            <Dropdown.Item key={index} {...item} />
          ))}
        </ul>
      )}
      {children}
    </>
  );
};
