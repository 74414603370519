import {ProfilesTable} from '../../components/profiles-table';
import {BffFilterOptions} from '../../types';
import {UseProfilesTableResult} from './useMyConnectionsTable';

type MyConnectionsTableProps = {
  filters: BffFilterOptions;
  tableData: UseProfilesTableResult;
  onChangeSuccess: () => void;
  totalCount: number;
};

export const MyConnectionsTable = ({
  filters,
  tableData,
  onChangeSuccess,
  totalCount,
}: MyConnectionsTableProps) => (
  <ProfilesTable
    filters={filters}
    tableData={tableData}
    totalCount={totalCount}
    onChangeSuccess={onChangeSuccess}
    bulkActionsConfig={{
      addToPipeline: true,
      tags: true,
      crmExport: true,
      editStrength: true,
    }}
  />
);
