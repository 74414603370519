import cs from 'classnames';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {createSuggestion, deletePipelineProfile, useQueryPipelines} from '../../../../api';
import {useConfirm, useCurrentTeam, useCurrentUser, usePremiumFeatureCallback} from '../../../../hooks';
import {BffProfileInPipelineSuggestion, Permission} from '../../../../types';
import {segmentTrack, sleep} from '../../../../utils';
import {Button} from '../../../button';
import {Dropdown} from '../../../dropdown';
import {DropdownSearch} from '../../../dropdown-search';
import {notify} from '../../../notifications';
import {PipelineCreateModal, usePipelineFormModal} from '../../../pipeline-form';
import {onRowHoverVisible, onRowHoverVisibleIgnore} from '../../../table';
import {EmptyPipelinesListInfo} from '../../empty-pipelines-list';
import {PipelineDropdownItem} from '../../pipeline-dropdown-item';
import Styles from './AddToPipelineButton.module.scss';

type AddToPipelineButtonProps = {
  profileId: string;
  title?: string;
  children?: ReactNode;
  alwaysVisible?: boolean;
  profilePipelines: BffProfileInPipelineSuggestion[];
  onSuccess?: () => void;
};

export const AddToPipelineButton = ({
  profileId,
  title,
  children,
  alwaysVisible,
  profilePipelines,
  onSuccess,
}: AddToPipelineButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const {id: userId} = useCurrentUser();
  const {id: teamId} = useCurrentTeam();
  const [pendingRequestPipelineId, setPendingRequestPipelineId] = useState<string>();
  const isPendingRequest = Boolean(pendingRequestPipelineId);
  const {isConfirmed: isDeletionConfirmed} = useConfirm();

  const {data: pipelinesResponse} = useQueryPipelines(teamId, {
    limit: 100,
    offset: 0,
  });

  const allPipelines = useMemo(
    () =>
      pipelinesResponse?.items.map(item => ({
        ...item,
        pipeline: {
          ...item.pipeline,
          containProfile: profilePipelines.some(({id}) => id === item.pipeline.id),
        },
      })) || [],
    [pipelinesResponse?.items, profilePipelines]
  );

  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible} = usePipelineFormModal();
  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const handleCreatePipeline = useCallback(() => {
    openPipelineFormModal();
    closeDropdown();
  }, [closeDropdown, openPipelineFormModal]);

  const handleCreatePipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleCreatePipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const commonAnalyticsProperties = useMemo(() => ({userId, teamId, profileId}), [userId, teamId, profileId]);

  const handleProfileInPipeline = useCallback(
    (pipelineId: string, pipelineTitle: string) => {
      setPendingRequestPipelineId(pipelineId);
      createSuggestion(pipelineId, profileId)
        .then(() => {
          segmentTrack('Selector Applied', {
            label: 'add to pipeline',
            ...commonAnalyticsProperties,
            pipelineId,
            pipelineTitle,
          });
        })
        .then(() => setPendingRequestPipelineId(undefined))
        .then(closeDropdown)
        .then(() => {
          onSuccess?.();
          notify(`Connection added to ${pipelineTitle}.`);
        });
    },
    [profileId, commonAnalyticsProperties, onSuccess, closeDropdown]
  );

  const handleProfileInPipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleProfileInPipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const handleRemoveFromPipeline = useCallback(
    async (pipelineId: string, pipelineName: string) => {
      closeDropdown();
      const confirmDelete = await isDeletionConfirmed(
        `Are you sure you want to remove this profile from ${pipelineName}?`,
        'removal'
      );
      if (confirmDelete) {
        setPendingRequestPipelineId(pipelineId);
        deletePipelineProfile(pipelineId, profileId)
          .then(() => sleep(2000))
          .then(() => {
            setPendingRequestPipelineId(undefined);
            onSuccess?.();
            notify(`Profile removed from ${pipelineName}.`);
          });
      }
    },
    [isDeletionConfirmed, onSuccess, profileId, closeDropdown]
  );

  const searchedPipelines = allPipelines.filter(({pipeline}) =>
    pipeline.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const CreateNewPipelineButton = () => (
    <Button
      fullWidth
      size="sm"
      variant="tertiary"
      onClick={handleCreatePipelineAsPremiumFeature}
      icon={<PlusIcon />}
    >
      Save to new list
    </Button>
  );

  useEffect(() => {
    if (isOpen) {
      setSearchQuery('');
    }
  }, [isOpen]);

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderDropdownTrigger={() => (
          <div
            className={cs('flex', Styles.addToPipelineButton, {
              [Styles.addToPipelineButtonOpen]: isOpen,
              [onRowHoverVisible]: !alwaysVisible,
              [onRowHoverVisibleIgnore]: isOpen,
            })}
          >
            {children}
          </div>
        )}
        dropdownHeader={
          <DropdownSearch
            placeholder="Search for a List"
            onChange={q => setSearchQuery(q)}
            visible={allPipelines.length > 3}
          />
        }
        dropdownFooter={<CreateNewPipelineButton />}
      >
        {allPipelines.length > 0 ? (
          <Dropdown.ItemsGroup
            title={title}
            items={searchedPipelines.map(({pipeline}) => {
              const suggestionToPipeline = profilePipelines.find(({id}) => id === pipeline.id);
              const suggestionCreators = suggestionToPipeline?.suggestedBy ?? [];
              const canSuggestToPipeline = !suggestionCreators.some(
                suggestionCreator => suggestionCreator.id === userId
              );
              const isItemRequestPending = pendingRequestPipelineId === pipeline.id;
              const removeAction = () => handleRemoveFromPipeline(pipeline.id, pipeline.title);

              return {
                label: pipeline.title,
                type: {
                  name: 'custom',
                  onClick: () =>
                    pipeline.containProfile
                      ? removeAction()
                      : canSuggestToPipeline &&
                        handleProfileInPipelineAsPremiumFeature(pipeline.id, pipeline.title),
                  element: (
                    <PipelineDropdownItem
                      pipelineName={pipeline.title}
                      checked={pipeline.containProfile}
                      isPending={isItemRequestPending}
                      isPrivate={pipeline.private}
                    />
                  ),
                },
                dataIntercomTarget: 'add or remove profile from pipeline',
                className: cs({[Styles.disabled]: isPendingRequest}),
                disabled: isPendingRequest,
              };
            })}
          ></Dropdown.ItemsGroup>
        ) : (
          <EmptyPipelinesListInfo />
        )}
      </Dropdown>
      <PipelineCreateModal
        visible={pipelineFormModalVisible}
        onClose={closePipelineFormModal}
        onSubmit={onSuccess}
        profileIDs={[profileId]}
      />
    </>
  );
};
