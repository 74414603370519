import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import {Avatar, AvatarProps} from '../avatar';
import Styles from './AvatarWithFullname.module.scss';

type Props = Pick<AvatarProps, 'fullname' | 'empty' | 'outline' | 'avatarImage'>;

export const AvatarWithFullname = ({fullname, avatarImage, outline, empty}: Props) => {
  return (
    <span className={Styles.avatarWithFullname}>
      <Avatar fullname={fullname} avatarImage={avatarImage} outline={outline} empty={empty} />
      <span className={Styles.fullname}>
        <TruncateWithTooltip content={fullname}>{fullname}</TruncateWithTooltip>
      </span>
    </span>
  );
};
