import {useState} from 'react';

const INVITATIONS_MODAL_DISMISSED = 'invitationsModalDismissed';

export const useDismissInvitationsModal = () => {
  const invitationsModalDismissed = sessionStorage.getItem(INVITATIONS_MODAL_DISMISSED) === 'true';

  const [invitationsDismissed, setInvitationsDismissed] = useState(invitationsModalDismissed);

  const dismissInvitationsModal = () => {
    sessionStorage.setItem(INVITATIONS_MODAL_DISMISSED, 'true');
    setInvitationsDismissed(true);
  };

  return {
    invitationsDismissed,
    dismissInvitationsModal,
  };
};
