import {flatten} from 'flat';
import {isEmpty, partition} from 'lodash';
import {Record} from 'phosphor-react';
import {CompanyCrmData, Crm} from '../types';
import {linkify} from './linkify';

export const integrationNameMap: Record<Crm, string> = {
  affinity: 'Affinity',
  hubspot: 'HubSpot',
  salesforce: 'Salesforce',
};

export const prettifyValue = (value: unknown) => {
  if (typeof value === 'boolean') {
    return value ? '✅' : '❌';
  }

  if (typeof value === 'string') {
    const dateFromValue = new Date(value);

    if (!isNaN(dateFromValue.getTime())) {
      return dateFromValue.toLocaleString();
    }

    return <span dangerouslySetInnerHTML={{__html: linkify(value)}} />;
  }

  return JSON.stringify(value);
};

export const transformLabel = (label: string) => {
  return label
    .replaceAll('_', ' ')
    .replaceAll('.', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const prepareCrmData = (rawData: CompanyCrmData['rawData']) => {
  const crmRawData = flatten<Record<string, unknown>, string>(rawData);

  const [filledRecords, emptyRecords] = partition(
    Object.entries(crmRawData),
    ([_label, value]) => !isEmpty(value) || typeof value === 'boolean' || typeof value === 'number'
  );
  const crmData = [...filledRecords, ...emptyRecords]; // to keep filled data on top
  const initialAmount = filledRecords.length; // to show only filled records initially

  return {crmData, initialAmount};
};
