import cs from 'classnames';
import {useCallback, useMemo} from 'react';
import {unassignTag} from '../../../../api';
import {AssignTagRequestParams, ExportCrmTargetType, Permission, TagSimple} from '../../../../types';
import {notifyGenericError, segmentTrack, trackError} from '../../../../utils';
import {PermissionChecker} from '../../../permission';
import {Tag} from '../../index';
import {TagsProfileAction} from '../TagsProfileAction';
import Styles from './ProfileTagsList.module.scss';

type Props = {
  tags: TagSimple[];
  targetId: string;
  targetType: ExportCrmTargetType;
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
  className?: string;
};
export const ProfileTagsList = ({
  tags,
  targetId,
  targetType,
  onAssignSuccess,
  onUnassignSuccess,
  className,
}: Props) => {
  const params: AssignTagRequestParams = useMemo(
    () => ({
      targetIds: [targetId],
      targetType,
    }),
    [targetId, targetType]
  );

  const handleUnassign = useCallback(
    (tagId: string) => {
      segmentTrack('Selection Removed', {
        label: 'tags',
        tag: tagId,
      });
      return unassignTag(tagId, params);
    },
    [params]
  );

  const addTagsDropdown = (
    <TagsProfileAction
      tags={tags}
      targetId={targetId}
      targetType={targetType}
      onAssignSuccess={onAssignSuccess}
      onUnassignSuccess={onUnassignSuccess}
    />
  );

  return (
    <div className={cs(Styles.wrapper, className)}>
      <PermissionChecker permission={Permission.TagAssign} missingPlanFallback={addTagsDropdown}>
        {addTagsDropdown}
      </PermissionChecker>
      {tags.map(({id, name, color}) => (
        <PermissionChecker
          permission={Permission.TagAssign}
          key={id}
          fallback={<Tag key={id} content={name} color={color} />} // without unassign
        >
          <Tag
            key={id}
            content={name}
            color={color}
            unassign={() =>
              handleUnassign(id)
                .then(() => onUnassignSuccess?.(id))
                .catch(e => {
                  notifyGenericError();
                  trackError(e);
                })
            }
          />
        </PermissionChecker>
      ))}
    </div>
  );
};
