import {SortingState} from '@tanstack/react-table';
import {useCallback, useEffect, useState} from 'react';
import {useSearchParamsHelpers} from '../../hooks';
import {parseSortingState, stringifySortingState} from './useTableSorting.utils';

export const useTableSorting = (defaultSorting: SortingState = []) => {
  const {getFromSearchParam, saveInSearchParam} = useSearchParamsHelpers({
    searchParamName: 'sort',
    replaceHistory: true,
  });
  const gatherSortingFromUrl = useCallback(
    () => parseSortingState(getFromSearchParam() || ''),
    [getFromSearchParam]
  );
  const sortingFromUrl = gatherSortingFromUrl();
  const initialSorting = sortingFromUrl.length > 0 ? sortingFromUrl : defaultSorting;
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  useEffect(() => {
    saveInSearchParam(stringifySortingState(sorting));
  }, [sorting, saveInSearchParam]);

  return {
    sortingState: sorting,
    setSorting,
  };
};
