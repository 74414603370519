import cs from 'classnames';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {createNote, editNote} from '../../../api';
import {useCurrentUser} from '../../../hooks';
import {NoteResponse} from '../../../types';
import {getUserFullName, trackError} from '../../../utils';
import {Avatar} from '../../avatar';
import {Button} from '../../button';
import {Textarea} from '../../form';
import {notify} from '../../notifications';
import Styles from './NoteForm.module.scss';

type ConditionalProps = {onSuccess: () => void} & (
  | ({editMode: true} & {note: NoteResponse; onCancel: () => void})
  | ({editMode: false} & {
      profileId: string;
      teamId?: string;
    })
);

type FormProps = {
  content: string;
  isPrivate: boolean;
};

export const NoteForm = (props: ConditionalProps) => {
  const {editMode, onSuccess} = props;
  const profileId = editMode ? props.note.profileId : props.profileId;
  const teamId = editMode ? props.note.teamId : props.teamId;
  const onCancel = editMode ? props.onCancel : undefined;
  const currentUser = useCurrentUser();

  const note = editMode ? props.note : {id: '', content: '', teamId: ''};
  const {register, handleSubmit, control, watch, reset} = useForm({
    defaultValues: {content: note.content, isPrivate: editMode ? !note.teamId : false},
  });

  const handleCreateNote = useCallback(
    (content: string, isPrivate: boolean) => {
      createNote({content, profileId, teamId: isPrivate ? undefined : teamId})
        .then(() => {
          reset();
          onSuccess();
        })
        .catch(e => {
          notify(e.message);
          trackError(e);
        });
    },
    [profileId, teamId, onSuccess, reset]
  );

  const handleEditNote = useCallback(
    (content: string, noteId: string) => {
      editNote(content, noteId)
        .then(() => {
          onSuccess();
        })
        .catch(e => {
          notify(e.message);
          trackError(e);
        });
    },
    [onSuccess]
  );

  const onSubmit = ({content, isPrivate}: FormProps) => {
    editMode ? handleEditNote(content, note.id) : handleCreateNote(content, isPrivate);
  };

  const notePlaceholder = watch('isPrivate')
    ? 'Type your private note here.'
    : 'Type your note here. It will be visible to all team members.';

  const currentUserFullName = getUserFullName(currentUser);
  return (
    <div className={Styles.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cs(Styles.noteTextareaWrapper, {[Styles.editMode]: editMode})}>
          <div className={Styles.noteTextareaHeader}>
            <Avatar fullname={currentUserFullName} />
          </div>
          <Controller
            control={control}
            name="content"
            render={({field: {onChange, value}, fieldState: {error}}) => (
              <Textarea
                onChange={onChange}
                value={value}
                placeholder={notePlaceholder}
                message={error?.message}
                className={Styles.noteTextarea}
                isInvalid={!!error}
                rows={1}
              />
            )}
            rules={{required: "Note can't be empty."}}
          />
        </div>
        <div className={cs(Styles.formFooter, {[Styles.formFooterEditMode]: editMode})}>
          {editMode ? (
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
          ) : (
            <label className={Styles.markNotePrivate}>
              <input {...register('isPrivate')} type="checkbox" /> Make this note private
            </label>
          )}
          <Button
            variant="primary"
            type="submit"
            tracking={{label: editMode ? 'edit note' : 'add note', location: 'profile notes'}}
          >
            {editMode ? 'Save' : 'Add note'}
          </Button>
        </div>
      </form>
    </div>
  );
};
