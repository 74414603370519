import {useCallback, useState} from 'react';
import {SimpleCarousel} from '../../common';
import {ImportConnectionsSuccess} from '../success/ImportConnectionsSuccess';
import {ImportConnectionsGoogleInstruction} from './ImportConnectionsGoogleInstruction';

type Props = {
  onClose: () => unknown;
};

export const ImportConnectionsGoogle = ({onClose}: Props) => {
  const [step, setStep] = useState(0);

  const goToSuccessScreen = useCallback(() => setStep(1), []);

  return (
    <SimpleCarousel
      steps={[
        <ImportConnectionsGoogleInstruction
          onClose={onClose}
          goToSuccessScreen={goToSuccessScreen}
          key={0}
        />,
        <ImportConnectionsSuccess onClose={onClose} key={1} />,
      ]}
      displayStepIndex={step}
    />
  );
};
