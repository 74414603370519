import {useCallback, useEffect} from 'react';
import {segmentTrack} from '../../../../../utils';
import {Modal} from '../../../../modal';
import {ListFilterContent} from './ListFilterContent';
import {FilterListTexts, MatchingLinesInfo, TokenList} from './types';

type Props = FilterListTexts & {
  value: TokenList;
  onCancel: () => unknown;
  onApply: (list: string[]) => unknown;
  isOpen: boolean;
  title: string;
  transformRule?: (value: string) => string | null;
  matchingRule?: (value: string) => boolean;
  placeholderText?: string;
  matchingLinesInfo?: MatchingLinesInfo;
};
export const ListFilter = ({
  onApply,
  isOpen,
  value,
  title,
  onCancel,
  modalTitle,
  modalDescription,
  tokensLabel,
  matchingRule,
  transformRule,
  placeholderText,
  matchingLinesInfo,
}: Props) => {
  const handleApply = useCallback(
    (newValue: TokenList) => {
      const transformedValue = transformRule
        ? newValue.map(token => transformRule(token)).filter(token => token !== null)
        : newValue;

      onApply(transformedValue);

      segmentTrack('Selector Applied', {
        label: 'filter',
        filter: title,
      });
    },
    [onApply, title, transformRule]
  );

  useEffect(() => {
    if (isOpen) {
      segmentTrack('Modal Viewed', {label: 'list filter', title});
    }
  }, [isOpen, title]);

  return (
    <Modal visible={isOpen} className="w-[650px] font-light text-swarm-gray-700">
      <ListFilterContent
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        tokensLabel={tokensLabel}
        onApply={handleApply}
        onCancel={onCancel}
        value={value}
        matchingRule={matchingRule}
        transformRule={transformRule}
        placeholderText={placeholderText}
        matchingLinesInfo={matchingLinesInfo}
      />
    </Modal>
  );
};
