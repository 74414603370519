import {useEffect} from 'react';
import {FiMail as MailIcon} from 'react-icons/fi';
import {AuthBox, SignInAside} from '..';
import {useAuthContext} from '../../../contexts';
import {segmentPageview, segmentVirtualPageview} from '../../../utils';
import {AuthBoxIcon} from '../auth-box/AuthBoxIcon';
import {ConfirmationForm} from './ConfirmationForm';

const HeaderImage = () => (
  <AuthBoxIcon>
    <MailIcon size={32} />
  </AuthBoxIcon>
);

export const Confirmation = () => {
  const {email} = useAuthContext();

  useEffect(() => {
    segmentVirtualPageview('/verify-email');
  }, []);

  useEffect(() => {
    segmentPageview({
      previousPathname: 'sign-in',
      name: 'verify_email',
    });
  }, []);

  return (
    <AuthBox
      aside={<SignInAside />}
      headerTitle="Confirm your email"
      headerImage={<HeaderImage />}
      headerDescription={
        <>
          We’ve sent a one-time code to <br />
          <span className="whitespace-nowrap text-swarm-black underline">{email}</span>
        </>
      }
    >
      <ConfirmationForm />
    </AuthBox>
  );
};
