import cs from 'classnames';
import {LabelHTMLAttributes} from 'react';
import Styles from './Label.module.css';

type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  size?: 'base' | 'sm' | 'lg';
  inactive?: boolean;
  hasError?: boolean;
};

export const Label = ({size = 'base', inactive, hasError, children, className, ...rest}: Props) => (
  <label
    className={cs(
      `text-${size}`,
      {[Styles.labelInactive]: inactive, [Styles.labelError]: hasError},
      className
    )}
    data-intercom-target={`field label: ${children}`}
    {...rest}
  >
    {children}
  </label>
);
