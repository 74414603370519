import {ReactElement} from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {Analytics, Authorized} from './containers';
import {OnboardingContextProvider} from './contexts';
import {
  AccountPage,
  AfterGoogleSignin,
  AuthPage,
  CompaniesPage,
  CompanyProfilePage,
  DashboardPage,
  ErrorPage,
  IntegrationsPage,
  MyConnectionsPage,
  PeoplePage,
  PipelineDetailsPage,
  PipelineProfilesPage,
  PipelineSavedSearchPage,
  PipelinesPage,
  SettingsPage,
  SingleProfilePage,
  SubscriptionPage,
  TagsPage,
  TeamConnectorsMembersPage,
  TeamInvitedMembersPage,
  TeamJoinedMembersPage,
  TeamSettingsPage,
  ThankYouPage,
  UpgradePlanPage,
} from './pages';
import {Permission} from './types';

//TODO: remove this temporary redirect when all the links are updated (BE, FE, emails, website)

const TemporaryRedirectToPeople = () => {
  const {search} = useLocation();
  const redirectPath = `/people${search}`;

  return <Navigate to={redirectPath} replace={true} />;
};

const TemporaryRedirectToPeopleProfile = () => {
  const {id = ''} = useParams();
  const redirectPath = `/people/${id}`;

  return <Navigate to={redirectPath} replace={true} />;
};

//TODO: remove this temporary redirect when all the links are updated (BE, FE, emails, website)
const LegacyRedirectToLists = () => {
  const {search, hash} = useLocation();
  let redirectPath = `/lists${search}`;

  if (hash) {
    redirectPath += hash;
  }

  return <Navigate to={redirectPath} replace={true} />;
};
const LegacyRedirectToList = ({subpage}: {subpage?: 'saved-search' | 'details'}) => {
  const {id = ''} = useParams();
  const {search, hash} = useLocation();
  let redirectPath = `/lists/${id}`;
  if (subpage) {
    redirectPath += `/${subpage}`;
  }
  if (search) {
    redirectPath += search;
  }
  if (hash) {
    redirectPath += hash;
  }

  return <Navigate to={redirectPath} replace={true} />;
};

const publicRoutes = [
  {element: <AuthPage />, path: '/login'},
  {element: <AuthPage />, path: '/sign-in'},
  {element: <AfterGoogleSignin />, path: '/google-signin'},
];

const privateRoutes = [
  {element: <DashboardPage />, path: '/'},
  {element: <TemporaryRedirectToPeople />, path: '/network-graph'},
  {element: <TemporaryRedirectToPeople />, path: '/connections'},
  {element: <PeoplePage />, path: '/people'},
  {
    element: <MyConnectionsPage />,
    path: '/my-connections',
  },
  {element: <TemporaryRedirectToPeopleProfile />, path: '/connections/:id'},
  {element: <TemporaryRedirectToPeopleProfile />, path: '/profiles/:id'},
  {element: <TemporaryRedirectToPeopleProfile />, path: '/profile/:id'},
  {element: <SingleProfilePage />, path: '/people/:id'},
  {
    element: (
      <Authorized permission={Permission.PlanUpdate}>
        <UpgradePlanPage />
      </Authorized>
    ),
    path: '/upgrade-plan',
  },
  {
    element: (
      <Authorized permission={Permission.PlanUpdate}>
        <ThankYouPage />
      </Authorized>
    ),
    path: '/upgrade-success',
  },
  {
    element: (
      <Authorized permission={Permission.TeamMember}>
        <TeamJoinedMembersPage />
      </Authorized>
    ),
    path: '/members',
  },
  {
    element: (
      <Authorized permission={Permission.TeamMember}>
        <TeamInvitedMembersPage />
      </Authorized>
    ),
    path: '/members/invited',
  },
  {
    element: (
      <Authorized permission={Permission.TeamMember}>
        <TeamConnectorsMembersPage />
      </Authorized>
    ),
    path: '/members/connectors',
  },
  {
    element: (
      <Authorized permission={Permission.Company}>
        <CompaniesPage />
      </Authorized>
    ),
    path: '/companies',
  },
  {
    element: (
      <Authorized permission={Permission.Company}>
        <CompanyProfilePage />
      </Authorized>
    ),
    path: '/companies/:id',
  },
  {element: <PipelinesPage />, path: '/lists'},
  {element: <LegacyRedirectToLists />, path: '/pipelines'},
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineProfilesPage />
      </Authorized>
    ),
    path: '/lists/:id',
  },
  {element: <LegacyRedirectToList />, path: '/pipelines/:id'},
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineProfilesPage />
      </Authorized>
    ),
    path: '/lists/:id/profiles',
  },
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineSavedSearchPage />
      </Authorized>
    ),
    path: '/lists/:id/saved-search',
  },
  {element: <LegacyRedirectToList subpage="saved-search" />, path: '/pipelines/:id/saved-search'},
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineDetailsPage />
      </Authorized>
    ),
    path: '/lists/:id/details',
  },
  {element: <LegacyRedirectToList subpage="details" />, path: '/pipelines/:id/details'},
  {
    element: (
      <Authorized>
        <SettingsPage />
      </Authorized>
    ),
    path: '/settings',
  },
  {
    element: (
      <Authorized>
        <AccountPage />
      </Authorized>
    ),
    path: '/settings/account',
  },
  {
    element: (
      <Authorized permission={Permission.TeamSettingsUpdate}>
        <TeamSettingsPage />
      </Authorized>
    ),
    path: '/team-settings',
  },
  {
    element: (
      <Authorized permission={Permission.PlanUpdate}>
        <SubscriptionPage />
      </Authorized>
    ),
    path: '/team-settings/subscription',
  },
  {
    element: (
      <Authorized permission={Permission.CRMIntegration}>
        <IntegrationsPage />
      </Authorized>
    ),
    path: '/team-settings/integrations',
  },
  {
    element: (
      <Authorized permission={Permission.TagUpdate}>
        <TagsPage />
      </Authorized>
    ),
    path: '/team-settings/tags',
  },
  {element: <ErrorPage forbidden />, path: '/403'},
  {element: <ErrorPage />, path: '/*'},
];

const withAuthorization = (route: Route): Route => {
  const {element, path} = route;
  return {
    path,
    element: (
      <OnboardingContextProvider>
        <Authorized>
          <Analytics>{element}</Analytics>
        </Authorized>
      </OnboardingContextProvider>
    ),
  };
};
type Route = {path: string; element: ReactElement};
export const routes: Route[] = [...publicRoutes, ...privateRoutes.map(withAuthorization)];
