import {createContext, ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {useLayoutState} from './useLayoutState';

type Props = {
  scrollContentToTop: () => void;
  sidebarWidth?: string;
  headerHeight?: string;
  footerHeight?: string;
  children: ReactNode;
};

type ContextValue = {
  sidebarWidth: string;
  sidebarCollapsed: boolean;
  collapseSidebar: () => void;
  expandSidebar: () => void;
  favWidgetCollapsed: boolean;
  collapseFavWidget: () => void;
  expandFavWidget: () => void;
  headerHeight: string;
  footerHeight: string;
  scrollContentToTop: () => void;
};

const LayoutContext = createContext({} as ContextValue);
LayoutContext.displayName = 'SwarmLayoutContext';

export const useLayoutContext = () => useContext(LayoutContext);

const DEFAULT_VALUES: Required<Omit<Props, 'scrollContentToTop' | 'children'>> = {
  sidebarWidth: '250px',
  headerHeight: '64px',
  footerHeight: '60px',
};

const COLLAPSED_SIDEBAR_WIDTH = '72px';

export const LayoutContextProvider = ({
  children,
  scrollContentToTop,
  sidebarWidth: defaultSidebarWidth = DEFAULT_VALUES.sidebarWidth,
  headerHeight = DEFAULT_VALUES.headerHeight,
  footerHeight = DEFAULT_VALUES.footerHeight,
}: Props) => {
  const {saveSidebarCollapsed, getSidebarCollapsed, saveFavWidgetCollapsed, getFavWidgetCollapsed} =
    useLayoutState();
  const [isSidebarCollapsed, setIsSidebarCollapsed] =
    useState<ContextValue['sidebarCollapsed']>(getSidebarCollapsed());
  const [isFavWidgetCollapsed, setIsFavWidgetCollapsed] =
    useState<ContextValue['sidebarCollapsed']>(getFavWidgetCollapsed());
  const sidebarWidth = useMemo(
    () => (isSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : defaultSidebarWidth),
    [defaultSidebarWidth, isSidebarCollapsed]
  );

  const handleCollapseSidebar = useCallback(() => {
    setIsSidebarCollapsed(true);
    saveSidebarCollapsed(true);
  }, [saveSidebarCollapsed]);

  const handleExpandSidebar = useCallback(() => {
    setIsSidebarCollapsed(false);
    saveSidebarCollapsed(false);
  }, [saveSidebarCollapsed]);

  const handleCollapseFavWidget = useCallback(() => {
    setIsFavWidgetCollapsed(true);
    saveFavWidgetCollapsed(true);
  }, [saveFavWidgetCollapsed]);

  const handleExpandFavWidget = useCallback(() => {
    setIsFavWidgetCollapsed(false);
    saveFavWidgetCollapsed(false);
  }, [saveFavWidgetCollapsed]);

  const value: ContextValue = {
    sidebarWidth,
    sidebarCollapsed: isSidebarCollapsed,
    collapseSidebar: handleCollapseSidebar,
    expandSidebar: handleExpandSidebar,
    favWidgetCollapsed: isFavWidgetCollapsed,
    collapseFavWidget: handleCollapseFavWidget,
    expandFavWidget: handleExpandFavWidget,
    headerHeight,
    footerHeight,
    scrollContentToTop,
  };

  return (
    <LayoutContext.Provider value={value}>
      <style>
        {`
        :root {
          --sidebar-width: ${sidebarWidth};
          --header-height: ${headerHeight};
          --footer-height: ${footerHeight};
        }
      `}
      </style>
      {children}
    </LayoutContext.Provider>
  );
};
