import {PlusCircle as Plus} from 'phosphor-react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  FiArrowUpCircle as UpgradeIcon,
  FiMail as Envelope,
  FiSettings as SettingsIcon,
  FiUserPlus as InviteIcon,
} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {
  useChangeTeam,
  useCheckPermission,
  useCurrentTeam,
  useSearchParamsHelpers,
  useSubscription,
  useTeamsList,
} from '../../../hooks';
import {Permission} from '../../../types';
import {capitalizeString} from '../../../utils';
import {AddMembersModal, useAddMembersModal} from '../../add-members';
import {PulsingDot} from '../../animations';
import {AvatarWithFullname} from '../../avatar';
import {CreateTeamModal, useCreateTeamModal} from '../../create-team';
import {Dropdown, DropdownItemProps} from '../../dropdown';
import {PendingInvitations, useDismissInvitationsModal, useMyInvites} from '../../pending-invitations';
import {Pill} from '../../pill';
import {UpgradeLink} from '../../upgrade-link';
import {MenuTrigger} from '../components';
import Styles from './TeamMenu.module.scss';

const TRACKING_LOCATION = 'sidebar team menu';

export const TeamMenu = () => {
  const {invites} = useMyInvites();
  const {
    getFromSearchParam: checkForceCreateTeamInSearchParam,
    saveInSearchParam: saveForceCreateTeamInSearchParam,
  } = useSearchParamsHelpers({
    searchParamName: 'create-team',
    replaceHistory: true,
  });
  const openCreateTeamFromParam = checkForceCreateTeamInSearchParam() === 'true';

  const {createTeamModalVisible, closeCreateTeamModal, openCreateTeamModal} = useCreateTeamModal();
  const showCreateTeamModal = openCreateTeamFromParam || createTeamModalVisible;

  const {invitationsDismissed, dismissInvitationsModal} = useDismissInvitationsModal();
  const {addMembersVisible, initialRole, closeAddMembersModal, openAddMembersModal} = useAddMembersModal();
  const {isFreePlan} = useSubscription();

  const canPlanUpdate = useCheckPermission(Permission.PlanUpdate);
  const canTeamSettingsUpdate = useCheckPermission(Permission.TeamSettingsUpdate);
  const canTeamMembersUpdate = useCheckPermission(Permission.TeamMemberUpdate);

  const pendingInvites = invites.length > 0;

  const [teamsDropdownOpen, setTeamsDropdownOpen] = useState(false);
  const [pendingInvitationsOpen, setPendingInvitationsOpen] = useState(
    pendingInvites && !invitationsDismissed
  );

  const closeTeamsDropdown = useCallback(() => setTeamsDropdownOpen(false), []);

  const closePendingInvitations = useCallback(() => {
    setPendingInvitationsOpen(false);
    dismissInvitationsModal();
  }, [dismissInvitationsModal]);

  const openPendingInvitations = useCallback(() => {
    setPendingInvitationsOpen(true);
    closeTeamsDropdown();
  }, [closeTeamsDropdown]);

  useEffect(() => {
    setPendingInvitationsOpen(pendingInvites && !invitationsDismissed);
  }, [pendingInvites, invitationsDismissed]);

  const navigate = useNavigate();
  const teams = useTeamsList();
  const changeTeam = useChangeTeam();
  const activeTeam = useCurrentTeam();

  const handleChangeActiveTeam = (teamId: string) => {
    changeTeam(teamId);
    closeTeamsDropdown();
  };

  const handleCreateNewTeam = () => {
    closeTeamsDropdown();
    openCreateTeamModal();
  };

  const handleTeamSettings = useCallback(() => {
    closeTeamsDropdown();
    navigate('/team-settings');
  }, [navigate, closeTeamsDropdown]);

  const handleInviteMembers = useCallback(() => {
    closeTeamsDropdown();
    openAddMembersModal();
  }, [openAddMembersModal, closeTeamsDropdown]);

  const handleCloseCreateTeamModal = useCallback(() => {
    closeCreateTeamModal();
    saveForceCreateTeamInSearchParam(null);
  }, [closeCreateTeamModal, saveForceCreateTeamInSearchParam]);

  const teamMenuItems = useMemo(
    () => [
      ...(canPlanUpdate && isFreePlan
        ? [
            {
              label: 'Upgrade plan',
              tracking: {
                label: 'upgrade now',
                location: TRACKING_LOCATION,
              },
              type: {
                name: 'custom',
                onClick: closeTeamsDropdown,
                element: (
                  <UpgradeLink>
                    <div className="flex">
                      <span className="mr-2 flex w-5 items-center justify-center">
                        <UpgradeIcon size={18} />
                      </span>
                      Upgrade plan
                    </div>
                  </UpgradeLink>
                ),
              } satisfies DropdownItemProps['type'],
            },
          ]
        : []),
      ...(canTeamMembersUpdate
        ? [
            {
              label: 'Add members',
              tracking: {
                label: 'add members',
                location: TRACKING_LOCATION,
              },
              type: {name: 'button', onClick: handleInviteMembers} satisfies DropdownItemProps['type'],
              icon: <InviteIcon size={18} />,
            },
          ]
        : []),
      ...(canTeamSettingsUpdate
        ? [
            {
              label: 'Team settings',
              tracking: {
                label: 'settings',
                location: TRACKING_LOCATION,
              },
              type: {name: 'button', onClick: handleTeamSettings} satisfies DropdownItemProps['type'],
              icon: <SettingsIcon size={18} />,
            },
          ]
        : []),
    ],
    [
      canPlanUpdate,
      isFreePlan,
      canTeamMembersUpdate,
      canTeamSettingsUpdate,
      closeTeamsDropdown,
      handleInviteMembers,
      handleTeamSettings,
    ]
  );

  return (
    <div>
      <Dropdown
        isOpen={teamsDropdownOpen}
        setIsOpen={setTeamsDropdownOpen}
        snapWidth
        tracking={{
          label: 'team',
          location: 'sidebar',
        }}
        renderDropdownTrigger={ref => (
          <MenuTrigger
            label={activeTeam.name}
            imageUrl={activeTeam.logoURL}
            ref={ref}
            dropdownOpen={teamsDropdownOpen}
            showAlert={pendingInvites}
          />
        )}
      >
        <RoleAndPlanIndicator />
        <LimitedFeaturesInfo />
        {teamMenuItems.length > 0 && <hr className={Styles.divider} />}
        <Dropdown.ItemsGroup items={teamMenuItems} />
        {teams.length > 1 && (
          <>
            <hr className={Styles.divider} />
            <Dropdown.ItemsGroup
              title="switch team"
              items={teams
                .filter(team => !team.isActive)
                .map(team => ({
                  label: team.name,
                  type: {
                    name: 'custom',
                    onClick: () => handleChangeActiveTeam(team.id),
                    element: <SwitchTeamItem name={team.name} role={team.role} logoURL={team.logoURL} />,
                  },
                }))}
            />
          </>
        )}
        <hr className={Styles.divider} />
        <Dropdown.ItemsGroup
          items={[
            ...(pendingInvites
              ? [
                  {
                    label: 'Pending invitations',
                    tracking: {
                      label: 'pending invitations',
                      location: TRACKING_LOCATION,
                    },
                    type: {
                      name: 'button',
                      onClick: openPendingInvitations,
                    } satisfies DropdownItemProps['type'],
                    icon: <Envelope size={16} />,
                    rightIcon: <PulsingDot className={Styles.pendingInvitationsAlert} />,
                  },
                ]
              : []),
            {
              label: 'Create New Team',
              tracking: {
                label: 'create new team',
                location: TRACKING_LOCATION,
              },
              type: {name: 'button', onClick: handleCreateNewTeam} satisfies DropdownItemProps['type'],
              icon: <Plus size={18} />,
            },
          ]}
        />
      </Dropdown>
      <PendingInvitations visible={pendingInvitationsOpen} close={closePendingInvitations} />
      <AddMembersModal visible={addMembersVisible} onClose={closeAddMembersModal} initialRole={initialRole} />
      <CreateTeamModal visible={showCreateTeamModal} onClose={handleCloseCreateTeamModal} />
    </div>
  );
};

const LimitedFeaturesInfo = () => {
  const {isFreePlan} = useSubscription();

  return isFreePlan ? (
    <div className="rounded bg-swarm-yellow-light p-2.5 text-xs">
      <b className="text-sm">⚠️ Unlock more features</b>
      <p className="font-light text-swarm-gray-800">
        Get access to companies, lists, contact information, Swarm Extended Network, and more...
      </p>
    </div>
  ) : null;
};

type SwitchTeamItemProps = {
  name: string;
  role: string;
  logoURL?: string;
};
const SwitchTeamItem = ({name, role, logoURL}: SwitchTeamItemProps) => (
  <div className={Styles.switchItem}>
    <AvatarWithFullname fullname={name} avatarImage={logoURL} />
    <Pill className={Styles.switchItemRole}>{role}</Pill>
  </div>
);

const RoleAndPlanIndicator = () => {
  const {role} = useCurrentTeam();
  const {plan} = useSubscription();
  const {type: planName} = plan || {type: ''};

  return (
    <div className={Styles.roleAndPlanIndicator}>
      <div>
        <span className={Styles.roleAndPlanIndicatorTitle} data-intercom-target="my role">
          My role
        </span>
        {capitalizeString(role)}
      </div>
      <div>
        <span className={Styles.roleAndPlanIndicatorTitle}>Team plan</span>
        {planName}
      </div>
    </div>
  );
};
