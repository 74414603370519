import {ReactNode} from 'react';
import {usePermissionWithReason} from '../../hooks';
import {Permission} from '../../types';

export type PermissionCheckerProps = {
  children: ReactNode;
  fallback?: ReactNode;
  missingPlanFallback?: ReactNode;
  permission: Permission;
};
export const PermissionChecker = ({
  permission,
  children,
  fallback,
  missingPlanFallback,
}: PermissionCheckerProps) => {
  const permissionWithReason = usePermissionWithReason(permission);

  if (permissionWithReason.hasPermission) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  if (permissionWithReason.reason === 'plan' && missingPlanFallback) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{missingPlanFallback}</>;
  }

  if (fallback) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback}</>;
  }

  return null;
};
