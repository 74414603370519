import {useCallback} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {useMassAction} from '../../../../hooks';
import {BffFilterOptions} from '../../../../types';
import {Button} from '../../../button';

type Props = {
  filters: BffFilterOptions;
  totalCount: number;
  pipelineId: string;
  pipelineName: string;
  onSuccess?: () => void;
};

export const MassAddToParticularPipelineButton = ({
  filters,
  totalCount,
  pipelineId,
  pipelineName,
  onSuccess,
}: Props) => {
  const {executeMassAction} = useMassAction('addSuggestion');

  const massAddToPipeline = useCallback(() => {
    executeMassAction({
      targetId: pipelineId,
      targetName: pipelineName,
      filters,
      totalCount,
      onSuccess,
    });
  }, [executeMassAction, filters, onSuccess, pipelineId, pipelineName, totalCount]);

  return (
    <Button
      onClick={massAddToPipeline}
      variant="tertiary"
      outline
      icon={<PlusIcon size={16} />}
      size="sm"
      intercomTarget="mass add selected profiles to pipeline"
    >
      Add to this List
    </Button>
  );
};
