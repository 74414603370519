import {useMemo} from 'react';
import {FiLock as LockIcon} from 'react-icons/fi';
import {useQueryNotesCount, useQueryProfileCrmData} from '../../api';
import {Pill} from '../../components/pill';
import {ProfileNotes} from '../../components/profile-notes';
import {TabsType} from '../../components/tabs';
import {UpgradeTooltipContent} from '../../components/upgrade-tooltip';
import {usePermissionWithReason} from '../../hooks';
import {BffProfile, Permission} from '../../types';
import {integrationNameMap} from '../../utils';
import {Connections} from './connections';
import {CrmData} from './CrmData';
import {ProfileDetails} from './ProfileDetails';
import Styles from './SingleProfilePageContent.module.scss';

type NotesBadgeProps = {
  notesCount: number;
};

const NotesBadge = ({notesCount}: NotesBadgeProps) => <div className={Styles.notesBadge}>{notesCount}</div>;

type Props = {
  profile: BffProfile;
  teamId: string;
  onScoreUpdate?: () => void;
};
export const useTabsConfig = ({profile, teamId, onScoreUpdate}: Props) => {
  const connectionStrengthPermissionWithReason = usePermissionWithReason(Permission.ConnectionStrength);
  const isConnectionStrengthNotAllowedByPlan =
    !connectionStrengthPermissionWithReason.hasPermission &&
    connectionStrengthPermissionWithReason.reason === 'plan';

  const {data: profileCrmData} = useQueryProfileCrmData(profile.id);

  const {data: notesCountData, refetch: refetchNotesCount} = useQueryNotesCount({
    profileId: profile.id,
    teamId,
  });

  const notesCount = notesCountData?.total || 0;

  const tabsConfig: TabsType = useMemo(
    () =>
      [
        {title: 'Profile Details', content: <ProfileDetails profile={profile} />},
        {
          title: 'Connection Strength',
          badge: isConnectionStrengthNotAllowedByPlan ? (
            <Pill className="ml-2" color="yellow">
              <LockIcon />
              Upgrade
            </Pill>
          ) : null,
          content: (
            <Connections
              connections={profile.connections}
              profileId={profile.id}
              onScoreUpdate={() => onScoreUpdate?.()}
            />
          ),
          path: 'connection-strength',
          disabled: !connectionStrengthPermissionWithReason.hasPermission,
          tooltip: isConnectionStrengthNotAllowedByPlan ? (
            <UpgradeTooltipContent featureName="Connection Strength" location="profile connection score" />
          ) : undefined,
        },
        {
          title: 'Notes',
          content: <ProfileNotes profileId={profile.id} refetchNotesCount={refetchNotesCount} />,
          badge: <NotesBadge notesCount={notesCount} />,
          path: 'notes',
        },
        ...(profileCrmData
          ? profileCrmData.map((integration, index) => ({
              title: `${integrationNameMap[integration.crm]} Data`,
              content: <CrmData profileCrmData={integration} />,
              key: integration.crm + index,
            }))
          : []),
      ] satisfies TabsType,
    [
      profile,
      isConnectionStrengthNotAllowedByPlan,
      connectionStrengthPermissionWithReason.hasPermission,
      refetchNotesCount,
      notesCount,
      profileCrmData,
      onScoreUpdate,
    ]
  );

  return tabsConfig;
};
