import {useCallback, useEffect, useState} from 'react';
import {useCurrentTeam} from '../../hooks';
import {FilterKey, FilterOperator, FiltersState, FilterState} from './types';

const DEFAULT_OPERATOR = FilterOperator.is;
const DEFAULT_STATE: FilterState = {operator: DEFAULT_OPERATOR, value: []};

const filterSavedFiltersState = (
  savedFiltersState: FiltersState,
  availableFilters: FilterKey[]
): FiltersState => {
  return Object.keys(savedFiltersState)
    .filter(filterKey => availableFilters.includes(filterKey as FilterKey))
    .reduce((filteredState, filterKey) => {
      filteredState[filterKey as FilterKey] = savedFiltersState[filterKey as FilterKey];
      return filteredState;
    }, {} as FiltersState);
};

type UseFiltersStateProps = {
  defaultState?: FiltersState;
  availableFilters?: FilterKey[];
  saveFiltersKey?: string;
};

export const useFiltersState = ({
  defaultState = {},
  availableFilters = [],
  saveFiltersKey,
}: UseFiltersStateProps) => {
  const {id: teamID} = useCurrentTeam();

  const saveToLocalStorage = saveFiltersKey !== undefined;
  const localStorageKey = saveToLocalStorage ? `filtersState-${teamID}_${saveFiltersKey}` : '';

  const savedFiltersState =
    saveToLocalStorage && localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey) as string)
      : null;

  const initialFiltersState = savedFiltersState
    ? filterSavedFiltersState(savedFiltersState, availableFilters)
    : defaultState;

  const [filtersState, setFiltersState] = useState<FiltersState>(initialFiltersState);

  useEffect(() => {
    if (saveToLocalStorage) {
      localStorage.setItem(localStorageKey, JSON.stringify(filtersState));
    }
  }, [localStorageKey, filtersState, saveToLocalStorage]);

  const addNewFilter = useCallback(
    (filterKey: FilterKey, initialState: FilterState = DEFAULT_STATE) => {
      if (filtersState.hasOwnProperty(filterKey)) {
        return;
      }

      setFiltersState(prev => ({...prev, [filterKey]: initialState}));
    },
    [filtersState]
  );

  const removeFilter = useCallback((filterKey: FilterKey) => {
    setFiltersState(prev => {
      const newState = {...prev};
      delete newState[filterKey];
      return newState;
    });
  }, []);

  const setFilter = useCallback(
    (filterKey: FilterKey, newState?: FilterState) => {
      if (!filtersState.hasOwnProperty(filterKey)) {
        addNewFilter(filterKey, newState);
      } else {
        if (!newState) {
          removeFilter(filterKey);
        } else {
          setFiltersState(prev => ({...prev, [filterKey]: newState}));
        }
      }
    },
    [addNewFilter, filtersState, removeFilter]
  );

  const restoreFiltersToDefault = useCallback(() => {
    setFiltersState(defaultState);
  }, [defaultState]);

  const isFilterActive = useCallback(
    (filter: FilterKey) => {
      return filtersState.hasOwnProperty(filter);
    },
    [filtersState]
  );

  return {
    filtersState,
    setFilter,
    restoreFiltersToDefault,
    isFilterActive,
  };
};
