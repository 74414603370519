import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {FiArrowUpRight as ManageTagsIcon, FiPlusSquare as CreateTagIcon} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {useQueryTagsSimple} from '../../../api';
import {usePrevious} from '../../../hooks';
import {Permission, TagSimple} from '../../../types';
import {notifyGenericError, segmentTrack} from '../../../utils';
import {LoadingSpinner} from '../../animations';
import {Button} from '../../button';
import {Dropdown} from '../../dropdown';
import {DropdownSearch} from '../../dropdown-search';
import {DropdownItem} from '../../dropdown/DropdownItem';
import {IndeterminateCheckbox} from '../../indeterminate-checkbox';
import {PermissionChecker} from '../../permission';
import {Tag} from '../tag';
import {TagModal, useTagModal} from '../tag-form';
import {EmptyTagsListInfo} from './EmptyTagsListInfo';
import Styles from './TagsListDropdown.module.scss';

type Props = {
  assignedTags: string[];
  indeterminatedTags?: string[];
  onAssign: (tagId: string) => Promise<unknown>;
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassign: (tagId: string) => Promise<unknown>;
  onUnassignSuccess?: (tagId: string) => void;
  onSuccessChange?: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  trigger: ReactElement;
};

const defaultChangeHandler = () => Promise.resolve();

export const TagsListDropdown = ({
  assignedTags,
  indeterminatedTags,
  onAssign = defaultChangeHandler,
  onAssignSuccess,
  onUnassign = defaultChangeHandler,
  onUnassignSuccess,
  onSuccessChange,
  isOpen,
  setIsOpen,
  trigger,
}: Props) => {
  const {tagModalVisible, closeTagModal, openTagModal} = useTagModal();
  const [pendingRequestTagIds, setPendingRequestTagIds] = useState<string[]>([]);
  const prevPendingRequestTagIds = usePrevious(pendingRequestTagIds, true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      prevPendingRequestTagIds &&
      prevPendingRequestTagIds !== pendingRequestTagIds &&
      pendingRequestTagIds.length === 0 &&
      onSuccessChange
    ) {
      onSuccessChange();
    }
  }, [onSuccessChange, pendingRequestTagIds, pendingRequestTagIds.length, prevPendingRequestTagIds]);

  useEffect(() => {
    if (isOpen) {
      segmentTrack('Selector Opened', {
        label: 'tags',
      });
    }
  }, [isOpen]);

  const {
    data,
    isLoading: isTagsListLoading,
    refetch,
  } = useQueryTagsSimple({
    enabled: isOpen,
  });

  const onCreateSuccess = useCallback(() => {
    onSuccessChange?.();
    refetch();
  }, [onSuccessChange, refetch]);

  const tags = useMemo(() => data?.items || [], [data?.items]);
  const filteredTags = tags.filter(tag => tag.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleToggle = useCallback(
    async (tagId: string) => {
      setPendingRequestTagIds(oldPendingRequestTagIds => [...oldPendingRequestTagIds, tagId]);
      if (assignedTags.includes(tagId)) {
        segmentTrack('Selection Removed', {
          label: 'tags',
          tag: tagId,
        });
        try {
          await onUnassign(tagId);
          onUnassignSuccess?.(tagId);
        } catch {
          notifyGenericError();
        }
      } else {
        segmentTrack('Item Selected', {
          label: 'tags',
          tag: tagId,
        });
        try {
          await onAssign(tagId);
          const tag = tags.find(tag => tag.id === tagId);
          if (tag && onAssignSuccess) {
            onAssignSuccess(tag);
          }
        } catch {
          notifyGenericError();
        }
      }
      setPendingRequestTagIds(oldPendingRequestTagIds => oldPendingRequestTagIds.filter(id => id !== tagId));
    },
    [assignedTags, onAssign, onAssignSuccess, onUnassign, onUnassignSuccess, tags]
  );

  const handleOpenCreateTagModal = () => {
    openTagModal();
    setIsOpen(false);
  };

  const dropdownFooter = (
    <PermissionChecker permission={Permission.TagUpdate}>
      <div className={Styles.actionsButtonsWrapper}>
        <Button
          variant="tertiary"
          size="sm"
          icon={<ManageTagsIcon />}
          reversedOrder
          onClick={() => navigate('/team-settings/tags')}
          tracking={{
            label: 'Manage tags',
          }}
        >
          Manage tags
        </Button>
        <Button
          variant="tertiary"
          size="sm"
          icon={<CreateTagIcon />}
          reversedOrder
          onClick={handleOpenCreateTagModal}
          tracking={{
            label: 'Create new tag',
            location: 'tags list dropdown',
          }}
        >
          Create new tag
        </Button>
      </div>
    </PermissionChecker>
  );

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderDropdownTrigger={() => trigger}
        dropdownHeader={
          !isTagsListLoading && (
            <DropdownSearch
              query=""
              placeholder="Search for a tag"
              onChange={q => setSearchQuery(q)}
              visible={tags.length > 3}
            />
          )
        }
        dropdownFooter={dropdownFooter}
      >
        {isTagsListLoading && (
          <div className={Styles.loadingStateWrapper}>
            <LoadingSpinner size="small" color="black" />
          </div>
        )}
        {!isTagsListLoading &&
          filteredTags.map(tag => {
            const isItemRequestPending = pendingRequestTagIds.includes(tag.id);
            return (
              <DropdownItem
                key={tag.id}
                label={tag.name}
                type={{
                  name: 'custom',
                  disabled: isItemRequestPending,
                  onClick: () => handleToggle(tag.id),
                  element: (
                    <TagsListDropdownItem
                      tag={tag}
                      checked={assignedTags.includes(tag.id)}
                      indeterminate={indeterminatedTags?.includes(tag.id)}
                      isPending={isItemRequestPending}
                    />
                  ),
                }}
              />
            );
          })}
        {!isTagsListLoading && !filteredTags.length && <EmptyTagsListInfo />}
      </Dropdown>
      <TagModal
        onAssign={onAssign}
        onAssignSuccess={onAssignSuccess}
        onSaveSuccess={onCreateSuccess}
        visible={tagModalVisible}
        onClose={closeTagModal}
      />
    </>
  );
};

type ItemProps = {
  tag: TagSimple;
  checked: boolean;
  indeterminate?: boolean;
  isPending?: boolean;
};
const TagsListDropdownItem = ({tag, checked, indeterminate, isPending}: ItemProps) => {
  return (
    <div className={Styles.item}>
      {isPending ? (
        <LoadingSpinner size="x-small" />
      ) : (
        <IndeterminateCheckbox checked={checked} indeterminate={indeterminate} onChange={() => {}} />
      )}
      <Tag content={tag.name} color={tag.color} />
    </div>
  );
};
