import {SuccessProgressScreen} from '../../success-progress-screen';

type Props = {
  onClose: () => unknown;
};

export const CompanyMapSuccess = ({onClose}: Props) => {
  return (
    <SuccessProgressScreen
      description="We’re in the process of mapping and enriching your connections. It’ll take a few minutes to build your
        network."
      onClose={onClose}
      closeText="Your connections are being added in the background."
    />
  );
};
