import {ColumnSort, SortingState} from '@tanstack/react-table';
import {uniqBy} from 'lodash';
import {Sort, SortOption} from '../types';

const separator = '.';
export const sortToSortingState = (sort: Sort): SortingState =>
  sort.map((sortString): ColumnSort => {
    const [attribute, order] = sortString.split(separator);

    return {id: attribute, desc: order === 'desc'};
  });

export const sortingStateToSort = (sortingState: SortingState): Sort =>
  uniqBy(sortingState, i => i.id) // remove duplicated sort keys
    .map(({id, desc}) => `${id}${separator}${desc ? 'desc' : 'asc'}`);

export const sortingStateToSortOptions = (sortingState: SortingState): SortOption[] =>
  sortingState.map(({id, desc}) => ({
    field: id,
    direction: desc ? 'desc' : 'asc',
  }));
