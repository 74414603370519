import {VerifiedProfile} from '../../../../types';
import {Avatar} from '../../../avatar';
import {List} from '../../../list';
import {Tooltip} from '../../../tooltip';

type Props = {
  profiles: VerifiedProfile[];
};
export const AddableProfilesList = ({profiles}: Props) => (
  <div className="pt-4">
    <h3 className="text-lg font-semibold text-swarm-black">
      You are about to add these {profiles.length} profiles as Connectors:
    </h3>
    <List
      className="mt-4 max-h-40 overflow-y-auto rounded border border-swarm-gray-300 bg-swarm-gray-50"
      items={profiles.map(({id, fullName, companyName, jobTitle}) => (
        <Tooltip
          key={id}
          content={
            <div className="flex flex-row gap-2">
              <span>{jobTitle}</span>
              <DotSeparator />
              <span>{companyName}</span>
            </div>
          }
          placement="bottom-center"
        >
          <div className="flex flex-row items-center gap-2 px-2 text-sm text-swarm-gray-700">
            <Avatar fullname={fullName} />
            <p className="overflow-hidden text-ellipsis text-nowrap">
              <span className="text-swarm-black">{fullName}</span>
              <DotSeparator />
              <span>{jobTitle}</span>
              <DotSeparator />
              <span>{companyName}</span>
            </p>
          </div>
        </Tooltip>
      ))}
    />
  </div>
);

const DotSeparator = () => <span className="px-1">&#183;</span>;
