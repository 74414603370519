import cs from 'classnames';
import type {CSSProperties, ReactNode} from 'react';

export type BaseMarkProps = {
  shape: 'circle' | 'square';
  size: 'x-small' | 'small' | 'default' | 'large';
  bgColor: string;
  iconColor: string;
  icon: ReactNode;
  className?: string;
};

const SizesMap: Record<BaseMarkProps['size'], number> = {
  'x-small': 18,
  small: 24,
  default: 32,
  large: 48,
};

const BorderRadiusMap: Record<BaseMarkProps['shape'], string> = {
  circle: '100%',
  square: '8px',
};

export const BaseMark = ({shape, size, bgColor, iconColor, icon, className}: BaseMarkProps) => {
  const styles: CSSProperties = {
    backgroundColor: bgColor,
    color: iconColor,
    width: `${SizesMap[size]}px`,
    height: `${SizesMap[size]}px`,
    fontSize: `${SizesMap[size] * 0.75}px`,
    borderRadius: BorderRadiusMap[shape],
  };

  return (
    <span className={cs('inline-flex items-center justify-center', className)} style={styles}>
      {icon}
    </span>
  );
};
