export enum TagColors {
  Gray = 'gray',
  Blue = 'blue',
  Turquoise = 'turquoise',
  Green = 'green',
  Yellow = 'yellow',
  Orange = 'orange',
  Peach = 'peach',
  Pink = 'pink',
  Violet = 'violet',
}
