import {ImportStrategy} from '../../types';
import {segmentTrack} from '../../utils';

export const trackStartImportClick = (strategy: ImportStrategy) =>
  segmentTrack('Button Clicked', {label: 'start import', location: 'connections import', strategy});

export const trackStrategySelection = (strategy: ImportStrategy) => {
  segmentTrack('Button Clicked', {
    label: 'select import strategy',
    location: 'connections import',
    strategy,
  });
};

export const trackBackButtonOnStrategyView = (strategy: ImportStrategy) => {
  segmentTrack('Button Clicked', {
    label: 'back',
    location: 'connections import',
    strategy,
  });
};

export const trackCancelButtonOnStrategyView = (strategy: ImportStrategy) => {
  segmentTrack('Button Clicked', {
    label: 'cancel',
    location: 'connections import',
    strategy,
  });
};
