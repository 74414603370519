import {AppLayout} from '../../layouts';
import {PremiumFeatureWarningContent} from '../premium-feature-warning-content';

export const PaywallPage = () => (
  <AppLayout>
    <div className="flex h-full items-center justify-center">
      <PremiumFeatureWarningContent location="premium feature page" />
    </div>
  </AppLayout>
);
