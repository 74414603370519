export const Instructions = () => (
  <div className="pt-4 text-base font-normal leading-tight text-swarm-gray-800">
    <h3 className="py-2 font-semibold text-swarm-black">What's next?</h3>
    <p>
      For each Connector, their professional network will be automatically mapped using work history,
      education, investments, and public data.
    </p>

    <h3 className="mt-4 py-2 font-semibold text-swarm-black">Invite them later</h3>
    <p>As they won’t be notified, you can invite them later as active members</p>
  </div>
);
