import cs from 'classnames';
import {useEffect} from 'react';
import {useImportTasks} from '../../../../contexts';
import {Task} from '../../../../types';
import {getConfig, segmentTrack} from '../../../../utils';
import {LoadingProgressBar} from '../../../animations';
import {taskSettings} from './ImportTaskSettings';

import Styles from './ImportStatusWidget.module.scss';

const {showImportProgressEnabled} = getConfig();

export const ImportStatusWidget = () => {
  const {pendingTasks, hasPendingTasks, isTasksFetched} = useImportTasks();

  if (!isTasksFetched || !hasPendingTasks) {
    return null;
  }

  return (
    <div className={Styles.listWrapper}>
      {pendingTasks.map((task, index) => (
        <ImportStatusItem task={task} key={task.taskId} isLast={index + 1 === pendingTasks.length} />
      ))}
    </div>
  );
};

type ImportStatusItemProps = {
  task: Task;
  isLast: boolean;
};
const ImportStatusItem = ({task, isLast}: ImportStatusItemProps) => {
  const {dataProvider, progress} = task;
  const strategy = taskSettings[dataProvider].strategy;

  useEffect(() => {
    segmentTrack('Notification Viewed', {
      message: 'importing connections',
      type: 'progress bar',
      process: 'connections import',
      strategy,
    });
  }, [strategy]);

  return (
    <div className={Styles.itemWrapper}>
      <h3 className={Styles.heading}>
        {taskSettings[dataProvider].icon}
        {taskSettings[dataProvider].title}
      </h3>

      <LoadingProgressBar className={Styles.loadingBar} />

      {isLast && ( // visible only for last item
        <p className={Styles.description}>{taskSettings[dataProvider].description}</p>
      )}

      <span
        className={cs({
          hidden: !showImportProgressEnabled,
        })}
      >
        {progress.enriched}/{progress.total}
      </span>
    </div>
  );
};
