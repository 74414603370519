import {addHttpsToUrl} from '../../../utils';

type Props = {
  domain: string;
};

export const CompanyWebsiteCellContent = ({domain}: Props) => (
  <a
    href={addHttpsToUrl(domain)}
    target="_blank"
    rel="nofollow noreferrer"
    className="text-swarm-black hover:underline"
  >
    {domain}
  </a>
);
