import cs from 'classnames';
import {ReactNode, useCallback, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {ErrorBoundary} from '../components/common';
import {Sidebar} from '../components/sidebar';
import {
  ConfirmContextProvider,
  ImportConnectionsModalContextProvider,
  ImportTasksContextProvider,
  LayoutContextProvider,
} from '../contexts';
import {PremiumFeatureModalContextProvider} from '../contexts/PremiumFeatureModalContext';
import Styles from './AppLayout.module.scss';

type Props = {
  header?: ReactNode;
  subHeader?: ReactNode;
  footer?: ReactNode;
  noGrowContent?: boolean;
  children: ReactNode;
};

export const AppLayout = ({header, subHeader, footer, noGrowContent, children}: Props) => {
  const contentEl = useRef<HTMLElement>(null);
  const scrollContentToTop = useCallback(() => contentEl.current?.scrollTo(0, 0), [contentEl]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=860, initial-scale=0.35" />
      </Helmet>
      <LayoutContextProvider scrollContentToTop={scrollContentToTop}>
        <ImportConnectionsModalContextProvider>
          <PremiumFeatureModalContextProvider>
            <ImportTasksContextProvider>
              <ConfirmContextProvider>
                <div className={Styles.container}>
                  <aside className={Styles.sidebar}>
                    <ErrorBoundary name="sidebar">
                      <Sidebar />
                    </ErrorBoundary>
                  </aside>
                  <div className={Styles.contentWrapper}>
                    <div className={Styles.contentWrapperInner}>
                      {header && <header className={Styles.header}>{header}</header>}
                      {subHeader && <div className={Styles.subHeader}>{subHeader}</div>}
                      <ErrorBoundary name="main">
                        <main
                          id="main-content"
                          ref={contentEl}
                          className={cs(Styles.content, {[Styles.contentNoGrow]: noGrowContent})}
                        >
                          {children}
                        </main>
                      </ErrorBoundary>
                      {footer && <footer className={Styles.footer}>{footer}</footer>}
                    </div>
                  </div>
                </div>
              </ConfirmContextProvider>
            </ImportTasksContextProvider>
          </PremiumFeatureModalContextProvider>
        </ImportConnectionsModalContextProvider>
      </LayoutContextProvider>
    </>
  );
};
