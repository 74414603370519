import {ProfilesBffColumns} from '../../../defs';

export const COMPANY_CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'company_profiles_columns_4'; // postfix in the name to abandon old, not-compatible settings before columns naming changes
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.StartDate,
  ProfilesBffColumns.EndDate,
  ProfilesBffColumns.TeamConnectionsStrength,
  ProfilesBffColumns.KeyRelationship,
  ProfilesBffColumns.ConnectedMembersCount,
  ProfilesBffColumns.Emails,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
];
