import {ReactElement, useCallback} from 'react';
import {Button} from '../../../components/button';
import {Pill} from '../../../components/pill';
import {useSubscription} from '../../../hooks';
import {Plan, PlanType} from '../../../types';
import {humanizeDate, segmentTrack} from '../../../utils';
import Styles from './Subscription.module.scss';

type Props = {
  plan: Plan;
};

export const Subscription = ({plan}: Props) => {
  const {payment, type: planType} = plan;
  const {cancel, manage, updatePaymentMethod} = useSubscription();
  const {trialActive, endDate, period: billingPeriod, creditCardCollected, canceled} = payment || {};

  const humanizedEndDate = endDate && humanizeDate(endDate);

  const handleUpdatePaymentMethod = useCallback(() => {
    updatePaymentMethod();
    segmentTrack('Button Clicked', {
      label: 'update payment method',
    });
  }, [updatePaymentMethod]);

  return (
    <>
      <div>
        <SubscriptionItem title="Current plan">{planType}</SubscriptionItem>
        {planType !== PlanType.Free && (
          <>
            <SubscriptionItem title="Status">
              {canceled ? (
                <>
                  <Pill color="red" className="mr-0.5">
                    Canceled
                  </Pill>{' '}
                  {trialActive ? 'Trial' : planType + ' plan'} ends on {humanizedEndDate}
                </>
              ) : trialActive ? (
                <>
                  <Pill color="yellow" className="mr-0.5">
                    Trial
                  </Pill>{' '}
                  {creditCardCollected
                    ? `After your free trial ends on ${humanizedEndDate}, your payment method on file will be charged.`
                    : `ends on ${humanizedEndDate}`}
                </>
              ) : (
                <>
                  <Pill color="green" className="mr-0.5">
                    Active
                  </Pill>{' '}
                  next invoice on {humanizedEndDate}
                </>
              )}
            </SubscriptionItem>
            {billingPeriod && !trialActive && (
              <SubscriptionItem title="Subscription period">{billingPeriod}</SubscriptionItem>
            )}
            {creditCardCollected ? (
              <SubscriptionItem title="Payment method">Provided</SubscriptionItem>
            ) : (
              <SubscriptionItem title="Payment method">
                <>
                  No payment method.{' '}
                  <button className={Styles.addPayment} onClick={handleUpdatePaymentMethod}>
                    Add payment method
                  </button>
                </>
              </SubscriptionItem>
            )}
          </>
        )}
      </div>
      <div className={Styles.buttons}>
        {planType === PlanType.Free ? (
          <>
            <Button
              variant="black"
              href="/upgrade-plan"
              tracking={{
                label: 'upgrade',
                location: 'current plan',
              }}
            >
              Upgrade
            </Button>{' '}
          </>
        ) : (
          <>
            <Button
              variant="black"
              onClick={() => manage()}
              tracking={{
                label: 'manage subscription',
              }}
            >
              Manage subscription
            </Button>{' '}
            {!canceled && (
              <Button
                variant="red"
                onClick={() => cancel()}
                tracking={{
                  label: 'cancel',
                }}
              >
                Cancel plan
              </Button>
            )}
          </>
        )}
        <Button variant="secondary" href="/upgrade-plan" className={Styles.allPlansButton}>
          All plans
        </Button>
      </div>
    </>
  );
};

type SubscriptionInfoProps = {
  title: string;
  children: ReactElement | string;
};

const SubscriptionItem = ({title, children}: SubscriptionInfoProps) => (
  <div className={Styles.subscriptionItem}>
    <h5 className={Styles.subscriptionItemHeader}>{title}</h5>
    {children}
  </div>
);
