import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, useQuery, UseQueryOptions} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';
import {ErrorResponse, Member, MemberListItem, Role, Team} from '../types';
import {apiClient} from './apiClient';

type GetTeamResponse = Team;
type GetTeamsResponse = {
  teams: Team[];
};

const getTeam = (teamId: string) =>
  apiClient
    .get<GetTeamResponse>(`/teams/${teamId}`, {
      headers: {'X-Swarm-Bff': 'true'},
    })
    .then(res => res.data);

export const useQueryTeam = (teamId = '', options?: UseQueryOptions<Team>) =>
  useQuery<Team>(['team', teamId], () => getTeam(teamId), {
    enabled: Boolean(teamId),
    refetchOnMount: false,
    ...options,
  });

const getTeams = () =>
  apiClient
    .get<GetTeamsResponse>('/teams', {
      headers: {'X-Swarm-Bff': 'true'},
    })
    .then(res => res.data);

export const useQueriesTeams = (options?: UseQueryOptions<GetTeamsResponse>) =>
  useQuery<GetTeamsResponse>('teams', () => getTeams(), {
    refetchOnMount: false,
    ...options,
  });

export const getMembers = (teamId: string): Promise<MemberListItem[]> =>
  apiClient.get<{data: MemberListItem[]}>(`/teams/${teamId}/members`).then(res => res.data.data);

type UpdateMemberInput = {
  teamId: string;
  memberId: string;
  role: Role;
};

export const updateMember = async (input: UpdateMemberInput): Promise<Member> => {
  type Output = {data: Member};
  const {teamId, memberId} = input;
  const resp = await apiClient.patch<Output, AxiosResponse<Output>, UpdateMemberInput>(
    `/teams/${teamId}/members/${memberId}`,
    input
  );

  return resp.data.data;
};

type DeleteMemberInput = {
  teamId: string;
  memberId: string;
};

export const deleteMember = ({teamId, memberId}: DeleteMemberInput) =>
  apiClient.delete<void>(`/teams/${teamId}/members/${memberId}`).then(res => res.data);

export const useGetTeamMembers = (teamId: string, refetchOnMount?: boolean) => {
  const membersQueryKey = getMembersQueryKey(teamId);

  return useQuery(membersQueryKey, () => getMembers(teamId), {
    enabled: Boolean(teamId),
    refetchOnMount,
  });
};

export const getMembersQueryKey = (teamId: string) => ['team', teamId, 'members'];

type CreateTeamInput = {
  name: string;
  linkedin_company_url?: string;
  additional_registration_data: {
    main_use_case: string;
  } & Record<string, unknown>;
};

export type CreateTeamResult = string;

export const createTeam = (input: CreateTeamInput): Promise<CreateTeamResult> =>
  apiClient
    .post<GetTeamResponse, AxiosResponse<GetTeamResponse>, CreateTeamInput>('/teams', input, {
      headers: {'X-Swarm-Bff': 'true'},
    })
    .then(resp => resp.data.id);

export const useMutationCreateTeam = (
  options?: UseMutationOptions<CreateTeamResult, AxiosError<ErrorResponse>, CreateTeamInput>
) =>
  useMutation<CreateTeamResult, AxiosError<ErrorResponse>, CreateTeamInput>(
    input => createTeam(input),
    options
  );

type UpdateTeamData = {
  name: string;
  logo: File | null;
  companyLinkedinURL: string;
};

const updateTeam = (teamId: string, data: Partial<UpdateTeamData>): Promise<Team> =>
  apiClient
    .patch<Team>(`/teams/${teamId}`, data, {
      headers: {'X-Swarm-Bff': 'true', 'content-type': 'multipart/form-data'},
    })
    .then(res => res.data);

export const useMutationUpdateTeam = (
  teamId: string,
  options?: UseMutationOptions<Team, AxiosError<ErrorResponse>, Partial<UpdateTeamData>>
) =>
  useMutation<Team, AxiosError<ErrorResponse>, Partial<UpdateTeamData>>(
    data => updateTeam(teamId, data),
    options
  );
