import {ExportCrmTargetType, TagSimple} from '../../../../types';
import {TagsProfileAction} from '../TagsProfileAction';

type Props = {
  targetId: string;
  assignedTags: TagSimple[];
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
  targetType: ExportCrmTargetType;
};

export const TagsTableCell = ({
  targetId,
  assignedTags,
  onAssignSuccess,
  onUnassignSuccess,
  targetType,
}: Props) => {
  return (
    <TagsProfileAction
      tags={assignedTags}
      targetId={targetId}
      targetType={targetType}
      onAssignSuccess={onAssignSuccess}
      onUnassignSuccess={onUnassignSuccess}
      inTableCell
    />
  );
};
