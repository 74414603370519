import cs from 'classnames';
import {useCallback, useState} from 'react';
import {FiCheck as CheckIcon, FiChevronDown as ChevronDown} from 'react-icons/fi';
import {Dropdown} from '../../dropdown';
import {CommonProps, ValidationProps} from '../types';
import {Message} from '../utils';
import Styles from './Multiselect.module.scss';
import {OptionTag} from './OptionTag';
import {OptionType} from './types';

type SingleOptionProps = CommonProps &
  ValidationProps & {
    options: OptionType[];
    onChange?: (option: string) => void;
    singleOption: true;
    initialValues?: string[];
  };
type MultiOptionProps = CommonProps &
  ValidationProps & {
    options: OptionType[];
    onChange?: (options: string[]) => void;
    singleOption?: false;
    initialValues?: string[];
  };

export const Multiselect = ({
  options,
  onChange,
  singleOption,
  isInvalid,
  message,
  initialValues,
  placeholder = 'Select...',
}: MultiOptionProps | SingleOptionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialValues || []);

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const toggleSelect = useCallback(
    (value: string) => {
      let updatedValues;
      if (selectedOptions.includes(value)) {
        updatedValues = selectedOptions.filter(item => item !== value);
      } else {
        updatedValues = singleOption ? [value] : [...selectedOptions, value];
      }
      setSelectedOptions(updatedValues);

      singleOption ? onChange?.(updatedValues[0]) : onChange?.(updatedValues);

      closeDropdown();
    },
    [closeDropdown, selectedOptions, onChange, singleOption]
  );

  const selectedTags = options.filter(option => selectedOptions.includes(option.value));
  return (
    <>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placement="bottom-end"
        offset={2}
        renderDropdownTrigger={ref => (
          <div
            className={cs(Styles.wrapper, {[Styles.singleOption]: singleOption, [Styles.invalid]: isInvalid})}
            ref={ref}
          >
            <div className={Styles.tagsArea}>
              {selectedTags.length > 0 ? (
                selectedTags.map((option: OptionType) => (
                  <OptionTag
                    option={option}
                    key={option.value}
                    onClick={() => toggleSelect(option.value)}
                    singleOption={singleOption}
                  />
                ))
              ) : (
                <span className={Styles.placeholder}>{placeholder}</span>
              )}
            </div>
            <div className={Styles.triggerableArea}>
              <ChevronDown />
            </div>
          </div>
        )}
        triggerClass={Styles.growingTrigger}
        snapWidth
      >
        <Dropdown.ItemsGroup
          items={options.map((item: OptionType) => ({
            label: item.label || item.value,
            type: {
              name: 'custom',
              onClick: () => toggleSelect(item.value),
              element: <OptionTag option={item} inDropdown />,
            },
            rightIcon: selectedOptions.includes(item.value) ? <CheckIcon /> : undefined,
            className: 'justify-between',
          }))}
        />
      </Dropdown>
      {message && <Message message={message} hasError={isInvalid} className="pt-1" />}
    </>
  );
};
