const checkForHalloweenSeason = () => {
  const today = new Date();
  const month = today.getMonth();
  const day = today.getDate();

  const isOctober = month === 9;
  const isNovember = month === 10;

  return (isOctober && day >= 29) || (isNovember && day <= 3);
};

export const useSwarmLogo = (collapsed: boolean) => {
  const isHalloween = checkForHalloweenSeason();
  if (collapsed) {
    return '/images/brand-logo.svg';
  }

  return isHalloween ? '/images/brand-logo-halloween.png' : '/images/brand-logo.svg';
};
