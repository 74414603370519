import cs from 'classnames';
import {DotsThreeVertical} from 'phosphor-react';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {deletePipeline, getPipelineProfilesCSV} from '../../../api';
import {Button} from '../../../components/button';
import {Dropdown} from '../../../components/dropdown';
import {Pill} from '../../../components/pill';
import {onRowHoverVisible, onRowHoverVisibleIgnore} from '../../../components/table';
import {
  useCheckPermission,
  useConfirm,
  useCurrentTeam,
  useCurrentUser,
  useSubscription,
} from '../../../hooks';
import {Permission} from '../../../types';
import {trackError} from '../../../utils';
import Styles from './DotsMenu.module.scss';

const downloadCSV = (pipelineId: string, pipelineTitle: string) => {
  const date = new Date().toString().slice(0, 21);
  const filename = `${pipelineTitle}_${date}`.replace(':', '').replace(/\s+/g, '_');

  getPipelineProfilesCSV(pipelineId)
    .then(response => response.data)
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(trackError);
};

type Props = {
  pipelineId: string;
  pipelineTitle: string;
  pipelineCreatorId: string;
  switchToEdit: () => void;
  closeEditMode?: () => void;
  alwaysVisible?: boolean;
  circularTrigger?: boolean;
  refetchPipelines?: () => Promise<unknown>;
  redirectToPipelinesList?: boolean;
};

export const DotsMenu = ({
  pipelineId,
  pipelineTitle = 'pipeline',
  pipelineCreatorId,
  switchToEdit,
  closeEditMode,
  alwaysVisible,
  circularTrigger,
  refetchPipelines,
  redirectToPipelinesList,
}: Props) => {
  const {id: teamId} = useCurrentTeam();
  const {id: currentUserId} = useCurrentUser();
  const isOwnPipeline = currentUserId === pipelineCreatorId;
  const canTeamPipelineUpdate = useCheckPermission(Permission.TeamPipelineUpdate);
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const navigate = useNavigate();
  const {isFreePlan} = useSubscription();
  const {isConfirmed} = useConfirm();

  const handleSwitchToEdit = useCallback(() => {
    switchToEdit();
    closeDropdown();
  }, [switchToEdit, closeDropdown]);

  const handleDownloadCSV = useCallback(() => {
    downloadCSV(pipelineId, pipelineTitle);
    closeDropdown();
  }, [pipelineId, pipelineTitle, closeDropdown]);

  const handleDeletePipeline = useCallback(async () => {
    closeDropdown();
    const confirmed = await isConfirmed(
      `This will remove the "${pipelineTitle}" list will also erase all associated statuses from the profiles it contains. This action cannot be undo.`,
      'removal'
    );
    if (confirmed) {
      await deletePipeline(teamId, pipelineId);
      refetchPipelines && (await refetchPipelines());
      closeEditMode?.();
      redirectToPipelinesList && navigate({pathname: '/lists'});
    }
  }, [
    teamId,
    pipelineId,
    pipelineTitle,
    closeDropdown,
    refetchPipelines,
    redirectToPipelinesList,
    closeEditMode,
    navigate,
    isConfirmed,
  ]);

  const canEdit = isOwnPipeline || canTeamPipelineUpdate;

  return (
    <div
      className={cs({
        [onRowHoverVisible]: !alwaysVisible,
        [onRowHoverVisibleIgnore]: isOpen,
      })}
    >
      <Dropdown
        renderDropdownTrigger={() => (
          <Button
            variant="tertiary"
            icon={<DotsThreeVertical weight="bold" size={22} />}
            iconOnly
            rounded={circularTrigger}
            intercomTarget="pipeline-dots-menu"
          ></Button>
        )}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placement="bottom-start"
      >
        <Dropdown.Item
          label="Export To CSV"
          type={{
            name: 'button',
            onClick: handleDownloadCSV,
          }}
          rightIcon={isFreePlan && <Pill color="yellow">Upgrade</Pill>}
          info={isFreePlan ? 'Feature available in paid plan' : undefined}
          tracking={{
            label: 'export to csv',
            location: 'pipeline three dots dropdown',
          }}
        />
        {canEdit && (
          <>
            <Dropdown.Item
              label="Edit List"
              type={{name: 'button', onClick: handleSwitchToEdit}}
              tracking={{
                label: 'edit pipeline',
                location: 'pipeline three dots dropdown',
              }}
            />
            <Dropdown.Item
              label="Remove List"
              type={{
                name: 'button',
                onClick: handleDeletePipeline,
              }}
              className={Styles.deleteButton}
              tracking={{
                label: 'remove pipeline',
                location: 'pipeline three dots dropdown',
              }}
            />
          </>
        )}
      </Dropdown>
    </div>
  );
};
