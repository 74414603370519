import cs from 'classnames';
import {FunctionComponent} from 'react';
import {Crm} from '../../types';
import {AffinityIcon, HubSpotIcon, SalesforceIcon} from '../icons';

export const integrationIconMap: Record<Crm, FunctionComponent<{className?: string}>> = {
  hubspot: HubSpotIcon,
  salesforce: SalesforceIcon,
  affinity: AffinityIcon,
};

type Props = {
  crm: Crm;
  className?: string;
};

export const CrmIcon = ({crm, className}: Props) => {
  const Icon = integrationIconMap[crm];
  return <Icon className={cs('size-6', className)} />;
};
