import {Plus} from 'phosphor-react';
import {ReactNode, useCallback} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {inviteMember} from '../../../api';
import {useCurrentTeam, useCurrentUser} from '../../../hooks';
import {Role} from '../../../types';
import {emailPattern, getUserFullName} from '../../../utils';
import {AddInvitationNote} from '../../add-invitation-note';
import {LoadingSpinner} from '../../animations';
import {Button} from '../../button';
import {Message} from '../../form';
import {FormWrapper} from '../../form-wrapper';
import {notify} from '../../notifications';
import {AddMemberRow} from './AddMemberRow';
import Styles from './AddMembersForm.module.scss';
import {defaultInvitees, INITIAL_NUMBER_OF_INVITEES} from './const';
import {InviteMembersFormProps} from './types';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  role: Role;
  children?: ReactNode;
};

export const AddMembersForm = ({onSuccess, onCancel, role, children}: Props) => {
  const {name: teamName, id: teamId} = useCurrentTeam();
  const currentUser = useCurrentUser();
  const sender = getUserFullName(currentUser);

  const {
    formState: {isSubmitting, errors},
    getValues,
    setValue,
    register,
    watch,
    control,
    handleSubmit,
    setError,
  } = useForm<InviteMembersFormProps>({
    defaultValues: {
      invitees: defaultInvitees,
      note: '',
    },
    mode: 'onBlur',
  });

  const {fields, append} = useFieldArray({
    control,
    name: 'invitees',
    rules: {
      minLength: INITIAL_NUMBER_OF_INVITEES,
    },
  });

  const onSubmit = async () => {
    const uniqueInvitees = getValues('invitees').filter(
      (invitee, index, self) =>
        invitee.email && self.findIndex(({email}) => email === invitee.email) === index
    );

    const note = getValues('note');

    if (!uniqueInvitees.length) {
      setError('invitees', {message: 'At least one email address is required.'});
      return;
    }

    for (const {email} of uniqueInvitees) {
      try {
        await inviteMember(teamId, {
          email: email.trim(),
          role,
          note,
        });
        onSuccess();
        notify(`Invitation sent to ${email}.`);
      } catch (e) {
        notify(`Sending invitation to ${email} failed.`);
        setError('invitees', {message: 'Something went wrong.'});
      }
    }
  };

  const setNote = useCallback(
    (value: string) => {
      setValue('note', value);
    },
    [setValue]
  );

  if (!teamName || !teamId)
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );

  return (
    <FormWrapper
      skipButton={{
        label: 'Back',
        onClick: onCancel,
        tracking: {label: 'back', location: 'add members modal'},
      }}
      actionButton={{
        label: 'Send invites',
        onClick: handleSubmit(onSubmit),
        loading: isSubmitting,
        tracking: {label: 'send invites', location: 'add members modal'},
      }}
      reverseButtonsOrder
      onModal
      fullWidth
      additionalButton={
        <AddInvitationNote
          content={`Join now and add your connections to the ${teamName} collective network on The Swarm.`}
          note={watch('note') || ''}
          setNote={setNote}
          subject={`${sender} has invited you to The Swarm`}
          title={`${sender} has invited you to The Swarm`}
          trackingLocation="add members modal"
        />
      }
    >
      <div className={Styles.formContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <AddMemberRow
              key={field.id}
              registerEmail={register(`invitees.${index}.email`, {
                validate: value => {
                  const trimmedValue = value.trim();
                  return emailPattern.test(trimmedValue) || 'Invalid email address';
                },
              })}
              invalidEmailMessage={errors.invitees?.[index]?.email?.message}
            />
          ))}
          {errors.invitees?.message && (
            <Message message={errors.invitees.message} hasError className={Styles.formMessage} />
          )}
        </form>
        <Button variant="secondary" fullWidth icon={<Plus />} onClick={() => append({email: '', role})}>
          Add more members
        </Button>
      </div>
      {children}
      <hr className={Styles.divider} />
    </FormWrapper>
  );
};
