import qs from 'qs';
import {useLocation} from 'react-router-dom';

type DefaultResultType = ReturnType<typeof qs.parse>;

export const useQueryParam = <ResultType = DefaultResultType>(key: string): ResultType => {
  const {search: queryString} = useLocation();
  const queryParams = qs.parse(queryString, {ignoreQueryPrefix: true});

  return queryParams[key] as ResultType;
};
