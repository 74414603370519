import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useMutationUpdateTeam} from '../../../api';
import {Input, Label} from '../../../components/form';
import {notify} from '../../../components/notifications';
import {useCurrentTeam, useCurrentUserSession} from '../../../hooks';
import Styles from './TeamProfile.module.scss';

type FormProps = {
  companyLinkedinURL: string;
};

export const CompanyLinkedin = () => {
  const {id: teamId, companyLinkedinURL} = useCurrentTeam();
  const {refetchTeam} = useCurrentUserSession();

  // constructed as a form for future, now readonly
  const {
    formState: {errors},
    register,
    handleSubmit,
    setError,
  } = useForm<FormProps>({
    defaultValues: {companyLinkedinURL},
  });

  const mutationUpdateTeam = useMutationUpdateTeam(teamId, {
    onSuccess: () => {
      refetchTeam().then(() => {
        notify('Company Linkedin saved successfully');
      });
    },
    onError: () => {
      setError('root', {message: 'Something went wrong. Please try again later.'});
    },
  });

  const onSubmit = useCallback(
    ({companyLinkedinURL}: FormProps) => {
      mutationUpdateTeam.mutate({
        companyLinkedinURL: companyLinkedinURL,
      });
    },
    [mutationUpdateTeam]
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={Styles.linkedinSection}>
          <Label>Your company linkedin profile</Label>
          <Input
            {...register('companyLinkedinURL', {required: 'Company Linkedin Url is required'})}
            placeholder="https://www.linkedin.com/company/yourcompany"
            type="text"
            isInvalid={Boolean(errors.companyLinkedinURL)}
            message={errors.companyLinkedinURL?.message}
            messageClassName={Styles.errorMessage}
            disabled
          />
        </div>
      </form>
    </div>
  );
};
