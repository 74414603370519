import {Action} from '@remix-run/router';
import {useEffect, useState} from 'react';
import {useNavigationType} from 'react-router-dom';

type ScrollObject = {
  left: number;
  top: number;
};
const defaultScroll: ScrollObject = {
  left: 0,
  top: 0,
};
const scrollPositionMap = new Map<string, ScrollObject>();
export const useRestoreScrollOnPopState = (pageName: string, loadedPage = true) => {
  const [fromPopState, setFromPopState] = useState(false);
  const navigationType = useNavigationType();
  useEffect(() => {
    switch (navigationType) {
      case Action.Push:
        setFromPopState(false);
        break;
      case Action.Pop:
        setFromPopState(true);
        break;
      case Action.Replace:
        // do nothing
        break;
      default:
        ((_: never) => {})(navigationType);
        break;
    }
  }, [navigationType]);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');
    if (mainContent && fromPopState && loadedPage) {
      mainContent.scrollTo({
        ...(scrollPositionMap.get(pageName) ?? defaultScroll),
        behavior: 'smooth',
      });
    }
  }, [loadedPage, fromPopState, pageName]);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');
    if (!mainContent) return () => {};
    const saveScrollTop = () => {
      scrollPositionMap.set(pageName, {
        left: mainContent.scrollLeft,
        top: mainContent.scrollTop,
      });
    };
    mainContent.addEventListener('scroll', saveScrollTop);
    return () => {
      mainContent.removeEventListener('scroll', saveScrollTop);
    };
  }, [pageName]);
};
