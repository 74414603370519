import {useState} from 'react';
import {getExtensionVersion} from '../utils/extensionCommunication';

export const useExtensionVersion = () => {
  const [extensionVersion, setExtensionVersion] = useState<string | null>(null);
  getExtensionVersion().then(version => {
    setExtensionVersion(version);
  });
  return extensionVersion;
};
