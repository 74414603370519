import cs from 'classnames';
import {Children, CSSProperties, ReactElement, ReactNode, Ref, useCallback} from 'react';
import {Placement, useLayer} from 'react-laag';
import {useElementSize} from 'usehooks-ts';
import {segmentTrack} from '../../utils';
import Styles from './Dropdown.module.scss';
import {DropdownItem} from './DropdownItem';
import {DropdownItemsGroup} from './DropdownItemsGroup';

type Props = {
  renderDropdownTrigger: (ref?: Ref<HTMLDivElement>) => ReactElement;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  placement?: Placement;
  offset?: number;
  actionButton?: {
    label: string;
    onClick: () => void;
    icon: ReactNode;
    type?: 'Danger' | 'Success';
  };
  dropdownHeader?: ReactNode;
  dropdownFooter?: ReactNode;
  visibleOnEmpty?: boolean;
  children?: ReactNode;
  narrow?: boolean;
  dropdownClass?: string;
  triggerClass?: string;
  snapWidth?: boolean;
  tracking?: {
    label: string;
    location?: string;
  };
};

const DropdownComponent = ({
  renderDropdownTrigger,
  isOpen,
  setIsOpen,
  actionButton,
  placement,
  offset,
  dropdownHeader,
  dropdownFooter,
  visibleOnEmpty,
  children,
  narrow,
  dropdownClass,
  triggerClass,
  snapWidth,
  tracking,
}: Props) => {
  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const [wrapperRef, {width}] = useElementSize();

  const {renderLayer, triggerProps, layerProps} = useLayer({
    isOpen,
    onOutsideClick: close,
    onDisappear: close,
    overflowContainer: true,
    auto: true, // automatically find the best placement
    placement: placement || 'bottom-start',
    possiblePlacements: ['bottom-start', 'top-start'],
    triggerOffset: offset || 0,
  });

  const widthStyles: CSSProperties = snapWidth
    ? {
        width: `${width}px`,
        maxWidth: `${width}px`,
      }
    : {};

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (tracking && !isOpen) {
      const {label, location} = tracking;
      segmentTrack('Selector Opened', {
        label,
        ...(location ? {location} : {}),
      });
    }
  }, [isOpen, setIsOpen, tracking]);

  const visibleChildCount = Children.toArray(children).filter(Boolean).length;
  if (!visibleOnEmpty && visibleChildCount === 0) {
    return null;
  }
  return (
    <>
      <div {...triggerProps} onClick={toggle} className={cs(Styles.dropdownTrigger, triggerClass)}>
        {renderDropdownTrigger(wrapperRef)}
      </div>
      {isOpen &&
        renderLayer(
          <div>
            <div {...layerProps}>
              <div
                className={cs(Styles.dropdown, {[Styles.dropdownNarrow]: narrow}, dropdownClass)}
                style={widthStyles}
              >
                {dropdownHeader && <div className={Styles.dropdownHeader}>{dropdownHeader}</div>}
                <div className={Styles.dropdownItems}>
                  {children}
                  {actionButton && (
                    <div className={Styles.dropdownItem}>
                      <button
                        onClick={actionButton.onClick}
                        className={cs(
                          Styles.dropdownItemButton,
                          Styles[`dropdownItemButton${actionButton.type}`]
                        )}
                      >
                        {actionButton.icon}
                        {actionButton.label}
                      </button>
                    </div>
                  )}
                </div>
                {dropdownFooter && <div className={Styles.dropdownFooter}>{dropdownFooter}</div>}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export const Dropdown = Object.assign(DropdownComponent, {
  Item: DropdownItem,
  ItemsGroup: DropdownItemsGroup,
});
