import {notify} from '../components/notifications';

export const notifyGenericError = () =>
  notify(
    <p>
      Something went wrong. Try to{' '}
      <a onClick={() => window.location.reload()} className="cursor-pointer">
        refresh
      </a>
    </p>,
    true
  );
