import {useEffect, useState} from 'react';
import {useCurrentTeam} from '../../hooks';

export const useScope = (defaultScope?: string, pageName?: string) => {
  const {id: teamID} = useCurrentTeam();

  const saveToLocalStorage = !!pageName;
  const localStorageKey = `scope-${teamID}_${pageName}`;

  const savedScope = localStorage.getItem(localStorageKey) || defaultScope;
  const [scope, setScope] = useState<string | undefined>(savedScope === 'all' ? undefined : savedScope);

  useEffect(() => {
    if (saveToLocalStorage) {
      localStorage.setItem(localStorageKey, scope || 'all');
    }
  }, [saveToLocalStorage, scope, localStorageKey]);

  const scopeForSwitcher = scope === 'all' ? undefined : scope;

  return {
    scope: scopeForSwitcher,
    setScope,
  };
};
