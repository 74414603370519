import cs from 'classnames';
import {CaretDown} from 'phosphor-react';
import {Message} from '../utils';
import {Options} from './Options';
import Styles from './Select.module.css';
import {SelectProps} from './types';

export const Select = ({
  options,
  className,
  isInvalid,
  message,
  placeholder,
  value,
  ...rest
}: SelectProps) => {
  return (
    <div className={cs(Styles.wrapper, className)}>
      <div className={Styles.selectWrapper}>
        <select
          className={cs(Styles.select, {
            [Styles.selectError]: isInvalid,
            [Styles.selectUnselected]: placeholder && !value,
          })}
          value={value}
          {...rest}
        >
          <Options options={options} placeholder={placeholder} />
        </select>
        <CaretDown weight="bold" size={12} className={Styles.selectIcon} />
      </div>
      {message && <Message message={message} hasError={isInvalid} className={Styles.selectInputMessage} />}
    </div>
  );
};
