import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

type Params<T> = {
  defaultObject: T;
  searchParamName?: string;
  typeGuard?: (value: unknown) => value is T;
  replaceHistory?: boolean;
};
const defaultTypeGuard = <T extends object>(value: unknown): value is T => {
  return typeof value === 'object' && value !== null;
};
export const useObjectInSearchParam = <T extends object>(params: Params<T>) => {
  const {defaultObject, searchParamName, typeGuard = defaultTypeGuard, replaceHistory} = params;
  const {getFromSearchParam, saveInSearchParam} = useSearchParamsHelpers({searchParamName, replaceHistory});
  const getObjectFromSearchParam = useCallback((): T => {
    const filtersString = getFromSearchParam() || '{}';
    try {
      const value = JSON.parse(filtersString);
      if (typeGuard(value)) {
        return value;
      }
    } catch {}

    return defaultObject;
  }, [getFromSearchParam, defaultObject, typeGuard]);

  const saveObjectAsSearchParam = useCallback(
    (newObject: T) => {
      const isEmpty = Object.keys(newObject).length === 0;
      const value = isEmpty ? null : JSON.stringify(newObject);

      saveInSearchParam(value);
    },
    [saveInSearchParam]
  );

  return {
    getObjectFromSearchParam,
    saveObjectAsSearchParam,
  };
};

type SearchParamsHelpersParams = {
  searchParamName?: string;
  replaceHistory?: boolean;
};
export const useSearchParamsHelpers = ({
  searchParamName = 'objectKey',
  replaceHistory = false,
}: SearchParamsHelpersParams) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getFromSearchParam = useCallback(
    () => searchParams.get(searchParamName),
    [searchParams, searchParamName]
  );

  const saveInSearchParam = useCallback(
    (value: string | null) => {
      const prevValue = searchParams.get(searchParamName);
      if (prevValue === value) return;

      if (value) {
        searchParams.set(searchParamName, value);
      } else {
        searchParams.delete(searchParamName);
      }

      setSearchParams(searchParams, {replace: replaceHistory});
    },
    [setSearchParams, searchParams, replaceHistory, searchParamName]
  );
  return {
    getFromSearchParam,
    saveInSearchParam,
  };
};
