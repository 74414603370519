import cs from 'classnames';
import {useLayoutContext} from '../../contexts';
import {Permission} from '../../types';
import {PermissionChecker} from '../permission';
import {Links} from './links';
import {SidebarLogo} from './logo';
import {Navigation} from './navigation';
import Styles from './Sidebar.module.scss';
import {TeamMenu} from './team-menu';
import {UserMenu} from './user-menu';
import {FavoriteListsWidget, ImportStatusWidget, SubscriptionWidget} from './widgets';

export const Sidebar = () => {
  const {sidebarCollapsed} = useLayoutContext();

  return (
    <div className={Styles.sidebarInner}>
      <div className={Styles.sidebarHeader}>
        <SidebarLogo />
      </div>
      <div className={cs(Styles.sidebarContent, {[Styles.sidebarContentCollapsed]: sidebarCollapsed})}>
        <TeamMenu />
        <Navigation />
        {!sidebarCollapsed && (
          <PermissionChecker permission={Permission.Pipeline}>
            <FavoriteListsWidget />
          </PermissionChecker>
        )}
        <div className={Styles.push} />
        {!sidebarCollapsed && (
          <div className={Styles.sidebarWidgets}>
            <ImportStatusWidget />
          </div>
        )}
        <SubscriptionWidget collapsed={sidebarCollapsed} />
        <Links />
      </div>
      <div className={Styles.sidebarFooter}>
        <UserMenu />
      </div>
    </div>
  );
};
