import cs from 'classnames';
import {Check as CheckIcon, XCircle as ClearIcon, WarningCircle as WarningIcon} from 'phosphor-react';
import {ComponentProps, ComponentType, InputHTMLAttributes, forwardRef} from 'react';
import {ValidationProps} from '../types';
import {Message} from '../utils';
import Styles from './Input.module.css';

type Props = InputHTMLAttributes<HTMLInputElement> &
  ValidationProps & {
    inputStyle?: 'bordered' | 'clearable';
    inputSize?: 'base' | 'lg';
    icon?: ComponentType<ComponentProps<'svg'>>;
    onClear?: () => void;
    intercomTarget?: string;
    className?: string;
    messageClassName?: string;
  };

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      inputStyle = 'bordered',
      inputSize = 'base',
      icon: Icon,
      disabled,
      isValid,
      isInvalid,
      value,
      onClear,
      message,
      intercomTarget,
      className,
      messageClassName,
      ...rest
    },
    ref
  ) => {
    const renderAddon = () => {
      if (isValid) {
        return <CheckIcon size={20} className="text-swarm-green" />;
      } else if (isInvalid) {
        return <WarningIcon weight="fill" size={20} className="fill-swarm-red-darken" />;
      }
      return null;
    };

    return (
      <>
        <div
          className={cs(Styles.inputWrapper, 'group', className)}
          {...(intercomTarget ? {'data-intercom-target': intercomTarget} : {})}
        >
          <span
            className={cs(Styles.inputIcon, Styles[`${inputStyle}InputIcon`], {
              [Styles.filledInputIcon]: value,
              [Styles.inputIconValid]: isValid,
              [Styles.inputIconInvalid]: isInvalid,
              [Styles.inputIconDisabled]: disabled,
            })}
          >
            {Icon && <Icon className={cs('h-4 w-4')} />}
          </span>
          <input
            type={type}
            value={value}
            className={cs(Styles.input, Styles[`${inputSize}Input`], Styles[`${inputStyle}Input`], {
              [Styles.inputWithIcon]: Icon,
              [Styles[`${inputStyle}InputValid`]]: isValid,
              [Styles[`${inputStyle}InputInvalid`]]: isInvalid,
              [Styles[`${inputStyle}InputHasClearBtn`]]: !!onClear,
            })}
            disabled={disabled}
            ref={ref}
            {...rest}
          ></input>
          {onClear && (
            <button
              type="button"
              className={Styles.inputClearButton}
              onMouseDown={ev => {
                ev.preventDefault();
                ev.stopPropagation();
              }}
              onClick={onClear}
            >
              <ClearIcon size={20} weight="fill" className="fill-swarm-black" />
            </button>
          )}
          <span className={Styles.inputAddon}>{renderAddon()}</span>
        </div>
        {message && (
          <Message
            message={message}
            hasError={isInvalid}
            hasSuccess={isValid}
            className={messageClassName || 'pt-1'}
          />
        )}
      </>
    );
  }
);

// below code is added according to:
// https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref

Input.displayName = 'Input';

export {Input};
