import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, useQuery, UseQueryOptions} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';
import {useCurrentTeam} from '../hooks';
import {CompanyCrmData, CrmIntegration, Crm, ErrorResponse, ProfileCrmData} from '../types';
import {apiClient} from './apiClient';

// CONNECTED INTEGRATIONS

type IntegrationsResponse = {
  integrations: CrmIntegration[];
};

const getCrmIntegrations = async (): Promise<IntegrationsResponse> =>
  apiClient.get<IntegrationsResponse>('/crm/integrations').then(res => res.data);

export const useQueryCrmIntegrations = (options: UseQueryOptions<IntegrationsResponse> = {}) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<IntegrationsResponse>(['getIntegrations', teamId], () => getCrmIntegrations(), options);
};

// INTEGRATION LINK TOKEN

type CrmLinkResponse = {
  linkToken: string;
};

const getCrmLinkToken = async (crm: Crm): Promise<CrmLinkResponse> =>
  apiClient.get<CrmLinkResponse>(`/crm/integrations/link-token/${crm}`).then(res => res.data);

export const useQueryCrmLinkToken = (crm: Crm, options: UseQueryOptions<CrmLinkResponse> = {}) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<CrmLinkResponse>(['getCrmLinkToken', teamId, crm], () => getCrmLinkToken(crm), options);
};

// INTEGRATION LINK ACCOUNT

type CrmLinkAccountInput = {
  publicToken: string;
};

const crmLlinkAccount = (token: string): Promise<void> =>
  apiClient
    .post<void, AxiosResponse<void>, CrmLinkAccountInput>('/crm/integrations/link-account', {
      publicToken: token,
    })
    .then(res => res.data);

export const useMutationCrmLinkAccount = (
  options?: UseMutationOptions<unknown, AxiosError<ErrorResponse>, CrmLinkAccountInput>
) =>
  useMutation<unknown, AxiosError<ErrorResponse>, CrmLinkAccountInput>(
    ({publicToken}) => crmLlinkAccount(publicToken),
    options
  );

// INTEGRATION UNLINK ACCOUNT

type CrmUnlinkAccountInput = {
  integrationId: string;
};

const crmUnlinkAccount = ({integrationId}: CrmUnlinkAccountInput): Promise<void> =>
  apiClient.delete(`/crm/integrations/${integrationId}`);

export const useMutationCrmUnlinkAccount = () =>
  useMutation<void, AxiosError<ErrorResponse>, CrmUnlinkAccountInput>(({integrationId}) =>
    crmUnlinkAccount({integrationId})
  );

// COMPANY EXPORT

type CrmCompanyExportRequest = {
  integrationId: string;
  companiesIds: string[];
};

const crmCompanyExport = ({integrationId, companiesIds}: CrmCompanyExportRequest): Promise<void> =>
  apiClient.post('/crm/companies/export', {
    integrationId,
    companiesIds,
  });

export const useMutationCrmCompanyExport = (
  {integrationId, companiesIds}: CrmCompanyExportRequest,
  options?: UseMutationOptions<void, AxiosError<ErrorResponse>>
) =>
  useMutation<void, AxiosError<ErrorResponse>>(
    () => crmCompanyExport({integrationId, companiesIds}),
    options
  );

// PROFILE'S CRM DATA

type GetProfileCrmDataResponse = {
  data: ProfileCrmData[];
};

const getProfileCrmData = async (profileId: string): Promise<ProfileCrmData[]> =>
  apiClient.get<GetProfileCrmDataResponse>(`/crm/profiles/${profileId}`).then(res => res.data.data);

export const useQueryProfileCrmData = (
  profileId: string,
  options: UseQueryOptions<ProfileCrmData[]> = {}
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<ProfileCrmData[]>(
    ['getProfileCrmData', teamId, profileId],
    () => getProfileCrmData(profileId),
    options
  );
};

// COMPANY'S CRM DATA

type GetCompanyCrmDataResponse = {
  data: CompanyCrmData[];
};

const getCompanyCrmData = async (companyId: string): Promise<CompanyCrmData[]> =>
  apiClient.get<GetCompanyCrmDataResponse>(`/crm/companies/${companyId}`).then(res => res.data.data);

export const useQueryCompanyCrmData = (
  companyId: string,
  options: UseQueryOptions<CompanyCrmData[]> = {}
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<CompanyCrmData[]>(
    ['getCompanyCrmData', teamId, companyId],
    () => getCompanyCrmData(companyId),
    options
  );
};

// PROFILE EXPORT

type CrmProfileExportRequest = {
  integrationId: string;
  profilesIds: string[];
};

const crmProfileExport = ({integrationId, profilesIds}: CrmProfileExportRequest): Promise<void> =>
  apiClient.post('/crm/profiles/export', {
    integrationId,
    profilesIds,
  });

export const useMutationCrmProfileExport = (
  {integrationId, profilesIds}: CrmProfileExportRequest,
  options?: UseMutationOptions<void, AxiosError<ErrorResponse>>
) =>
  useMutation<void, AxiosError<ErrorResponse>>(() => crmProfileExport({integrationId, profilesIds}), options);
