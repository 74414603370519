import {SortingState} from '@tanstack/react-table';
import {sortingStateToSort, sortToSortingState} from '../../utils';
export const stringifySortingState = (sorting: SortingState): string => {
  if (sorting.length === 0) {
    return '';
  }
  return sortingStateToSort(sorting)[0];
};

export const parseSortingState = (sorting: string): SortingState =>
  sortToSortingState(sorting ? [sorting] : []);
