import {Cell, flexRender} from '@tanstack/react-table';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import Styles from './Table.module.scss';

type Props<TItem> = {
  cell: Cell<TItem, unknown>;
  height: string;
};

export const TableCell = <TItem extends object>({cell, height}: Props<TItem>) => {
  const autoTruncate = cell.column.columnDef.meta?.truncate;
  const cellContent = flexRender(cell.column.columnDef.cell, cell.getContext());

  return (
    <div
      className={Styles.tableCell}
      style={{
        left: cell.column.getStart(),
        width: cell.column.getSize(),
        height,
      }}
    >
      {autoTruncate ? (
        <div>
          <TruncateWithTooltip content={cellContent} key={cell.id}>
            {cellContent}
          </TruncateWithTooltip>
        </div>
      ) : (
        cellContent
      )}
    </div>
  );
};
