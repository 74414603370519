import {SortingState} from '@tanstack/react-table';
import {isEqual, uniqBy} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryBffProfilesList, useQueryProfileListBffAggregate} from '../../../api';
import {LoadingSpinner} from '../../../components/animations';
import {ColumnSettingsDropdown} from '../../../components/column-settings';
import {filtersStateToRequest, FiltersWrapper, useFiltersState} from '../../../components/filters';
import {Input} from '../../../components/form';
import {NoResults} from '../../../components/no-results';
import {PaginationButtons} from '../../../components/pagination-buttons';
import {RequestIntro, useRequestIntroModal} from '../../../components/request-intro';
import {useTablePagination, useTableSearch, useTableSorting} from '../../../components/table';
import {useAdjustSortToQuery} from '../../../components/table/useAdjustSortToQuery';
import {TabLinks} from '../../../components/tabs';
import {ProfilesBffColumns, ProfilesBffFilter, profilesBffLabelsMap as labelsMap} from '../../../defs';
import {
  useColumnSettings,
  useOnFilteringEvent,
  usePrevious,
  useRestoreScrollOnPopState,
} from '../../../hooks';
import {AppLayout} from '../../../layouts';
import {GetProfilesListRequest, GetProfilesListResponse, PipelineCommonResponse} from '../../../types';
import {getRequestOffset, pluralizedPeople, sortingStateToSortOptions} from '../../../utils';
import {PipelineHeader} from '../components/PipelineHeader';
import {getTabLinksConfig} from '../getTabLinksConfig';
import {availableColumns, PROFILES_IN_PIPELINE_PREFERENCES_KEY} from './columns';
import {filtersConfig} from './filtersConfig';
import {PipelineProfilesTable} from './PipelineProfilesTable';
import {usePipelineProfilesTable} from './usePipelineProfilesTable';

const emptyProfilesResponse: GetProfilesListResponse = {
  items: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};

const subSorting: SortingState = [
  {id: ProfilesBffColumns.ConnectedMembersCount, desc: true},
  {id: ProfilesBffColumns.FullName, desc: false},
];

type Props = {
  pipeline: PipelineCommonResponse;
};

export const Profiles = ({pipeline}: Props) => {
  const {id: pipelineId, title: pipelineName} = pipeline;

  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();

  const {filtersState, setFilter, isFilterActive} = useFiltersState({
    saveFiltersKey: filtersConfig.filtersName,
    availableFilters: filtersConfig.availableFilters,
  });

  const {columnSettings, setColumnSettings, resetColumnSettings, visibleColumnsState, columnsOrder} =
    useColumnSettings(availableColumns, `${PROFILES_IN_PIPELINE_PREFERENCES_KEY}_${pipelineId}`, {
      initiallyHiddenColumns: [ProfilesBffColumns.JobCompanyWebsite],
      columnsWithForcedVisibility: isFilterActive(ProfilesBffFilter.currentJobCompanyWebsite)
        ? [ProfilesBffColumns.JobCompanyWebsite]
        : undefined,
    });
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();

  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const {sortingState, setSorting} = useTableSorting();
  const sort = useMemo(
    () => sortingStateToSortOptions(uniqBy([...sortingState, ...subSorting], item => item.id)),
    [sortingState]
  );

  const adjustedSort = useAdjustSortToQuery(sort, searchQueryFetchParam);

  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);

  const filters = useMemo(() => {
    return {
      ...filtersStateToRequest(filtersState),
      ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
      inPipeline: [pipelineId],
    };
  }, [filtersState, searchQueryFetchParam, pipelineId]);

  useOnFilteringEvent({
    filters,
  });

  const searchParams: GetProfilesListRequest = useMemo(
    () => ({
      offset,
      limit: paginationState.pageSize,
      filters,
      sort: adjustedSort,
    }),
    [offset, paginationState.pageSize, filters, adjustedSort]
  );

  const {
    data: pipelineProfiles = emptyProfilesResponse,
    isLoading,
    isFetchedAfterMount,
    refetch,
  } = useQueryBffProfilesList(searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });

  const table = usePipelineProfilesTable({
    rows: pipelineProfiles.items,
    isLoaded: isFetchedAfterMount,
    pagination: {
      paginationState,
      setPaginationState,
      currentPage,
      totalCount,
      setMeta,
    },
    sorting: {
      sortingState,
      setSorting,
    },
    onAssignTag: () => refetch(),
    onUnassignTag: () => refetch(),
    visibleColumnsState,
    columnsOrder,
    openIntroModal,
    pipelineId,
    pipelineName,
    refetchProfiles: refetch,
  });

  useRestoreScrollOnPopState('pipelineProfiles', isFetchedAfterMount);

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilters, returnToFirstPage]);

  const paginationFullInfo = `${visibleItemsString} ${pluralizedPeople(totalCount)}`;

  const tabLinksConfig = getTabLinksConfig(pipelineId);

  const hasProfiles = totalCount > 0;

  if (isLoading) {
    return <LoadingSpinner size="small" color="black" centered />;
  }

  return (
    <AppLayout
      header={<PipelineHeader pipeline={pipeline} />}
      subHeader={
        <>
          <TabLinks tabs={tabLinksConfig} activeTab="Profiles" />
          <div className="flex items-end justify-between pl-2">
            <FiltersWrapper
              filtersState={filtersState}
              onFilterSet={setFilter}
              filtersConfig={filtersConfig}
              useFetchOptions={useQueryProfileListBffAggregate}
              outOfStateFilters={{
                inPipeline: [pipelineId],
                ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
              }}
              className="pb-0"
            />
          </div>
          <div className="m-2 flex items-center justify-between">
            <div className="w-72">
              <Input
                icon={SearchIcon}
                type="text"
                value={searchQuery}
                onChange={e => onChangeSearchQuery(e.target.value)}
                onClear={onClearSearchQuery}
                className="max-w-sm"
                placeholder="Search for name, email, title..."
                intercomTarget="search"
              />
            </div>
            <ColumnSettingsDropdown
              columnSettings={columnSettings}
              setColumnSettings={setColumnSettings}
              resetColumnSettings={resetColumnSettings}
              fixedColumns={[ProfilesBffColumns.FullName]}
              labelsMap={labelsMap}
            />
          </div>
        </>
      }
      footer={
        hasProfiles && (
          <PaginationButtons
            visibleItemsString={paginationFullInfo}
            pageIndex={currentPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            navigate={{
              next: nextPage,
              previous: previousPage,
              goToPage: goToPage,
            }}
            totalCount={totalCount}
            pageSize={paginationState.pageSize}
            setPageSize={setPageSize}
          />
        )
      }
    >
      {!hasProfiles ? (
        <NoResults
          heading="Sorry, there are no results."
          subheading={
            'Please clear search filters and try again.\nConsider adding members to expand your network.'
          }
          page="results"
        ></NoResults>
      ) : (
        <>
          <PipelineProfilesTable
            tableData={table}
            onChangeSuccess={refetch}
            pipelineId={pipelineId}
            pipelineName={pipelineName}
            totalCount={totalCount}
            filters={filters}
          />
          <RequestIntro profileId={introProfileId} visible={introModalVisible} onClose={closeIntroModal} />
        </>
      )}
    </AppLayout>
  );
};
