import {Component, ErrorInfo, ReactNode} from 'react';
import {trackError} from '../../utils';
import {NoResults} from '../no-results';

type Props = {
  children: ReactNode;
  name?: string;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const {name = ''} = this.props;

    trackError(error, {extras: {errorInfo, boundaryName: name}});
  }

  public render() {
    if (this.state.hasError) {
      return <NoResults page="error" heading="Sorry. There has been an error." />;
    }

    return this.props.children;
  }
}
