import {FilterCategory} from '../../components/filters';

export enum ProfilesBffFilterCategory {
  PROFILE = 'profile',
  COMPANY = 'company',
  GENERAL = 'general',
}

export const profilesBffFiltersCategoriesConfig: Record<ProfilesBffFilterCategory, FilterCategory> = {
  [ProfilesBffFilterCategory.PROFILE]: {
    key: ProfilesBffFilterCategory.PROFILE,
    title: 'Profile Filters',
  },
  [ProfilesBffFilterCategory.COMPANY]: {
    key: ProfilesBffFilterCategory.COMPANY,
    title: 'Companies Filters',
  },
  [ProfilesBffFilterCategory.GENERAL]: {
    key: ProfilesBffFilterCategory.GENERAL,
    title: 'General Data',
  },
};

export const profilesBffFiltersCategories = Object.values(profilesBffFiltersCategoriesConfig);
