import {uniqBy} from 'lodash';
import {FaLinkedin as LinkedinIcon} from 'react-icons/fa';
import {FaXTwitter as X} from 'react-icons/fa6';
import {FiLink as LinkIcon} from 'react-icons/fi';
import {SocialMediaEnum} from '../../../types';
import {BffCompany} from '../../../types/bffTypes';
import {addHttpsToUrl} from '../../../utils';
import {displaySize} from './displaySize';

export const getCompanyProfiles = (company: BffCompany) => {
  const {website, networks} = company;
  const uniqueSocialProfiles = uniqBy(networks, 'network');
  const linkedinProfile = uniqueSocialProfiles.find(({network}) => network === SocialMediaEnum.linkedin);
  const twitterProfile = uniqueSocialProfiles.find(({network}) => network === SocialMediaEnum.twitter);

  return [
    ...(website
      ? [
          {
            label: (
              <>
                <LinkIcon /> Website
              </>
            ),
            value: (
              <a href={addHttpsToUrl(website)} target="_blank" rel="noreferrer">
                {website}
              </a>
            ),
          },
        ]
      : []),
    {
      label: (
        <>
          <LinkedinIcon color="#1166c2" /> LinkedIn
        </>
      ),
      value: linkedinProfile?.url ? (
        <a href={addHttpsToUrl(linkedinProfile.url)} target="_blank" rel="noreferrer">
          View profile
        </a>
      ) : (
        ''
      ),
    },
    {
      label: (
        <>
          <X /> X
        </>
      ),
      value: twitterProfile?.url ? (
        <a href={addHttpsToUrl(twitterProfile.url)} target="_blank" rel="noreferrer">
          View profile
        </a>
      ) : (
        ''
      ),
    },
  ];
};

export const getCompanyInfo = (company: BffCompany) => {
  const {industry = '', locationName, size} = company;
  return [
    {
      label: 'Industry',
      value: industry,
    },
    {
      label: 'Company size',
      value: size ? displaySize(size) : '-',
    },
    {
      label: 'Location',
      value: locationName,
    },
  ];
};
