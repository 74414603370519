import cx from 'classnames';
import {Children, cloneElement, HTMLAttributes, isValidElement, ReactNode, useCallback} from 'react';
import {ButtonProps} from './Button';

export const ButtonsGroup = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
} & HTMLAttributes<HTMLDivElement>) => {
  const validChildren = Children.toArray(children).filter(isValidElement);
  const childrenCount = validChildren.length;

  const getPosition = useCallback(
    (index: number): ButtonProps['positionInGroup'] => {
      if (childrenCount < 2) {
        return undefined;
      }
      if (index === 0) {
        return 'first';
      }
      if (index === childrenCount - 1) {
        return 'last';
      }
      return 'middle';
    },
    [childrenCount]
  );

  return (
    <div className={cx('flex', className)} {...props}>
      {validChildren.map((Child, index) =>
        isValidElement<ButtonProps>(Child)
          ? cloneElement(Child, {positionInGroup: getPosition(index)})
          : Child
      )}
    </div>
  );
};
