import {getCookie, removeCookie, setCookie} from 'typescript-cookie';

const generateCookieName = (profileId: string, receiverId: string, pipelineId?: string) =>
  `request_intro_${profileId}${receiverId}${pipelineId ? pipelineId : ''}`;

const EXPIRATION_DAYS = 1;

const hostname = window.location.hostname;
const DOMAIN = hostname;

export const useRequestIntroLog = () => {
  const writeToLog = (profileId: string, receiverId: string, pipelineId?: string) => {
    const name = generateCookieName(profileId, receiverId, pipelineId);
    setCookie(name, 'block intro requests', {path: '', domain: DOMAIN, expires: EXPIRATION_DAYS});
  };

  const isInLog = (profileId: string, receiverId: string, pipelineId?: string) => {
    const name = generateCookieName(profileId, receiverId, pipelineId);
    return !!getCookie(name);
  };

  const removeFromLog = (profileId: string, receiverId: string, pipelineId?: string) => {
    const name = generateCookieName(profileId, receiverId, pipelineId);
    removeCookie(name, {path: '', domain: DOMAIN});
  };

  return {
    writeToLog,
    isInLog,
    removeFromLog,
  };
};
