import {trackError} from '../../utils';

const ErrorsMapping = [
  {
    apiError: 'An account already exists with this email address, please sign in using Google.',
    uiMessage: 'An account already exists with this email address, please sign in using Google.',
  },
  {
    apiError: 'An account with the given email already exists.',
    uiMessage: 'Email already exists, you can try logging in with this email.',
  },
  {
    apiError: 'Username cannot be empty',
    uiMessage: 'You have to enter your email address to sign in.',
  },
];

export const getErrorMessage = (error: string): string => {
  if (!error) {
    return '';
  }

  const errorMapping = ErrorsMapping.find(mapping => error.includes(mapping.apiError));

  if (!errorMapping) {
    trackError(new Error(`No error mapping found for error: ${error}`));
  }

  return errorMapping ? errorMapping.uiMessage : error;
};
