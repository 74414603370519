import {ProfilesTable} from '../../../components/profiles-table';
import {BffFilterOptions} from '../../../types';
import {UsePipelineProfilesTableResult} from './usePipelineProfilesTable';

type PipelineProfilesTableProps = {
  filters: BffFilterOptions;
  tableData: UsePipelineProfilesTableResult;
  onChangeSuccess: () => void;
  totalCount: number;
  pipelineId: string;
  pipelineName: string;
};

export const PipelineProfilesTable = ({
  filters,
  tableData,
  onChangeSuccess,
  totalCount,
  pipelineId,
  pipelineName,
}: PipelineProfilesTableProps) => (
  <ProfilesTable
    filters={filters}
    tableData={tableData}
    totalCount={totalCount}
    onChangeSuccess={onChangeSuccess}
    bulkActionsConfig={{
      removeFromPipeline: {pipelineId, pipelineName},
    }}
  />
);
