import classNames from 'classnames';
import {ReactNode} from 'react';
import {useSwarmLogo} from '../hooks';
import {logout} from '../providers';
import Styles from './AuthLayout.module.scss';

type AuthLayoutProps = {
  footer?: ReactNode;
  className?: string;
  children: ReactNode;
};

export const AuthLayout = ({className, children, footer}: AuthLayoutProps) => {
  const logoSrc = useSwarmLogo(false);
  const handleLogout = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'e') {
      e.preventDefault();
      logout();
    }
  };
  return (
    <div className={Styles.background} onKeyDown={handleLogout} tabIndex={0}>
      <div className={Styles.topbar}>
        <a href="https://www.theswarm.com/" target="_blank" rel="noreferrer">
          <img alt="the swarm logo" src={logoSrc} className={Styles.logo} />
        </a>
      </div>
      <div className={classNames(className, Styles.wrapper)}>
        {children}
        {footer && <p className={Styles.footer}>{footer}</p>}
      </div>
    </div>
  );
};
