import {OptionType} from '../../components/form/select';
import {Role} from '../../types';

export const rolesSelectOptions: OptionType<Role>[] = [
  {value: Role.Admin, text: 'Admin', description: 'Can invite and remove members.'},
  {
    value: Role.Contributor,
    text: 'Contributor',
    description: 'Cannot manage members.',
  },
  {
    value: Role.Guest,
    text: 'Guest',
    description: 'Cannot see connections of other guests, nor any contact information.',
  },
];
