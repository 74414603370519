import {useCallback, useState} from 'react';
import {useMutationBulkEditConnectionStrength} from '../../api';
import {ScoreValue} from '../../types';
import {pluralizedConnection, segmentTrack} from '../../utils';
import {LoadingSpinner} from '../animations';
import {Button} from '../button';
import {Dropdown} from '../dropdown';
import {DropdownItem} from '../dropdown/DropdownItem';
import {notify} from '../notifications';
import Styles from '../pipelines-actions/add-to-pipeline/add-to-pipeline/AddToPipelineButton.module.scss';
import {levels, Score} from './Score';

type Props = {
  profileIds: string[];
  onScoreChangeSuccess?: (profileIds: string[], score: ScoreValue) => void;
  disabled?: boolean;
};

export const BulkScoreDropdown = ({profileIds, onScoreChangeSuccess, disabled}: Props) => {
  const [loadingValue, setLoadingValue] = useState<ScoreValue | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const {mutateAsync, isLoading} = useMutationBulkEditConnectionStrength({});

  const onChangeValue = useCallback(
    async (strength: ScoreValue) => {
      setLoadingValue(strength);
      try {
        await mutateAsync({strength, profileIds});
        onScoreChangeSuccess?.(profileIds, strength);
        closeDropdown();
        const profilesCount = profileIds.length;
        segmentTrack('Bulk Action Applied', {
          label: 'edit strength',
          profile_count: profilesCount,
          strength,
        });
        notify(
          `Strength has been updated to "${levels[strength]}" for ${profilesCount} ${pluralizedConnection(
            profilesCount
          )}.`
        );
      } catch {
        notify('Something went wrong.');
      }
      setLoadingValue(null);
    },
    [closeDropdown, mutateAsync, onScoreChangeSuccess, profileIds]
  );

  const possibleValues = [3, 2, 1, 0] as const;

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => (
        <Button
          className={Styles.bulkAddToPipelineButton}
          size="sm"
          variant="tertiary"
          outline
          chevron="down"
          icon={<Score value={0} />}
          disabled={disabled}
        >
          Edit strength
        </Button>
      )}
    >
      {possibleValues.map(value => (
        <DropdownItem
          key={value}
          icon={<Score value={value} />}
          rightIcon={
            loadingValue === value && (
              <div className="mx-0.5 w-6">
                <LoadingSpinner size="x-small" className="m-auto" />
              </div>
            )
          }
          label={levels[value]}
          type={{
            name: 'button',
            onClick: () => onChangeValue(value),
            disabled: isLoading,
          }}
        />
      ))}
    </Dropdown>
  );
};
