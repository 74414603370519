import cs from 'classnames';
import {useCallback, useState} from 'react';
import {deleteNote} from '../../../api';
import {useCurrentUser} from '../../../hooks';
import {NoteResponse} from '../../../types';
import {getRelativeTimeToNow, getUserFullName, segmentTrack, trackError} from '../../../utils';
import {Avatar} from '../../avatar';
import {notify} from '../../notifications';
import {NoteForm} from '../note-form';
import Styles from './Note.module.scss';
import {NoteMenu} from './NoteMenu';

type Props = {
  note: NoteResponse;
  refetchNotes: () => void;
};

export const Note = ({note, refetchNotes}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const {creator, createdAt, content: noteContent, teamId} = note;

  const currentUser = useCurrentUser();

  const isPrivate = !teamId;
  const isOwnNote = currentUser.id === creator.id;

  const noteDate = new Date(createdAt);
  const recency = getRelativeTimeToNow(noteDate);

  const fullname = getUserFullName(creator);

  const editNote = useCallback(() => {
    setEditMode(true);
  }, []);

  const handleSuccess = useCallback(() => {
    segmentTrack('Notification Viewed', {
      message: 'note edited successfully',
      type: 'confirmation',
      process: 'profile notes',
    });
    notify('Note edited successfully.');
    setEditMode(false);
    refetchNotes();
  }, [refetchNotes]);

  const handleCancel = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleDeleteNote = useCallback(() => {
    deleteNote(note.id)
      .then(() => {
        refetchNotes();
        segmentTrack('Notification Viewed', {
          message: 'note deleted successfully',
          type: 'confirmation',
          process: 'profile notes',
        });
        notify('Note deleted successfully.');
      })
      .catch(e => {
        notify(e.message);
        trackError(e);
      });
  }, [refetchNotes, note.id]);

  return (
    <div className={cs(Styles.note, {[Styles.notePrivate]: isPrivate})}>
      <div className={Styles.noteHeader}>
        <div className={Styles.noteAuthor}>
          <Avatar fullname={fullname} />
          {fullname}
        </div>
        <div className={Styles.noteDate}>{recency}</div>
        {isPrivate && <img alt="private note" className={Styles.privacyIcon} src="/icons/private-note.png" />}
        <NoteMenu isOwnNote={isOwnNote} editNote={editNote} deleteNote={handleDeleteNote} />
      </div>
      {editMode ? (
        <NoteForm note={note} onSuccess={handleSuccess} editMode={true} onCancel={handleCancel} />
      ) : (
        <div className={Styles.noteContent}>{noteContent}</div>
      )}
    </div>
  );
};
