import {HeaderGroup, RowModel} from '@tanstack/react-table';
import cs from 'classnames';
import {get} from 'lodash';
import {ReactNode} from 'react';
import {getConfig} from '../../utils';
import Styles from './Table.module.scss';
import {TableCell} from './TableCell';
import {TableHeader} from './TableHeader';
import {TableHeaderMassEnabled} from './TableHeaderMassEnabled';

type TableProps<TItem> = {
  headerGroups: HeaderGroup<TItem>[];
  rowModel: RowModel<TItem>;
  totalWidth: number;
  cellHeight?: number;
  noVerticalSeparators?: boolean;
  bulkActionsSlot?: ReactNode;
  rowKeyPath?: string; // the path in the row model to use as the row key
  rowClassName?: (row: TItem) => string;
  massActionsSwitchData?: {
    massActionsMode: boolean;
    activateMassActions: () => void;
    unselectAllRows: () => void;
    selectedRowsCount: number;
    totalCount: number;
  };
};

const {tableMassActionsEnabled} = getConfig();

export const Table = <T extends object>({
  rowModel,
  headerGroups,
  totalWidth,
  cellHeight,
  noVerticalSeparators,
  bulkActionsSlot,
  rowKeyPath = 'id',
  rowClassName,
  massActionsSwitchData,
}: TableProps<T>) => {
  const height = `${cellHeight}px`;

  return (
    <div
      className={cs(Styles.table, {[Styles.noVerticalSeparators]: noVerticalSeparators})}
      style={{width: totalWidth}}
    >
      {tableMassActionsEnabled ? (
        <TableHeaderMassEnabled<T>
          headerGroups={headerGroups}
          bulkActionsSlot={bulkActionsSlot}
          massActionsSwitchData={massActionsSwitchData}
        />
      ) : (
        <TableHeader<T> headerGroups={headerGroups} height={height} bulkActionsSlot={bulkActionsSlot} />
      )}
      <div className={Styles.tableBody}>
        {rowModel.rows.map(row => (
          <div
            key={get(row, rowKeyPath, 'id')}
            className={cs(
              Styles.tableRow,
              {
                [Styles.tableRowSelected]: row.getIsSelected(),
              },
              rowClassName?.(row.original)
            )}
            style={{height: height}}
          >
            {row.getVisibleCells().map(cell => (
              <TableCell cell={cell} height={height} key={`${get(row, rowKeyPath, 'id')}-${cell.id}`} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
