import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {LoadingSpinner} from '../../components/animations';
import {TrialNotification} from '../../components/trial-notification';
import {useSubscription} from '../../hooks';
import {AppLayout} from '../../layouts';
import {PaymentPeriod} from '../../types';
import {PeriodSelector} from './period-selector';
import {PlansOverview} from './plans-overview';
import Styles from './UpgradePlanPage.module.scss';

export const UpgradePlanPage = () => {
  const [period, setPeriod] = useState<PaymentPeriod>(PaymentPeriod.Monthly);

  const {plan, isPlanLoading} = useSubscription();

  const {payment, type: planType} = plan || {};
  const {trialActive} = payment || {};

  if (isPlanLoading) {
    return (
      <AppLayout>
        <LoadingSpinner />
      </AppLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>Upgrade Plan - The Swarm</title>
      </Helmet>
      <AppLayout>
        <div className={Styles.pageWrapper}>
          <div className={Styles.upgrade}>
            <TrialNotification />
            <div className={Styles.header}>
              <h2 className={Styles.heading}>Manage your plan</h2>
              <h3 className={Styles.subheading}>
                Visit{' '}
                <a
                  href="https://www.theswarm.com/product/pricing"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  theswarm.com/pricing
                </a>{' '}
                for more details.
              </h3>
            </div>
            <PeriodSelector period={period} setPeriod={setPeriod} />
            <PlansOverview billingPeriod={period} currentPlan={planType} isTrial={trialActive} />
          </div>
        </div>
      </AppLayout>
    </>
  );
};
