import cs from 'classnames';
import {ScoreValue} from '../../types';
import {Tooltip} from '../tooltip';
import Styles from './Score.module.scss';

type Props = {
  value?: ScoreValue;
  showLabel?: boolean;
  withTooltip?: boolean;
};

type IndicatorProps = {
  value?: ScoreValue;
};

export const levels = ['Unfamiliar', 'Weak', 'Familiar', 'Strong'] as const;

export const Indicator = ({value}: IndicatorProps) => (
  <div
    className={cs(Styles.indicator, Styles[`indicatorLevel${levels[value || 0]}`], {
      [Styles.noScore]: value === undefined,
    })}
  >
    <div />
    <div />
    <div />
  </div>
);

const SimpleScore = ({value, showLabel}: Props) => (
  <div className={cs(Styles.score, {[Styles.scoreWithLabel]: showLabel})}>
    <Indicator value={value} />
    {showLabel && value !== undefined && <span>{levels[value]}</span>}
  </div>
);
export const Score = ({withTooltip, value, showLabel}: Props) => {
  const score = <SimpleScore value={value} showLabel={showLabel} />;
  if (!withTooltip) {
    return score;
  }
  return (
    <Tooltip content={<SimpleScore value={value} showLabel />}>
      <div className={Styles.scoreWrapper}>{score}</div>
    </Tooltip>
  );
};
