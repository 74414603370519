export const getTabLinksConfig = (pipelineId: string) => [
  {
    title: 'Profiles',
    path: `/lists/${pipelineId}`,
  },
  {
    title: 'Saved search',
    path: `/lists/${pipelineId}/saved-search`,
  },
  {
    title: 'Details',
    path: `/lists/${pipelineId}/details`,
  },
];
