import {useCallback} from 'react';
import {FiTrash2 as DeleteIcon} from 'react-icons/fi';
import {useMassAction} from '../../../hooks';
import {BffFilterOptions} from '../../../types';
import {Button} from '../../button';

type Props = {
  filters: BffFilterOptions;
  totalCount: number;
  pipelineId: string;
  pipelineName: string;
  onSuccess?: () => void;
};

export const MassRemoveFromParticularPipelineButton = ({
  filters,
  totalCount,
  pipelineId,
  pipelineName,
  onSuccess,
}: Props) => {
  const {executeMassAction} = useMassAction('removeFromPipeline');

  const massRemoveFromPipeline = useCallback(() => {
    executeMassAction({
      targetId: pipelineId,
      targetName: pipelineName,
      filters,
      totalCount,
      onSuccess,
    });
  }, [executeMassAction, filters, onSuccess, pipelineId, pipelineName, totalCount]);

  return (
    <Button
      onClick={massRemoveFromPipeline}
      variant="tertiary"
      outline
      icon={<DeleteIcon color="#ED4D37" />}
      size="sm"
      intercomTarget="remove selected profiles from pipeline"
    >
      <span className="text-swarm-red">Remove</span>
    </Button>
  );
};
