import type {ReactNode} from 'react';
import {Label} from '../../form';
import Styles from './FieldRow.module.scss';

type Props = {
  children?: ReactNode;
  label: string;
};

export const FieldRow = ({children, label}: Props) => (
  <div className={Styles.fieldRow}>
    <div className={Styles.labelArea}>
      <Label className={Styles.label}>{label}</Label>
    </div>
    <div className={Styles.fieldArea}>{children}</div>
  </div>
);
