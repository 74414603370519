const scriptsMap: Map<string, Promise<unknown>> = new Map();

export const loadScript = (url: string): Promise<unknown> => {
  if (scriptsMap.has(url)) {
    return scriptsMap.get(url) as Promise<unknown>;
  }

  const script = document.createElement('script');
  script.src = url;

  const promise = new Promise(res => {
    script.onload = res;
  });
  scriptsMap.set(url, promise);

  document.head.appendChild(script);
  return promise;
};
