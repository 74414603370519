import {Button} from '../../../components/button';
import {Crm} from '../../../types';
import {useIntegrationAction} from './useIntegrationAction.hook';

type Props = {
  crm: Crm;
};

export const IntegrationActionButton = ({crm}: Props) => {
  const {isConnected, isLoading, connect, disconnect} = useIntegrationAction(crm);

  if (isConnected) {
    return (
      <Button
        variant="red"
        onClick={disconnect}
        loading={isLoading}
        tracking={{
          label: 'disconnect',
          customProperties: {
            integration: crm,
          },
        }}
      >
        Disconnect
      </Button>
    );
  }

  return (
    <Button
      variant="primary"
      onClick={connect}
      loading={isLoading}
      tracking={{
        label: 'connect',
        customProperties: {
          integration: crm,
        },
      }}
    >
      Connect
    </Button>
  );
};
