import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useForm} from 'react-hook-form';
import {FiEdit2 as EditIcon} from 'react-icons/fi';
import {checkTeamNameUnique, useMutationUpdateTeam} from '../../../api';
import {Avatar} from '../../../components/avatar';
import {Button} from '../../../components/button';
import {Input, Label, Message} from '../../../components/form';
import {notify} from '../../../components/notifications';
import {useCurrentTeam, useCurrentUserSession} from '../../../hooks';
import Styles from './TeamProfile.module.scss';

type FormProps = {
  name: string;
  logo: FileList;
};

type ErrorDetails = {path: string; violation: string};

const ALLOWED_FORMATS = ['image/png', 'image/jpeg'];
const MAX_SIZE = 5 * 1024 * 1024; // 5mb
const LOGO_REQUIREMENTS = 'Logo should be a square, max size 5mb, and in png or jpg format';

const validateFile = (file: File) => {
  return ALLOWED_FORMATS.includes(file.type) && file.size <= MAX_SIZE;
};

export const TeamProfile = () => {
  const hiddenImageInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);
  const [selectedLogo, setSelectedLogo] = useState<File | undefined>(undefined);

  const {refetchTeam} = useCurrentUserSession();
  const {name: currentTeamName, id: teamId, logoURL} = useCurrentTeam();

  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
    watch,
    reset,
  } = useForm<FormProps>({
    defaultValues: useMemo(() => ({name: currentTeamName}), [currentTeamName]),
  });

  const clearForm = useCallback(() => {
    reset(undefined, {keepErrors: true, keepDefaultValues: true});
    setImagePreview(undefined);
    setSelectedLogo(undefined);
  }, [reset]);

  const handleUploadedFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const isValid = validateFile(file);
        if (!isValid) {
          setError('root', {
            type: 'manual',
            message: LOGO_REQUIREMENTS,
          });
          return;
        }
        const urlImage = URL.createObjectURL(file);
        setImagePreview(urlImage);
        setSelectedLogo(file);
      }
    },
    [setError]
  );

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  useEffect(() => {
    reset({name: currentTeamName});
  }, [currentTeamName, reset]);

  const onUpload = useCallback(
    (event: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      if (hiddenImageInputRef.current) {
        hiddenImageInputRef.current.click();
      }
    },
    []
  );

  const mutationUpdateTeam = useMutationUpdateTeam(teamId, {
    onSuccess: () => {
      refetchTeam().then(() => {
        notify('Team updated successfully');
      });
    },
    onError: error => {
      if (error.response?.status === 400) {
        const serverErrors = error.response.data.errors as ErrorDetails[];
        serverErrors.forEach(err => {
          if (err.path === 'name') {
            setError('name', {type: 'manual', message: err.violation});
          } else if (err.path === 'logo') {
            setError('root', {
              type: 'manual',
              message: LOGO_REQUIREMENTS,
            });
            clearForm();
          } else {
            setError('root', {message: 'An error occurred: ' + err.violation});
          }
        });
      } else {
        setError('root', {message: 'Something went wrong. Please try again later.'});
      }
    },
  });

  const onSubmit = useCallback(
    async ({name}: FormProps) => {
      const nameEdited = currentTeamName !== name;
      const logoEdited = selectedLogo !== undefined;

      if (!nameEdited && !logoEdited) {
        return false;
      }

      const formData: {name?: string; logo?: File} = {};

      if (nameEdited) {
        try {
          await checkTeamNameUnique(name);
        } catch (error) {
          setError('name', {
            type: 'manual',
            message: 'Team name already in use.',
          });
          return false;
        }
        formData.name = name;
      }

      if (logoEdited) {
        formData.logo = selectedLogo;
      }

      mutationUpdateTeam.mutate(formData);
      return true;
    },
    [currentTeamName, mutationUpdateTeam, selectedLogo, setError]
  );

  return (
    <div>
      <form>
        <div className={Styles.accountSettings}>
          <HiddenInput type="file" onChange={handleUploadedFile} ref={hiddenImageInputRef} />
          <span onClick={onUpload} className={Styles.fileUpload}>
            <Avatar size="xxl" fullname={currentTeamName} avatarImage={imagePreview || logoURL} />
            <span className={Styles.editIcon}>
              <EditIcon />
            </span>
          </span>
          <div className={Styles.formWrapper}>
            <div className={Styles.editTeamName}>
              <div className={Styles.editTeamNameField}>
                <Label>Team name</Label>
                <Input
                  {...register('name', {required: 'Team name is required'})}
                  type="text"
                  placeholder="Enter your team name"
                  isInvalid={Boolean(errors.name)}
                  message={errors.name?.message}
                  messageClassName={Styles.errorMessage}
                />
                {errors.root && (
                  <Message message={errors.root.message || ''} hasError={true} className="py-1" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <Button
            variant="primary"
            loading={isSubmitting}
            disabled={currentTeamName === watch('name') && !selectedLogo}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

const HiddenInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
  <input type="file" style={{display: 'none'}} ref={ref} {...props} accept={ALLOWED_FORMATS.join(',')} />
));

HiddenInput.displayName = 'HiddenInput';
