import {useCallback} from 'react';
import {useIntercom} from 'react-use-intercom';
import {segmentTrack} from '../../utils';
import Styles from './ArticleCard.module.scss';
import {Article} from './types';

type Props = {
  article: Article;
};

export const ArticleCard = ({article}: Props) => {
  const {showArticle} = useIntercom();
  const {image, title, description, id} = article;

  const onClick = useCallback(() => {
    segmentTrack('Button Clicked', {
      label: 'select article',
      location: 'home page',
      title,
    });
    showArticle(id);
  }, [id, title, showArticle]);

  return (
    <article className={Styles.article}>
      <img src={image} alt={title} className={Styles.image} onClick={onClick} />
      <h2 className={Styles.title}>{title}</h2>
      <p className={Styles.description}>{description}</p>
    </article>
  );
};
