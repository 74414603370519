import cs from 'classnames';
import {LottieAnimation} from '../lottie-animation';
import Styles from './PulsingDot.module.scss';
import pulsingDot from './pulsingDot.json';

type Props = {
  className?: string;
};

export const PulsingDot = ({className}: Props) => (
  <LottieAnimation animationData={pulsingDot} className={cs(className, Styles.pulsingDot)} />
);
