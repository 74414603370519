type EventsMap = Record<string, unknown>;
type ErrorHandler = (error: unknown) => unknown;
export const customEventListener = <Event extends EventsMap, Key extends keyof Event = keyof Event>(
  eventName: string,
  onError: ErrorHandler = console.error
) => {
  type Callback<ExactKey extends Key> = (key: ExactKey, value: Event[ExactKey]) => void;

  const globalListeners: Callback<Key>[] = [];
  document.addEventListener(eventName, (event: unknown) => {
    if (!(event instanceof CustomEvent)) {
      onError(new Error('Invalid event type received on ' + eventName));
      return;
    }
    const {key, value} = event.detail;
    globalListeners.forEach(callback => callback(key, value));
  });

  type DetachListener = () => void;
  const addGlobalListener = (callback: Callback<Key>): DetachListener => {
    globalListeners.push(callback);

    return () => {
      const index = globalListeners.indexOf(callback);
      if (index !== -1) {
        globalListeners.splice(index, 1);
      }
    };
  };

  type ValueCallback<ExactKey extends Key> = (value: Event[ExactKey]) => unknown;
  const callbackKeyFilter =
    <ExactKey extends Key>(wantedKey: ExactKey, valueCallback: ValueCallback<ExactKey>) =>
    (key: Key, value: Event[Key]) => {
      if (key === wantedKey) {
        return valueCallback(value as Event[ExactKey]);
      }
      return undefined;
    };

  const addKeyListener = <ExactKey extends Key>(key: ExactKey, valueCallback: ValueCallback<ExactKey>) =>
    addGlobalListener(callbackKeyFilter(key, valueCallback));

  return {addKeyListener, addGlobalListener, callbackKeyFilter};
};
