import {FiAlertTriangle as AlertIcon} from 'react-icons/fi';
import {Role} from '../../../types';
import Styles from './AddMembersModal.module.scss';
import {RolesOverview} from './roles-overview';

type Props = {
  setSelectedRole: (role: Role) => void;
};
export const SelectRoleStep = ({setSelectedRole}: Props) => (
  <>
    <h4 className={Styles.header}>Add members</h4>
    <p className={Styles.subheader}>Compare and choose the right role to add to your team</p>
    <hr className={Styles.divider} />

    <RolesOverview setSelectedRole={setSelectedRole} />

    <div className={Styles.footer}>
      <AlertIcon color="#666565" size="18" />
      <div>
        <p className={Styles.learnMore}>
          Guests and Connectors are free on the Base plan.{' '}
          <a
            className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
            href="http://theswarm.com/pricing"
            target="_blank"
            rel="nofollow noreferrer"
          >
            See pricing
          </a>
          .
        </p>
        <p className={Styles.learnMore}>
          Connectors do not receive notifications nor invitations.{' '}
          <a
            className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
            href="https://help.theswarm.com/en/articles/7065679-what-are-the-different-roles-and-user-permissions"
            target="_blank"
            rel="nofollow noreferrer"
          >
            Learn more
          </a>
          .
        </p>
      </div>
    </div>
  </>
);
