import {TabLinks} from '../../../components/tabs';
import {AppLayout} from '../../../layouts';
import {PipelineCommonResponse} from '../../../types';
import {PipelineHeader} from '../components/PipelineHeader';
import {getTabLinksConfig} from '../getTabLinksConfig';
import {Details} from './Details';

type Props = {
  pipeline: PipelineCommonResponse;
};

export const PipelineDetails = ({pipeline}: Props) => {
  const {id} = pipeline;
  const tabLinksConfig = getTabLinksConfig(id);

  return (
    <AppLayout
      header={<PipelineHeader pipeline={pipeline} />}
      subHeader={<TabLinks tabs={tabLinksConfig} activeTab="Details" />}
    >
      <Details pipeline={pipeline} />
    </AppLayout>
  );
};
