import {useNavigate} from 'react-router-dom';
import {BffProfileInPipelineSuggestion} from '../../types';
import {getUserFullName} from '../../utils';
import {Avatar, AvatarCollection} from '../avatar';
import {Tooltip} from '../tooltip';
import {CREATOR} from './consts';
import {SuggestionIcon} from './SuggestionIcon';
import Styles from './SuggestionsIndicator.module.scss';

type PipelineSuggestionProps = {
  showPipelineTitle?: boolean;
  pipelines: BffProfileInPipelineSuggestion[];
};

export const PipelineSuggestionsIndicator = ({pipelines, showPipelineTitle}: PipelineSuggestionProps) => {
  return (
    <div className={Styles.suggestionsIndicator}>
      {pipelines.length > 0 && (
        <Tooltip
          content={<PipelineSuggestionsBubble showPipelineTitle={showPipelineTitle} pipelines={pipelines} />}
        >
          <SuggestionIcon creator={CREATOR.USER} />
        </Tooltip>
      )}
    </div>
  );
};

const PipelineSuggestionsBubble = ({showPipelineTitle, pipelines}: PipelineSuggestionProps) => {
  const navigate = useNavigate();
  const goToPipeline = (id: string) => {
    navigate(`/lists/${id}`);
  };

  return (
    <div className={Styles.suggestionBubble}>
      <div className={Styles.suggestionBubbleHeader}>
        <h3 className={Styles.headerBlue}>
          <SuggestionIcon creator={CREATOR.USER} white />
          added {showPipelineTitle ? 'to' : 'by'}
        </h3>
      </div>
      <div className={Styles.suggestionBubbleContent}>
        <ul className={Styles.SuggestedRoleList}>
          {pipelines.map(({id, name, suggestedBy}) => (
            <li key={id}>
              <div className={Styles.SuggestedRoleRow}>
                {showPipelineTitle && (
                  <span className={Styles.linkToPipeline} onClick={() => goToPipeline(id)}>
                    {name}
                  </span>
                )}
                <AvatarCollection size="xs" bgColor="black">
                  {suggestedBy.map((member, index) => (
                    <Avatar size="xs" key={index} fullname={getUserFullName(member)} />
                  ))}
                </AvatarCollection>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
