import {useEffect} from 'react';
import {LoadingSpinner} from '../../../components/animations';
import {useAuthenticated} from '../../../containers/Authenticated';
import {useGoToApp} from '../../../hooks';
import {AuthLayout} from '../../../layouts';
import {loginWithGoogle} from '../../../providers';
import {segmentPageview, segmentTrack} from '../../../utils';

export const AfterGoogleSignin = () => {
  const {authenticated: isAuthenticatedUser} = useAuthenticated();
  const goToApp = useGoToApp();
  useEffect(() => {
    if (isAuthenticatedUser) {
      segmentTrack('User Signed In with Google');
      goToApp();
      return () => {};
    }

    // we have issue with sign-up flow with google, that sometimes not login us correctly
    // (when we have already account with this email and we try to link it with google account)
    // in that case we try login again after 1s
    const loginTimeout = setTimeout(() => {
      loginWithGoogle();
    }, 1000);

    return () => clearTimeout(loginTimeout);
  }, [isAuthenticatedUser, goToApp]);

  useEffect(() => {
    segmentPageview({
      category: 'log_in',
      name: 'google_signin',
    });
  }, []);

  return (
    <AuthLayout>
      <div className="mx-auto my-6 w-16">
        <LoadingSpinner />
      </div>
    </AuthLayout>
  );
};
