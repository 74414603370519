import {GraduationCap, Suitcase, UserCircle, UserRectangle} from 'phosphor-react';
import {ReactNode} from 'react';
import {DataList, List} from '../../components/list';
import {PermissionChecker} from '../../components/permission';
import {TagsCloud} from '../../components/tags-cloud';
import {BffProfile, Permission} from '../../types';
import {RefreshProfileButton} from './RefreshProfileButton';
import Styles from './SingleProfilePageContent.module.scss';
import {useGetItems} from './useGetItems';

type ProfileDetailsProps = {
  profile: BffProfile;
};

export const ProfileDetails = ({profile}: ProfileDetailsProps) => {
  const {profileInfoItems, educationItems, experienceItems, contactItems} = useGetItems(profile);

  const {linkedinUrl, interests, skills} = profile.info;

  const contactSection = (
    <DataList
      items={contactItems}
      headerTitle="Contact"
      headerIcon={<UserRectangle />}
      titleWidth="120px"
      initialAmount={5}
      className={Styles.profileContentBlock}
    />
  );

  return (
    <div className={Styles.profileWidthContainer}>
      <div className={Styles.profileColumn}>
        <DataList
          items={profileInfoItems}
          headerTitle="Profile"
          headerIcon={<UserCircle />}
          emptyItemsPlaceholder="Unknown"
          itemsExcludedFromHiding={['Work email', 'Personal email']}
          hideEmptyItems
          className={Styles.profileContentBlock}
        />
        {educationItems.length > 0 && (
          <DataList
            items={educationItems}
            headerTitle="Education"
            headerIcon={<GraduationCap />}
            emptyItemsPlaceholder="Unknown"
            itemsExcludedFromHiding={['Work email', 'Personal email']}
            hideEmptyItems
            className={Styles.profileContentBlock}
          />
        )}
      </div>

      <div className={Styles.profileColumn}>
        <List
          items={experienceItems as ReactNode[]}
          headerTitle="Experience"
          headerIcon={<Suitcase />}
          headerSlot={<RefreshProfileButton profileAddress={linkedinUrl} />}
          itemClassName="border-b-0 pb-0 pt-1"
          initialAmount={4}
          className={Styles.profileContentBlock}
        />
      </div>

      <div className={Styles.profileColumn}>
        <PermissionChecker
          permission={Permission.ProfileContactDataRead}
          missingPlanFallback={contactSection}
        >
          {contactSection}
        </PermissionChecker>

        <DataList
          orientation="stacked"
          items={[
            {
              label: 'Interests',
              value: interests?.length && <TagsCloud items={interests} initialAmount={8} />,
            },
            {
              label: 'Skills',
              value: skills?.length && <TagsCloud items={skills} initialAmount={8} />,
            },
          ]}
          headerTitle="Skills & Interests"
          headerIcon={<UserRectangle />}
          hideEmptyItems
          className={Styles.profileContentBlock}
        />
      </div>
    </div>
  );
};
