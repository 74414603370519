import {UseFormSetValue} from 'react-hook-form';
import {createBulkSuggestions, createPipeline, updatePipeline} from '../../api';
import {PipelineType} from '../../types';
import {trackError} from '../../utils';
import {notify} from '../notifications';

type Props = {
  getValues: () => {
    pipelineType?: PipelineType;
    title: string;
    description: string;
    forwardableTemplate: string;
    privatePipeline: boolean;
  };
  setValue: UseFormSetValue<{
    pipelineType?: PipelineType;
    title: string;
    description: string;
    forwardableTemplate: string;
    privatePipeline: boolean;
    savedSearch: string;
  }>;
  teamId: string;
  onSubmit?: (pipelineId: string, pipelineTitle: string) => void;
  close: () => void;
  pipeline?: {
    id: string;
    savedSearch: string;
  };
  profileIDs?: string[];
};

export const usePipelineForm = ({
  getValues,
  setValue,
  teamId,
  onSubmit,
  close,
  pipeline,
  profileIDs,
}: Props) => {
  const pipelineType = [
    {
      label: PipelineType.recruiting,
      value: PipelineType.recruiting,
      onChange: () => setValue('pipelineType', PipelineType.recruiting),
    },
    {
      label: PipelineType.sales,
      value: PipelineType.sales,
      onChange: () => setValue('pipelineType', PipelineType.sales),
    },
    {
      label: PipelineType.fundraising,
      value: PipelineType.fundraising,
      onChange: () => setValue('pipelineType', PipelineType.fundraising),
    },
    {
      label: PipelineType.other,
      value: PipelineType.other,
      onChange: () => setValue('pipelineType', PipelineType.other),
    },
  ];

  const handleCreatePipeline = () => {
    const {pipelineType, title, description, forwardableTemplate, privatePipeline} = getValues();

    if (!pipelineType) {
      notify('Please select a list type.');
      return;
    }

    createPipeline(teamId, {
      pipelineType,
      title,
      descriptionUrl: description,
      forwardableTemplate,
      private: privatePipeline,
      savedSearch: '{}',
    })
      .then(({id: pipelineId}) => {
        onSubmit?.(pipelineId, title);
        close();
        notify('List created successfully.');
        if (profileIDs?.length) {
          createBulkSuggestions(pipelineId, {profileIDs});
        }
      })
      .catch(e => {
        notify('Unable to create list.');
        trackError(e);
      });
  };

  const handleEditPipeline = () => {
    const {pipelineType, title, description, forwardableTemplate, privatePipeline} = getValues();

    if (!pipelineType) {
      notify('Please select a list type.');
      return;
    }

    updatePipeline(
      {
        pipelineType: pipelineType,
        title,
        descriptionUrl: description,
        forwardableTemplate,
        private: privatePipeline,
        savedSearch: pipeline?.savedSearch || '{}',
      },
      pipeline?.id as string
    )
      .then(value => {
        onSubmit?.(value.id, value.title);
        close();
        notify('List edited successfully.');
      })
      .catch(e => {
        notify('Unable to edit list.');
        trackError(e);
      });
  };

  return {
    pipelineType,
    handleCreatePipeline,
    handleEditPipeline,
  };
};
