import {Plan, Role} from '../../types';

export function createElement(tag: string, innerHtml: string) {
  const element = document.createElement(tag);
  element.innerHTML = innerHtml;
  return element;
}

export function addElementsTo(selector: string, elements: Array<Element>) {
  document.querySelector(selector)?.prepend(...elements);
}
export type UserAnalyticsData = {
  email: string;
  name: string;
  id: string;
  teamId: string | undefined;
  role?: Role | 'solo user';
  userTeams?: string;
  linkedinUrl?: string;
  connectionsCount?: number;
  intercomHash: string;
};

export type TeamGroupAnalyticsData = {
  id: string;
  name: string;
  membersCount: number;
  plan?: Plan;
};

export type RoleAnalyticsData = {
  role: Role;
};

export type EventFunction = (event: string, data?: Record<string, unknown>) => void;
