import {useEffect, useState} from 'react';
import {VerifyLinkedinProfilesResponse} from '../../../../types';
import {segmentTrack} from '../../../../utils';
import {ConfirmationScreen} from './ConfirmationScreen';
import {FormScreen} from './FormScreen';

type Props = {
  goBack: () => void;
  onSuccess: () => void;
};
export const AddConnectorsStep = ({goBack, onSuccess}: Props) => {
  const [verificationResult, setVerificationResult] = useState<VerifyLinkedinProfilesResponse>();

  const clearVerificationResults = () => {
    setVerificationResult(undefined);
  };

  useEffect(() => {
    segmentTrack('Modal Viewed', {
      label: 'add connectors',
      location: 'add members modal',
    });
  }, []);

  return verificationResult ? (
    <ConfirmationScreen
      verificationResult={verificationResult}
      onSuccess={onSuccess}
      goBack={clearVerificationResults}
    />
  ) : (
    <FormScreen onSuccess={setVerificationResult} goBack={goBack} />
  );
};
