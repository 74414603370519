import {useLocation} from 'react-router-dom';
import {NavItem} from './NavItem';
import Styles from './Navigation.module.scss';
import {useNavItems} from './useNavItems.hook';

export const Navigation = () => {
  const navItems = useNavItems();
  const {pathname} = useLocation();
  const isCurrentPath = (path: string) => (path === '/' ? pathname === path : pathname.startsWith(path));

  return (
    <div className={Styles.navigation}>
      {navItems.map(({label, icon, path, isLocked}) => (
        <NavItem
          key={label}
          label={label}
          icon={icon}
          path={path}
          isActive={isCurrentPath(path)}
          locked={isLocked}
        />
      ))}
    </div>
  );
};
