import {useEffect, useState} from 'react';

/**
 * Usage:
 * const [phrase, setPhrase] = useState('');
 * const debouncedPhrase = useDebounce(phrase, 500);
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
