import cs from 'classnames';
import {PipelineStatus} from '../../types';
import {statuses} from '../../utils';
import {Pill} from '../pill';
import Styles from './StatusTag.module.scss';

type Props = {
  value: PipelineStatus;
  small?: boolean;
};

export const StatusTag = ({value, small}: Props) => {
  const status = statuses.find(el => el.value === value);

  return (
    <div className={cs(Styles.statusTag, {[Styles.small]: small})}>
      <Pill color={status?.color} className={Styles.tagPill}>
        {status?.label}
      </Pill>
    </div>
  );
};
