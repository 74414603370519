import cs from 'classnames';
import {ReactNode} from 'react';
import Styles from './AuthBox.module.scss';

type Props = {
  children: ReactNode;
  aside?: ReactNode;
  headerImage?: ReactNode;
  headerTitle?: ReactNode;
  headerSubtitle?: ReactNode;
  headerDescription?: ReactNode;
  footer?: ReactNode;
  flexible?: boolean;
};

export const AuthBox = ({
  children,
  aside,
  headerImage,
  headerTitle,
  headerSubtitle,
  headerDescription,
  footer,
  flexible,
}: Props) => {
  return (
    <div className={cs(Styles.wrapper, {[Styles.wrapperFlexible]: flexible})}>
      {aside && <aside className={Styles.aside}>{aside}</aside>}
      <div className={cs(Styles.main, {[Styles.mainFlexible]: flexible})}>
        <div className={Styles.card}>
          <div className={Styles.header}>
            {headerImage && <div className={Styles.headerImage}>{headerImage}</div>}
            {headerTitle && <h2 className={Styles.headerTitle}>{headerTitle}</h2>}
            {headerSubtitle && <h3 className={Styles.headerSubtitle}>{headerSubtitle}</h3>}
            {headerDescription && <p className={Styles.headerDescription}>{headerDescription}</p>}
          </div>
          {children}
        </div>
        {footer && <footer className={Styles.footer}>{footer}</footer>}
      </div>
    </div>
  );
};
