import {UserFocus} from 'phosphor-react';
import Styles from './EmptyPipelinesListInfo.module.scss';

export const EmptyPipelinesListInfo = () => {
  return (
    <div className={Styles.emptyInfoWrapper}>
      <UserFocus size={40} weight="light" />
    </div>
  );
};
