import {Sort} from './common';
import {ErrorResponse} from './errors';
import {Permission} from './permission';
import {PlanType} from './plan';
import {TagColors} from './tag';
import {Membership} from './team';

// common User type from swarm-api
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isAnonymized?: boolean;
};

export type TeamUser = User & {
  role: Role;
};

export enum PipelineStatus {
  suggested = 'suggested',
  introRequested = 'introRequested',
  inContact = 'inContact',
  meetingSet = 'meetingSet',
  removed = 'removed',
}

export type GetPipelinesResponseItem = {
  pipeline: PipelineCommonResponse;
  profilesCount: number;
};

export enum PipelineType {
  recruiting = 'recruiting',
  sales = 'sales',
  fundraising = 'fundraising',
  other = 'other',
}
export type GetPipelinesResponse = {
  items: GetPipelinesResponseItem[];
  teamId: string;
  meta: SearchMeta;
};
export type GetPipelinesRequest = {
  sort?: Sort;
} & Page;

export type PipelineCommonResponse = {
  id: string;
  title: string;
  descriptionUrl: string;
  pipelineType: PipelineType;
  creator: User;
  teamId: string;
  createdAt: string;
  updatedAt: string;
  forwardableTemplate: string;
  private: boolean;
  savedSearch: string;
};

export type CreatePipelineRequest = {
  title: string;
  descriptionUrl: string;
  pipelineType: PipelineType;
  forwardableTemplate?: string;
  private: boolean;
  savedSearch: string;
};

export type NotesCount = {
  total: number;
  private: number;
  team: number;
};

// SUGGESTIONS

type Creator = {
  creatorId: string;
  creator: User;
};

export type ProfileInPipelinesSuggestion = {
  id: string;
  title: string;
  teamId: string;
  containProfile: boolean;
  status: PipelineStatus | '';
  suggestions: Creator[];
  private: boolean;
};

export type Source = {
  origin: string;
  network: string;
};

export type ScoreValue = 0 | 1 | 2 | 3;

export type UpdateProfileRequest = {
  status: PipelineStatus;
};

export type ProfileInPipelinesSuggestions = ProfileInPipelinesSuggestion[];

export type CreateBulkSuggestionsInput = {
  profileIDs: string[];
};

export enum InviteStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum Role {
  Owner = 'owner',
  Admin = 'admin',
  Contributor = 'contributor',
  Guest = 'guest',
  Connector = 'connector',
}

export type Invite = {
  created_at: string;
  creator_id: string;
  invite_email: string;
  invite_id: string;
  invite_role: Role;
  invite_status: InviteStatus;
  team_id: string;
  updated_at: string;
  team_name?: string;
  creator_name?: string;
};

export type Member = {
  id: string;
  user: TeamUser;
  teamId: string;
};
export type MemberListItem = Member & {
  connectionCount: number;
  profileId?: string;
  linkedInUrl?: string;
};

export type SearchMeta = Page & {
  totalCount: number;
};

export enum TaskStatus {
  StatusCreated = 'StatusCreated',
  StatusEnqueued = 'StatusEnqueued',
  StatusRunning = 'StatusRunning',
  StatusDone = 'StatusDone',
}

export enum TaskResult {
  OK = 'OK',
  Failed = 'Failed',
}

export enum TaskDataProvider {
  Google = 'Google',
  LinkedInCSV = 'LinkedInCSV',
  LinkedInPlugin = 'LinkedInPlugin',
  WorkOverlaps = 'WorkOverlaps',
  UserProfile = 'UserProfile',
}

export type Task = {
  accountId: string;
  createdAt: string;
  description: string;
  progress: {
    enriched: number;
    skipped: number;
    total: number;
  };
  result: TaskResult;
  status: TaskStatus;
  dataProvider: TaskDataProvider;
  taskId: string;
  updatedAt: string;
};

export type GetTasksResponse = Task[];

export type UserSessionData = User & {
  linkedInUrl: string;
  memberships: MembershipWithPermission[];
  intercomHash: string;
};

export type PermissionAccess = {
  permission: Permission;
  allowed: boolean;
  availableInRole: Role[];
  availableInPlan: PlanType[];
};

type MembershipWithPermission = Membership & {
  permissionAccesses: PermissionAccess[];
};

// share-network invitations
export type GetInvitationsResponse = {
  invitations: ShareNetworkInvitation[];
};
export type SendInvitationResponse = {
  invitations: ShareNetworkInvitation[];
};

export type ShareNetworkInvitation = {
  id: string;
  userId: string;
  inviteeEmail: string;
  note: string;
  validUntil: string;
  createdAt: string;
  updatedAt: string;
  user?: User;
};

/** @see {isShareNetworkInvitationWithUser} ts-auto-guard:type-guard */
export type ShareNetworkInvitationWithUser = Required<ShareNetworkInvitation>;

export type SendInvitationRequest = {
  inviteeEmails: string[];
  note: string;
};

export type SendInvitationRequestError = ErrorResponse;

type Page = {
  offset: number;
  limit: number;
};

export type GetNotesResponse = {
  items: NoteResponse[];
  meta: SearchMeta;
};

export type GetNotesRequest = {
  teamId?: string;
  profileId: string;
  limit: number;
  offset: number;
};

export type GetNotesCountRequest = {
  teamId?: string;
  profileId: string;
};

export type GetNotesCountResponse = NotesCount & {
  profileId: string;
};

export type CreateNoteRequest = {
  content: string;
  profileId: string;
  teamId?: string;
};

export type EditNoteRequest = {
  content: string;
};
export type NoteResponse = {
  content: string;
  profileId: string;
  teamId?: string;
  creator: User;
  id: string;
  updatedAt: string;
  createdAt: string;
};

export type GetTagsListRequest = {
  sort?: string[];
  query?: string;
};

export type GetTagsResponse = {
  items: TagListItem[];
  meta: SearchMeta;
};

export type TagItem = {
  id: string;
  name: string;
  color: TagColors;
  description: string;
  creator: User;
  createdAt: string;
  updatedAt: string;
};

export type TagListItem = {
  tag: TagItem;
  count: {
    profile: number;
    company: number;
  };
};

export type GetTagsSimpleResponse = {
  items: TagSimple[];
};

export type TagSimple = {
  id: string;
  name: string;
  color: TagColors;
};

export type CreateTagInput = {
  name: string;
  color: string;
  description?: string;
};

export type AssignTagRequestParams = {
  targetIds: string[];
  targetType: 'company' | 'profile';
};

export const enum SocialMediaEnum {
  aboutme = 'aboutme',
  angellist = 'angellist',
  behance = 'behance',
  crunchbase = 'crunchbase',
  dribbble = 'dribbble',
  ello = 'ello',
  facebook = 'facebook',
  flickr = 'flickr',
  foursquare = 'foursquare',
  github = 'github',
  gitlab = 'gitlab',
  google = 'google',
  gravatar = 'gravatar',
  indeed = 'indeed',
  instagram = 'instagram',
  klout = 'klout',
  linkedin = 'linkedin',
  meetup = 'meetup',
  myspace = 'myspace',
  pinterest = 'pinterest',
  quora = 'quora',
  reddit = 'reddit',
  soundcloud = 'soundcloud',
  stackoverflow = 'stackoverflow',
  twitter = 'twitter',
  vimeo = 'vimeo',
  wordpress = 'wordpress',
  xing = 'xing',
  youtube = 'youtube',
}

export type VerifyLinkedinProfilesInput = {
  linkedinUrls: string[];
};

export type VerifiedProfile = {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
};

export type VerifyLinkedinProfilesResponse = {
  success: VerifiedProfile[];
  notFound: string[];
  alreadyInTeam: string[];
};

export type AddConnectorsInput = {
  profilesIds: string[];
};
