import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {useCallback, useEffect, useState} from 'react';
import {FiX as ClearIcon} from 'react-icons/fi';
import {HiMiniViewColumns as ColumnsIcon} from 'react-icons/hi2';

import {segmentTrack} from '../../utils';
import {Button, ButtonsGroup} from '../button';
import {Dropdown} from '../dropdown';
import {DropdownItemsGroup} from '../dropdown/DropdownItemsGroup';
import {ColumnSettingsItem} from './ColumnSettingsItem';

type Columns<ColumnType> = ColumnType[];

type ColumnSettings<ColumnType> = {
  visibleColumns: ColumnType[];
  columnsOrder: ColumnType[];
};

type Props<ColumnType extends string> = {
  fixedColumns?: Columns<ColumnType>;
  labelsMap?: Record<ColumnType, string>;
  columnSettings: ColumnSettings<ColumnType>;
  setColumnSettings: (settings: ColumnSettings<ColumnType>) => void;
  resetColumnSettings?: () => void;
};

export const ColumnSettingsDropdown = <ColumnType extends string>({
  fixedColumns,
  labelsMap,
  columnSettings,
  setColumnSettings,
  resetColumnSettings,
}: Props<ColumnType>) => {
  type ColumnsSettingsType = ColumnSettings<ColumnType>;
  const [isOpen, setIsOpen] = useState(false);
  const [activeId, setActiveId] = useState<ColumnType | null>(null);
  const [localColumnSettings, setLocalColumnSettings] = useState<ColumnsSettingsType>(columnSettings);

  useEffect(() => {
    setLocalColumnSettings(columnSettings);
  }, [columnSettings]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const setStates = useCallback(
    (newColumnSettings: ColumnsSettingsType) => {
      setLocalColumnSettings(newColumnSettings);
      setColumnSettings(newColumnSettings);
    },
    [setColumnSettings]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    setActiveId(null);
    if (active.id !== over?.id) {
      const currentColumnsOrder = localColumnSettings.columnsOrder;
      const newColumnsOrder = arrayMove(
        currentColumnsOrder,
        currentColumnsOrder.indexOf(active.id as ColumnType),
        currentColumnsOrder.indexOf(over?.id as ColumnType)
      );
      setStates({
        ...localColumnSettings,
        columnsOrder: newColumnsOrder,
      });
      segmentTrack('Item Reordered', {
        label: 'edit columns',
        column: (labelsMap?.[active.id as ColumnType] || (active.id as ColumnType)).toLowerCase(),
      });
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as ColumnType);
  };

  const checkItem = useCallback(
    (column: ColumnType) => {
      const columnName = (labelsMap?.[column] || column).toLowerCase();
      if (localColumnSettings.visibleColumns.includes(column)) {
        setStates({
          ...localColumnSettings,
          visibleColumns: localColumnSettings.visibleColumns.filter(col => col !== column),
        });
        segmentTrack('Selection Removed', {
          label: 'edit columns',
          column: columnName,
        });
      } else {
        setStates({
          ...localColumnSettings,
          visibleColumns: [...localColumnSettings.visibleColumns, column],
        });
        segmentTrack('Item Selected', {
          label: 'edit columns',
          column: columnName,
        });
      }
    },
    [localColumnSettings, setStates, labelsMap]
  );

  const onClear = useCallback(() => {
    resetColumnSettings?.();
    segmentTrack('Selector Cleared', {
      label: 'edit columns',
    });
  }, [resetColumnSettings]);

  const selectableColumns = localColumnSettings.columnsOrder.filter(
    column => !fixedColumns?.includes(column)
  );

  return (
    <ButtonsGroup>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderDropdownTrigger={() => (
          <Button
            variant="secondary"
            onClick={() => setIsOpen(!isOpen)}
            icon={<ColumnsIcon />}
            chevron="down"
            positionInGroup={resetColumnSettings ? 'first' : undefined}
          >
            Edit columns
          </Button>
        )}
        tracking={{label: 'edit columns'}}
      >
        {fixedColumns && (
          <DropdownItemsGroup title="fixed columns">
            {fixedColumns.map(columnAccessorKey => (
              <Dropdown.Item
                key={columnAccessorKey}
                label={labelsMap?.[columnAccessorKey] || columnAccessorKey}
                noHover
                type={{
                  name: 'button',
                  onClick: () => {},
                  disabled: true,
                }}
              />
            ))}
          </DropdownItemsGroup>
        )}
        <DropdownItemsGroup title="active columns">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext items={selectableColumns} strategy={verticalListSortingStrategy}>
              {selectableColumns.map(columnAccessorKey => (
                <ColumnSettingsItem
                  key={columnAccessorKey}
                  id={columnAccessorKey}
                  label={labelsMap?.[columnAccessorKey] || columnAccessorKey}
                  onClick={() => checkItem(columnAccessorKey)}
                  checked={localColumnSettings.visibleColumns.includes(columnAccessorKey)}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeId ? (
                <ColumnSettingsItem
                  id={activeId}
                  label={labelsMap?.[activeId] || activeId}
                  onClick={() => checkItem(activeId)}
                  checked={localColumnSettings.visibleColumns.includes(activeId)}
                  active
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </DropdownItemsGroup>
      </Dropdown>
      {resetColumnSettings && <Button variant="secondary" icon={<ClearIcon />} iconOnly onClick={onClear} />}
    </ButtonsGroup>
  );
};
