import {FiCheck as CheckIcon} from 'react-icons/fi';
import {BaseMark, BaseMarkProps} from './BaseMark';

type Props = Pick<BaseMarkProps, 'size' | 'shape' | 'className'>;

export const CheckMark = ({size, shape, className}: Props) => (
  <BaseMark
    shape={shape}
    size={size}
    bgColor="#3DC583"
    iconColor="#FFF"
    icon={<CheckIcon />}
    className={className}
  />
);
