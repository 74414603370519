import {Dialog} from '@headlessui/react';
import {FileCsv} from 'phosphor-react';
import {useCallback} from 'react';
import {FiArrowRight as ArrowRight} from 'react-icons/fi';
import {Button} from '../../button';
import {Card} from '../../card';
import {trackCancelButtonOnStrategyView, trackStartImportClick} from '../analytics.utils';
import illustration from './chrome-extension-instruction-started.png';
import {extensionUrl} from '../../../utils';

type Props = {
  onClose: () => unknown;
  goToManual: () => unknown;
};

export const ImportConnectionsChromeExtension = ({onClose, goToManual}: Props) => {
  const onClickInstallChromeExtension = useCallback(() => {
    onClose();
    trackStartImportClick('plugin');
  }, [onClose]);

  const onCancelButtonClick = useCallback(() => {
    onClose();
    trackCancelButtonOnStrategyView('plugin');
  }, [onClose]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        Install our Chrome Extension
      </Dialog.Title>

      <Dialog.Description className="mb-4 mt-2 font-normal">
        Automatically fetch and import your LinkedIn connections.
      </Dialog.Description>

      <img src={illustration} alt="Chrome Extension screenshot" className="mb-4 h-[321px] w-full" />

      <ul className="list-decimal space-y-2 pl-5 font-normal leading-6">
        <li>
          Install the extension{' '}
          <a href={extensionUrl} target="_blank" rel="noreferrer" className="text-swarm-black underline">
            here
          </a>
          . Make sure to keep Swarm open.
        </li>
        <li>
          Log in to LinkedIn in a new tab and your connections will be automatically fetched. The import will
          start automatically after 30-60 seconds.
        </li>
        <li>You may close LinkedIn once the extension shows “Import Started”.</li>
        <li>
          Go back to The&nbsp;Swarm and refresh your browser, you’ll start seeing connections appearing in
          batches.
        </li>
      </ul>

      <Card
        className="mt-8 flex cursor-pointer flex-row items-center gap-2 bg-swarm-gray-50 p-4 transition hover:bg-swarm-gray-100"
        onClick={goToManual}
      >
        <FileCsv size={24} className="text-swarm-black" />
        <span className="grow">Import LinkedIn connections manually via CSV upload</span>
        <ArrowRight size={20} className="text-swarm-black" />
      </Card>

      <div className="mt-5 flex justify-between">
        <Button onClick={onCancelButtonClick}>Cancel</Button>
        <Button variant="primary" href={extensionUrl} target="_blank" onClick={onClickInstallChromeExtension}>
          Install Chrome Extension
        </Button>
      </div>
    </>
  );
};
