import cs from 'classnames';
import {uniqBy} from 'lodash';
import {BffCompany} from '../../../types/bffTypes';
import {CompanyLogo} from '../../../components/company-logo';
import {PermissionChecker} from '../../../components/permission';
import {ProfileTagsList} from '../../../components/tags';
import {Vcard} from '../../../components/vcard';
import {VcardIntegrationsList} from '../../../components/vcard-integrations-list';
import {Crm, Permission, SocialMediaEnum, TagSimple} from '../../../types';
import Styles from './CompanyData.module.scss';
import {DataItem} from './data-item';
import {getCompanyInfo, getCompanyProfiles} from './staticCompanyData';

type Props = {
  company: BffCompany;
  tags: TagSimple[];
  crms: Crm[];
  onAssignTagSuccess?: (tag: TagSimple) => void;
  onUnassignTagSuccess?: (tagId: string) => void;
};

export const CompanyData = ({company, crms, tags, onAssignTagSuccess, onUnassignTagSuccess}: Props) => {
  const {id, name = '', industry = '', locationName, networks, logoURL} = company;

  const uniqueSocialProfiles = uniqBy(networks, 'network');

  const vcardProfiles = uniqueSocialProfiles.map(({network = '', url = ''}) => ({
    network: network as SocialMediaEnum,
    url,
  }));

  const companyInfo = getCompanyInfo(company);
  const companyProfiles = getCompanyProfiles(company);

  return (
    <div className={cs(Styles.profileWidthContainer, Styles.profileHeader)}>
      <div className={cs(Styles.profileColumn, Styles.profileColumnWithLogo)}>
        <Vcard
          fullName={name}
          specialization={industry}
          where={locationName}
          profiles={vcardProfiles}
          avatar={<CompanyLogo logoUrl={logoURL} companyName={name} companyId={id} size={88} />}
          icons={
            <PermissionChecker permission={Permission.CRM}>
              <VcardIntegrationsList crms={crms} />
            </PermissionChecker>
          }
        />
        <ProfileTagsList
          targetId={company.id}
          targetType="company"
          tags={tags}
          onAssignSuccess={onAssignTagSuccess}
          onUnassignSuccess={onUnassignTagSuccess}
          className="mt-4"
        />
      </div>
      <div className={Styles.profileColumn}>
        {companyInfo.map(({label, value}) => (
          <DataItem label={label} content={value} key={label} />
        ))}
      </div>
      <div className={Styles.profileColumn}>
        {companyProfiles.map(({label, value}, index) => (
          <DataItem label={label} content={value} key={index} />
        ))}
      </div>
    </div>
  );
};
