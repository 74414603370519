import {useContext} from 'react';
import {ConfirmContext} from '../contexts/ConfirmationContext';

export const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext);

  const isConfirmed = (prompt: string, actionType?: 'removal' | 'info') => {
    const confirmation = {
      prompt,
      actionType,
      proceed: () => {},
      cancel: () => {},
      isOpen: true,
    };
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        ...confirmation,
        proceed: resolve,
        cancel: reject,
      });
    });
    return promise
      .then(
        () => true,
        () => false
      )
      .catch(() => false)
      .finally(() => {
        setConfirm({
          ...confirmation,
          isOpen: false,
        });
      });
  };

  return {
    ...confirm,
    isConfirmed,
  };
};
