import {Dialog} from '@headlessui/react';
import {LoadingProgressBar, ProgressProcessing} from '../animations';
import {Button} from '../button';

type Props = {
  description?: string;
  closeText?: string;
  onClose?: () => unknown;
};

export const SuccessProgressScreen = ({
  description = 'Processing... please wait',
  onClose,
  closeText,
}: Props) => {
  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        Awesome, you're almost there!
      </Dialog.Title>
      <Dialog.Description className="my-4 font-normal">{description}</Dialog.Description>

      <div className="text-center">
        <ProgressProcessing className="mx-2 inline-block w-96" />
      </div>

      <LoadingProgressBar className="mb-6 mt-4" />

      {onClose && (
        <>
          <Dialog.Description className="my-4 items-center text-center font-normal">
            You can close this window. <br />
            {closeText}
          </Dialog.Description>

          <Button onClick={onClose} variant="tertiary" outline className="mx-auto">
            Close
          </Button>
        </>
      )}
    </>
  );
};
