import {ReactElement} from 'react';
import {usePermissionWithReason} from '../../hooks';
import {PaywallClickBlocker} from '../paywall-click-blocker';
import {PermissionCheckerProps} from '../permission';

type Props = Pick<PermissionCheckerProps, 'children' | 'permission'>;

export const PremiumFeatureClickWrapper = ({permission, children}: Props) => {
  const permissionWithReason = usePermissionWithReason(permission);

  if (permissionWithReason.hasPermission || permissionWithReason.reason !== 'plan') {
    return children as ReactElement;
  }

  return <PaywallClickBlocker>{children}</PaywallClickBlocker>;
};
