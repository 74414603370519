import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate, useParams} from 'react-router-dom';
import {useQueryPipeline} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {Button} from '../../components/button';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {useCurrentTeam} from '../../hooks';
import {AppLayout} from '../../layouts';
import {SavedSearch} from './saved-search';

export const PipelineSavedSearchPage = () => {
  const {id = ''} = useParams();
  const {id: teamId} = useCurrentTeam();
  const navigate = useNavigate();
  const {data: pipeline, isLoading, refetch: refetchPipeline} = useQueryPipeline(id, teamId);

  useEffect(() => {
    if (!isLoading && !pipeline) {
      navigate('/lists');
    }
  }, [navigate, isLoading, pipeline]);

  if (isLoading)
    return (
      <AppLayout header={<PageHeader hasBottomSeparator></PageHeader>}>
        <LoadingSpinner size="small" color="black" centered />
      </AppLayout>
    );

  if (!pipeline) {
    return (
      <>
        <Helmet>
          <title>List not found - The Swarm</title>
        </Helmet>
        <AppLayout>
          <NoResults heading="This list does not exist." page="error">
            <Button variant="secondary" onClick={() => navigate('/lists')}>
              Go to Lists
            </Button>
          </NoResults>
        </AppLayout>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${pipeline.title} - The Swarm`}</title>
      </Helmet>
      <SavedSearch pipeline={pipeline} refetchPipeline={refetchPipeline} />
    </>
  );
};
