import {useCallback, useState} from 'react';
import {useOnboardingContext} from '../../../../contexts';
import {CreateTeamScreen, JoinTeamScreen} from './screens';

type Props = {
  invitationAvailable?: boolean;
};

enum TeamScreens {
  JoinTeamScreen = 'JoinTeamScreen',
  CreateTeamScreen = 'CreateTeamScreen',
}

export const NewTeamStep = ({invitationAvailable = false}: Props) => {
  const {onFinishStep} = useOnboardingContext();
  const [currentView, setCurrentView] = useState<TeamScreens>(
    invitationAvailable ? TeamScreens.JoinTeamScreen : TeamScreens.CreateTeamScreen
  );

  const goToCreateTeamScreen = useCallback(() => setCurrentView(TeamScreens.CreateTeamScreen), []);

  switch (currentView) {
    case TeamScreens.JoinTeamScreen:
      return <JoinTeamScreen onSuccess={onFinishStep} goToCreateTeam={goToCreateTeamScreen} />;
    case TeamScreens.CreateTeamScreen:
      return <CreateTeamScreen onFinish={onFinishStep} />;
    default:
      return <>Invalid screen</>;
  }
};
