import {groupBy} from 'lodash';
import {ReactNode} from 'react';
import {Pill} from '../../components/pill';
import {PremiumFeatureWrapper} from '../../components/premium-feature-wrapper';
import {BffProfile, Permission, SocialMediaEnum} from '../../types';
import {addHttpsToUrl, capitalizeString} from '../../utils';
import Styles from './SingleProfilePageContent.module.scss';
import {WorkExperience, WorkExperienceGrouped} from './WorkExperience';

export const useGetItems = (profile: BffProfile) => {
  const {info, currentJob, education, experience} = profile;

  const profileInfoItems = [
    {
      label: 'Current job title',
      value: currentJob?.title,
    },
    {
      label: 'Seniority',
      value: currentJob?.titleLevels?.join(', '),
    },
    {
      label: 'Industry',
      value: info.industry,
    },
    {
      label: 'Location',
      value: info.locationName,
    },
  ];

  const educationItems = education.map(({school, startDate, endDate, degrees, majors}) => ({
    label: 'School',
    value: (
      <div className={Styles.educationItem}>
        {school?.website ? (
          <a href={`http://${school.website}`} target="_blank" rel="noreferrer" className="underline">
            {school.name}
          </a>
        ) : (
          <p>{school?.name}</p>
        )}
        <p>
          {degrees?.join(', ')}
          {degrees && majors && ', '}
          {majors?.join(', ')}
        </p>
        <p className="text-swarm-gray-700">
          {startDate} - {endDate}
        </p>
      </div>
    ),
  }));

  const groupedExperience = groupBy(experience, 'company.name');

  const experienceItems = Object.entries(groupedExperience).map(([companyName, experiences]) =>
    experiences.length > 1 ? (
      <WorkExperienceGrouped
        companyName={companyName}
        companyId={experiences[0].company?.id}
        experiences={experiences}
        key={companyName}
      />
    ) : (
      <WorkExperience {...experiences[0]} key={companyName} />
    )
  );

  const notEmptySocialProfiles = info.profiles?.filter(({url}) => url);

  const contactItems = [
    {
      label: 'Personal email',
      value: info.personalEmails?.length ? (
        <ContactEmailPaywall>
          <a href={`mailto:${info.personalEmails[0]}`} className="ml-2 block text-swarm-black underline">
            {info.personalEmails[0]}
          </a>
        </ContactEmailPaywall>
      ) : (
        <span className="ml-2 text-swarm-gray-700">Unknown</span>
      ),
    },
    {
      label: 'Work email',
      value: info.workEmail ? (
        <ContactEmailPaywall>
          <a href={`mailto:${info.workEmail}`} className="ml-2 text-swarm-black underline">
            {info.workEmail}
          </a>
        </ContactEmailPaywall>
      ) : (
        <span className="ml-2 text-swarm-gray-700">Unknown</span>
      ),
    },
    ...(notEmptySocialProfiles?.map(({network = '', url = ''}) => ({
      label: capitalizeString(network as SocialMediaEnum),
      value: (
        <a
          href={addHttpsToUrl(url)}
          target="_blank"
          rel="noreferrer"
          className="ml-2 text-swarm-black underline"
        >
          View profile
        </a>
      ),
    })) || []),
  ];

  return {
    profileInfoItems,
    educationItems,
    experienceItems,
    contactItems,
  };
};

const ContactEmailPaywall = ({children}: {children: ReactNode}) => (
  <PremiumFeatureWrapper
    permission={Permission.ProfileContactDataRead}
    featureName="contact emails"
    fallback={<Pill color="yellow">Upgrade to unlock</Pill>}
    location="profile contact data"
  >
    {children}
  </PremiumFeatureWrapper>
);
