import {Tab} from '@headlessui/react';
import cs from 'classnames';
import {useNavigate} from 'react-router-dom';
import {segmentTrack} from '../../utils';
import {Tooltip} from '../tooltip';
import Styles from './Tabs.module.scss';
import {TabLinkItem} from './types';

type Props = {
  tabs: TabLinkItem[];
  activeTab: string;
};

export const TabLinks = ({tabs, activeTab}: Props) => {
  const navigate = useNavigate();

  const handleOnClick = (path: string | undefined, title: string) => {
    if (path) {
      navigate(path);
    }

    segmentTrack('Tab Switched', {
      label: title.toLowerCase(),
    });
  };

  return (
    <div className={cs(Styles.wrapper, Styles.wrapperLinks)}>
      <Tab.Group>
        <Tab.List className={cs(Styles.tabsList, Styles.tabsListInPage)}>
          {tabs.map(tab => (
            <TabsListItem
              key={tab.title}
              onClick={() => handleOnClick(tab.path, tab.title)}
              tab={tab}
              selected={activeTab === tab.title}
            />
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};

type TabsListItemProps = {
  tab: TabLinkItem;
  onClick: () => void;
  selected?: boolean;
};
const TabsListItem = ({tab, onClick, selected}: TabsListItemProps) => {
  const {title, disabled, badge, tooltip} = tab;

  const label = (
    <>
      {title}
      {badge}
    </>
  );

  const labelWithTooltip = tooltip ? <Tooltip content={tooltip}>{label}</Tooltip> : label;

  return (
    <Tab
      onClick={onClick}
      className={cs(Styles.tab, {
        [Styles.selectedTab]: selected,
        [Styles.unselectedTab]: !selected,
        [Styles.disabledTab]: disabled,
      })}
      disabled={disabled}
    >
      {labelWithTooltip}
    </Tab>
  );
};
