import cs from 'classnames';
import {useCallback, useState} from 'react';
import {FiCheck as CheckIcon, FiChevronDown as ChevronDown} from 'react-icons/fi';
import {updateStatus} from '../../api';
import {usePremiumFeatureCallback} from '../../hooks';
import {Permission, PipelineStatus} from '../../types';
import {segmentTrack, statuses} from '../../utils';
import {Dropdown} from '../dropdown';
import {StatusTag} from '../status-tag';
import Styles from './StatusSwitch.module.scss';

type Props = {
  profileId: string;
  pipelineId: string;
  currentStatus: PipelineStatus;
  onChangeSuccess?: () => void;
  className?: string;
};

export const StatusSwitch = ({profileId, pipelineId, currentStatus, onChangeSuccess, className}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const handleStatusChange = useCallback(
    (status: PipelineStatus) => {
      updateStatus(pipelineId, profileId, {status: status})
        .then(closeDropdown)
        .then(() => onChangeSuccess?.());
      segmentTrack('Selector Applied', {
        label: 'pipeline status',
        pipeline_id: pipelineId,
        value: status,
      });
    },
    [closeDropdown, onChangeSuccess, pipelineId, profileId]
  );
  const handleStatusChangeAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleStatusChange,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  return (
    <div className={cs(Styles.statusSwitch, className)}>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        narrow
        dropdownClass="-ml-0.5"
        renderDropdownTrigger={() => (
          <div
            className={cs('flex', Styles.statusSwitchButton, {
              [Styles.statusSwitchButtonOpen]: isOpen,
            })}
          >
            <StatusTag value={currentStatus} />
            <ChevronDown />
          </div>
        )}
      >
        <Dropdown.ItemsGroup
          items={statuses.map(({value}) => ({
            label: value,
            type: {
              name: 'custom',
              onClick: () => handleStatusChangeAsPremiumFeature(value),
              element: <StatusTag value={value} />,
            },
            rightIcon: value === currentStatus ? <CheckIcon className="-mr-1 ml-1" /> : undefined,
          }))}
        />
      </Dropdown>
    </div>
  );
};
