import {ReactNode} from 'react';
import {LoadingSpinner} from '../components/animations';
import {PaywallPage} from '../components/paywall-page';
import {PermissionChecker} from '../components/permission';
import {useCurrentUserSession} from '../hooks';
import {ErrorPage} from '../pages';
import {Permission} from '../types';
import {Authenticated} from './Authenticated';

type AuthorizedProps = {
  children: ReactNode;
  bypassTeamCheck?: boolean;
  permission?: Permission;
};

export const Authorized = ({children, permission, bypassTeamCheck}: AuthorizedProps) => {
  const {team, isFetched: isUserSessionFetched} = useCurrentUserSession();
  const teamCheckPassed = team || bypassTeamCheck;

  if (!isUserSessionFetched) {
    return (
      <Authenticated>
        <div className="flex h-screen justify-center">
          <LoadingSpinner />
        </div>
      </Authenticated>
    );
  }

  if (!teamCheckPassed) {
    return (
      <Authenticated>
        <ErrorPage forbidden />
      </Authenticated>
    );
  }

  if (!permission) {
    return <Authenticated>{children}</Authenticated>;
  }

  return (
    <Authenticated>
      <PermissionChecker
        permission={permission}
        missingPlanFallback={<PaywallPage />}
        fallback={<ErrorPage forbidden />}
      >
        {children}
      </PermissionChecker>
    </Authenticated>
  );
};
