import {cloneElement, ReactElement, ReactNode, useCallback} from 'react';
import {usePremiumFeatureModal} from '../../hooks';

type Props = {
  children: ReactNode;
};

export const PaywallClickBlocker = ({children}: Props) => {
  const showPremiumFeatureModal = usePremiumFeatureModal();

  const blockClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      showPremiumFeatureModal();
    },
    [showPremiumFeatureModal]
  );

  const childrenHasOnClick = (children as ReactElement).props.hasOwnProperty('onClick');

  if (childrenHasOnClick) {
    return cloneElement(children as ReactElement, {onClick: blockClick});
  }

  return (
    <div className="flex" onClickCapture={blockClick}>
      {children}
    </div>
  );
};
