import {DeniedPermissionReason, Permission} from '../types';
import {useCurrentUserSession} from './useCurrentUserSession';
import {usePermissionAccess} from './usePermissionAccess';

type HasPermissionReturnType = {
  hasPermission: true;
};

type HasNotPermissionReturnType = {
  hasPermission: false;
  reason: DeniedPermissionReason;
};

type ReturnType = HasPermissionReturnType | HasNotPermissionReturnType;

export const usePermissionWithReason = (permission: Permission): ReturnType => {
  const permissionAccess = usePermissionAccess(permission);
  const {team} = useCurrentUserSession();
  const userRole = team?.role;

  if (!permissionAccess) {
    return {
      hasPermission: false,
      reason: 'not-found',
    };
  }

  if (permissionAccess.allowed) {
    return {
      hasPermission: true,
    };
  }

  const availableInUsersRole = userRole && permissionAccess.availableInRole.includes(userRole);

  return {
    hasPermission: false,
    reason: availableInUsersRole ? 'plan' : 'role',
  };
};
