import {useEffect, useRef} from 'react';
import {useUiSetting} from '../../../hooks';

const RETURNING_USER_KEY = 'returning-user';

export const useIsRuturningUser = () => {
  const [isReturningUser = false, setIsReturningUser] = useUiSetting<boolean>(RETURNING_USER_KEY);

  const onUnmount = useRef<() => void>(() => setIsReturningUser(true));
  onUnmount.current = () => {
    if (!isReturningUser) {
      setIsReturningUser(true);
    }
  };

  useEffect(() => {
    // perform only on unmount
    return () => onUnmount.current();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isReturningUser;
};
