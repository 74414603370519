import cs from 'classnames';
import {ReactElement, ReactNode} from 'react';
import {Button} from '../button';
import {Card} from '../card';
import Styles from './FormWrapper.module.scss';

type NavigationButton = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  tracking?: {
    label: string;
    location?: string;
  };
  intercomTarget?: string;
};

type Props = {
  heading?: string;
  subtitle?: string | ReactElement;
  skipButton?: NavigationButton;
  dataIntercomTarget?: string;
  cancelButton?: NavigationButton;
  actionButton?: NavigationButton;
  children: ReactNode;
  additionalButton?: ReactElement;
  fullWidth?: boolean;
  reverseButtonsOrder?: boolean;
  onModal?: boolean;
};

export const FormWrapper = ({
  heading,
  subtitle,
  skipButton,
  cancelButton,
  actionButton,
  dataIntercomTarget,
  additionalButton,
  children,
  fullWidth,
  reverseButtonsOrder,
  onModal,
}: Props) => {
  const hasButtons = skipButton || cancelButton || actionButton || additionalButton;
  const Wrapper = onModal ? 'div' : Card;
  return (
    <Wrapper className={cs(Styles.contentWrapper, {[Styles.contentWrapperFull]: fullWidth})}>
      {heading && (
        <div className={Styles.header}>
          <Card.Header>
            {dataIntercomTarget ? <span data-intercom-target={dataIntercomTarget}>{heading}</span> : heading}
          </Card.Header>
          {subtitle && (
            <Card.Subtitle>
              <span className={Styles.subtitle}>{subtitle}</span>
            </Card.Subtitle>
          )}
        </div>
      )}
      {children}
      {hasButtons && (
        <div
          className={cs(Styles.nextButtons, {
            [Styles.nextButtonsWithCancel]: cancelButton,
            [Styles.reverseButtonsOrder]: reverseButtonsOrder,
          })}
        >
          {cancelButton && (
            <Button
              variant="tertiary"
              onClick={cancelButton.onClick}
              intercomTarget={cancelButton.intercomTarget}
            >
              {cancelButton.label}
            </Button>
          )}
          {actionButton && (
            <Button
              type="submit"
              variant="primary"
              onClick={actionButton.onClick}
              disabled={actionButton.disabled}
              loading={actionButton.loading}
              tracking={actionButton.tracking}
              intercomTarget={actionButton.intercomTarget}
            >
              {actionButton.label}
            </Button>
          )}
          {skipButton && (
            <Button
              variant="tertiary"
              onClick={skipButton.onClick}
              intercomTarget={skipButton.intercomTarget}
            >
              {skipButton.label}
            </Button>
          )}
          {additionalButton && (
            <span className={reverseButtonsOrder ? 'mr-auto' : 'ml-auto'}>{additionalButton}</span>
          )}
        </div>
      )}
    </Wrapper>
  );
};
