import cs from 'classnames';
import {ReactNode} from 'react';
import {capitalizeString} from '../../utils';
import Styles from './Card.module.scss';
import {CardHeader} from './CardHeader';
import {CardSubtitle} from './CardSubtitle';

type Props = {
  color?: 'default' | 'orange' | 'yellow' | 'blue' | 'pink' | 'red' | 'green' | 'gray';
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  intercomTarget?: string;
};

const CardComponent = ({children, className, color = 'default', onClick, intercomTarget}: Props) => {
  return (
    <div
      className={cs(Styles.card, Styles[`card${capitalizeString(color)}`], className)}
      onClick={onClick}
      {...(intercomTarget ? {'data-intercom-target': intercomTarget} : {})}
    >
      {children}
    </div>
  );
};

CardComponent.displayName = 'Card';

export const Card = Object.assign(CardComponent, {
  Header: CardHeader,
  Subtitle: CardSubtitle,
});
