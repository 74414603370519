import {Testimonial} from './types';

export const testimonials: Testimonial[] = [
  {
    quote:
      'Successful companies constantly tap the networks of their teams, investors, and advisors. The Swarm makes it easy to reveal and harness those warm connections.',
    author: 'Kevin Mahaffey',
    role: 'Co-Founder at Lookout',
  },
  {
    quote: 'We’re using The Swarm as the single source of truth for people relationships.',
    author: 'Eric Tarczynski',
    role: 'Managing Partner at Contrary',
  },
  {
    quote:
      'We can create a shortlist of warm, qualified profiles in minutes. This is the most efficient way to run recruiting and sales prospecting, period!',
    author: 'Ian Yamey',
    role: 'Founder at Retirable',
  },
  {
    quote:
      'We can search through our combined +80k connections and find high-quality, responsive leads. Excited to dig into this relationship goldmine for sales, recruiting, fundraising, and partnerships.',
    author: 'Doug Shultz',
    role: 'CEO at Factor',
  },
];

export const companiesList = ['ethic', 'aws', 'reforge', 'contrary', '500', 'twosigmaventures'];
