import {useEffect} from 'react';
import {segmentTrack} from '../../utils';
import {Modal} from '../modal';
import {PremiumFeatureWarningContent} from '../premium-feature-warning-content';

type Props = {
  visible: boolean;
  onClose: () => unknown;
};

export const PremiumFeatureModal = ({onClose, visible}: Props) => {
  useEffect(() => {
    if (visible) {
      segmentTrack('Modal Viewed', {label: 'premium feature'});
    }
  }, [visible]);

  return (
    <Modal className="w-[480px]" visible={visible} onClose={onClose} closeButtonVisible>
      <PremiumFeatureWarningContent location="premium feature modal" />
    </Modal>
  );
};
