import {useCallback, useState} from 'react';
import {Role} from '../../../types';

export const useAddMembersModal = () => {
  const [addMembersVisible, setAddMembersVisible] = useState(false);
  const [initialRole, setInitialRole] = useState<Role>();

  const closeAddMembersModal = () => {
    setAddMembersVisible(false);
  };

  const openAddMembersModal = useCallback((initialRole?: Role) => {
    initialRole && setInitialRole(initialRole);
    setAddMembersVisible(true);
  }, []);

  return {
    addMembersVisible,
    initialRole,
    closeAddMembersModal,
    openAddMembersModal,
  };
};
