import cs from 'classnames';
import {FiChevronDown as ChevronDown} from 'react-icons/fi';
import {TagSimple} from '../../../../../types';
import {Tooltip} from '../../../../tooltip';
import {Tag} from '../../../tag';
import Styles from './TagsCellButton.module.scss';

type Props = {
  assignedTags: TagSimple[];
  onClick?: () => void;
  isActive?: boolean;
};

export const TagsCellButton = ({assignedTags = [], onClick, isActive}: Props) => {
  return (
    <div className={Styles.tagsCellButtonWrapper} onClick={onClick}>
      <div className={Styles.tagsCellButton}>
        <Tooltip disabled={assignedTags.length <= 1} content={assignedTags.map(({name}) => name).join(', ')}>
          <div className={cs(Styles.button, {[Styles.active]: isActive})}>
            <ChevronDown className={Styles.icon} />
            <div className={Styles.preview}>
              {assignedTags.length === 0 ? (
                <span className={Styles.empty}>--</span>
              ) : (
                <AssignedTagsList tags={assignedTags} />
              )}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

type TagsListProps = {
  tags: TagSimple[];
};

const AssignedTagsList = ({tags}: TagsListProps) => {
  return (
    <div className={Styles.tagsList}>
      {tags.map(({name, color, id}) => (
        <Tag content={name} color={color} key={id} />
      ))}
    </div>
  );
};
