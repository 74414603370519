import {getConfig} from '../../utils';
import askingForIntros from './images/asking-for-intros.png';
import buildingYourSwarm from './images/building-your-swarm.png';
import creatingLists from './images/creating-lists.png';
import gettingStartedImg from './images/getting-started.png';
import searchingAndFiltering from './images/searching-and-filtering.png';
import settingUpProcesses from './images/setting-up-processes.png';
import {Article} from './types';

const stagingTestArticleId = 8492618;
const {envName} = getConfig();
const isProd = envName === 'production';

export const articles: Article[] = [
  {
    id: isProd ? 6671513 : stagingTestArticleId,
    title: 'Getting started',
    description: 'Start here with a quick overview of The Swarm functionalities.',
    image: gettingStartedImg,
  },
  {
    id: isProd ? 8443857 : stagingTestArticleId,
    title: 'Building your Swarm',
    description: 'Learn about our different import sources and how to add members.',
    image: buildingYourSwarm,
  },
  {
    id: isProd ? 8443865 : stagingTestArticleId,
    title: 'Searching and filtering',
    description: 'Use powerful filters and sort through your combined networks.',
    image: searchingAndFiltering,
  },
  {
    id: isProd ? 6674481 : stagingTestArticleId,
    title: 'Creating lists',
    description: 'Add connections to lists and work as a team on The Swarm.',
    image: creatingLists,
  },
  {
    id: isProd ? 7023790 : stagingTestArticleId,
    title: 'Asking for intros',
    description: 'Get in touch with the people and companies you want to reach.',
    image: askingForIntros,
  },
  {
    id: isProd ? 8443888 : stagingTestArticleId,
    title: 'Setting up processes',
    description: 'Build simple and scalable processes to tap into your network.',
    image: settingUpProcesses,
  },
];
