import {PlanType} from '../../types';
import {getConfig} from '../getConfig';
import {
  addElementsTo,
  createElement,
  EventFunction,
  RoleAnalyticsData,
  TeamGroupAnalyticsData,
  UserAnalyticsData,
} from './utils';

const {envName, segmentWriteKey: writeKey} = getConfig();

const isLocal = envName === 'local';

type SegmentAnalytics = {
  identify: (userId: string, traits: Record<string, unknown>, options?: unknown, callback?: unknown) => void;
  track: (event: string, data?: Record<string, unknown>, options?: unknown, callback?: unknown) => void;
  page: (properties: Record<string, unknown>) => void;
  group: (groupId: string, traits: Record<string, unknown>) => void;
};
type WindowWithSegment = Window & {
  analytics: SegmentAnalytics;
};

const isWindowWithSegment = (window: Window): window is WindowWithSegment =>
  !!(window as WindowWithSegment).analytics;

export const initializeSegment = () => {
  if (!writeKey) {
    return;
  }
  const snippet = `
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${writeKey}";analytics.SNIPPET_VERSION="4.15.2";
analytics.load("${writeKey}");
}}();
`;

  addElementsTo('html', [createElement('script', snippet)]);
};
export const segmentIdentifyUser = (user: UserAnalyticsData) => {
  if (isWindowWithSegment(window)) {
    const {email, intercomHash, name, id, teamId, role, userTeams, linkedinUrl, connectionsCount} = user;
    window.analytics.identify(
      id,
      {
        name,
        email,
        teamId,
        role,
        userTeams,
        linkedinUrl,
        connectionsCount,
      },
      {
        integrations: {
          Intercom: {
            user_hash: intercomHash,
          },
        },
      }
    );
  }
};

export const setupSegmentTeamGroup = (traits: TeamGroupAnalyticsData) => {
  if (isWindowWithSegment(window)) {
    const {id, name, membersCount, plan} = traits;
    window.analytics.group(id, {
      type: 'team',
      name,
      membersCount,
      ...(plan && {
        plan: plan.type.toLowerCase(),
        ...(plan.type === PlanType.Premium && {
          inTrial: plan.payment?.trialActive,
        }),
      }),
    });
  }
};

export const setupSegmentRole = (traits: RoleAnalyticsData) => {
  if (isWindowWithSegment(window)) {
    const {role} = traits;
    window.analytics.group(role, {
      type: 'permission level',
    });
  }
};

export const segmentTrack: EventFunction = (event, data) => {
  if (isWindowWithSegment(window)) {
    if (isLocal) {
      // eslint-disable-next-line no-console
      console.log(`Segment event: ${event}`, data);
    }

    window.analytics.track(event, data);
  }
};

type PageviewProperties = {
  previousPathname?: string;
  category?: string;
  name?: string;
};
export const segmentPageview = (properties: PageviewProperties = {}) => {
  const {previousPathname, ...restProperties} = properties;

  isWindowWithSegment(window) &&
    window.analytics.page({
      domain: window.location.hostname,
      ...(previousPathname ? {referrer: `${window.location.origin}${previousPathname}`} : {}),
      ...restProperties,
    });
};

export const segmentVirtualPageview = (pathname: string) => {
  isWindowWithSegment(window) &&
    window.analytics.page({
      domain: window.location.hostname,
      path: pathname,
    });
};
