import {first} from 'lodash';
import {useCurrentUser, useSortBffConnections} from '../../../hooks';
import {BffConnection, Role} from '../../../types';
import {getUserFullName} from '../../../utils';
import {Avatar} from '../../avatar';
import {RequestIntroButton} from '../../request-intro';
import {Tooltip} from '../../tooltip';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './KeyRelationshipCellContent.module.scss';

type Props = {
  connections: BffConnection[];
  openIntroModal?: (profileId: string) => void;
  profileId: string;
};

export const KeyRelationshipCellContent = ({connections, openIntroModal, profileId}: Props) => {
  const {id: currentUserId} = useCurrentUser();
  const sortedConnections = useSortBffConnections({connections});
  const keyRelationshipConnection = first(sortedConnections)?.user;

  if (!keyRelationshipConnection) {
    return null;
  }

  const isCurrentUser = keyRelationshipConnection.id === currentUserId;
  const fullName = getUserFullName(keyRelationshipConnection) + (isCurrentUser ? ' (you)' : '');
  const isConnector = keyRelationshipConnection.role === Role.Connector;

  return (
    <div className={Styles.container}>
      <div className={Styles.label}>
        <Avatar fullname={fullName} outline={isConnector} empty={isConnector} size="sm" />
        <TruncateWithTooltip content={fullName}>{fullName}</TruncateWithTooltip>
      </div>
      {!isCurrentUser && (
        <div className={Styles.requestIntro}>
          <Tooltip content="Send intro request" hideHovered>
            <RequestIntroButton
              inTable
              showModal={() => openIntroModal?.(profileId)}
              connectors={[keyRelationshipConnection]}
              profileId={profileId}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
