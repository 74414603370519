import {useCallback, useState} from 'react';
import {updatePipeline} from '../../../api';
import {usePipelineForm} from '../../../hooks';
import {PipelineCommonResponse} from '../../../types';
import {Button} from '../../button';
import {Textarea} from '../../form';
import {Modal} from '../../modal';
import Styles from './Forwardable.module.scss';

type ForwardableProps = {
  pipeline: PipelineCommonResponse;
  refetchPipelines: () => void;
};

export const Forwardable = ({pipeline, refetchPipelines}: ForwardableProps) => {
  const {forwardableTemplate, id} = pipeline;
  const [forwardableFormVisible, setForwardableFormVisible] = useState(false);
  const {inputProps, isFormEdited, isFormFilled, formState} = usePipelineForm(pipeline);

  const showForwardableForm = useCallback(() => setForwardableFormVisible(true), []);
  const hideForwardableForm = useCallback(() => setForwardableFormVisible(false), []);

  const onUpdatePipeline = async () => {
    await updatePipeline({...formState, private: pipeline.private}, id);
    refetchPipelines();
    hideForwardableForm();
  };

  return (
    <>
      <div className={Styles.editForwardableTrigger}>
        <Textarea
          readOnly={true}
          className={Styles.forwardableTextarea}
          value={forwardableTemplate}
          placeholder="Click here to add a forwardable template."
        />
        <div className={Styles.editForwardableTriggerOverlay} onClick={showForwardableForm}>
          <p>Click to edit forwardable template</p>
        </div>
      </div>
      <Modal
        className={Styles.forwardableModal}
        visible={forwardableFormVisible}
        onClose={hideForwardableForm}
        closeButtonVisible
      >
        <h3 className={Styles.heading}>Edit forwardable template</h3>
        <Textarea
          {...inputProps('forwardableTemplate')}
          onBlur={() => {}}
          placeholder={`More about us:
- Product: (e.g. value proposition, use cases)
- Traction: (e.g. customers, trials, proof points)
- Founders: (e.g. experience, exits)
- Investors: (e.g. amount raised, key investors)`}
          message="This note will be added to the forwardable intro request."
          rows={8}
        />
        <div className={Styles.buttons}>
          <Button onClick={hideForwardableForm} variant="tertiary">
            Cancel
          </Button>
          <Button variant="primary" onClick={onUpdatePipeline} disabled={!(isFormFilled && isFormEdited)}>
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};
