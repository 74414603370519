export enum ProfilesBffColumns {
  FullName = 'name',
  AddToPipeline = 'addToPipeline',
  StatusInPipeline = 'pipelineStatus',
  TeamConnectionsStrength = 'teamConnectionStrength',
  ConnectionsStrength = 'connectionStrength',
  Score = 'score',
  JobTitle = 'jobTitle',
  JobCompanyName = 'jobCompanyName',
  JobCompanyWebsite = 'jobCompanyWebsite',
  LocationName = 'location_name',
  StartDate = 'experienceStartDate',
  EndDate = 'experienceEndDate',
  Tags = 'tags',
  KeyRelationship = 'keyRelationship',
  ConnectedMembersCount = 'connectedMembersCount',
  Emails = 'emails',
}

export const profilesBffLabelsMap: Record<ProfilesBffColumns, string> = {
  [ProfilesBffColumns.FullName]: 'Name',
  [ProfilesBffColumns.AddToPipeline]: 'Add',
  [ProfilesBffColumns.StatusInPipeline]: 'Status',
  [ProfilesBffColumns.ConnectionsStrength]: 'Strength',
  [ProfilesBffColumns.TeamConnectionsStrength]: 'Strength',
  [ProfilesBffColumns.Score]: 'Strength',
  [ProfilesBffColumns.JobTitle]: 'Title',
  [ProfilesBffColumns.JobCompanyName]: 'Company',
  [ProfilesBffColumns.JobCompanyWebsite]: 'Company Website',
  [ProfilesBffColumns.LocationName]: 'Location',
  [ProfilesBffColumns.StartDate]: 'Start Date',
  [ProfilesBffColumns.EndDate]: 'End Date',
  [ProfilesBffColumns.Tags]: 'Profile Tags',
  [ProfilesBffColumns.KeyRelationship]: 'Key Relationship',
  [ProfilesBffColumns.ConnectedMembersCount]: 'Connected To',
  [ProfilesBffColumns.Emails]: 'Emails',
};
