import cs from 'classnames';
import Styles from './Message.module.css';

export type MessageType = {message: string; hasError?: boolean; hasSuccess?: boolean; className?: string};

export const Message = ({message, hasError, hasSuccess, className}: MessageType) => (
  <p
    className={cs(
      Styles.inputMessage,
      {
        [Styles.inputMessageError]: hasError,
        [Styles.inputMessageSuccess]: hasSuccess,
      },
      className
    )}
  >
    {message}
  </p>
);
