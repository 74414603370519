import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {addConnectors} from '../../../../api/connectors';
import {VerifyLinkedinProfilesResponse} from '../../../../types';
import {sleep} from '../../../../utils';
import {FormWrapper} from '../../../form-wrapper';
import {notify} from '../../../notifications';
import Styles from '../AddMembersModal.module.scss';
import {AddableProfilesList} from './AddableProfilesList';
import {Instructions} from './Instructions';
import {UnaddableProfilesAlert} from './UnaddableProfilesAlert';

const ADDING_CONNECTORS_DELAY = 5_000;

type Props = {
  verificationResult: VerifyLinkedinProfilesResponse;
  onSuccess: () => void;
  goBack: () => void;
};

export const ConfirmationScreen = ({verificationResult, onSuccess, goBack}: Props) => {
  const {success, notFound, alreadyInTeam} = verificationResult;
  const profilesIds = success.map(({id}) => id);

  const {
    formState: {isSubmitting},
    handleSubmit,
  } = useForm();

  const onSubmit = useCallback(async () => {
    await addConnectors({profilesIds}).then(async () => {
      await sleep(ADDING_CONNECTORS_DELAY);
      onSuccess();
      notify("Connectors added. We're mapping their connections now. They will appear shortly.");
    });
  }, [profilesIds, onSuccess]);

  return (
    <>
      <h4 className={Styles.header}>Add Connectors</h4>

      <hr className={Styles.divider} />

      <FormWrapper
        skipButton={{label: 'Back', onClick: goBack}}
        actionButton={{
          label: 'Add Connectors',
          onClick: handleSubmit(onSubmit),
          loading: isSubmitting,
        }}
        reverseButtonsOrder
        onModal
        fullWidth
      >
        <AddableProfilesList profiles={success} />

        <Instructions />
        <UnaddableProfilesAlert alreadyInTeamProfiles={alreadyInTeam} notFoundProfiles={notFound} />

        <hr className={Styles.divider} />
      </FormWrapper>
    </>
  );
};
