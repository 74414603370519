import {ProfilesBffColumns} from '../../defs';

export const PEOPLE_COLUMNS_PREFERENCES_KEY = 'people_columns_5'; // postfix in the name to abandon old, not-compatible settings before columns naming changes
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.JobCompanyWebsite,
  ProfilesBffColumns.TeamConnectionsStrength,
  ProfilesBffColumns.KeyRelationship,
  ProfilesBffColumns.ConnectedMembersCount,
  ProfilesBffColumns.Emails,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
];
