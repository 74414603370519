import {
  AddressBook as ConnectionsIcon,
  SuitcaseSimple as CompaniesIcon,
  UserFocus as PipelinesIcon,
} from 'phosphor-react';
import {ReactElement} from 'react';
import {FiHome as HomeIcon, FiUsers as MembersIcon} from 'react-icons/fi';
import {useCheckPermission, usePermissionWithReason} from '../../../hooks';
import {Permission} from '../../../types';

export type NavItem = {
  label: string;
  path: string;
  icon: ReactElement;
  isLocked?: boolean;
};

const ICON_SIZE = 20;
const FI_ICON_SIZE = 16;

enum TeamRoutes {
  Home = '/',
  Members = '/members',
  Lists = '/lists',
  Connections = '/connections',
  Companies = '/old-companies',
  People = '/people',
  CompaniesNew = '/companies',
}

export const useNavItems = (): NavItem[] => {
  const hasTeamMemberPermission = useCheckPermission(Permission.TeamMember);
  const companyPermissionWithReason = usePermissionWithReason(Permission.Company);
  const shouldShowCompaniesLink =
    companyPermissionWithReason.hasPermission || companyPermissionWithReason.reason !== 'role';
  const shouldLockCompaniesLink =
    !companyPermissionWithReason.hasPermission && companyPermissionWithReason.reason === 'plan';

  return [
    {
      label: 'Home',
      path: TeamRoutes.Home,
      icon: <HomeIcon size={FI_ICON_SIZE} />,
    },
    ...(hasTeamMemberPermission
      ? [
          {
            label: 'Members',
            path: TeamRoutes.Members,
            icon: <MembersIcon size={FI_ICON_SIZE} />,
          },
        ]
      : []),
    {
      label: 'People',
      path: TeamRoutes.People,
      icon: <ConnectionsIcon size={ICON_SIZE} />,
    },
    ...(shouldShowCompaniesLink
      ? [
          {
            label: 'Companies',
            path: TeamRoutes.CompaniesNew,
            icon: <CompaniesIcon size={ICON_SIZE} />,
            isLocked: shouldLockCompaniesLink,
          },
        ]
      : []),
    {
      label: 'Lists',
      path: TeamRoutes.Lists,
      icon: <PipelinesIcon size={ICON_SIZE} />,
    },
  ];
};
