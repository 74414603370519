import Styles from './Logos.module.scss';
import {companiesList} from './consts';

export const Logos = () => (
  <div>
    <h3 className={Styles.logosHeading}>
      Trusted&nbsp;by&nbsp;network-minded companies&nbsp;and&nbsp;investors.
    </h3>
    <div className={Styles.logosWrapper}>
      {companiesList.map(company => (
        <div className={Styles.logoWrapper} key={company}>
          <img
            className={Styles.logo}
            src={`/welcome-screen-logos/logo-${company}.svg`}
            alt={`${company} logo`}
          />
        </div>
      ))}
    </div>
  </div>
);
