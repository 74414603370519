import cs from 'classnames';
import {FaLock as LockIcon} from 'react-icons/fa';
import {Tooltip} from '../tooltip';

type Props = {
  className?: string;
  dataIntercomTarget?: string;
};

export const PrivateLabel = ({className, dataIntercomTarget}: Props) => (
  <span className={cs('inline-flex', className)} data-intercom-target={dataIntercomTarget}>
    <Tooltip content="Private" hideHovered offset={14}>
      <LockIcon size={14} color="black" />
    </Tooltip>
  </span>
);
