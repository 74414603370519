import {getCookie} from 'typescript-cookie';
import {MOBILE_BLOCKER_OFF_COOKIE} from './consts';

export const isMobile = () => {
  const blockerOffCookie = getCookie(MOBILE_BLOCKER_OFF_COOKIE);
  if (blockerOffCookie) return false;
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  if (!isTouchDevice) return false;
  if (!/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) return false;

  const pixelRatio = window.devicePixelRatio;

  const breakpoints = {
    vertical: 460,
    horizontal: 940,
  };

  const isMobilePortrait =
    window.innerWidth / pixelRatio < breakpoints.vertical &&
    window.innerHeight / pixelRatio < breakpoints.horizontal;
  const isMobileLandscape =
    window.innerHeight / pixelRatio < breakpoints.vertical &&
    window.innerWidth / pixelRatio < breakpoints.horizontal;

  return isMobilePortrait || isMobileLandscape;
};
