import {ChangeEvent, Dispatch, useCallback} from 'react';
import {Input} from '../../../../form';
import {FilterValue} from '../../../types';

type Props = {
  currentValue: FilterValue;
  onChange: Dispatch<FilterValue>;
  isMultipleChoice: boolean;
};

export const StringFilter = ({currentValue, onChange, isMultipleChoice}: Props) => {
  const handleChange = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      const newValue = isMultipleChoice ? value.split(',') : [value];
      onChange(newValue);
    },
    [onChange, isMultipleChoice]
  );

  return (
    <Input
      onChange={handleChange}
      value={isMultipleChoice ? currentValue.join(',') : currentValue}
      className="my-0"
    />
  );
};
