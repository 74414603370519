import {CrmIcon} from '../../../components/crm-icon';
import {getRelativeTimeToNow} from '../../../utils';
import {IntegrationActionButton} from './IntegrationActionButton';
import Styles from './Integrations.module.scss';
import {IntegrationItem} from './types';
import {useExistingIntegration} from './useExistingIntegration.hook';

export const Integration = ({title, description, crmName}: IntegrationItem) => {
  const {integration} = useExistingIntegration(crmName);
  const lastSynced = integration?.lastSyncedAt ? new Date(integration.lastSyncedAt) : undefined;

  return (
    <div className={Styles.integration}>
      <div className="flex">
        <div className={Styles.integrationIcon}>
          <CrmIcon crm={crmName} />
        </div>
        <div className={Styles.integrationDescription}>
          <h3 className={Styles.integrationDescriptionHeader}>{title}</h3>
          <p>{description}</p>
          {lastSynced && (
            <div className={Styles.integrationLastSync}>
              Last sync:{' '}
              <span className={Styles.integrationLastSyncValue}>{getRelativeTimeToNow(lastSynced)}</span>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.integrationAction}>
        <IntegrationActionButton crm={crmName} />
      </div>
    </div>
  );
};
