import cs from 'classnames';
import {useCallback} from 'react';
import {FaRegStar as StarIcon} from 'react-icons/fa';
import {FiChevronRight as ChevronRight} from 'react-icons/fi';
import {PiUserList as ListIcon} from 'react-icons/pi';
import {useLocation} from 'react-router-dom';
import {useLayoutContext} from '../../../../contexts';
import {useFavoriteLists} from '../../../../hooks';
import {segmentTrack} from '../../../../utils';
import {NavItem} from '../../navigation/NavItem';
import Styles from './FavoriteListsWidget.module.scss';

const createListLink = (listId: string) => `/lists/${listId}`;

export const FavoriteListsWidget = () => {
  const {pathname: currentPath} = useLocation();
  const {favoriteLists, removeFav} = useFavoriteLists();
  const {favWidgetCollapsed, collapseFavWidget, expandFavWidget} = useLayoutContext();

  const toggleExpanded = useCallback(() => {
    segmentTrack(favWidgetCollapsed ? 'Element Expanded' : 'Element Collapsed', {
      label: 'my favorite lists',
    });
    favWidgetCollapsed ? expandFavWidget() : collapseFavWidget();
  }, [collapseFavWidget, expandFavWidget, favWidgetCollapsed]);

  const handleRemoveFav = useCallback(
    (favId: string) => {
      segmentTrack('Button Clicked', {
        label: 'unstar',
        pipeline_id: favId,
        location: 'my favorite lists',
      });
      removeFav(favId);
    },
    [removeFav]
  );

  return (
    <div className={Styles.container}>
      <h3 onClick={toggleExpanded} className={Styles.header}>
        <ChevronRight className={cs(Styles.favHeaderChevron, {[Styles.expanded]: !favWidgetCollapsed})} />
        My Favorite Lists
      </h3>
      {!favWidgetCollapsed && (
        <div className={Styles.favLists}>
          {favoriteLists.length <= 0 ? (
            <div className={Styles.noFavLists}>
              <StarIcon />
              Add lists to favorites
            </div>
          ) : (
            favoriteLists.map(fav => (
              <NavItem
                isActive={currentPath.includes(createListLink(fav.id))}
                icon={<ListIcon />}
                label={fav.title}
                path={createListLink(fav.id)}
                key={fav.id}
                locked={false}
                removeItem={() => handleRemoveFav(fav.id)}
                tracking={{
                  label: 'favorite list item',
                  pipeline_id: fav.id,
                }}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};
