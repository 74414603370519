import {ProfilesTable} from '../../../components/profiles-table';
import {BffFilterOptions} from '../../../types';
import {UseProfilesTableResult} from './useCompanyConnectionsTable';

type CompanyConnectionsTableProps = {
  filters: BffFilterOptions;
  tableData: UseProfilesTableResult;
  onChangeSuccess: () => void;
  totalCount: number;
};

export const CompanyConnectionsTable = ({
  filters,
  tableData,
  onChangeSuccess,
  totalCount,
}: CompanyConnectionsTableProps) => (
  <ProfilesTable
    filters={filters}
    tableData={tableData}
    totalCount={totalCount}
    onChangeSuccess={onChangeSuccess}
    bulkActionsConfig={{
      addToPipeline: true,
      crmExport: true,
    }}
  />
);
