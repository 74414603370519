import classNames from 'classnames';
import {LottieAnimation} from '../lottie-animation';
import Styles from './LoadingSpinner.module.scss';
import loadingSpinner from './loadingSpinner.json';

const SIZE = {
  AUTO: 'auto',
  X_SMALL: 'x-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'x-large',
} as const;
type Size = (typeof SIZE)[keyof typeof SIZE];

export const COLOR = {
  BLUE: 'blue',
  BLACK: 'black',
  WHITE: 'white',
  RED: 'red',
  GREEN: 'green',
};
type Color = (typeof COLOR)[keyof typeof COLOR];

const SizesMap: Record<Size, string> = {
  [SIZE.AUTO]: '',
  [SIZE.X_SMALL]: 'w-4',
  [SIZE.SMALL]: 'w-8',
  [SIZE.MEDIUM]: 'w-16',
  [SIZE.LARGE]: 'w-32',
  [SIZE.X_LARGE]: 'w-48',
};

const ColorsMap: Record<Color, string> = {
  [COLOR.BLUE]: '',
  [COLOR.BLACK]: Styles.colorBlack,
  [COLOR.WHITE]: Styles.colorWhite,
  [COLOR.RED]: Styles.colorRed,
  [COLOR.GREEN]: Styles.colorGreen,
};

type Props = {
  size?: Size;
  color?: Color;
  centered?: boolean;
  className?: string;
};

export const LoadingSpinner = ({size = SIZE.MEDIUM, color = COLOR.BLUE, centered, className}: Props) => (
  <LottieAnimation
    animationData={loadingSpinner}
    className={classNames(className, SizesMap[size], Styles.spinnerSVG, ColorsMap[color], {
      [Styles.centered]: centered,
    })}
  />
);
