import {ReactElement, ReactNode} from 'react';
import Styles from './DataItem.module.scss';

type Props = {
  label?: ReactElement | string;
  content?: ReactNode | string;
};
export const DataItem = ({label, content}: Props) => (
  <div className={Styles.dataItem}>
    <div className={Styles.dataItemLabel}>{label}</div>
    <div className={Styles.dataItemValue}>{content}</div>
  </div>
);
