import {useState} from 'react';
import {BsThreeDotsVertical as ThreeDots} from 'react-icons/bs';
import {FiEdit as EditIcon, FiTrash2 as DeleteIcon} from 'react-icons/fi';
import {useCheckPermission, useConfirm} from '../../../hooks';
import {Permission} from '../../../types';
import {Button} from '../../button';
import {Dropdown} from '../../dropdown';
import Styles from './Note.module.scss';

type Props = {
  editNote: () => void;
  deleteNote: () => void;
  isOwnNote: boolean;
};
export const NoteMenu = ({editNote, deleteNote, isOwnNote}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {isConfirmed} = useConfirm();

  const canTeamNoteDelete = useCheckPermission(Permission.TeamNoteDelete);
  const canDeleteNote = isOwnNote || canTeamNoteDelete;
  const canEditNote = isOwnNote;
  const showMenu = canDeleteNote || canEditNote;

  const handleEditNote = () => {
    editNote();
    setIsOpen(false);
  };

  const handleDeleteNote = async () => {
    setIsOpen(false);
    const confirmDelete = await isConfirmed('Are you sure you want to delete this note?', 'removal');
    if (confirmDelete) {
      deleteNote();
    }
  };

  if (!showMenu) {
    return null;
  }

  return (
    <div className={Styles.noteMenu}>
      <Dropdown
        renderDropdownTrigger={() => <Button variant="tertiary" size="sm" iconOnly icon={<ThreeDots />} />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placement="bottom-end"
        narrow
      >
        {canEditNote && (
          <Dropdown.Item
            label="Edit"
            type={{
              name: 'button',
              onClick: handleEditNote,
            }}
            icon={<EditIcon />}
          />
        )}
        {canDeleteNote && (
          <Dropdown.Item
            label="Remove"
            className={Styles.deleteButton}
            type={{
              name: 'button',
              onClick: handleDeleteNote,
            }}
            icon={<DeleteIcon />}
            tracking={{
              label: 'delete note',
              location: 'profile notes',
            }}
          />
        )}
      </Dropdown>
    </div>
  );
};
