import {useEffect} from 'react';
import {useQueryTasks} from '../../../api/tasks';
import {useImportTasks} from '../../../contexts';
import {SuccessProgressScreen} from '../../success-progress-screen';

const REFETCH_INTERVAL = 1_000;

type Props = {
  onClose: () => unknown;
};

export const ImportConnectionsSuccess = ({onClose}: Props) => {
  const {hasPendingTasks} = useImportTasks();

  useEffect(() => {
    if (hasPendingTasks) {
      onClose();
    }
  }, [hasPendingTasks, onClose]);

  // just for accelerate refetching interval for `useImportTasks` hook when this screen is active
  useQueryTasks({
    keepPreviousData: true,
    refetchOnMount: true,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
  });

  return (
    <SuccessProgressScreen
      onClose={onClose}
      description="We’re in the process of mapping and enriching your connections. It’ll take a few minutes to build your
        network."
      closeText="Your connections are being added in the background."
    />
  );
};
