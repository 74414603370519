import {useCallback, useState} from 'react';
import {AuthBox, AuthBoxIcon} from '../../../../components/auth';
import {LinkedinWarningIcon, WorkOverlapsIcon} from '../../../../components/icons';
import {FindOutMoreContent, SkipWorkExperienceButton} from '../../../../components/onboarding';
import {segmentTrack} from '../../../../utils';
import {Form} from './Form';

type Props = {
  onSuccess: () => void;
  blueUserUrl?: string;
};

export const FormScreen = ({onSuccess, blueUserUrl}: Props) => {
  const [isFindOutMoreOpen, setIsFindOutMoreOpen] = useState(false);
  const toggleFindOutMore = useCallback(() => {
    setIsFindOutMoreOpen(!isFindOutMoreOpen);
    segmentTrack('Button clicked', {
      label: 'find out more',
      location: 'add overlaps',
    });
  }, [isFindOutMoreOpen]);

  return (
    <AuthBox
      headerImage={<HeaderImage />}
      headerTitle="Add Your Overlaps"
      headerDescription={
        <>
          First, we automatically find people you may know based on your experience.{' '}
          <a
            className="mx-auto mb-4 cursor-pointer text-swarm-gray-700 underline"
            onClick={toggleFindOutMore}
          >
            Learn more
          </a>
        </>
      }
    >
      <FindOutMoreContent visible={isFindOutMoreOpen} />

      <Form onSuccess={onSuccess} blueUserUrl={blueUserUrl} />

      <SkipWorkExperienceButton visible={isFindOutMoreOpen} afterSkip={onSuccess} />
      {!blueUserUrl && (
        <p className="mb-0 mt-1 flex items-center gap-2 text-left text-base font-light text-swarm-gray-700">
          <LinkedinWarningIcon />
          It doesn't add your LinkedIn connections, <br />
          they can be mapped later.
        </p>
      )}
    </AuthBox>
  );
};

const HeaderImage = () => (
  <AuthBoxIcon>
    <WorkOverlapsIcon className="w-8" />
  </AuthBoxIcon>
);
