import {Dialog} from '@headlessui/react';
import {useState} from 'react';
import {FiBookOpen as BookOpenIcon} from 'react-icons/fi';
import {Button} from '../../../../button';
import {MatchingLinesInput} from '../../../../form/matching-lines-input/MatchingLinesInput';
import {Editor} from './Editor';
import {ListFilterContentProps} from './types';

export const ListFilterContent = ({
  value,
  modalTitle,
  modalDescription,
  tokensLabel,
  onApply,
  onCancel,
  minItemLength,
  matchingRule,
  placeholderText,
  matchingLinesInfo,
}: ListFilterContentProps) => {
  const [tokens, setTokens] = useState(value);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        {modalTitle}
      </Dialog.Title>
      {modalDescription && <Dialog.Description className="mb-4 mt-2">{modalDescription}</Dialog.Description>}
      {matchingRule ? (
        <MatchingLinesInput
          value={tokens}
          onChange={setTokens}
          validateSingleLineHandler={matchingRule}
          placeholder={placeholderText}
          info={matchingLinesInfo}
        />
      ) : (
        <Editor
          tokens={tokens}
          onChange={setTokens}
          tokensLabel={tokensLabel}
          minItemLength={minItemLength}
        />
      )}

      <div className="mt-8 flex justify-between">
        <div>
          <Button
            variant="tertiary"
            icon={<BookOpenIcon />}
            href="https://help.theswarm.com/en/articles/6863913-how-to-search-through-the-network-graph"
            target="_blank"
            tracking={{label: 'learn more', location: 'list filter'}}
          >
            Learn more about advanced filtering
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={onCancel} tracking={{label: 'cancel', location: 'list filter'}}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => onApply(tokens)}
            tracking={{
              label: 'apply',
              location: 'list filter',
              customProperties: {item_count: tokens.length},
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};
