import {NoteResponse} from '../../../types';
import {NoResults} from '../../no-results';
import {Note} from '../note-component';

type Props = {
  profileFullName?: string | null;
  notes: NoteResponse[];
  refetchNotes: () => void;
};

export const NotesList = ({profileFullName, notes, refetchNotes}: Props) => {
  if (!notes.length) {
    return (
      <NoResults
        page="notes"
        heading="Add your first note"
        subheading={`Add a note ${
          profileFullName ? 'about ' + profileFullName : ''
        }\nfor yourself or for someone on your team`}
      />
    );
  }
  return (
    <>
      {notes.map(note => (
        <Note note={note} key={note.id} refetchNotes={refetchNotes} />
      ))}
    </>
  );
};
