import {ReactNode, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LoadingSpinner} from '../components/animations';
import {checkAuth} from '../providers';
import {trackError} from '../utils';

const loginPath = '/login';
export const useAuthenticated = (): {loading: boolean; authenticated: boolean} => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    checkAuth()
      .then(authenticated => {
        setAuthenticated(authenticated);
        setLoading(false);
      })
      .catch(e => {
        setAuthenticated(false);
        setLoading(false);
        trackError(e);
      });
  }, []);

  return {
    loading,
    authenticated,
  };
};

export type AuthenticatedProps = {
  children: ReactNode;
};

export const Authenticated = ({children}: AuthenticatedProps) => {
  const {authenticated, loading: isLoading} = useAuthenticated();
  const navigate = useNavigate();
  const {pathname, search} = useLocation();

  if (isLoading) {
    return (
      <div className="flex h-screen justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!authenticated) {
    if (!pathname.includes(loginPath)) {
      const toURL = `${pathname}${search}`;
      let query = '';

      if (search.includes('email=')) {
        query = search;
      } else if (toURL !== '/') {
        query = `?to=${encodeURIComponent(toURL)}`;
      }

      navigate(`${loginPath}${query}`);
    }
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
