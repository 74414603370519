import {ReactElement, useEffect, useState} from 'react';
import {useQueryCrmIntegrations} from '../../api';
import {Crm} from '../../types';
import {segmentTrack} from '../../utils';
import {LoadingSpinner} from '../animations';
import {Dropdown} from '../dropdown';
import {ExportMenuItem} from './CrmExportMenuItem';

type Props = {
  targetIds: string[];
  targetType: 'profile' | 'company';
  trigger: ReactElement;
  onExportSuccess?: (crm: Crm) => void;
};

export const CrmExportDropdown = ({targetIds, targetType, trigger, onExportSuccess}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      segmentTrack('Selector Opened', {
        label: 'crm_export',
      });
    }
  }, [isOpen]);

  const {data, isLoading} = useQueryCrmIntegrations({
    enabled: isOpen,
  });
  const existingIntegrations = data?.integrations || [];
  const hasIntegrations = existingIntegrations.length > 0;

  return (
    <Dropdown
      renderDropdownTrigger={() => trigger}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      placement="bottom-start"
      visibleOnEmpty
    >
      {isLoading && (
        <div className="flex justify-center p-4">
          <LoadingSpinner size="small" color="black" />
        </div>
      )}
      {!isLoading &&
        existingIntegrations.map(integration => (
          <ExportMenuItem
            key={integration.id}
            crm={integration.crm}
            integrationId={integration.id}
            targetIds={targetIds}
            targetType={targetType}
            onExportSuccess={onExportSuccess}
          />
        ))}
      {!isLoading && !hasIntegrations && (
        <div className="flex flex-col items-center justify-center gap-2 py-4">No integrations configured</div>
      )}
    </Dropdown>
  );
};
