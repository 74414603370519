import cs from 'classnames';
import {ReactNode} from 'react';
import Styles from './NoResults.module.scss';

type Props = {
  page?:
    | 'my-connections'
    | 'pipelines'
    | 'pipeline'
    | 'error'
    | 'forbidden'
    | 'results'
    | 'notes'
    | 'oon-no-filters';
  heading?: string;
  subheading?: string;
  children?: ReactNode;
};

export const NoResults = ({page, heading, subheading, children}: Props) => {
  return (
    <div
      className={cs(Styles.noResults, {[Styles.errorPage]: page && ['error', 'forbidden'].includes(page)})}
    >
      <div className={Styles.ilustrationHolder}>
        <img className={Styles.ilustration} src={`/images/empty-${page}.png`} alt={`empty ${page} list`} />
      </div>
      <h2 className={Styles.heading}>{heading}</h2>
      <p className={Styles.subheading}>{subheading}</p>
      {children}
    </div>
  );
};
