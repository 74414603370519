import {useEffect} from 'react';
import {useQueryTag} from '../../../../api';
import {TagItem} from '../../../../types';
import {segmentTrack} from '../../../../utils';
import {LoadingSpinner} from '../../../animations';
import {Modal} from '../../../modal';
import {TagForm} from '../form';
import Styles from './TagModal.module.scss';

type Props = {
  visible: boolean;
  onClose?: () => void;
  onAssign?: (tagId: string) => Promise<unknown>;
  onAssignSuccess?: (tag: TagItem) => void;
  onSaveSuccess?: () => void;
  tagId?: string;
};

export const TagModal = ({visible, onClose, onAssign, onAssignSuccess, onSaveSuccess, tagId}: Props) => {
  const {data: tag, isLoading} = useQueryTag(tagId || '', {enabled: !!tagId && visible});

  useEffect(() => {
    if (visible) {
      segmentTrack('Modal Viewed', {label: tagId ? 'edit tag' : 'create new tag'});
    }
  }, [tagId, visible]);

  return (
    <Modal visible={visible} onClose={onClose} closeButtonVisible className={Styles.createTagModal}>
      <h4 className={Styles.header}>{tagId ? 'Edit' : 'Create new'} tag</h4>
      <hr className={Styles.divider} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TagForm
          tag={tag}
          onAssign={onAssign}
          onAssignSuccess={onAssignSuccess}
          onSaveSuccess={onSaveSuccess}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};
