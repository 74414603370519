import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {Input} from '../form';

type Props = {
  query?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onSubmit?: (value: string) => void;
  visible?: boolean;
};
export const DropdownSearch = ({query, placeholder, onChange, onSubmit, visible = true}: Props) => {
  const [searchQuery, setSearchQuery] = useState(query ?? '');

  const submitSearch = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit?.(searchQuery);
    },
    [onSubmit, searchQuery]
  );

  const onSearch = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(value);
      onChange(value);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    setSearchQuery('');
    onChange('');
  }, [onChange]);

  return visible ? (
    <form className="w-full" onSubmit={submitSearch}>
      <Input
        inputStyle="clearable"
        icon={SearchIcon}
        type="text"
        value={searchQuery}
        onChange={onSearch}
        className="my-0"
        placeholder={placeholder ?? 'Start typing'}
        onClear={onClear}
      />
    </form>
  ) : null;
};
