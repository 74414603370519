type Props = {
  logoUrl?: string;
  companyName: string;
  companyId: string;
  size?: number;
};

const placeholder = '/images/company-logo-placeholder.png';

export const CompanyLogo = ({logoUrl, companyName, companyId, size = 24}: Props) => {
  const logo = logoUrl || hardcodedCompanyLogos[companyId];

  const sizeStyles = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <div
      className="shrink-0 bg-contain bg-top bg-no-repeat"
      style={{backgroundImage: logo ? '' : `url(${placeholder})`, ...sizeStyles}}
    >
      {logo && <img src={logo} alt={companyName} style={sizeStyles} />}
    </div>
  );
};

const hardcodedCompanyLogos: Record<string, string> = {
  '78e2ba45-edfe-4442-b325-dc0306ef4a51': '/images/company-logos/interplayvc.png',
};
