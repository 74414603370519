import {DEFAULT_REQUEST_INTRO_MESSAGE_KEY} from './consts';

export const getDefaultMessage = (pipeline?: boolean) => {
  const savedDefaultRequestIntroMessage = localStorage.getItem(DEFAULT_REQUEST_INTRO_MESSAGE_KEY);
  return (
    savedDefaultRequestIntroMessage ||
    `Hi {first_name},

I'm looking to connect with {profile_full_name}. ${
      pipeline ? 'I’ve included more context below to pass along.' : ''
    }

Thanks!`
  );
};
