import {ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {useSwarmTable, UseSwarmTableProps} from '../../../components/table';
import {ProfilesBffColumns} from '../../../defs';
import {profilesBffTableCellDefinitions} from '../../../defs/columns/profilesBffTableCellDefinitions';
import {useCheckPermission} from '../../../hooks';
import {GetProfilesListResponse, Permission, ProfilesListRow, TagSimple} from '../../../types';

type GenericUseSwarmTableProps = UseSwarmTableProps<GetProfilesListResponse>;

export type UsePipelineProfilesTableProps = {
  rows: ProfilesListRow[];
  isLoaded: boolean;
  pagination: GenericUseSwarmTableProps['pagination'];
  sorting: GenericUseSwarmTableProps['sorting'];
  onAssignTag: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag: (profileIds: string[], tagId: string) => void;
  openIntroModal: (profileId: string) => void;
  visibleColumnsState: GenericUseSwarmTableProps['visibleColumnsState'];
  columnsOrder: GenericUseSwarmTableProps['columnsOrder'];
  pipelineId: string;
  pipelineName: string;
  refetchProfiles?: () => void;
};

export const usePipelineProfilesTable = ({
  rows,
  isLoaded,
  pagination,
  sorting,
  onAssignTag,
  onUnassignTag,
  openIntroModal,
  visibleColumnsState,
  columnsOrder,
  pipelineId,
  pipelineName,
  refetchProfiles,
}: UsePipelineProfilesTableProps) => {
  const canConnectionStrength = useCheckPermission(Permission.ConnectionStrength);

  const columns = useMemo<ColumnDef<ProfilesListRow>[]>(
    () => [
      profilesBffTableCellDefinitions[ProfilesBffColumns.FullName]({
        pipelineId,
        pipelineName,
        onChangeSuccess: refetchProfiles,
        openIntroModal,
      }),
      profilesBffTableCellDefinitions[ProfilesBffColumns.StatusInPipeline]({
        pipelineId,
        onChangeSuccess: refetchProfiles,
      }),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobTitle](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobCompanyName](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.JobCompanyWebsite](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.LocationName](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.Tags]({onAssignTag, onUnassignTag}),
      profilesBffTableCellDefinitions[ProfilesBffColumns.TeamConnectionsStrength]({canConnectionStrength}),
      profilesBffTableCellDefinitions[ProfilesBffColumns.KeyRelationship]({openIntroModal}),
      profilesBffTableCellDefinitions[ProfilesBffColumns.ConnectedMembersCount](),
      profilesBffTableCellDefinitions[ProfilesBffColumns.Emails](),
    ],
    [
      canConnectionStrength,
      onAssignTag,
      onUnassignTag,
      openIntroModal,
      pipelineId,
      pipelineName,
      refetchProfiles,
    ]
  );

  const table = useSwarmTable<ProfilesListRow>({
    uniqueName: 'pipelineProfiles',
    selectable: true,
    rows,
    isLoaded,
    columns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
  });

  return table;
};

export type UsePipelineProfilesTableResult = ReturnType<typeof usePipelineProfilesTable>;
