import cs from 'classnames';
import {ReactNode} from 'react';
import {PillInteractiveIcon} from './PillInteractiveIcon';

export type Props = {
  color?:
    | 'default'
    | 'orange'
    | 'yellow'
    | 'blue'
    | 'red'
    | 'green'
    | 'lilac'
    | 'gray'
    | 'black'
    | 'plain'
    | 'white';
  size?: 'small' | 'medium' | 'large';
  circle?: boolean;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  dataIntercomTarget?: string;
};

export const ColorsMap: Record<
  NonNullable<Props['color']>,
  Record<'background' | 'border' | 'textColor', string>
> = {
  default: {
    background: 'bg-swarm-gray-50',
    border: 'shadow-swarm-border',
    textColor: 'text-swarm-overlay',
  },
  orange: {
    background: 'bg-swarm-orange-light',
    border: 'shadow-swarm-border-light',
    textColor: 'text-swarm-orange-dark',
  },
  yellow: {
    background: 'bg-swarm-yellow-light',
    border: 'shadow-swarm-border-light',
    textColor: 'text-swarm-yellow-dark',
  },
  blue: {
    background: 'bg-swarm-blue-light',
    border: 'shadow-swarm-border-light',
    textColor: 'text-swarm-blue-dark',
  },
  red: {
    background: 'bg-swarm-red-light',
    border: 'shadow-swarm-border-light',
    textColor: 'text-swarm-red-dark',
  },
  green: {
    background: 'bg-swarm-green-light',
    border: 'shadow-swarm-border-light',
    textColor: 'text-swarm-green-dark',
  },
  lilac: {
    background: 'bg-swarm-lilac-light',
    border: 'shadow-swarm-border-dark',
    textColor: 'text-swarm-lilac-darken',
  },
  gray: {
    background: 'bg-swarm-gray-200',
    border: 'shadow-swarm-border-dark',
    textColor: 'text-swarm-gray-900',
  },
  white: {
    background: 'white',
    border: 'shadow-swarm-border',
    textColor: 'text-swarm-gray-900',
  },
  black: {background: 'bg-swarm-black', border: 'border-0', textColor: 'text-white'},
  plain: {background: 'bg-white', border: 'border-0', textColor: 'text-swarm-black'},
};

export const SizesMap: Record<NonNullable<Props['size']>, Record<'fontSize' | 'paddingX', string>> = {
  small: {fontSize: 'text-xs leading-[18px]', paddingX: 'px-1.5'},
  medium: {fontSize: 'text-sm leading-[21px]', paddingX: 'px-2'},
  large: {fontSize: 'text-base leading-6', paddingX: 'px-2'},
};

const PillComponent = ({
  children,
  className,
  onClick,
  color = 'default',
  size = 'small',
  circle,
  dataIntercomTarget,
}: Props) => {
  return (
    <span
      data-intercom-target={dataIntercomTarget}
      onClick={onClick}
      className={cs(
        'w-fit',
        'py-0.5',
        circle ? 'rounded-full' : 'rounded-[3px]',
        'font-light',
        'inline-flex',
        'items-center justify-between gap-1',
        onClick && 'cursor-pointer',
        ColorsMap[color].background,
        ColorsMap[color].border,
        ColorsMap[color].textColor,
        SizesMap[size].fontSize,
        SizesMap[size].paddingX,
        className
      )}
    >
      {children}
    </span>
  );
};

export const Pill = Object.assign(PillComponent, {InteractiveIcon: PillInteractiveIcon});
