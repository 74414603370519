import cs from 'classnames';
import {FiTag as TagIcon} from 'react-icons/fi';
import Styles from './EmptyTagsListInfo.module.scss';

type Props = {
  className?: string;
};

export const EmptyTagsListInfo = ({className}: Props) => {
  return (
    <div className={cs(Styles.emptyInfoWrapper, className)}>
      <TagIcon size={40} />
      No tags found
    </div>
  );
};
