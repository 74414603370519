import {AddConnectorsInput, VerifyLinkedinProfilesInput, VerifyLinkedinProfilesResponse} from '../types';
import {apiClient} from './apiClient';

export const addProfilesPreview = async (
  input: VerifyLinkedinProfilesInput
): Promise<VerifyLinkedinProfilesResponse> => {
  return apiClient
    .post('/teams/verify-linkedin-profiles', input, {headers: {'X-Swarm-Bff': 'true'}})
    .then(res => res.data);
};

export const addConnectors = async (input: AddConnectorsInput): Promise<void> => {
  return apiClient.post('/teams/add-connectors', input, {headers: {'X-Swarm-Bff': 'true'}});
};
