import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {PiList as DragIcon} from 'react-icons/pi';
import {Dropdown} from '../dropdown';
import cs from 'classnames';
import Styles from './ColumnSettings.module.scss';

type SortableItemProps = {
  id: string;
  label: string;
  onClick: () => void;
  checked: boolean;
  active?: boolean;
};

export const ColumnSettingsItem = ({id, label, onClick, checked, active}: SortableItemProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cs(Styles.sortableItem, {[Styles.activeItem]: active, [Styles.uncheckedItem]: !checked})}
    >
      <div className={Styles.sortableInner}>
        <Dropdown.Item
          label={label}
          type={{
            name: 'checkbox',
            onClick,
            checked,
          }}
        />
        <div className={Styles.grabElement} {...attributes} {...listeners}>
          <DragIcon className="text-swarm-gray-700" />
        </div>
      </div>
    </div>
  );
};
