import {useCallback} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {createBulkSuggestions} from '../../../../api';
import {PipelineCommonResponse} from '../../../../types';
import {pluralizedConnection, segmentTrack} from '../../../../utils';
import {Button} from '../../../button';
import {notify} from '../../../notifications';

type Props = {
  profileIDs: string[];
  onSuggestionsChangeSuccess?: () => void;
  pipelineId: PipelineCommonResponse['id'];
  pipelineName: string;
  disabled?: boolean;
};

export const BulkAddToParticularPipelineButton = ({
  profileIDs,
  onSuggestionsChangeSuccess,
  pipelineId,
  pipelineName,
  disabled,
}: Props) => {
  const handleBulkAddToPipeline = useCallback(() => {
    createBulkSuggestions(pipelineId, {profileIDs})
      .then(() =>
        notify(
          <>
            {profileIDs.length} {pluralizedConnection(profileIDs.length)} successfully added to "
            {pipelineName}" list.
          </>
        )
      )
      .then(() => {
        setTimeout(() => {
          onSuggestionsChangeSuccess?.();
        }, 1200);
      });

    segmentTrack('Bulk Action Applied', {
      label: 'add to this pipeline',
      location: 'pipeline saved search',
      profile_count: profileIDs.length,
    });
  }, [pipelineId, pipelineName, profileIDs, onSuggestionsChangeSuccess]);

  return (
    <Button
      onClick={handleBulkAddToPipeline}
      variant="tertiary"
      outline
      icon={<PlusIcon size={16} />}
      size="sm"
      intercomTarget="add selected profiles to pipeline"
      disabled={disabled}
    >
      Add to this List
    </Button>
  );
};
