import {Button} from '../../../components/button';
import Styles from './Account.module.scss';

export const AccountDeletion = () => (
  <div className={Styles.settingsActionBoxWrapper}>
    <p className={Styles.settingsActionBoxInfo}>
      All data from your Swarm account will be permanently deleted.
      <br />
      You will be asked to confirm on the next screen.
    </p>
    <Button
      variant="red"
      intercomTarget="Request account removal"
      tracking={{label: 'request account removal'}}
    >
      Request account removal
    </Button>
  </div>
);
