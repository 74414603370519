import {resolveValue, toast, Toaster} from 'react-hot-toast';
import {Notification} from '../notification';

export const Notifications = () => {
  return (
    <Toaster position="bottom-center">
      {t => (
        <Notification
          color="black"
          symbol="info"
          className="sm:w-[480px]"
          closable
          onClose={() => toast.remove(t.id)}
        >
          {resolveValue(t.message, t)}
        </Notification>
      )}
    </Toaster>
  );
};
