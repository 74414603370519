import {RefObject, useEffect} from 'react';

export const useOnClickOutside = (ref: RefObject<HTMLDivElement>, handleClickOutside: () => void) => {
  useEffect(() => {
    const listener = (event: globalThis.MouseEvent): void => {
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return;
      }
      handleClickOutside();
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handleClickOutside]);
};
