import {useCallback, useMemo} from 'react';
import {useCurrentTeam, useUiSetting} from '../../hooks';
import {TaskPurpose} from './types';

type TaskSettings = {
  done?: boolean;
  hidden?: boolean;
};

const DASHBOARD_TASKS_SETTINGS_KEY = 'dashboard-tasks';

export const useTaskState = (taskName: TaskPurpose) => {
  const {id: teamId} = useCurrentTeam();
  const [dashboardTasksSettings = {}, updateDashboardTasksSettings] = useUiSetting<
    Record<typeof teamId, Partial<Record<TaskPurpose, TaskSettings>> | undefined>
  >(DASHBOARD_TASKS_SETTINGS_KEY);

  const taskSettings = useMemo(() => {
    const teamTasks = dashboardTasksSettings[teamId];

    return teamTasks?.[taskName] ?? {};
  }, [dashboardTasksSettings, taskName, teamId]);

  const setDone = useCallback(() => {
    updateDashboardTasksSettings({
      ...dashboardTasksSettings,
      [teamId]: {
        ...dashboardTasksSettings[teamId],
        [taskName]: {
          ...taskSettings,
          done: true,
        },
      },
    });
  }, [dashboardTasksSettings, taskName, taskSettings, teamId, updateDashboardTasksSettings]);

  const setHidden = useCallback(() => {
    updateDashboardTasksSettings({
      ...dashboardTasksSettings,
      [teamId]: {
        ...dashboardTasksSettings[teamId],
        [taskName]: {
          ...taskSettings,
          hidden: true,
        },
      },
    });
  }, [dashboardTasksSettings, taskName, taskSettings, teamId, updateDashboardTasksSettings]);

  return {
    ...taskSettings,
    setDone,
    setHidden,
  };
};
