import {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {Permission} from '../../types';
import {PermissionChecker} from '../permission';

type Props = {
  children?: ReactNode;
};

export const UpgradeLink = ({children}: Props) => (
  <PermissionChecker permission={Permission.PlanUpdate} fallback={children || 'Upgrade'}>
    <Link to="/upgrade-plan">{children || 'Upgrade'}</Link>
  </PermissionChecker>
);
