import cs from 'classnames';
import {ReactNode, RefObject, useEffect, useRef, useState} from 'react';
import {Placement} from 'react-laag';
import {Tooltip} from '../tooltip';
import Styles from './TruncateWithTooltip.module.scss';

type Props = {
  children: ReactNode;
  content?: string | ReactNode;
  placement?: Placement;
  contentClassName?: string;
};

const isEllipsisActive = ({current}: RefObject<HTMLDivElement>) => {
  if (!current) {
    return false;
  }

  return current.offsetWidth < current.scrollWidth;
};

export const TruncateWithTooltip = ({children, content, placement, contentClassName}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [renderTooltip, setRenderTooltip] = useState<boolean>(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setRenderTooltip(isEllipsisActive(ref));
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, [ref]);

  return (
    <div className="truncate">
      <Tooltip content={content} disabled={!renderTooltip} placement={placement}>
        <div ref={ref} className={cs(Styles.wrapper, contentClassName)}>
          {children}
        </div>
      </Tooltip>
    </div>
  );
};
