import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

const {history, location} = window;
const {referrer} = document;

export const useConditionallyGoBack = ({defaultPath}: {defaultPath: string}) => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    if (referrer && history.length < 2) {
      // is a visit by opening in the new tab
      location.href = referrer;
    } else if (history.length > 1) {
      // is an SPA visit
      history.back();
    } else {
      // is a visit directly by URL
      navigate(defaultPath);
    }
  }, [defaultPath, navigate]);

  return goBack;
};
