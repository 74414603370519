import {createContext, ReactNode} from 'react';
import {PremiumFeatureModal} from '../components/premium-feature-modal';
import {useModal} from '../hooks';

type ContextValue = {
  showPremiumFeatureModal: (featureName?: string) => void;
};

export const PremiumFeatureModalContext = createContext<ContextValue>({
  showPremiumFeatureModal: () => {},
});
PremiumFeatureModalContext.displayName = 'SwarmPremiumFeatureModalContext';

type Props = {
  children: ReactNode;
};

export const PremiumFeatureModalContextProvider = ({children}: Props) => {
  const {
    visible: premiumFeatureModalVisible,
    show: showPremiumFeatureModal,
    close: closePremiumFeatureModal,
  } = useModal();

  const value: ContextValue = {
    showPremiumFeatureModal,
  };

  return (
    <PremiumFeatureModalContext.Provider value={value}>
      {children}
      <PremiumFeatureModal onClose={closePremiumFeatureModal} visible={premiumFeatureModalVisible} />
    </PremiumFeatureModalContext.Provider>
  );
};
