import cs from 'classnames';

type Props = {
  title: string;
  profiles: string[];
  className?: string;
};
export const UnaddableProfilesList = ({title, profiles, className}: Props) => (
  <div className={cs('py-3', className)}>
    <p>{title}</p>
    <ul className="pt-1 text-xs">
      {profiles.map(profile => (
        <li key={profile}>{profile}</li>
      ))}
    </ul>
  </div>
);
