import cs from 'classnames';
import {useCallback, useState} from 'react';
import {FiTrash2 as DeleteIcon} from 'react-icons/fi';
import {deletePipelineProfile} from '../../../api';
import {useConfirm} from '../../../hooks';
import {sleep} from '../../../utils';
import {Button} from '../../button';
import {notify} from '../../notifications';
import {onRowHoverVisible} from '../../table';
import Styles from './RemoveFromPipelineButton.module.scss';

type Props = {
  pipelineId: string;
  pipelineName: string;
  onSuccess?: () => void;
  profileId: string;
  inTable?: boolean;
};

export const RemoveFromPipelineButton = ({
  pipelineId,
  pipelineName,
  profileId,
  onSuccess,
  inTable,
}: Props) => {
  const {isConfirmed: isDeletionFromPipelineConfirmed} = useConfirm();
  const [loading, setLoading] = useState(false);

  const deleteProfileFromPipeline = useCallback(async () => {
    const confirmDelete = await isDeletionFromPipelineConfirmed(
      `Are you sure you want to remove this profile from ${pipelineName} list?`,
      'removal'
    );
    if (confirmDelete) {
      deletePipelineProfile(pipelineId, profileId).then(() => {
        setLoading(true);
        sleep(2000).then(() => {
          onSuccess?.();
          notify(`Profile removed from ${pipelineName} list.`);
          setLoading(false);
        });
      });
    }
  }, [isDeletionFromPipelineConfirmed, onSuccess, pipelineId, pipelineName, profileId]);

  return (
    <Button
      variant="tertiary"
      outline={inTable && !loading}
      icon={<DeleteIcon size={16} />}
      onClick={deleteProfileFromPipeline}
      className={cs(Styles.deleteProfileFromPipeline, {[onRowHoverVisible]: inTable && !loading})}
      iconOnly
      rounded={inTable}
      size="xs"
      loading={loading}
    />
  );
};
