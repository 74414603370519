import {PipelineStatus} from '../../types';

type PipelineStatusColors = 'blue' | 'yellow' | 'green' | 'red' | 'lilac';

type StatusType = {
  label: string;
  value: PipelineStatus;
  color: PipelineStatusColors;
};

export const statuses: StatusType[] = [
  {
    label: 'Suggested',
    value: PipelineStatus.suggested,
    color: 'blue',
  },
  {
    label: 'Intro requested',
    value: PipelineStatus.introRequested,
    color: 'lilac',
  },
  {
    label: 'In contact',
    value: PipelineStatus.inContact,
    color: 'yellow',
  },
  {
    label: 'Meeting set',
    value: PipelineStatus.meetingSet,
    color: 'green',
  },
  {
    label: 'Removed',
    value: PipelineStatus.removed,
    color: 'red',
  },
];
