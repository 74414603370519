import {ReactNode, useMemo} from 'react';
import {LoadingSpinner} from '../../components/animations';
import {useOnboardingContext} from '../../contexts';
import {AuthLayout} from '../../layouts';
import {OnboardingStep} from '../../types';
import {FirstLastNameStep, NewTeamStep, WorkExperienceStep} from './steps';

type WindowWithChrome = Window & {chrome?: unknown};

export const OnboardingPage = () => {
  const {currentStep} = useOnboardingContext();
  const isChromiumBrowser = typeof (window as WindowWithChrome).chrome !== 'undefined';

  const stepsViews: Record<OnboardingStep, ReactNode> = useMemo(
    () => ({
      [OnboardingStep.Loading]: <LoadingSpinner size="large" color="black" centered className="!my-16" />,
      [OnboardingStep.FirstLastName]: <FirstLastNameStep />,
      [OnboardingStep.WorkExperience]: <WorkExperienceStep isChromiumBrowser={isChromiumBrowser} />,
      [OnboardingStep.TeamWithInvitations]: <NewTeamStep invitationAvailable />,
      [OnboardingStep.TeamWithoutInvitations]: <NewTeamStep />,
    }),
    [isChromiumBrowser]
  );

  if (!currentStep) {
    return null;
  }

  return <AuthLayout>{stepsViews[currentStep]}</AuthLayout>;
};
