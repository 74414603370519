import {Switch} from '@headlessui/react';
import cs from 'classnames';
import {Dispatch, SetStateAction} from 'react';
import {Pill} from '../../../components/pill';
import {PaymentPeriod} from '../../../types';
import {segmentTrack} from '../../../utils';

const emitAnalyticsEvent = (period: PaymentPeriod) =>
  segmentTrack('Toggle Switched', {
    label: 'subscription interval',
    value: period.toLowerCase(),
    location: 'plan selection',
  });

type PeriodSelectorProps = {
  period: PaymentPeriod;
  setPeriod: Dispatch<SetStateAction<PaymentPeriod>>;
};

export const PeriodSelector = ({period, setPeriod}: PeriodSelectorProps) => {
  const enabledYearly = period === PaymentPeriod.Yearly;

  const setYearly = () => {
    setPeriod(PaymentPeriod.Yearly);
    emitAnalyticsEvent(PaymentPeriod.Yearly);
  };

  const setMonthly = () => {
    setPeriod(PaymentPeriod.Monthly);
    emitAnalyticsEvent(PaymentPeriod.Monthly);
  };

  const togglePeriod = () => {
    if (enabledYearly) {
      setMonthly();
    } else {
      setYearly();
    }
  };

  return (
    <div className="mt-6 flex items-center justify-center">
      <div className="flex gap-2 p-2">
        <span onClick={setMonthly} className={cs('cursor-pointer', {'text-gray-400': enabledYearly})}>
          Monthly
        </span>
        <Switch
          checked={enabledYearly}
          onChange={togglePeriod}
          className="relative inline-flex h-6 w-11 items-center rounded-full bg-swarm-black"
        >
          <span className="sr-only">Enable yearly plan</span>
          <span
            className={`${
              enabledYearly ? 'translate-x-6' : 'translate-x-1'
            } inline-block size-4 rounded-full bg-white transition`}
          />
        </Switch>
        <span onClick={setYearly} className={cs('cursor-pointer', {'text-gray-400': !enabledYearly})}>
          Yearly
        </span>
      </div>
      <Pill className="ml-2 h-5" color="yellow">
        20% OFF
      </Pill>
    </div>
  );
};
