import cs from 'classnames';
import {Children, ReactNode} from 'react';
import {Avatar} from '../avatar';
import Styles from './AvatarCollection.module.scss';

const SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  XL: 'xl',
};

type Size = (typeof SIZE)[keyof typeof SIZE];

type Props = {
  children?: ReactNode;
  size?: Size;
  bgColor?: 'white' | 'gray' | 'black';
  limit?: number;
};

const AvatarsEllipsis = ({size}: {size: Size}) => (
  <>
    {[...Array(3)].map((_, index) => (
      <div className={cs(Styles.avatarCollectionElement, Styles.avatarCollectionElementEtc)} key={index}>
        <Avatar fullname="" size={size} empty key={index} />
      </div>
    ))}
    ;
  </>
);

export const AvatarCollection = ({children, size = 'sm', bgColor, limit}: Props) => {
  const childrenCount = Children.count(children);
  const limitOverflow = limit && childrenCount > limit;
  const avatars = Children.toArray(children).slice(0, limitOverflow ? limit : childrenCount);

  return (
    <div
      className={cs(Styles.avatarCollection, Styles[`avatarCollection${size}`], {
        [Styles.avatarCollectionOnBlack]: bgColor === 'black',
        [Styles.avatarCollectionOnGray]: bgColor === 'gray',
      })}
    >
      {/* elements are in reversed order, then again reversed in styles, to make the first-element-on-top stack without messing with z-index */}
      {limitOverflow && <AvatarsEllipsis size={size} />}
      {avatars.reverse().map((avatar, index) => (
        <div key={index} className={Styles.avatarCollectionElement}>
          {avatar}
        </div>
      ))}
    </div>
  );
};
