import {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {CreateTeamResult, startTrial, useMutationCreateTeam} from '../../../api';
import {useChangeTeam, useTrialInUrlHelper} from '../../../hooks';
import {
  HowDidYouHearAnswer,
  InterestedInOurImplementationServicesAnswer,
  TeamMainUseCase,
} from '../../../types';
import {Button} from '../../button';
import {Input, Label, Message, Multiselect, simpleOptionMapper} from '../../form';
import {FormWrapper} from '../../form-wrapper';

const teamMainUseCases = Object.values(TeamMainUseCase);
const mainUseCaseOptions = simpleOptionMapper(teamMainUseCases);
const howDidYouHearAnswers = Object.values(HowDidYouHearAnswer);
const howDidYouHearOptions = simpleOptionMapper(howDidYouHearAnswers);
const interestedInOurImplementationServicesAnswers = Object.values(
  InterestedInOurImplementationServicesAnswer
);
const interestedInOurImplementationServicesOptions = simpleOptionMapper(
  interestedInOurImplementationServicesAnswers
);

type CreateTeamFormState = {
  teamName: string;
  companyLinkedinURL?: string;
  mainUseCase: string;
  howDidYouHear: string;
  interestedInOurImplementationServices: string;
};

type Props = {
  teamName?: string;
  onSuccess: (newTeamId?: CreateTeamResult) => void;
  onClose?: () => void;
  isOnModal?: boolean;
  isInOnboarding?: boolean;
  companyLinkedinURL?: string;
};

export const CreateTeamForm = ({
  onSuccess,
  onClose,
  isOnModal,
  isInOnboarding,
  teamName,
  companyLinkedinURL,
}: Props) => {
  const [globalError, setGlobalError] = useState<string>();
  const changeTeam = useChangeTeam();
  const setTrialStartedInUrl = useTrialInUrlHelper();
  const {
    formState: {isSubmitting, errors},
    register,
    control,
    handleSubmit,
  } = useForm<CreateTeamFormState>({
    defaultValues: {
      teamName: teamName || '',
      companyLinkedinURL: companyLinkedinURL || '',
    },
  });

  const mutationCreateTeam = useMutationCreateTeam({
    onSuccess,
  });

  const onSubmit = useCallback(
    ({
      teamName,
      companyLinkedinURL,
      mainUseCase,
      howDidYouHear,
      interestedInOurImplementationServices,
    }: CreateTeamFormState) => {
      setGlobalError(undefined);
      mutationCreateTeam.mutate(
        {
          name: teamName,
          linkedin_company_url: companyLinkedinURL,
          additional_registration_data: {
            main_use_case: mainUseCase,
            ...(isInOnboarding && {
              how_did_you_hear: howDidYouHear,
              interested_in_our_implementation_services: interestedInOurImplementationServices,
            }),
          },
        },
        {
          onSuccess: teamId => {
            startTrial(teamId);
            setTrialStartedInUrl('true');
            onSuccess(teamId);
            if (isOnModal) {
              changeTeam(teamId);
            }
          },
          onError: () => setGlobalError('Ooops, something went wrong. Try again'),
        }
      );
    },

    [mutationCreateTeam, isInOnboarding, setTrialStartedInUrl, onSuccess, isOnModal, changeTeam]
  );

  const form = (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2 text-left">
      <div>
        <Label>Team name</Label>
        <Input
          {...register('teamName', {
            required: 'Please enter a team name',
            minLength: {value: 2, message: 'Team name must be at least 2 characters long'},
          })}
          type="text"
          placeholder="Enter your team name"
          isInvalid={Boolean(errors.teamName)}
          message={errors.teamName?.message}
        />
      </div>
      {!companyLinkedinURL && (
        <div>
          <Label>Company Linkedin</Label>
          <Input
            {...register('companyLinkedinURL', {
              pattern: {value: /linkedin.com\/company\/.*/, message: 'Please enter a valid LinkedIn URL'},
            })}
            type="text"
            placeholder="linkedin.com/company (optional)"
            isInvalid={Boolean(errors.companyLinkedinURL)}
            message={errors.companyLinkedinURL?.message}
          />
        </div>
      )}

      <div>
        <Label>What's your main use case?</Label>
        <Controller
          control={control}
          name="mainUseCase"
          rules={{required: 'Please select a main use case'}}
          render={({field: {onChange, value}}) => (
            <Multiselect
              singleOption
              options={mainUseCaseOptions}
              onChange={onChange}
              {...(value && {initialValues: [value]})}
              isInvalid={Boolean(errors.mainUseCase)}
              message={errors.mainUseCase?.message}
              placeholder="Select from the list"
            />
          )}
        />
      </div>

      {isInOnboarding && (
        <>
          <div>
            <Label>Interested in our implementation services?</Label>
            <Controller
              control={control}
              name="interestedInOurImplementationServices"
              rules={{required: 'Please select the answer'}}
              render={({field: {onChange, value}}) => (
                <Multiselect
                  singleOption
                  options={interestedInOurImplementationServicesOptions}
                  onChange={onChange}
                  {...(value && {initialValues: [value]})}
                  isInvalid={Boolean(errors.interestedInOurImplementationServices)}
                  message={errors.interestedInOurImplementationServices?.message}
                  placeholder="Select from the list"
                />
              )}
            />
          </div>

          <div>
            <Label>How did you hear about The Swarm?</Label>
            <Controller
              control={control}
              name="howDidYouHear"
              rules={{required: 'Please select how did you hear'}}
              render={({field: {onChange, value}}) => (
                <Multiselect
                  singleOption
                  options={howDidYouHearOptions}
                  onChange={onChange}
                  {...(value && {initialValues: [value]})}
                  isInvalid={Boolean(errors.howDidYouHear)}
                  message={errors.howDidYouHear?.message}
                  placeholder="Select from the list"
                />
              )}
            />
          </div>
        </>
      )}

      {!isOnModal && (
        <Button
          type="submit"
          variant="primary"
          fullWidth
          loading={isSubmitting}
          size="lg"
          className="mt-2"
          tracking={{
            label: 'continue',
            location: 'create team',
          }}
        >
          Continue
        </Button>
      )}

      {globalError && <Message message={globalError} hasError={true} className="pt-1" />}
    </form>
  );

  return isOnModal ? (
    <FormWrapper
      {...(onClose && {skipButton: {label: 'Cancel', onClick: onClose}})}
      actionButton={{label: 'Submit', onClick: handleSubmit(onSubmit), loading: isSubmitting}}
      reverseButtonsOrder
      onModal
      fullWidth
    >
      {form}
    </FormWrapper>
  ) : (
    form
  );
};
