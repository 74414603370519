import {ReactNode} from 'react';
import {TruncateLink} from '../truncate-link';

type Props = {
  profileId: string;
  profileName?: string;
  hash?: string;
  children?: ReactNode;
};

export const ProfileLink = ({profileId, profileName, hash, children}: Props) => {
  let href = encodeURI(`/people/${profileId}`);

  if (hash) {
    href += `#${hash}`;
  }

  return <TruncateLink href={href}>{children || profileName}</TruncateLink>;
};
