import qs from 'qs';
import {FormEvent, useCallback, useEffect, useMemo} from 'react';
import {HiOutlineMail as IconMail} from 'react-icons/hi';
import {useLocation} from 'react-router-dom';
import {Button} from '../../../components/button';
import {useAuthContext} from '../../../contexts';
import {getErrorMessage} from '../../../pages/auth/errorsMapping';
import {loginWithGoogle} from '../../../providers';
import {segmentPageview, segmentTrack, segmentVirtualPageview} from '../../../utils';
import {Divider} from '../../divider';
import {Input, Label} from '../../form';
import {SignInWithGoogleButton} from '../../sign-in-with-google-button';

type Props = {
  onSuccess: () => void;
};

export const SignIn = ({onSuccess}: Props) => {
  const {email, setEmail, signIn, signInError, loading} = useAuthContext();
  const {search: queryString} = useLocation();

  const queryParams = qs.parse(queryString, {ignoreQueryPrefix: true});
  const emailFromUrl = queryParams.email;

  useEffect(() => {
    if (typeof emailFromUrl === 'string') {
      setEmail(emailFromUrl);
    }
  }, [emailFromUrl, setEmail]);

  useEffect(() => {
    segmentVirtualPageview('/sign-in');
  }, []);

  const errorMessage = useMemo(() => getErrorMessage(signInError), [signInError]);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      return signIn(onSuccess);
    },
    [onSuccess, signIn]
  );

  const onSignInWithGoogleClick = useCallback(async () => {
    segmentTrack('Button Clicked', {
      label: 'continue with google',
      location: 'sign in',
    });
    await loginWithGoogle();
  }, []);

  useEffect(() => {
    segmentPageview({
      category: 'log_in',
      name: 'init',
    });
  }, []);

  return (
    <>
      {!emailFromUrl && (
        <>
          <SignInWithGoogleButton onClick={onSignInWithGoogleClick} className="w-full" />
          <Divider>or</Divider>
        </>
      )}
      <form onSubmit={onSubmit} className="text-left">
        <Label htmlFor="signin-email">Email</Label>
        <Input
          placeholder="Type your email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          inputSize="lg"
          icon={IconMail}
          isInvalid={!!signInError}
          message={signInError ? errorMessage : undefined}
        />

        <Button
          type="submit"
          variant="primary"
          fullWidth
          loading={loading}
          reversedOrder
          size="lg"
          className="mt-3.5"
          tracking={{
            label: 'continue with email',
            location: 'sign in',
          }}
        >
          Continue with email
        </Button>
      </form>
    </>
  );
};
