import {ProfilesTable} from '../../components/profiles-table';
import {BffFilterOptions} from '../../types';
import {UseProfilesTableResult} from './usePeopleTable';

type PeopleListTableProps = {
  filters: BffFilterOptions;
  tableData: UseProfilesTableResult;
  onChangeSuccess: () => void;
  totalCount: number;
};

export const PeopleListTable = ({filters, tableData, onChangeSuccess, totalCount}: PeopleListTableProps) => (
  <ProfilesTable
    filters={filters}
    tableData={tableData}
    totalCount={totalCount}
    onChangeSuccess={onChangeSuccess}
    bulkActionsConfig={{
      addToPipeline: true,
      tags: true,
      crmExport: true,
    }}
  />
);
