import {MouseEvent} from 'react';
import {useIntercom} from 'react-use-intercom';
import {Role} from '../../../types';
import {capitalizeString} from '../../../utils';
import {permissions} from '../add-members-form/const';
import Styles from './AddMembersModal.module.scss';

const LEARN_MORE_ARTICLE_ID = 7065679;

type Props = {
  role: Role;
};
export const RolePermissionsDescription = ({role}: Props) => {
  const {showArticle} = useIntercom();
  const openLearnMore = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    showArticle(LEARN_MORE_ARTICLE_ID);
  };

  return (
    <div>
      <h4 className={Styles.permissionsHeader}>{capitalizeString(role)} permissions:</h4>

      <ul className={Styles.permissionsList}>
        {permissions[role].map((permission, index) => (
          <li key={index}>{permission}</li>
        ))}
      </ul>

      <p className={Styles.permissionsLearnMoreText}>
        You can switch permissions later on.{' '}
        <a
          className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
          onClick={openLearnMore}
        >
          Learn more
        </a>{' '}
      </p>
    </div>
  );
};
