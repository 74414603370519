import {ReactNode, useEffect, useState} from 'react';
import {HideableContent} from './HideableContent';

type Props = {
  children: ReactNode;
  delayMs?: number;
  className?: string;
};

export const DelayedAppearingContent = ({children, delayMs = 0, className}: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), delayMs);
  }, [delayMs]);

  return (
    <HideableContent visible={visible} className={className}>
      {children}
    </HideableContent>
  );
};
