export const useHash = () => {
  const getHash = (): string => {
    const {hash} = window.location;
    return hash;
  };

  const setHash = (hash: string): void => {
    window.location.replace(`#${hash}`);
  };

  const removeHash = (): void => {
    window.location.replace('#');
  };

  return {
    getHash,
    setHash,
    removeHash,
  };
};
