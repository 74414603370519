import {useCallback, useEffect, useState} from 'react';
import {useDebounce, useSearchParamsHelpers} from '../../hooks';

export const useTableSearch = (saveInUrl = true) => {
  const {getFromSearchParam, saveInSearchParam} = useSearchParamsHelpers({
    searchParamName: 'query',
    replaceHistory: true,
  });

  const [searchQuery, setSearchQuery] = useState(getFromSearchParam() || '');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const onClearSearchQuery = useCallback(() => setSearchQuery(''), []);

  useEffect(() => {
    if (!saveInUrl) return;
    saveInSearchParam(debouncedSearchQuery);
  }, [debouncedSearchQuery, saveInUrl, saveInSearchParam]);

  return {
    searchQuery,
    searchQueryFetchParam: debouncedSearchQuery,
    onChangeSearchQuery: setSearchQuery,
    onClearSearchQuery,
  };
};
