import lottie from 'lottie-web';
import {useEffect, useRef} from 'react';

type PropsType = Readonly<{
  animationData: Record<string, unknown>;
  loop?: boolean;
  autoplay?: boolean;
  className?: string;
}>;

export function LottieAnimation(props: PropsType) {
  const {animationData, loop = true, autoplay = true, className} = props;
  const loaded = useRef(false);
  const animationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!animationRef.current || loaded.current) {
      return;
    }

    lottie.loadAnimation({
      animationData,
      loop,
      autoplay,
      container: animationRef.current,
      renderer: 'svg',
    });

    loaded.current = true;
  }, [animationData, loop, autoplay]);

  return <div ref={animationRef} className={className} />;
}
