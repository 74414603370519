import {LottieAnimation} from '../lottie-animation';
import loadingProgressBar from './loadingProgressBar.json';

type Props = {
  className?: string;
};

export const LoadingProgressBar = ({className}: Props) => {
  return <LottieAnimation animationData={loadingProgressBar} className={className} />;
};
