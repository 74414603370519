import {TrialNotification} from '../../components/trial-notification';
import {useCurrentUserSession} from '../../hooks';
import {AppLayout} from '../../layouts';
import {Dashboard} from './Dashboard';
import Styles from './DashboardPage.module.css';

export const DashboardPage = () => {
  const {user} = useCurrentUserSession();

  return (
    <AppLayout>
      <div className={Styles.pageWrapper}>
        <div className={Styles.contentWrapper}>
          <TrialNotification />
          <Dashboard user={user} />
        </div>
      </div>
    </AppLayout>
  );
};
