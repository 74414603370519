import {FiUserPlus as AddPersonIcon} from 'react-icons/fi';
import {Button} from '../../../components/button';
import {useImportConnectionsModal} from '../../../contexts';

export const HeaderButtons = () => {
  const {showImportModal} = useImportConnectionsModal();

  return (
    <Button
      icon={<AddPersonIcon />}
      onClick={() => showImportModal()}
      variant="primary"
      data-intercom-target="Add connections"
      tracking={{
        label: 'add connections',
        location: 'my connections top bar',
      }}
    >
      Add connections
    </Button>
  );
};
