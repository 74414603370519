import cs from 'classnames';
import {ReactNode, useCallback, useState} from 'react';
import {FiClock as Clock, FiInfo as Info, FiAlertTriangle as Warning, FiX as X} from 'react-icons/fi';
import {PremiumStarsIcon} from '../icons';
import Styles from './Notification.module.scss';

type Props = {
  color?: 'default' | 'yellow' | 'red' | 'green' | 'black';
  symbol?: 'info' | 'warning' | 'premium' | 'clock';
  title?: string | ReactNode;
  closable?: boolean;
  onClose?: () => void;
  className?: string;
  children: ReactNode;
};

const Premium = () => <PremiumStarsIcon className="h-4" />;

const symbols = {
  info: <Info />,
  warning: <Warning />,
  premium: <Premium />,
  clock: <Clock />,
};

export const ColorsMap: Record<NonNullable<Props['color']>, Record<'background' | 'border', string>> = {
  default: {background: 'bg-white', border: 'border-swarm-gray-400'},
  yellow: {background: 'bg-swarm-yellow-light', border: 'border-swarm-yellow-darken'},
  red: {background: 'bg-swarm-red-light', border: 'border-swarm-red-darken'},
  green: {background: 'bg-swarm-green-light', border: 'border-swarm-green-darken'},
  black: {background: 'bg-swarm-black', border: 'border-swarm-black'},
};

export const Notification = ({
  color = 'default',
  symbol,
  title,
  closable,
  onClose,
  className,
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeNotification = useCallback(() => {
    onClose?.();
    setIsOpen(false);
  }, [onClose]);

  return !isOpen ? null : (
    <div
      className={cs(
        Styles.notification,
        ColorsMap[color].background,
        ColorsMap[color].border,
        {[Styles.black]: color === 'black'},
        {[Styles.closable]: closable},
        className
      )}
    >
      {closable && <X className={Styles.close} size={20} onClick={() => closeNotification()} />}
      {title && (
        <div className={Styles.notificationHeading}>
          {symbol && symbols[symbol]}
          <h3>{title}</h3>
        </div>
      )}
      <div className={Styles.notificationContent}>
        {!title && symbol && <div className={Styles.iconInContent}>{symbols[symbol]}</div>}
        <div className={Styles.children}>{children}</div>
      </div>
    </div>
  );
};
