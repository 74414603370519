import {sanitize} from 'dompurify';
import {linkify} from '../../../utils';
import Styles from './MaybePlaceholder.module.scss';

export const MaybePlaceholder = ({
  value,
  isLink,
  placeholder = 'empty',
  asHtml,
}: {
  value: string;
  isLink?: boolean;
  placeholder?: string;
  asHtml?: boolean;
}) => {
  if (!value) {
    return <span className={Styles.placeholder}>{placeholder}</span>;
  }
  if (isLink) {
    return (
      <a href={value} target="_blank" rel="noreferrer" className={Styles.link}>
        {value}
      </a>
    );
  }
  return asHtml ? (
    <div className={Styles.paragraph} dangerouslySetInnerHTML={{__html: linkify(sanitize(value))}} />
  ) : (
    <span className={Styles.paragraph}>{value}</span>
  );
};
