import {List} from '../../../components/list';
import {integrationsListData} from './consts';
import {Integration} from './Integration';

export const Integrations = () => {
  return (
    <List
      items={integrationsListData.map(item => (
        <Integration {...item} key={item.crmName} />
      ))}
    />
  );
};
