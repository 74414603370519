import cs from 'classnames';
import {Checks as TitleIcon} from 'phosphor-react';
import {CheckMark, XMark} from '../../marks';
import {Pill} from '../../pill';
import {Textarea} from '../textarea';
import {CommonProps} from '../types';
import {Message} from '../utils';
import Styles from './MatchingLinesInput.module.scss';

type Props = CommonProps & {
  value: string[];
  onChange: (lines: string[]) => void;
  validateSingleLineHandler?: (line: string) => boolean;
  info?: {
    title: string;
    message: string;
    successMessage: string;
    failMessage: string;
  };
  isInvalid?: boolean;
  message?: string;
  className?: string;
};

export const MatchingLinesInput = ({
  value,
  onChange,
  validateSingleLineHandler,
  placeholder,
  info,
  isInvalid,
  message,
  className,
}: Props) => {
  const lines = value.map(line => line.trim()).filter(line => line.length);

  const invalidLines = lines.filter(line => !validateSingleLineHandler?.(line));

  const hasLines = Boolean(lines.length);
  const hasErrors = invalidLines.length > 0;
  const isValid = hasLines && !isInvalid && !hasErrors;

  return (
    <>
      <Textarea
        value={value.join('\n')}
        onChange={e => onChange(e.target.value.split('\n').map(line => line.trim()))}
        placeholder={placeholder}
        rows={6}
        isInvalid={isInvalid || hasErrors}
        className={cs(Styles.textarea, className)}
      />
      {info && (
        <div className={Styles.info}>
          <h3 className={Styles.infoTitle}>
            <TitleIcon /> {info.title} {hasLines && <Pill>{lines.length}</Pill>}
          </h3>
          {!hasLines && <p className={Styles.message}>{info.message}</p>}
          {isValid && (
            <p className={cs(Styles.message, Styles.messageSuccess)}>
              <CheckMark shape="circle" size="x-small" />
              {info.successMessage}
            </p>
          )}
          {hasErrors && (
            <p className={cs(Styles.message, Styles.messageFail)}>
              <XMark shape="circle" size="x-small" />
              {info.failMessage}
            </p>
          )}
        </div>
      )}
      {message && <Message message={message} hasError={isInvalid} className="mt-2" />}
    </>
  );
};
