import {useCallback} from 'react';
import {FiPlus as AddIcon, FiSearch as SearchIcon} from 'react-icons/fi';
import {deleteTag, useQueryTags} from '../../../api';
import {Button} from '../../../components/button';
import {Input} from '../../../components/form';
import {useTableSearch, useTableSorting} from '../../../components/table';
import {TagModal, useTagModal} from '../../../components/tags';
import {useConfirm} from '../../../hooks';
import {sortingStateToSort} from '../../../utils';
import Styles from './TagsManagement.module.scss';
import {TagsTable} from './TagsTable';

export const TagsManagement = () => {
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery} = useTableSearch();
  const sorting = useTableSorting([{id: 'createdAt', desc: true}]);
  const {isConfirmed} = useConfirm();

  const {
    data: tags,
    refetch: refetchTags,
    isLoading,
  } = useQueryTags({
    query: searchQuery,
    sort: sortingStateToSort(sorting.sortingState),
  });
  const {tagModalVisible, closeTagModal, openTagModal, tagToEdit, setTagToEdit} = useTagModal();

  const createTag = useCallback(() => {
    setTagToEdit(undefined);
    openTagModal();
  }, [openTagModal, setTagToEdit]);

  const editTag = useCallback(
    (tagId: string) => {
      setTagToEdit(tagId);
      openTagModal();
    },
    [openTagModal, setTagToEdit]
  );

  const removeTag = useCallback(
    async (tagId: string) => {
      const deletionConfirmed = await isConfirmed('Are you sure you want to remove this tag?', 'removal');
      if (deletionConfirmed) {
        deleteTag(tagId).then(() => refetchTags());
      }
    },
    [refetchTags, isConfirmed]
  );

  return (
    <>
      <div className={Styles.header}>
        <h2 className={Styles.heading}>Manage tags</h2>
        <div>
          <Input
            icon={SearchIcon}
            type="text"
            value={searchQuery}
            onChange={e => onChangeSearchQuery(e.target.value)}
            onClear={onClearSearchQuery}
            placeholder="Search"
            intercomTarget="search"
          />
        </div>
        <Button
          variant="primary"
          size="md"
          onClick={createTag}
          icon={<AddIcon />}
          tracking={{
            label: 'create new tag',
            location: 'manage tags page',
          }}
        >
          Create new tag
        </Button>
      </div>
      <div className={Styles.content}>
        {tags && (
          <TagsTable
            tags={tags}
            isLoading={isLoading}
            editTag={editTag}
            removeTag={removeTag}
            sorting={sorting}
          />
        )}
      </div>
      <TagModal
        visible={tagModalVisible}
        onClose={closeTagModal}
        tagId={tagToEdit}
        onSaveSuccess={refetchTags}
      />
    </>
  );
};
