import classNames from 'classnames';
import {useEffect} from 'react';
import {useAuthenticated} from '../../containers/Authenticated';

export const useRedirectIfAuthenticated = () => {
  const {authenticated, loading} = useAuthenticated();

  const hideContent = authenticated || loading;
  const className = classNames('transition-opacity', {
    'opacity-0': hideContent,
    'opacity-100': !hideContent,
  });

  useEffect(() => {
    if (authenticated) {
      window.location.href = '/';
    }
  }, [authenticated]);

  return {className};
};
