import cs from 'classnames';
import Styles from './SignInWithGoogleButton.module.scss';

type Props = {
  onClick: () => void;
  className?: string;
  label?: string;
};

export const SignInWithGoogleButton = ({onClick, label = 'Continue with Google', className}: Props) => {
  return (
    <button className={cs(Styles.googleButton, className)} onClick={onClick}>
      <div className={Styles.logo}>
        <img src="/icons/google.svg" alt="Google logo" />
      </div>
      <span className={Styles.label}>{label}</span>
    </button>
  );
};
