import {SuitcaseSimple} from 'phosphor-react';
import {HideableContent} from '../../common';
import {FindOutMoreItem} from './FindOutMoreItem';

type FindOutMoreProps = {
  visible: boolean;
};
export const FindOutMoreContent = ({visible}: FindOutMoreProps) => (
  <HideableContent visible={visible} className="py-2">
    <FindOutMoreItem
      title="How it works?"
      text={
        'By analyzing the companies you\'ve worked for, your roles, school, and timeframes, we\'ll find hundreds of "overlaps" and match them with our extensive database of millions of profiles!'
      }
      icon={SuitcaseSimple}
    />
  </HideableContent>
);
