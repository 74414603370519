import {BffFilterOptions, BulkActionsType, ProfilesListRow} from '../../../types';
import {BulkActions} from './bulk-actions';
import {MassActions} from './mass-actions';

type BulkActionsWrapperProps = {
  filters: BffFilterOptions;
  selectedProfiles: ProfilesListRow[];
  onBulkActionSuccess: () => void;
  actions: BulkActionsType;
  totalCount: number;
  disabled?: boolean;
  massActionsMode?: boolean;
};

export const BulkActionsWrapper = ({
  filters,
  selectedProfiles,
  onBulkActionSuccess,
  actions,
  totalCount,
  disabled,
  massActionsMode,
}: BulkActionsWrapperProps) => {
  return massActionsMode ? (
    <MassActions
      actions={actions}
      filters={filters}
      totalCount={totalCount}
      onBulkActionSuccess={onBulkActionSuccess}
    />
  ) : (
    <BulkActions
      selectedProfiles={selectedProfiles}
      onBulkActionSuccess={onBulkActionSuccess}
      actions={actions}
      disabled={disabled}
    />
  );
};
