import {useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Confirmation} from '../../components/auth';
import {AuthContextProvider} from '../../contexts';
import {useRedirectBackDirection} from '../../hooks';
import {AuthLayout} from '../../layouts';
import {useRedirectIfAuthenticated} from './useRedirectIfAuthenticated';
import {Welcome} from './welcome';

export const AuthPage = () => {
  const {saveRedirectBackPath} = useRedirectBackDirection();
  const {className} = useRedirectIfAuthenticated();
  const [step, setStep] = useState<'signIn' | 'confirm'>('signIn');

  const goToConfirm = useCallback(() => setStep('confirm'), []);

  useEffect(() => {
    saveRedirectBackPath();
  }, [saveRedirectBackPath]);

  return (
    <AuthContextProvider>
      <AuthLayout className={className}>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Sign in - The Swarm</title>
        </Helmet>
        {step === 'signIn' && <Welcome afterSignIn={goToConfirm} />}
        {step === 'confirm' && <Confirmation />}
      </AuthLayout>
    </AuthContextProvider>
  );
};
