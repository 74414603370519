import cs from 'classnames';
import {cloneElement, isValidElement, ReactElement, ReactNode, useState} from 'react';
import {slugify} from '../../utils';
import {ExpandButton} from '../expand-button';
import Styles from './List.module.scss';

type Props = {
  items: ReactNode[];
  headerTitle?: string;
  headerIcon?: ReactNode;
  headerSlot?: ReactNode;
  itemClassName?: string;
  className?: string;
  initialAmount?: number;
  cols?: 2 | 3 | 4 | 5;
  bottomBorder?: boolean;
};

export const List = ({
  items = [],
  headerTitle,
  headerIcon,
  headerSlot,
  className,
  itemClassName,
  initialAmount,
  cols,
  bottomBorder,
}: Props) => {
  const [expanded, setExpanded] = useState(initialAmount ? initialAmount > items.length : true);
  const itemsToRender = expanded ? items : items.slice(0, initialAmount);
  const intercomValue = headerTitle ? 'header-' + slugify(headerTitle) : undefined;

  const renderHeader = headerTitle || headerSlot;

  return (
    <div className={className}>
      {renderHeader && (
        <div className={Styles.listHeader}>
          {headerTitle ? (
            <h4 className={Styles.listHeaderTitle} data-intercom-target={intercomValue}>
              {isValidElement(headerIcon)
                ? cloneElement(headerIcon as ReactElement, {className: Styles.listHeaderTitleIcon})
                : null}
              {headerTitle}
            </h4>
          ) : null}
          {headerSlot}
        </div>
      )}
      <ul
        className={cs(Styles.listItemsWrapper, {
          'grid gap-2': cols,
          'grid-cols-2': cols === 2,
          'grid-cols-3': cols === 3,
          'grid-cols-4': cols === 4,
          'grid-cols-5': cols === 5,
        })}
      >
        {itemsToRender.map((item, index) => (
          <li
            key={index}
            className={cs(Styles.listItem, itemClassName, {
              [Styles.listItemWithBottomBorder]: bottomBorder,
            })}
          >
            {item}
          </li>
        ))}
      </ul>
      {initialAmount && initialAmount < items.length && (
        <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
      )}
    </div>
  );
};
