import {fillTemplate} from '../../../utils';

export const getIntroBody = (
  message: string,
  bodyTemplateVars: Record<string, string>,
  includeForwardable: boolean,
  selectedPipelineForwardable?: string
) =>
  fillTemplate({templateString: message, vars: bodyTemplateVars, withSanitization: true}) +
  (includeForwardable ? '\n\n---\n\n' + selectedPipelineForwardable : '');
