export const isString = (input: unknown): input is string => {
  return typeof input === 'string';
};

export const capitalizeString = (input: string): string => {
  if (!input) {
    return '';
  }
  return input[0].toUpperCase() + input.substring(1);
};

export const getInitialsFromNames = (firstName?: string, lastName?: string) => {
  return [firstName?.[0], lastName?.[0]].join('');
};

export const slugify = (input: string) => {
  return input
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const contains = (input: string, search: string) => {
  return input.toLowerCase().includes(search.toLowerCase());
};
