import {useCallback} from 'react';
import {getConfig, goToChromeExtension} from '../../../../utils';
import {getExtensionVersion, getUserBlueUrl} from '../../../../utils/extensionCommunication';
import {InitialScreen} from './install-extension-screen';
import {ConnectedToLinkedinScreen} from './install-extension-screen/ConnectedToLinkedinScreen';
import {InstallationTipsScreen} from './install-extension-screen/InstallationTipsScreen';
import {LoginToLinkedinScreen} from './install-extension-screen/LoginToLinkedinScreen';
import {InstallationWizardSteps} from './WorkExperienceStep';

type Props = {
  onSkip: () => void;
  setExtensionVersion: (version: string) => void;
  setBlueAuthenticated: (authenticated: boolean) => void;
  setBlueUserUrl: (url: string) => void;
  wizardStep: InstallationWizardSteps;
  setWizardStep: (step: InstallationWizardSteps) => void;
};

const {envName} = getConfig();

export const InstallExtensionScreen = ({
  onSkip,
  setExtensionVersion,
  setBlueAuthenticated,
  setBlueUserUrl,
  wizardStep,
  setWizardStep,
}: Props) => {
  const onInstall = useCallback(() => {
    getExtensionVersion().then(version => {
      setExtensionVersion(version);
      if (version) {
        setWizardStep(InstallationWizardSteps.LoginToLinkedin);
      } else {
        setWizardStep(InstallationWizardSteps.InstallationTips);
      }
      if (envName !== 'production') {
        window.location.reload(); // unnecessary on production, as installing extension from web store refreshes tabs. just for bee testing purposes
      }
    });
    goToChromeExtension();
  }, [setExtensionVersion, setWizardStep]);

  const onGetUserBlueUrl = useCallback(() => {
    getUserBlueUrl().then(url => {
      if (url) {
        setBlueUserUrl(url);
      } else {
        onSkip();
      }
    });
  }, [onSkip, setBlueUserUrl]);

  switch (wizardStep) {
    case InstallationWizardSteps.Initial:
      return (
        <InitialScreen onSkip={onSkip} onInstall={onInstall} setExtensionVersion={setExtensionVersion} />
      );
    case InstallationWizardSteps.InstallationTips:
      return <InstallationTipsScreen onSkip={onSkip} onInstall={onInstall} />;
    case InstallationWizardSteps.LoginToLinkedin:
      return <LoginToLinkedinScreen onSkip={onSkip} setBlueAuthenticated={setBlueAuthenticated} />;
    case InstallationWizardSteps.ConnectedToLinkedin:
      return <ConnectedToLinkedinScreen onGetUserBlueUrl={onGetUserBlueUrl} />;
    default:
      return (
        <InitialScreen onSkip={onSkip} onInstall={onInstall} setExtensionVersion={setExtensionVersion} />
      );
  }
};
