import {useCallback} from 'react';
import {useMutationCrmCompanyExport, useMutationCrmProfileExport} from '../../api';
import {Crm} from '../../types';
import {integrationNameMap, segmentTrack, trackError} from '../../utils';
import {LoadingSpinner} from '../animations';
import {CrmIcon} from '../crm-icon';
import {Dropdown} from '../dropdown';
import {notify} from '../notifications';

type Props = {
  crm: Crm;
  integrationId: string;
  onExportSuccess?: (crm: Crm) => void;
  targetIds: string[];
  targetType: 'profile' | 'company';
};

export const ExportMenuItem = ({crm, integrationId, onExportSuccess, targetIds, targetType}: Props) => {
  const {mutateAsync: mutateCompanies, isLoading: isLoadingCompanies} = useMutationCrmCompanyExport({
    integrationId,
    companiesIds: targetIds,
  });

  const {mutateAsync: mutateProfiles, isLoading: isLoadingProfiles} = useMutationCrmProfileExport({
    integrationId,
    profilesIds: targetIds,
  });

  const mutateAsync = targetType === 'company' ? mutateCompanies : mutateProfiles;
  const isLoading = isLoadingCompanies || isLoadingProfiles;

  const onClick = useCallback(async () => {
    const isBulkAction = targetIds.length > 1;

    isBulkAction
      ? segmentTrack('Bulk Action Applied', {
          label: 'crm_export',
          profile_count: targetIds.length,
          integration: crm,
        })
      : segmentTrack('Selector Applied', {
          label: 'crm_export',
          integration: crm,
        });

    mutateAsync()
      .then(() => {
        const successMessage = isBulkAction
          ? `${targetIds.length} ${targetType === 'company' ? 'companies' : 'profiles'} exported to ${integrationNameMap[crm]} CRM`
          : `The ${targetType} exported to ${integrationNameMap[crm]} CRM`;
        notify(successMessage);
        onExportSuccess?.(crm);
      })
      .catch(e => {
        notify('Something went wrong');
        trackError(e);
      });
  }, [targetIds.length, crm, mutateAsync, targetType, onExportSuccess]);

  return (
    <Dropdown.Item
      label={integrationNameMap[crm] + ' CRM'}
      icon={isLoading ? <LoadingSpinner /> : <CrmIcon crm={crm} />}
      type={{
        name: 'button',
        onClick,
        disabled: isLoading,
      }}
    />
  );
};
